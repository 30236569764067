import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Link } from '../Link';
import { SearchHeader } from './SearchHeader';
import { SearchInput } from './SearchInput';
import { SearchResults } from './SearchResults';
import { SearchSuggestions } from './SearchSuggestions';
import { useAlgoliaResults, useBodyScrollLock } from '../../hooks';
import { useGlobalContext } from '../../contexts';

export function Search() {
  const {
    state: { searchOpen },
    actions: { closeSearch, openSearch },
  } = useGlobalContext();

  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();

  useEffect(() => {
    if (searchOpen) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
  }, [searchOpen]);

  const [rawTerm, setRawTerm] = useState('');
  const [term, setTerm] = useState(rawTerm);
  const router = useRouter();

  const {
    dataResults,
    filtersResults,
    activeFilters,
    addFilter,
    removeFilter,
    changeSearchIndex,
    searchProductsNext,
    trackOnClick,
    trackOnClickFilter,
  } = useAlgoliaResults({
    term,
    mounted: searchOpen,
  });

  const hasProductResults =
    dataResults &&
    dataResults?.products &&
    dataResults?.products?.results?.length > 0;

  const hasCollectionResults =
    dataResults &&
    dataResults?.collections &&
    dataResults?.collections?.results?.length > 0;

  const hasPagesResults =
    dataResults &&
    dataResults?.pages &&
    dataResults?.pages?.results?.length > 0;

  const hasResults =
    hasProductResults || hasCollectionResults || hasPagesResults;

  useEffect(() => {
    // debounce raw term
    const handler = window.requestTimeout(() => {
      if (rawTerm) {
        router.query.modalQuery = rawTerm;
        router.push(router);
      }

      setTerm(rawTerm);
    }, 300);
    return () => {
      window.clearRequestTimeout(handler);
    };
  }, [rawTerm]);

  useEffect(() => {
    if (router.query.modalQuery) {
      openSearch();

      const handler = window.requestTimeout(() => {
        setTerm(router.query.modalQuery);
      }, 300);
      return () => {
        window.clearRequestTimeout(handler);
      };
    }
  }, [router]);

  const heightClasses = `h-full`;

  return (
    <>
      <div
        role="button"
        aria-hidden="true"
        aria-label="Close menu sidebar"
        tabIndex="-1"
        className={`fixed left-0 top-0 z-40 h-full w-full cursor-pointer bg-[rgba(0,0,0,0.3)] transition duration-200 ${
          searchOpen
            ? 'pointer-events-auto opacity-100'
            : 'pointer-events-none opacity-0'
        }`}
        onClick={() => closeSearch()}
      />

      <aside
        className={`fixed top-0 z-[70] flex h-full ${heightClasses} w-full flex-col overflow-hidden bg-background transition md:max-w-full  ${
          searchOpen
            ? 'pointer-events-auto translate-y-0 '
            : 'pointer-events-none -translate-y-full'
        }`}
      >
        {searchOpen && (
          <>
            <SearchHeader closeSearch={closeSearch} />

            <SearchInput
              closeSearch={closeSearch}
              rawTerm={rawTerm}
              searchOpen={searchOpen}
              setRawTerm={setRawTerm}
              hasResults={hasResults}
            />

            <SearchResults
              closeSearch={closeSearch}
              pagesResults={dataResults?.pages?.results || []}
              collectionResults={dataResults?.collections?.results || []}
              productResults={dataResults?.products?.results || []}
              filtersResults={filtersResults || []}
              activeFilters={activeFilters}
              addFilter={addFilter}
              removeFilter={removeFilter}
              changeSort={changeSearchIndex}
              searchProductsNext={searchProductsNext}
              rawTerm={rawTerm}
              trackOnClick={trackOnClick}
              trackOnClickFilter={trackOnClickFilter}
            />
          </>
        )}
      </aside>
    </>
  );
}

Search.displayName = 'Search';
