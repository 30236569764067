import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';

import {
  productTypeKey,
  sortAlphabetically,
  sortByCount,
  sortCustom,
  sortNumerically,
  updateFilterUrlParams,
} from './utils';

export function useCollectionFilters({
  enabledFilters = true,
  products = [],
  productsReady = true,
}) {
  const { isReady: routerIsReady, query } = useRouter();
  const settings = useSettings();
  const filtersSettings = { ...settings?.collection?.filters };

  const [activeFilters, setActiveFilters] = useState({});
  const [filters, setFilters] = useState([]);
  const [filtersMap, setFiltersMap] = useState({});

  const addFilter = useCallback(
    ({ key, value }) => {
      const updatedActiveFilters = { ...activeFilters };
      updatedActiveFilters[key] = updatedActiveFilters[key]
        ? [...updatedActiveFilters[key], value]
        : [value];
      setActiveFilters(updatedActiveFilters);
      updateFilterUrlParams({
        entriesToAdd: Object.entries(updatedActiveFilters),
      });
    },
    [activeFilters]
  );

  const removeFilter = useCallback(
    ({ key, value }) => {
      const updatedActiveFilters = { ...activeFilters };
      updatedActiveFilters[key] = updatedActiveFilters[key].filter(
        (item) => item !== value
      );
      if (updatedActiveFilters[key]?.length === 0)
        delete updatedActiveFilters[key];
      setActiveFilters(updatedActiveFilters);

      if (activeFilters[key]?.length === 1) {
        updateFilterUrlParams({
          keysToRemove: [key],
        });
      } else {
        updateFilterUrlParams({
          entriesToAdd: Object.entries(updatedActiveFilters),
        });
      }
    },
    [activeFilters]
  );

  const clearFilters = useCallback(() => {
    setActiveFilters({});
    updateFilterUrlParams({ keysToRemove: Object.keys(activeFilters) });
  }, [activeFilters]);

  const initialFiltersData = useMemo(() => {
    if (!enabledFilters || !filtersSettings.filters?.length) return null;

    // array of tag and option filter names set in customizer
    const tagFilters = [];
    const optionFilters = [];
    // set up initial filters map
    const _filtersMap = filtersSettings.filters.reduce(
      (
        acc,
        {
          customOrder,
          defaultOpenDesktop,
          defaultOpenMobile,
          isColor,
          isIcon,
          label,
          name,
          orderValuesBy,
          ranges: priceRanges,
          _template: source,
          usesColorGroups,
        }
      ) => {
        // ignore option and tag filters with no name
        if ((source === 'option' || source === 'tag') && !name) return acc;
        // filter name key
        let filterName;
        if (source === 'productType') {
          filterName = productTypeKey;
        } else if (source === 'price') {
          filterName = 'price';
        } else if (source === 'tag') {
          const _name = name?.trim();
          filterName = `${source}.${_name}`;
          tagFilters.push(_name);
        } else if (source === 'option') {
          const _name = name?.trim();
          filterName = `${source}.${_name}`;
          optionFilters.push(_name);
        }
        const filter = {
          name: filterName,
          label,
          isColor: isColor || false,
          isIcon: isIcon || false,
          usesColorGroups: usesColorGroups || false,
          defaultOpenDesktop: defaultOpenDesktop || false,
          defaultOpenMobile: defaultOpenMobile || false,
          orderValuesBy,
          customOrder,
          ...(source === 'price' ? { priceRanges } : null),
          source,
          values: [],
          valuesMap: {},
        };
        return { ...acc, [filter.name]: filter };
      },
      {}
    );

    return {
      tagFilters,
      optionFilters,
      filtersMap: _filtersMap,
    };
  }, [enabledFilters, filtersSettings.filters]);

  // sets up filters and options on collection load
  useEffect(() => {
    if (
      !enabledFilters ||
      !initialFiltersData ||
      !products.length ||
      !productsReady
    )
      return;
    const { tagFilters, optionFilters, filtersMap: initialFiltersMap } = initialFiltersData;
    const _filtersMap = { ...initialFiltersMap }; // Create a new instance of _filtersMap
    const colorGroups = filtersSettings.colorGroups || [];

    products.forEach(({ optionsMap, priceRange, productType, tags }) => {
      // product type options
      if (_filtersMap[productTypeKey] && productType) {
        _filtersMap[productTypeKey].valuesMap = {
          ..._filtersMap[productTypeKey].valuesMap,
          [productType]: {
            value: productType,
            count:
              (_filtersMap[productTypeKey].valuesMap[productType]?.count || 0) +
              1,
          },
        };
      }
      // price range options
      if (_filtersMap.price && priceRange?.min) {
        const price = parseFloat(priceRange.min);
        const range = _filtersMap.price.priceRanges?.find(({ min, max }) => {
          return price >= (min || 0) && price < (max || Infinity);
        })?.label;
        _filtersMap.price.valuesMap = {
          ..._filtersMap.price.valuesMap,
          [range]: {
            value: range,
            count: (_filtersMap.price.valuesMap[range]?.count || 0) + 1,
          },
        };
      }
      // tag filter options
      if (tagFilters?.length && tags?.length) {
        tags.filter((tag) => tag.includes('filter'))
          .forEach((tag) => {
            const filterFragments = tag.split('_')
            const key = filterFragments[1];
            const value = filterFragments[2] || '';
            if (value && tagFilters.includes(key)) {
              const filter = _filtersMap[`tag.${key}`];
              filter.valuesMap = {
                ...filter.valuesMap,
                [value]: {
                  value,
                  count: (filter.valuesMap[value]?.count || 0) + 1,
                },
              };
            }
          });
      }
      // option filter options
      Object.entries({ ...optionsMap }).forEach(([_key, values]) => {
        const key = _key.trim();
        if (optionFilters.includes(key)) {
          const filter = _filtersMap[`option.${key}`];
          filter.valuesMap = {
            ...filter.valuesMap,
            ...values.reduce((acc, valueItem) => {
              let value = valueItem;
              // if option filter uses color groups, find matching color group
              if (filter.usesColorGroups) {
                const colorGroup = colorGroups.find((group) =>
                  group?.colors?.some(
                    (color) =>
                      color.toLowerCase().trim() === value.toLowerCase()
                  )
                );
                if (!colorGroup) return acc;
                value = colorGroup.name;
              }

              return {
                ...acc,
                [value]: {
                  value,
                  count: (filter.valuesMap[value]?.count || 0) + 1,
                },
              };
            }, {}),
          };
        }
      });
    });
    // sort options
    Object.values(_filtersMap).forEach((filter) => {
      const values = Object.values(filter.valuesMap);
      if (filter.source === 'price') {
        _filtersMap[filter.name].values = sortCustom({
          values,
          sortOrder: filter.priceRanges?.map(({ label }) => label) || [],
        });
      } else if (filter.orderValuesBy === 'priceRange') {
        _filtersMap[filter.name].values = sortCustom({
          values,
          sortOrder: filter.priceRanges.map(({ label }) => label),
        });
      } else if (filter.orderValuesBy === 'alphabet') {
        _filtersMap[filter.name].values = sortAlphabetically({ values });
      } else if (filter.orderValuesBy === 'number') {
        _filtersMap[filter.name].values = sortNumerically({ values });
      } else if (filter.orderValuesBy === 'count') {
        _filtersMap[filter.name].values = sortByCount({ values });
      } else if (filter.orderValuesBy === 'custom') {
        _filtersMap[filter.name].values = sortCustom({
          values,
          sortOrder: filter.customOrder,
        });
      } else {
        _filtersMap[filter.name].values = values;
      }
      delete _filtersMap[filter.name].orderValuesBy;
      delete _filtersMap[filter.name].customOrder;
    });

    setFilters(Object.values(_filtersMap));
    setFiltersMap(_filtersMap);
  }, [
    enabledFilters,
    filtersSettings.colorGroups,
    initialFiltersData,
    productsReady,
  ]);

  // sets filters on page load
  useEffect(() => {
    if (!routerIsReady || !Object.keys({ ...filtersMap }).length) return;
    const filtersFromParams = Object.entries({ ...query }).reduce(
      (acc, [key, value]) => {
        if (key === 'handle') return acc;
        const values = value.split(',');
        const valuesMap = filtersMap[key]?.valuesMap;
        values.forEach((valuesItem) => {
          if (!valuesMap?.[valuesItem]) return;
          if (!acc[key]) acc[key] = [];
          acc[key] = [...acc[key], valuesItem];
        });
        return acc;
      },
      {}
    );
    setActiveFilters(filtersFromParams);
  }, [routerIsReady, filtersMap]);

  // clear filters state on unmount
  useEffect(() => {
    return () => {
      setActiveFilters({});
      setFilters([]);
      setFiltersMap({});
    };
  }, []);

  return {
    state: {
      activeFilters,
      filters,
      filtersMap,
    },
    actions: {
      addFilter,
      removeFilter,
      clearFilters,
    },
  };
}
