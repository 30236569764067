import { useMemo } from 'react';

import { Image, Link, Markdown } from '../../snippets';

export function ProductCompareContent({
  aboveTheFold,
  content,
  selectedOptionIndex,
}) {
  const {
    alignmentDesktop,
    alignmentMobile,
    buttons,
    color,
    heading,
    maxWidthDesktop,
    subtext,
    superheading,
    productFeatures,
  } = content;

  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;

  const headingWithBreaks = useMemo(() => {
    const splitHeading = heading?.split('\n');
    if (splitHeading?.length === 1) return heading;
    return splitHeading?.reduce((acc, line, index, arr) => {
      acc.push(<span key={index}>{line}</span>);
      if (index < arr.length - 1) acc.push(<br key={`br${index}`} />);
      return acc;
    }, []);
  }, [heading]);

  const product = productFeatures[selectedOptionIndex];
  return (
    <div className="px-contained py-contained w-full">
      <div
        className={`mx-auto flex flex-col gap-4 ${alignmentClasses} ${maxWidthDesktop}`}
        style={{ color }}
      >
        <div>
          {superheading && (
            <p className="text-superheading mb-1 lg:mb-1.5">{superheading}</p>
          )}

          {aboveTheFold ? (
            <h1 className="h3">{headingWithBreaks}</h1>
          ) : (
            <h2 className="h3">{headingWithBreaks}</h2>
          )}
        </div>

        {subtext && (
          <div className="[&_p]:text-body [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base">
            <Markdown>{subtext}</Markdown>
          </div>
        )}

        <div className="flex flex-col justify-start gap-x-10 md:max-h-60 md:min-h-[15rem] md:flex-wrap">
          {product?.features?.map(({ image, description }, index) => (
            <div className="my-1 flex flex-row gap-5" key={description + index}>
              <Image
                key={image?.alt}
                alt={image?.alt}
                className={`${image?.positionDesktop} relative h-[25px] w-[25px]`}
                height={25}
                width={25}
                priority={aboveTheFold}
                src={image?.imageDesktop.src}
              />
              <span className="text-base tracking-tight md:text-[14px]">
                {description ?? ''}
              </span>
            </div>
          ))}
        </div>

        {buttons?.length > 0 && (
          <ul className="mt-4 flex justify-center gap-4">
            {buttons.slice(0, 2).map(({ link, style }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-label={link?.text}
                    className={style}
                    href={link?.url}
                    newTab={link?.newTab}
                    type={link?.type}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}
