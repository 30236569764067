import { useCallback, useEffect, useRef, useState } from 'react';
import { useCart, useCurrency } from '@backpackjs/storefront';
import { nanoid } from 'nanoid';

import { mapLineItem } from './utils';

export function useDataLayerCustomer({ DEBUG, userProperties }) {
  const cart = useCart();
  const currencyCode = useCurrency();
  const [userDataEventTriggered, setUserDataEventTriggered] = useState(false);

  const userDataEvent = useCallback(
    ({ currencyCode: passedCurrencyCode, userProperties: _userProperties }) => {
      
      nostojs((api) => {
        api.defaultSession().setCustomer({
          customer_reference: _userProperties?.customer_id,
          email: _userProperties?.customer_email,
          first_name: _userProperties?.customer_first_name,
          last_name: _userProperties?.customer_last_name,
          newsletter: false,
        });
      });

      if (DEBUG) console.log(`NOSTO user_data`);
      setUserDataEventTriggered(true);
    },
    [cart?.updatedAt, currencyCode]
  );

  // Trigger 'user_data' event on path change after base data is ready
  useEffect(() => {
    if (!userProperties) return undefined;

    userDataEvent({ userProperties });
  }, [userProperties]);

  return { userDataEvent, userDataEventTriggered };
}
