export function Schema() {
  return {
    category: 'Product',
    label: 'Product Reviews',
    key: 'product-reviews',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/Screenshot_2023-11-28_at_11.02.04_AM.png?v=1701190958',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Products Slider Heading',
      },
      {
        label: 'Subheading',
        name: 'subheading',
        component: 'text',
      },
      {
        label: 'Button',
        name: 'button',
        component: 'text',
      },
      {
        label: 'Slider Settings',
        name: 'slider',
        component: 'group',
        description: 'Slider style, slides per view',
        fields: [
          {
            label: 'Slider Style',
            name: 'sliderStyle',
            component: 'select',
            description:
              'Loop and centered settings only apply if the number of products is at least twice the number of slides per view',
            options: [
              { label: 'Contained', value: 'contained' },
              { label: 'Contained w/ Loop', value: 'containedWithLoop' },
              { label: 'Full Bleed, Centered w/ Loop', value: 'fullBleed' },
              {
                label: 'Full Bleed, Centered w/ Loop & Gradient',
                value: 'fullBleedWithGradient',
              },
            ],
          },
          {
            label: 'Slides Per View (desktop)',
            name: 'slidesPerViewDesktop',
            component: 'number',
          },
          {
            label: 'Slides Per View (mobile)',
            name: 'slidesPerViewMobile',
            component: 'number',
            description: 'Tip: use decimals to show partial slides',
          },
        ],
        defaultValue: {
          sliderStyle: 'contained',
          slidesPerViewDesktop: 3,
          slidesPerViewMobile: 1,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Button style, full width',
        fields: [
          {
            label: 'Button Style',
            name: 'buttonStyle',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width from contained styles',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          buttonStyle: 'btn-primary',
          fullWidth: false,
        },
      },
    ],
  };
}
