import { TEXT_COLORS } from './common';

export default {
  label: 'Apps',
  name: 'apps',
  component: 'group',
  description: 'Manage different 3rd party apps integrations',
  fields: [
    {
      component: 'group',
      name: 'cashback',
      label: 'Cashback',
      description: 'Manage cashback settings.',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          description: 'Enabe disable cashback functionality',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          component: 'select',
          name: 'promoType',
          label: 'Promotion Type',
          options: [
            {
              label: 'Sitewide',
              value: 'site'
            },
            {
              label: 'URL Based',
              value: 'url'
            },
          ],
        },
        {
          component: 'text',
          name: 'utmParam',
          label: 'UTM Parameter',
          description: 'UTM Parameter used on the URL Based mode (Ex: “welcome20”)',
        },
        {
          component: 'number',
          name: 'cashbackPercentage',
          label: 'Cashback Percentage',
          description: 'Use this field to define the percentage used to calculate the possible cashback obtained to the checkout (The final cashback can be different due to some extra logic that cashback does on the checkout and can not be controlled in the headless site.',
        },
        {
          component: 'productSearch',
          name: 'cashbackProduct',
          label: 'Cashback Product',
          description: 'Select cashback product created on Fondue app.',
        },
        {
          component: 'text',
          name: 'variantId',
          label: 'Cashback Variant ID',
          description: '(Required) Use this field to define which variant will be used on cashback cart item.',
        },
        {
          component: 'text',
          name: 'cartMessage',
          label: 'Cart Item Message',
          description: 'Customize the Cart Item experience with a custom Message',
        },
        {
          component: 'link',
          name: 'redeemTermsLink',
          label: 'Modal Redeem Terms Link',
          defaultValue: {
            text: 'Redemtion Terms',
            url: '/'
          }
        }
      ]
    },
    {
      component: 'group',
      name: 'inveterate',
      label: 'Inveterate',
      description: 'Manage Inveterate settings.',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          description: 'Enabe disable Inveterate functionality',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          component: 'text',
          name: 'premiumVariantId',
          label: 'Inveterate Premium Variant ID',
          description:
            'Use this field to define a variant ID used by the Add To Cart Button.',
        },
        {
          component: 'text',
          name: 'sellingPlanId',
          label: 'Inveterate Selling Plan ID',
          description:
            'Use this field to define a default selling plan used by the Premium Tier information.',
        },
        {
          label: 'Enable PDP Callout Banner',
          name: 'pdpCalloutEnabled',
          component: 'toggle',
          description: 'Enabe disable Inveterate PDP Callout Banner',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          component: 'group',
          name: 'modal',
          label: 'Modal',
          description: 'Inveterate modal settings.',
          fields: [
            {
              component: 'text',
              name: 'heading',
              label: 'Heading',
            },
            {
              component: 'text',
              name: 'details',
              label: 'Details',
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'select',
              options: TEXT_COLORS.mobile,
            },
            {
              component: 'list',
              name: 'benefits',
              label: 'Benefits',
              field: {
                component: 'text',
              },
            },
            {
              label: 'Enable Add To Cart Button Mode',
              name: 'isBtnAtc',
              component: 'toggle',
              description: 'Enabe or disable the Add To Cart mode of the Modal Button, using this mode will add the Inveterate BYLT+ Tier product into the cart on clicking the button.',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              component: 'link',
              name: 'buttonLink',
              label: 'Button Link',
            },
            {
              label: 'Button Style',
              name: 'buttonStyle',
              component: 'select',
              options: [
                { label: 'Primary', value: 'btn-primary' },
                { label: 'Secondary', value: 'btn-secondary' },
                { label: 'Inverse Light', value: 'btn-inverse-light' },
                { label: 'Inverse Dark', value: 'btn-inverse-dark' },
              ],
            },
            {
              label: 'Enable Account Link',
              name: 'enableAccountLink',
              component: 'toggle',
              description: 'Enabe or disable account link',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              component: 'image',
              name: 'bgImage',
              label: 'Background Image',
            },
            {
              label: 'Content Style',
              name: 'contentStyle',
              component: 'select',
              options: [
                { label: 'Top & Bottom', value: 'justify-between' },
                { label: 'All Top', value: 'justify-start' },
                { label: 'All Bottom', value: 'justify-end' },
              ],
            },
          ],
        },
        {
          component: 'group',
          name: 'freeModal',
          label: 'Free Tier Modal',
          description: 'Inveterate modal settings.',
          fields: [
            {
              component: 'text',
              name: 'heading',
              label: 'Heading',
            },
            {
              component: 'text',
              name: 'details',
              label: 'Details',
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'select',
              options: TEXT_COLORS.mobile,
            },
            {
              component: 'list',
              name: 'benefits',
              label: 'Benefits',
              field: {
                component: 'text',
              },
            },
            {
              component: 'text',
              name: 'buttonText',
              label: 'CTA Button Text',
            },
            {
              label: 'Button Style',
              name: 'buttonStyle',
              component: 'select',
              options: [
                { label: 'Primary', value: 'btn-primary' },
                { label: 'Secondary', value: 'btn-secondary' },
                { label: 'Inverse Light', value: 'btn-inverse-light' },
                { label: 'Inverse Dark', value: 'btn-inverse-black' },
              ],
            },
            {
              component: 'text',
              name: 'sucessMessage',
              label: 'Sucess Message',
            },
            {
              label: 'Enable Account Link',
              name: 'enableAccountLink',
              component: 'toggle',
              description: 'Enabe or disable account link',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              component: 'image',
              name: 'bgImage',
              label: 'Background Image',
            },
            {
              label: 'Text Alignment',
              name: 'textAlign',
              component: 'select',
              options: [
                { label: 'Left', value: 'text-left' },
                { label: 'Center', value: 'text-center' },
                { label: 'Right', value: 'text-right' },
              ],
            },
            {
              label: 'Content Style',
              name: 'contentStyle',
              component: 'select',
              options: [
                { label: 'Top & Bottom', value: 'justify-between' },
                { label: 'All Top', value: 'justify-start' },
                { label: 'All Bottom', value: 'justify-end' },
              ],
            },
          ],
        },
        {
          component: 'group',
          name: 'accountBanner',
          label: 'Account Page Banner',
          description: 'Inveterate Account Page Banner settings.',
          fields: [
            {
              component: 'group',
              name: 'login',
              label: 'Logged-In Inveterate Premium State',
              description: 'Inveterate Account Page Banner settings.',
              fields: [
                {
                  label: 'Enable Custom Labels',
                  name: 'isCustom',
                  component: 'toggle',
                  toggleLabels: {
                    true: 'On',
                    false: 'Off',
                  }
                },
                {
                  component: 'markdown',
                  name: 'customBenefits',
                  label: 'Custom Benefits Text',
                }
              ]
            },
            {
              component: 'group',
              name: 'logout',
              label: 'Logged-Out State Inveterate Premium State',
              description: 'Inveterate Account Page Banner settings.',
              fields: [
                {
                  label: 'Enable Custom Labels',
                  name: 'isCustom',
                  component: 'toggle',
                  toggleLabels: {
                    true: 'On',
                    false: 'Off',
                  },
                },
                {
                  component: 'markdown',
                  name: 'customBenefits',
                  label: 'Custom Premium Benefits Text',
                },
                {
                  component: 'markdown',
                  name: 'customBasicBenefits',
                  label: 'Custom Basic Benefits Text',
                },
                {
                  component: 'text',
                  name: 'atcText',
                  label: 'Add To Cart Button Text',
                },
                {
                  component: 'link',
                  name: 'learnMoreLink',
                  label: 'Learn More Link',
                },
              ]
            }
          ]
        }
      ],
    },
  ],
};

