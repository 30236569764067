import { Svg } from '../Svg';

export function SearchHeader({ closeSearch }) {
  return (
    <div className="relative flex h-10 md:h-8 items-center justify-center bg-[#25293BE5] px-12">
      <button
        aria-label="Close cart"
        className="absolute right-4 md:right-5 top-1/2 md:top-3/4 -translate-y-1/2"
        onClick={closeSearch}
        type="button"
      >
        <Svg
          className="w-5 text-white"
          src="/svgs/close.svg#close"
          title="Arrow Left"
          viewBox="0 0 24 24"
        />
      </button>
    </div>
  );
}

SearchHeader.displayName = 'SearchHeader';
