import { Fragment, useMemo } from 'react';
import { useSettings, useCountry } from '@backpackjs/storefront';

import { CollectionPromoTile } from './CollectionPromoTile';
import { ProductItem } from '../ProductItem';
import { useGlobalContext } from '../../contexts';

export function CollectionGrid({
  activeFilters,
  collectionProductsData,
  enabledFilters,
  isSearchResults,
  promoTiles,
  swatchesMap,
}) {
  const settings = useSettings();
  const { country } = useCountry();
  const isUS = country?.isoCode === 'US'

  const { pagination, productItem } = { ...settings?.collection };

  const {
    state: { filteredProducts, productsLimit },
    actions: { loadMoreProducts },
    refs: { loadMoreRef },
  } = collectionProductsData;

  const hasActiveFilters = Object.keys(activeFilters).length > 0;

  const hasMoreProducts = filteredProducts?.length > productsLimit;

  const hasLoadmoreBtn = pagination?.loadType !== 'infinite';

  const progressMessage = useMemo(() => {
    if (!pagination?.progressMessage || !hasMoreProducts) return null;
    return pagination.progressMessage
      .replace('{{total}}', filteredProducts?.length)
      .replace('{{loaded}}', productsLimit);
  }, [filteredProducts?.length, pagination?.progressMessage, productsLimit]);

  const hasLazyLoadingImage = productItem?.enableLazyLoading || false;

  const {
    state: { loopSelected }
  } = useGlobalContext();

  return (
    <>
      {filteredProducts?.length > 0 && (
        <ul className="mx-auto grid grid-cols-2 gap-x-4 gap-y-12 px-0 md:grid-cols-4 md:gap-x-9 md:px-4">
          {filteredProducts.slice(0, productsLimit).map((product, index) => {
            const promoTile = promoTiles?.find(
              ({ position }) => position === index + 1
            );
            const key = product.id || product.handle || index;
            const imageLazyMode =
              index > pagination?.resultsPerPage ? true : hasLazyLoadingImage;
            const isHidden =
              product?.tags?.some(
                (tag) => tag === 'hide-product' || tag === 'collection-hidden'
              ) || false;

            const isHiddenInternational =  isUS ? false : product?.tags?.includes('hide-international') || false
            const isHiddenByLoop = !!(product?.handle === 'bylt-gift-card' && loopSelected)

            return (
              !isHidden && !isHiddenInternational && !isHiddenByLoop && (
                <Fragment key={key}>
                  {promoTile && (
                    <li key={`promo-tile-${key}`}>
                      <CollectionPromoTile tile={promoTile} />
                    </li>
                  )}
                  <li key={`collection-tile-${key}`}>
                    <ProductItem
                      enabledColorNameOnHover={
                        productItem?.enabledColorNameOnHover
                      }
                      enabledColorSelector={productItem?.enabledColorSelector}
                      enabledStarRating={productItem?.enabledStarRating}
                      handle={product.handle}
                      index={index}
                      isSearchResults={isSearchResults}
                      product={product}
                      swatchesMap={swatchesMap}
                      enableLazyLoading={imageLazyMode}
                    />
                  </li>
                </Fragment>
              )
            );
          })}
        </ul>
      )}

      {hasMoreProducts && pagination && (
        <div
          className="mt-10 flex flex-col items-center gap-3"
          ref={loadMoreRef}
        >
          {progressMessage && (
            <p className="text-label text-center">{progressMessage}</p>
          )}

          {hasLoadmoreBtn && (
            <button
              className={`${pagination?.buttonStyle}`}
              onClick={loadMoreProducts}
              type="button"
            >
              {pagination?.loadText}
            </button>
          )}
        </div>
      )}

      {!filteredProducts?.length && hasActiveFilters && (
        <div className="flex min-h-[12rem] items-center justify-center text-center">
          <p>No products found matching these filters.</p>
        </div>
      )}
    </>
  );
}

CollectionGrid.displayName = 'CollectionGrid';
