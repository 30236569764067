import { useMemo } from 'react';
import { Link, Markdown, ShopifyRichText } from '../../snippets';

export function ProductHalfHeroContent({ metafields, aboveTheFold, content }) {
  const {
    alignmentDesktop,
    alignmentMobile,
    buttons,
    color,
    heading,
    headingOverride,
    maxWidthDesktop,
    subtext,
    subtextOverride,
    superheading,
  } = { ...content };
  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;
  const headingWithBreaks = useMemo(() => {
    if (!headingOverride) {
      return (
        metafields?.bylt?.[heading] || metafields?.custom_fields?.[heading]
      );
    }

    const splitHeading = headingOverride?.split('\n');
    return splitHeading;
  }, [heading]);

  const afterClasses =
    'md:after:abolute md:after:bottom-[-1.25rem] md:after:left-0 md:after:bg-primary md:after:w-[15%] md:after:h-[0.2125rem] md:after:block md:after:mt-2';

  const showcaseIcons = JSON.parse(metafields?.bylt?.showcase_icon || '[]');
  const showcaseIconsTitle = JSON.parse(
    metafields?.bylt?.showcase_icon_title || '[]'
  );

  const showcaseIconsWithTitles =
    showcaseIconsTitle?.map((el, index) => {
      return { text: el, icon: showcaseIcons[index] || '' };
    }) || [];

  const showcaseDescription = metafields?.bylt?.new_description_text_1;

  return (
    <div className="px-contained py-contained w-full md:ml-[22%] md:!px-0">
      <div
        className={`mx-auto flex flex-col gap-4 ${alignmentClasses} ${maxWidthDesktop}`}
        style={{ color }}
      >
        <div className="w-full">
          {superheading && (
            <p className="text-superheading mb-1 lg:mb-1.5">{superheading}</p>
          )}

          {aboveTheFold ? (
            <h1 className="h2">{headingWithBreaks}</h1>
          ) : (
            <h2 className={`color-black ${afterClasses} h4 max-md:text-center max-md:tracking-tight`}>
              {headingWithBreaks}
            </h2>
          )}
        </div>

        {(subtext || subtextOverride) && (
          <div className="[&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:my-3 [&_p]:text-xs [&_p]:leading-[1.57em] [&_p]:tracking-[.05em] [&_p]:text-black first:[&_p]:mt-0">
            {subtextOverride ? (
              <Markdown>{subtextOverride}</Markdown>
            ) : (
              <div>
                <ShopifyRichText shopifyTextObject={showcaseDescription} />

                <div className="pt-4 max-md:flex max-md:flex-row max-md:flex-wrap max-md:justify-around	">
                  {showcaseIconsWithTitles?.map((icon) => {
                    return (
                      <div
                        key={icon.text}
                        className="mb-[12px] flex w-[30vw] md:w-full [&_svg]:h-[32px] [&_svg]:w-[32px]"
                      >
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: icon.icon,
                          }}
                        />
                        <p className="!my-0 self-center pl-[18px] font-bold">
                          {icon.text}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}

        {buttons?.length > 0 && (
          <ul className="mt-4 flex flex-col justify-center gap-4 xs:flex-row">
            {buttons.slice(0, 2).map(({ link, style }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-label={link?.text}
                    className={style}
                    href={link?.url}
                    newTab={link?.newTab}
                    type={link?.type}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

ProductHalfHeroContent.displayName = 'ProductHalfHeroContent';
