import { Schema } from './TrackingOrder.schema';
import Script from 'next/script';
import { useEffect } from 'react';

export function TrackingOrder({ cms }) {
  const { heading, trackingBtnText } = cms;

  useEffect(() => {
    const iframe = document.querySelector('#as-tracking-page');
    window.addEventListener('message', (event) => {
      if (
        event.data &&
        event.data.type === 'resize' &&
        typeof event.data.scrollHeight === 'number' &&
        iframe
      ) {
        iframe.style.height = event.data.scrollHeight + 'px';
      }
    });

    // support widget float
    window.addEventListener('message', (event) => {
      if (
        event.data &&
        event.data.type === 'widgetScriptLoad' &&
        typeof event.data.scripts === 'object'
      ) {
        event.data.scripts.forEach((script) => {
          const scriptDom = document.createElement('script');
          scriptDom.innerHTML = script;
          document.body.appendChild(scriptDom);
          window.postMessage({ type: 'hideAllWidget' }, '*');
        });
      }
    });
  }, []);

  return (
    <div className="px-contained py-contained">
      <iframe
        className="w-full"
        id="as-tracking-page"
        src="https://byltbasics.aftership.com/iframe"
        scrolling="no"
        frameborder="0"
      ></iframe>
    </div>
  );
}

TrackingOrder.displayName = 'TrackingOrder';
TrackingOrder.Schema = Schema;
