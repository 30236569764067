import { Link, Image, Svg } from '../../snippets';
import { Schema } from './FabricFitSlider.schema';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useCollectionMetafields } from '../../hooks';
import { fetchCollectionsFromHandles } from '../../utilities';

export function FabricFitSlider({ cms, collection }) {
  const { subCollection, section, enableMetafields } = cms;
  const metafields = useCollectionMetafields({ collection });

  const [swiper, setSwiper] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const selectedSubCollections = useMemo(() => {
    return subCollection

  }, [subCollection]);

  const hasSubcollections = selectedSubCollections?.length > 0;

  const desktopNavClass = selectedSubCollections.length > 4 ? 'md:flex' : 'md:hidden'

  const navigationVisibility = selectedSubCollections?.length > 2 ? `flex ${desktopNavClass}` : 'hidden'

  const desktopPages =  new Array(Math.ceil(selectedSubCollections.length / 4 )).fill(0)
  const mobilePages = new Array(Math.ceil(selectedSubCollections.length / 2 )).fill(0)

  return (
    hasSubcollections && (
      <div className="px-4 max-md:py-4 md:px-10 md:pt-12">
        <div className={`mx-auto ${section?.maxWidth} relative`}>
          {selectedSubCollections && selectedSubCollections.length && (
            <Swiper
              className={''}
              grabCursor
              loop={false}
              modules={[Navigation]}
              onSwiper={setSwiper}
              onActiveIndexChange={(swiper) => {
                setCurrentPage(swiper.activeIndex)
              }}
              slidesOffsetAfter={0}
              slidesOffsetBefore={0}
              slidesPerView={2}
              spaceBetween={16}
              breakpoints={{
                768: {
                  centeredSlides: false,
                  loop: false,
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                },
                1024: {
                  centeredSlides: false,
                  loop: false,
                  slidesPerView: 4,
                  spaceBetween: 20,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                },
              }}
            >
              {swiper &&
                selectedSubCollections.map((sub, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link aria-label={sub?.label} href={sub?.link?.url}>
                        <div className="relative aspect-square md:aspect-[2/1] mb-4">
                          {sub?.image?.src && (
                            <Image
                              alt={sub?.label}
                              className={``}
                              fill
                              priority
                              sizes="25vw"
                              src={sub?.image?.src}
                            />
                          )}
                        </div>
                      </Link>

                      <p className="font-bold">{sub?.title}</p>

                      <Link aria-label={sub?.label} href={sub?.link?.url}>
                        <p className="text-xs text-underline">{sub?.subtitle}</p>
                      </Link>
                      

                      <ul className="list-disc list-inside mt-3 pl-2">
                        {sub?.points?.map(point => {
                          return (
                            <li className="text-[10px] text-[#444447]">
                              <span className="text-xs relative right-[4px]">{point}</span>
                            </li>
                          )
                        })}
                      </ul>
                    </SwiperSlide>
                  );
                })}

              <div className={`z-1 absolute left-[-15px] right-0 top-0 aspect-[2/1] md:aspect-[8/1] md:px-8 xl:px-14 ${navigationVisibility}`}>
                <div
                  className={`swiper-button-prev left-0 left-0 top-[calc(50%)] !h-[3rem] !w-[3rem] after:hidden lg:!flex bg-white`}
                  onClick={() => swiper.slidePrev()}
                >
                  <Svg
                    className="w-[1.25rem] text-black"
                    src="/svgs/chevron-left.svg#chevron-left"
                    title="Arrow Left"
                    viewBox="0 0 24 24"
                  />
                </div>

                <div
                  className={`swiper-button-next right-[-15px] right-0 top-[calc(50%)] !h-[3rem] !w-[3rem] after:hidden lg:!flex bg-white`}
                  onClick={() => swiper.slideNext()}
                >
                  <Svg
                    className="w-[1.25rem] text-black"
                    src="/svgs/chevron-right.svg#chevron-right"
                    title="Arrow Right"
                    viewBox="0 0 24 24"
                  />
                </div>
              </div>
            </Swiper>
          )}

          <div className="w-full mt-8 flex max-md:hidden">
            {desktopPages?.map((el, index) => {
              return (
                <div className={`w-full border ${currentPage === index ? 'border-primary' : 'border-border'}`}>
                </div>
              )
            })}
          </div>

          <div className="w-full mt-8 flex md:hidden">
            {mobilePages?.map((el, index) => {
              return (
                <div className={`w-full border ${currentPage === index ? 'border-primary' : 'border-border'}`}>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  );
}

FabricFitSlider.displayName = 'Fabric Fit Slider';
FabricFitSlider.Schema = Schema;
