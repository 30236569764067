import { COLORS, OBJECT_POSITIONS } from '../../settings/common';

const media = {
  label: 'Media Settings',
  name: 'media',
  description: 'Image settings, order, aspect ratio',
  component: 'group',
  fields: [
    {
      label: 'Image Settings',
      name: 'images',
      description: 'Image, image position',
      component: 'group',
      fields: [
        {
          label: 'Product Images',
          name: 'productImages',
          component: 'group-list',
          description: 'Max of two images',
          itemProps: {
            label: '{{item.alt}}',
          },
          validate: {
            maxItems: 2,
          },
          fields: [
            {
              label: 'Image Alt',
              name: 'alt',
              component: 'text',
              description: 'Brief description of image',
            },
            {
              label: 'Image (tablet/desktop)',
              name: 'imageDesktop',
              component: 'image',
            },
            {
              label: 'Image Position (tablet/desktop)',
              name: 'positionDesktop',
              component: 'select',
              options: OBJECT_POSITIONS.desktop,
            },
            {
              label: 'Image (mobile)',
              name: 'imageMobile',
              component: 'image',
            },
            {
              label: 'Image Position (mobile)',
              name: 'positionMobile',
              component: 'select',
              options: OBJECT_POSITIONS.mobile,
            },
          ],
        },
      ],
    },
    {
      label: 'Media Order (tablet/desktop)',
      name: 'mediaOrderDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: '1' },
        { label: 'Right', value: '2' },
      ],
    },
    {
      label: 'Media Aspect Ratio (tablet)',
      name: 'aspectTablet',
      component: 'select',
      options: [
        { label: '3:2', value: 'md:before:pb-[67%]' },
        { label: '4:3', value: 'md:before:pb-[75%]' },
        { label: '5:4', value: 'md:before:pb-[80%]' },
        { label: '8:7', value: 'md:before:pb-[87.5%]' },
        { label: '1:1', value: 'md:before:pb-[100%]' },
        { label: '7:8', value: 'md:before:pb-[114%]' },
        { label: '4:5', value: 'md:before:pb-[125%]' },
        { label: '3:4', value: 'md:before:pb-[133%]' },
        { label: '2:3', value: 'md:before:pb-[150%]' },
      ],
    },
    {
      label: 'Media Aspect Ratio (desktop)',
      name: 'aspectDesktop',
      component: 'select',
      options: [
        { label: '3:2', value: 'lg:before:pb-[67%]' },
        { label: '4:3', value: 'lg:before:pb-[75%]' },
        { label: '5:4', value: 'lg:before:pb-[80%]' },
        { label: '8:7', value: 'lg:before:pb-[87.5%]' },
        { label: '1:1', value: 'lg:before:pb-[100%]' },
        { label: '7:8', value: 'lg:before:pb-[114%]' },
        { label: '4:5', value: 'lg:before:pb-[125%]' },
        { label: '3:4', value: 'lg:before:pb-[133%]' },
        { label: '2:3', value: 'lg:before:pb-[150%]' },
      ],
    },
    {
      label: 'Fill Empty Space (tablet/desktop)',
      name: 'fill',
      component: 'toggle',
      description:
        'Fill any vertical empty space with media. Applicable only to tablet and desktop',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Media Order (mobile)',
      name: 'mediaOrderMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Top', value: '1' },
        { label: 'Bottom', value: '2' },
      ],
    },
    {
      label: 'Media Aspect Ratio (mobile)',
      name: 'aspectMobile',
      component: 'select',
      options: [
        { label: '3:2', value: 'max-md:before:pb-[67%]' },
        { label: '4:3', value: 'max-md:before:pb-[75%]' },
        { label: '5:4', value: 'max-md:before:pb-[80%]' },
        { label: '8:7', value: 'max-md:before:pb-[87.5%]' },
        { label: '1:1', value: 'max-md:before:pb-[100%]' },
        { label: '7:8', value: 'max-md:before:pb-[114%]' },
        { label: '4:5', value: 'max-md:before:pb-[125%]' },
        { label: '3:4', value: 'max-md:before:pb-[133%]' },
        { label: '2:3', value: 'max-md:before:pb-[150%]' },
      ],
    },
  ],
  defaultValue: {
    images: [
      {
        alt: 'Man with backpack crossing the street',
        imageDesktop: {
          src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/mad-rabbit-tattoo-tn1yJqxNj-8-unsplash.jpg?v=1672787927',
        },
        positionDesktop: 'md:object-center',
        imageMobile: {
          src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/mad-rabbit-tattoo-tn1yJqxNj-8-unsplash.jpg?v=1672787927',
        },
        positionMobile: 'object-center',
      },
    ],
    mediaOrderDesktop: '1',
    aspectTablet: 'md:before:pb-[100%]',
    aspectDesktop: 'lg:before:pb-[100%]',
    fill: true,
    mediaOrderMobile: '1',
    aspectMobile: 'max-md:before:pb-[100%]',
  },
};

const content = {
  label: 'Content Settings',
  name: 'content',
  description:
    'Heading, superheading, subtext, product features, text color, alignment, content width',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'textarea',
    },
    {
      label: 'Superheading',
      name: 'superheading',
      component: 'text',
    },
    {
      label: 'Subtext',
      name: 'subtext',
      component: 'markdown',
    },
    {
      label: 'Product Features',
      name: 'productFeatures',
      component: 'group-list',
      description: 'Max of two products',
      validate: {
        maxItems: 2,
      },
      fields: [
        {
          label: 'Features',
          name: 'features',
          component: 'group-list',
          description: 'Max of eight features',
          itemProps: {
            label: '{{item.description}}',
          },
          validate: {
            maxItems: 8,
          },
          fields: [
            {
              label: 'Image Settings',
              name: 'image',
              description: 'Image',
              component: 'group',
              fields: [
                {
                  label: 'Icon Alt',
                  name: 'alt',
                  component: 'text',
                  description: 'Brief description of image',
                },
                {
                  label: 'Icon',
                  name: 'imageDesktop',
                  component: 'image',
                },
              ],
            },
            {
              label: 'Description',
              name: 'description',
              component: 'text',
            },
          ],
        },
      ],
    },
    {
      label: 'Product Selector Settings',
      name: 'productSelector',
      description: 'Product Selector',
      component: 'group',
      fields: [
        {
          label: 'Product Options',
          name: 'productOptions',
          component: 'group-list',
          description: 'Max of two options',
          itemProps: {
            label: '{{item.toggleText}}',
          },
          validate: {
            maxItems: 2,
          },
          fields: [
            {
              label: 'Toggle Text',
              name: 'toggleText',
              component: 'text',
              description: 'Option Title',
            },
          ],
        },
      ],
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'select',
      options: COLORS,
    },
    {
      label: 'Content Alignment (tablet/desktop)',
      name: 'alignmentDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'md:text-left md:items-start' },
        { label: 'Center', value: 'md:text-center md:items-center' },
        { label: 'Right', value: 'md:text-right md:items-end' },
      ],
    },
    {
      label: 'Max Content Width (tablet/desktop)',
      name: 'maxWidthDesktop',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'md:max-w-[22rem] lg:max-w-[28rem]' },
        { label: 'Medium', value: 'md:max-w-[30rem] lg:max-w-[38rem]' },
        { label: 'Wide', value: 'md:max-w-[38rem] lg:max-w-[48rem]' },
        { label: 'Full', value: 'md:max-w-full' },
      ],
    },
    {
      label: 'Content Alignment (mobile)',
      name: 'alignmentMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'text-left items-start' },
        { label: 'Center', value: 'text-center items-center' },
        { label: 'Right', value: 'text-right items-end' },
      ],
    },
  ],
  defaultValue: {
    heading: 'Product Compare Heading',
    superheading: '',
    subtext:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    color: 'var(--text)',
    alignmentDesktop: 'md:text-left md:items-start',
    maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
    alignmentMobile: 'text-left items-start',
  },
};

export const Schema = () => {
  return {
    category: 'Showcase',
    label: 'Product Compare',
    key: 'product-compare',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/Screenshot_2023-10-26_at_12.58.14_PM.png?v=1698343142',
    fields: [
      media,
      content,
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Background color, layout, above the fold, full width, full bleed, vertical padding',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
            defaultValue: 'var(--background)',
          },
          {
            label: 'Layout',
            name: 'layout',
            component: 'select',
            options: [
              { label: '1:1', value: 'grid-cols-2' },
              { label: '1:3', value: 'grid-cols-[1fr_2fr]' },
              { label: '3:1', value: 'grid-cols-[66%,auto]' },
            ],
            defaultValue: 'grid-cols-2',
          },
          {
            label: 'Above The Fold',
            name: 'aboveTheFold',
            component: 'toggle',
            description: `Sets the heading as H1 and image load as priority`,
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes horizontal padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          bgColor: 'var(--background)',
          layout: 'grid-cols-2',
          aboveTheFold: false,
          fullWidth: true,
          fullBleed: true,
          verticalPadding: false,
        },
      },
    ],
  };
};
