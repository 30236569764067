import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Schema } from './ShoppableInstagram.schema';
import { Image } from '../../snippets';

export function ShoppableInstagram({ cms }) {
  const { section } = cms;
  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  const maxWidthClass = section?.fullWidth
    ? '!max-w-none'
    : '!max-w-[var(--content-max-width)]';

  useEffect(() => {
    if (!inView) return;

    const widget = document.getElementById('ShoppableInstagram');

    if (!widget) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://apps.elfsight.com/p/platform.js';
      script.setAttribute('id', `ShoppableInstagram`);
      script.setAttribute('strategy', 'lazyOnLoad');
      document.body.appendChild(script);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`${maxWidthClass} mx-auto overflow-x-hidden lg:min-h-[920px] ${
        section?.hasXPadding ? 'px-contained' : ''
      } ${section?.hasYPadding ? 'py-contained' : ''}`}
    >
      <div className="product-ig-widget-container text-center">
        <div className="product-ig-widget">
          <div className="product-ig-widget-header my-7 w-full">
            <h2 className="ig-title text-title-h4">#byltforthis</h2>
            <p className="ig-text font-normal">
              Show the world what you&apos;re made of.
            </p>
          </div>
          <div className="elfsight-app-a057f636-c20f-4526-9bcb-7b6746808136 product-ig-deep" />
          <div className="product-ig-widget-footer my-7 flex w-full flex-col gap-2">
            <Image
              src="/svgs/noprecache/social/instagram.svg#instagram"
              alt="instagram logo"
              width={20}
              height={20}
              className="mx-auto"
            />
            <p className="ig-text font-normal">Keep up with us on instagram.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

ShoppableInstagram.displayName = 'ShoppableInstagram';
ShoppableInstagram.Schema = Schema;
