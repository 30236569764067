export function Schema() {
  return {
    category: 'Text',
    label: 'Collection Link Filters',
    key: 'collectionLinkFilters',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/link_filters.png?v=1701711206',
    fields: [
      {
        component: 'group-list',
        name: 'filterLinks',
        label: 'Filter Links',
        description: 'These are navigation links that can be placed inside the filters list.',
        itemProps: {
          label: '{{item.label}}',
        },
        fields: [
          {
            component: 'text',
            name: 'label',
            label: 'Label',
          },
          {
            component: 'link',
            name: 'link',
            label: 'URL',
            defaultValue: {
              text: 'Link name',
              url: '/',
            },
          },
          {
            component: 'image',
            name: 'image',
            label: 'Image',
          },
        ],
        validate: {
          maxItems: 10,
          required: false,
        },
      },
    ],
  };
}
