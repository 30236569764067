export function Schema() {
  return {
    category: 'Text',
    label: 'OutfitCustomizer',
    key: 'outfitCustomizer',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/outfit-customizer.png?v=1703004014',
    fields: [
      {
        label: 'Description',
        name: 'content',
        component: 'markdown',
        defaultValue: `**Lorem ipsum dolor sit amet**, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea _commodo consequat_.`,
      },
      {
        component: 'text',
        name: 'instructionsLabel',
        label: 'Instructions Label',
      },
      {
        label: 'Instructions',
        name: 'instructions',
        component: 'markdown',
        defaultValue: `**Lorem ipsum dolor sit amet**, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea _commodo consequat_.`,
      },
      {
        component: 'image',
        name: 'sideImage',
        label: 'Side Image',
      },
      {
        component: 'text',
        name: 'mainButtonLabel',
        label: 'Main Button Label',
        defaultValue: 'Begin Customizing'
      },
      {
        label: 'Main Button Style',
        name: 'mainButtonStyle',
        component: 'select',
        options: [
          { label: 'Primary', value: 'btn-primary' },
          { label: 'Secondary', value: 'btn-secondary' },
          { label: 'Inverse Light', value: 'btn-inverse-light' },
          { label: 'Inverse Dark', value: 'btn-inverse-dark' },
        ],
      },
      {
        label: 'Customizer Options',
        name: 'customizerOptions',
        component: 'group-list',
        description: 'Max of 3 options', 
        itemProps: {
          label: '{{item.optionHeading}}',
        },
        validate: { 
          maxItems: 3,
        },
        fields: [
          {
            component: 'text',
            name: 'optionHeading',
            label: 'Option Heading'
          },
          {
            component: 'group-list',
            name: 'styleGroup',
            label: 'Style Group',
            itemProps: {
              label: '{{item.styleName}}',
            },
            fields: [
              {
                component: 'text',
                name: 'styleName',
                label: 'Style Name',
              },
              {
                component: 'text',
                name: 'collectionHandle',
                label: 'Collection Handle',
                description: 'If this field is used the Product List will be ignored',
              },
              {
                component: 'list',
                name: 'productList',
                label: 'Product List',
                description: 'Use this field if you do not want to use a collection',
                field: {
                  component: 'productSearch'
                }, 
              }
            ],
          },
        ],
      },
      {
        label: 'Center All Text',
        name: 'centerAllText',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Max content width',
        fields: [
          {
            label: 'Max Content Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[60rem]',
              },
              {
                label: 'Medium Wide',
                value: 'max-w-[75rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[90rem]',
              },
              { label: 'Full', value: 'max-w-full' },
            ],
          },
        ],
        defaultValue: {
          maxWidth: 'max-w-[60rem]',
        },
      },
    ],
  };
}
