import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { Svg } from '../Svg';

export function FooterApps() {
  const settings = useSettings();
  const { links } = { ...settings?.footer?.mobileApps };

  return links?.length > 0 ? (
    <div className="text-current">
      <ul className="flex flex-wrap gap-3.5 pt-2 lg:pt-0">
        {links.map(({ platform, url }, index) => {
          return (
            <li key={index}>
              <Link aria-label={platform} href={url} newTab>
                <Svg
                  className="h-[37px] rounded-md text-current"
                  src={`/svgs/noprecache/apps/${platform}.svg#${platform}`}
                  viewBox={
                    platform === 'play-store' ? '0 0 135 40' : '0 0 119.7 40'
                  }
                  title={platform}
                />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}

FooterApps.displayName = 'FooterApps';
