import { useMemo } from 'react';

import { Svg } from '../../Svg';

export function CollectionFiltersSummary({
  activeFilters,
  clearFilters,
  filtersMap,
  removeFilter,
}) {
  const activeFiltersList = useMemo(() => {
    const filterEntries = Object.entries(activeFilters);
    if (!filterEntries.length) return [];

    return filterEntries.reduce((acc, [filterKey, filterValues]) => {
      return [
        ...acc,
        ...filterValues.map((value) => {
          return {
            key: filterKey,
            value,
          };
        }),
      ];
    }, []);
  }, [activeFilters]);

  return activeFiltersList.length ? (
    <div className="flex flex-row gap-2">
      {clearFilters && (
        <button
          className="flex max-w-full items-center rounded-full py-2 pl-2.5 pr-3 text-sm transition bg-lightGray"
          onClick={clearFilters}
          type="button"
        >
          View All
        </button>
      )}

      <ul className="flex flex-wrap gap-2">
        {activeFiltersList.map(({ key, value }, index) => {
          return (
            <li key={index} className="max-w-full">
              <button
                className="flex max-w-full items-center rounded-full py-2 pl-2.5 pr-3 text-sm transition bg-lightGray"
                onClick={() => removeFilter({ key, value })}
                type="button"
              >
                <Svg
                  className="ml-1 w-2.5 text-text"
                  src="/svgs/close.svg#close"
                  title="Close"
                  viewBox="0 0 24 24"
                />

                <div className="flex-1 truncate pl-1 capitalize">
                  {value}
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}

CollectionFiltersSummary.displayName = 'CollectionFiltersSummary';
