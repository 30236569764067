import { ImageTile } from './ImageTile';
import { Schema } from './ImageTiles.schema';

export function ImageTiles({ cms }) {
  const { content, heading, section, tiles, headingFontSize } = cms;

  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  const mobileCols = content?.mobileColumns || 'max-md:!grid-cols-1'

  const tilesCols = `${mobileCols} grid-cols-${tiles.length} md:grid-cols-${tiles.length}`

  const headingStyles = headingFontSize ? headingFontSize : ''
  
  return (
    <div className={`md:px-contained ${section?.verticalPaddingLevelMobile} ${section?.verticalPaddingLevel} `}>
      <div className={`mx-auto ${maxWidthClass}`}>
        {heading && (
          <h2 className={`${headingStyles} normal-case mb-6 px-4 text-center md:mb-10`}>
            {heading}
          </h2>
        )}

        {tiles?.length > 0 && (
          <div
            className={`grid ${tilesCols} grid-cols-5 ${
              mobileCols === 'max-md:!grid-cols-2' ? 'gap-[14px]' : 'gap-10'
            } max-md:px-[17px] md:gap-5 lg:gap-x-5 lg:gap-y-[unset] lg:px-[unset]`}
          >
            {tiles.map((item, index) => {
              return (
                <div className="relative" key={index}>
                  <ImageTile
                    mobileCols={mobileCols}
                    aspectRatio={section?.aspectRatio}
                    content={content}
                    item={item}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

ImageTiles.displayName = 'ImageTiles';
ImageTiles.Schema = Schema;
