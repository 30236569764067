import { useInView } from 'react-intersection-observer';

import { Schema } from './VideoEmbed.schema';

export function VideoEmbed({ cms }) {
  const { media, section } = cms;
  const { embed, aspectRatio } = { ...media };
  const { maxWidth, enableYPadding, enableXPadding } = { ...section };

  const { ref: inViewRef, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  return (
    <div
      className={`${enableYPadding ? 'py-4 md:py-6 lg:py-8' : ''} ${
        enableXPadding ? 'px-contained' : ''
      }`}
      ref={inViewRef}
    >
      <div
        className={`mx-auto bg-offWhite ${maxWidth} ${aspectRatio}`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: inView ? embed : '' }}
      />
    </div>
  );
}

VideoEmbed.displayName = 'VideoEmbed';
VideoEmbed.Schema = Schema;
