export function Schema() {
  return {
    category: 'Text',
    label: 'Fabric Fit Slider',
    key: 'fabricFitSlider',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/subcollections_3c5805f2-a7f2-40ba-82be-4035b124f147.png?v=1715369889',
    fields: [
      {
        component: 'group-list',
        name: 'subCollection',
        label: 'Subcollections',
        itemProps: {
          label: '{{item.title}}',
        },
        fields: [
          {
            component: 'text',
            name: 'title',
            label: 'Title',
          },
          {
            component: 'text',
            name: 'subtitle',
            label: 'Subtitle',
          },
          {
            component: 'link',
            name: 'link',
            label: 'URL',
            defaultValue: {
              text: 'Link name',
              url: '/',
            },
          },
          {
            component: 'list',
            name: 'points',
            label: 'Fabric Points',
            field: {
              component: 'text',
            },
            defaultValue: [''],
          },
          {
            component: 'image',
            name: 'image',
            label: 'Desktop Image',
          },
          {
            component: 'image',
            name: 'mobileImage',
            label: 'Mobile Image',
          },
        ],
        validate: {
          maxItems: 10,
          required: false,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Max content width',
        fields: [
          {
            label: 'Max Content Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[60rem]',
              },
              {
                label: 'Medium Wide',
                value: 'max-w-[75rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[90rem]',
              },
              { label: 'Full', value: 'max-w-full' },
            ],
          },
        ],
        defaultValue: {
          maxWidth: 'max-w-full',
        },
      },
    ],
  };
}
