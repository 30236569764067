import { Schema } from './ImageGrid.schema';
import { Image } from '../../snippets';

export function ImageGrid({ cms }) {
  const { images, section } = cms;
  const verticalPadding = section?.verticalPaddingLevel;
  const verticalPaddingMobile = section?.verticalPaddingMobile;

  return (
    <div
      className={`max-2xl:px-contained py-contained ${section?.maxWidth} ${verticalPadding} ${verticalPaddingMobile} mx-auto`}
    >
      <ul className="grid grid-cols-1 gap-y-8 gap-x-5 lg:gap-10 md:grid-cols-4">
        {images.map(({ alt, image, subheading, heading }) => {
          return (
            <li key={alt}>
              <Image
                className="w-full object-cover object-center"
                alt={alt}
                height={288}
                width={288}
                src={image.src}
              />
              {heading && (
                <h3 className="mt-6 h4">{heading}</h3>
              )}
              {subheading && <p className='mt-[8px] text-[#444447]'>{subheading}</p>}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

ImageGrid.displayName = 'ImageGrid';
ImageGrid.Schema = Schema;
