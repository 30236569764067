import { useState } from 'react';
import { Link } from '../Link';
import { Svg } from '../Svg';

export function SubmenuGroup({ subGroup, sgIndex, handleCloseSidebar }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div key={sgIndex}>
      <details className="border-b border-lightGray px-[22px] py-4">
        <summary
          className="text-nav flex items-center text-base"
          onClick={() => setIsOpen(!isOpen)}
        >
          {subGroup?.mainLink?.text}
          <button className="ml-auto pointer-events-none" type="button">
            <Svg
              className={`${isOpen ? 'rotate-90' : ''} w-4`}
              src="/svgs/chevron-right.svg#chevron-right"
              title="Arrow Left"
              viewBox="0 0 24 24"
            />
          </button>
        </summary>

        <div className="mt-4 leading-normal">
          <ul>
            {subGroup?.secondaryLinks?.map((sgLink, sglIndex) => {
              return (
                <li className="mt-[22px]" key={sglIndex}>
                  <Link
                    aria-label={sgLink?.text}
                    className="color-primary hover-text-underline text-sm"
                    href={sgLink?.url}
                    newTab={sgLink?.newTab}
                    onClick={handleCloseSidebar}
                    type={sgLink?.type}
                  >
                    {sgLink?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </details>
    </div>
  );
}
