import React, { useState } from 'react';

import { Link } from '../Link';
import { Svg } from '../Svg';

export function MobileMenuItem({ item, triggerAction }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-b-gray">
      <button
        aria-label={
          isOpen ? `Close ${item.title} menu` : `Open ${item.title} menu`
        }
        className="flex h-14 w-full items-center justify-between px-0 py-5"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <h3 className="text-nav text-base capitalize">{item.title}</h3>

        {isOpen ? (
          <Svg
            className="w-[26px] text-white"
            src="/svgs/custom-minimize.svg#minimize"
            title="Minus"
            viewBox="0 0 55 55"
          />
        ) : (
          <Svg
            className="w-[26px] text-white"
            src="/svgs/custom-expand.svg#expand"
            title="Plus"
            viewBox="0 0 55 55"
          />
        )}
      </button>

      <ul
        className={`flex-col items-start gap-2 px-4 pb-6 pl-0 ${
          isOpen ? 'flex' : 'hidden'
        }`}
      >
        {item.links?.map(({ link, actionName, isActionButton }, linkIndex) => {
          const action = actionName || '';
          const isAction = isActionButton || false;

          return (
            <li key={linkIndex}>
              {!isAction ? (
                <Link
                  aria-label={link?.text}
                  className="hover-text-underline text-[13px]"
                  href={link?.url}
                  newTab={link?.newTab}
                  type={link?.type}
                >
                  {link?.text}
                </Link>
              ) : (
                <button
                  type="button"
                  aria-label={link?.text}
                  className="hover-text-underline cursor-pointer text-[13px]"
                  onClick={() => triggerAction(action)}
                >
                  {link?.text}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

MobileMenuItem.displayName = 'MobileMenuItem';
