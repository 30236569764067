import { useState } from 'react';

import { SearchFilterDropdown } from './SearchFilterDropdown';

export function SearchFilters({
  closeSearch,
  filters,
  addFilter,
  removeFilter,
  activeFilters,
  trackOnClick,
}) {
  return (
    <div className="">
      <div className="relative h-full max-h-[75vh] w-full overflow-y-auto px-4 max-md:hidden">
        <p className="font-sm border-b border-border pb-[19px] font-bold uppercase text-black">
          Filters
        </p>

        <ul
          className={`border-border max-md:hidden ${
            filters.length ? 'border-b' : ''
          }`}
        >
          {filters.map((filter, index) => {
            if (!filter.values.length) return null;

            return (
              <li key={index}>
                <SearchFilterDropdown
                  filter={filter}
                  activeFilters={activeFilters}
                  addFilter={addFilter}
                  removeFilter={removeFilter}
                  trackOnClick={trackOnClick}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

SearchFilters.displayName = 'SearchFilters';
