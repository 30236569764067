// Update color labels and values as per project's theme
// IMPORTANT: Do not string interpolate values as Tailwind does not register that

const COLORS = [
  { label: 'Background', value: 'var(--background)' },
  { label: 'Text', value: 'var(--text)' },
  { label: 'Border', value: 'var(--border)' },
  { label: 'Sale', value: 'var(--sale)' },
  { label: 'Primary', value: 'var(--primary)' },
  { label: 'Secondary', value: 'var(--secondary)' },
  { label: 'Accent 1', value: 'var(--accent1)' },
  { label: 'Accent 2', value: 'var(--accent2)' },
  { label: 'Black', value: 'var(--black)' },
  { label: 'Off Black', value: 'var(--off-black)' },
  { label: 'Dark Gray', value: 'var(--dark-gray)' },
  { label: 'Medium Dark Gray', value: 'var(--medium-dark-gray)' },
  { label: 'Medium Gray', value: 'var(--medium-gray)' },
  { label: 'Gray', value: 'var(--gray)' },
  { label: 'Light Gray', value: 'var(--light-gray)' },
  { label: 'Off White', value: 'var(--off-white)' },
  { label: 'White', value: 'var(--white)' },
  { label: 'Transparent', value: 'transparent' },
];

const TEXT_COLORS = {
  mobile: [
    { label: 'Background', value: 'text-[var(--background)]' },
    { label: 'Text', value: 'text-[var(--text)]' },
    { label: 'Border', value: 'text-[var(--border)]' },
    { label: 'Sale', value: 'text-[var(--sale)]' },
    { label: 'Primary', value: 'text-[var(--primary)]' },
    { label: 'Secondary', value: 'text-[var(--secondary)]' },
    { label: 'Accent 1', value: 'text-[var(--accent1)]' },
    { label: 'Accent 2', value: 'text-[var(--accent2)]' },
    { label: 'Black', value: 'text-[var(--black)]' },
    { label: 'Off Black', value: 'text-[var(--off-black)]' },
    { label: 'Dark Gray', value: 'text-[var(--dark-gray)]' },
    { label: 'Medium Dark Gray', value: 'text-[var(--medium-dark-gray)]' },
    { label: 'Medium Gray', value: 'text-[var(--medium-gray)]' },
    { label: 'Gray', value: 'text-[var(--gray)]' },
    { label: 'Light Gray', value: 'text-[var(--light-gray)]' },
    { label: 'Off White', value: 'text-[var(--off-white)]' },
    { label: 'White', value: 'text-[var(--white)]' },
    { label: 'Transparent', value: 'text-transparent' },
  ],
  desktop: [
    { label: 'Background', value: 'md:text-[var(--background)]' },
    { label: 'Text', value: 'md:text-[var(--text)]' },
    { label: 'Border', value: 'md:text-[var(--border)]' },
    { label: 'Sale', value: 'md:text-[var(--sale)]' },
    { label: 'Primary', value: 'md:text-[var(--primary)]' },
    { label: 'Secondary', value: 'md:text-[var(--secondary)]' },
    { label: 'Accent 1', value: 'md:text-[var(--accent1)]' },
    { label: 'Accent 2', value: 'md:text-[var(--accent2)]' },
    { label: 'Black', value: 'md:text-[var(--black)]' },
    { label: 'Off Black', value: 'md:text-[var(--off-black)]' },
    { label: 'Dark Gray', value: 'md:text-[var(--dark-gray)]' },
    { label: 'Medium Dark Gray', value: 'md:text-[var(--medium-dark-gray)]' },
    { label: 'Medium Gray', value: 'md:text-[var(--medium-gray)]' },
    { label: 'Gray', value: 'md:text-[var(--gray)]' },
    { label: 'Light Gray', value: 'md:text-[var(--light-gray)]' },
    { label: 'Off White', value: 'md:text-[var(--off-white)]' },
    { label: 'White', value: 'md:text-[var(--white)]' },
    { label: 'Transparent', value: 'md:text-transparent' },
  ],
};

const FLEX_POSITIONS = {
  mobile: [
    {
      value: 'justify-start items-start',
      label: 'Left Top',
    },
    {
      value: 'justify-start items-center',
      label: 'Left Center',
    },
    {
      value: 'justify-start items-end',
      label: 'Left Bottom',
    },
    {
      value: 'justify-center items-start',
      label: 'Center Top',
    },
    {
      value: 'justify-center items-center',
      label: 'Center Center',
    },
    {
      value: 'justify-center items-end',
      label: 'Center Bottom',
    },
    {
      value: 'justify-end items-start',
      label: 'Right Top',
    },
    {
      value: 'justify-end items-center',
      label: 'Right Center',
    },
    {
      value: 'justify-end items-end',
      label: 'Right Bottom',
    },
  ],
  desktop: [
    {
      value: 'md:justify-start md:items-start',
      label: 'Left Top',
    },
    {
      value: 'md:justify-start md:items-center',
      label: 'Left Center',
    },
    {
      value: 'md:justify-start md:items-end',
      label: 'Left Bottom',
    },
    {
      value: 'md:justify-center md:items-start',
      label: 'Center Top',
    },
    {
      value: 'md:justify-center md:items-center',
      label: 'Center Center',
    },
    {
      value: 'md:justify-center md:items-end',
      label: 'Center Bottom',
    },
    {
      value: 'md:justify-end md:items-start',
      label: 'Right Top',
    },
    {
      value: 'md:justify-end md:items-center',
      label: 'Right Center',
    },
    {
      value: 'md:justify-end md:items-end',
      label: 'Right Bottom',
    },
  ],
};

const OBJECT_POSITIONS = {
  mobile: [
    {
      value: 'object-left-top',
      label: 'Left Top',
    },
    {
      value: 'object-left',
      label: 'Left Center',
    },
    {
      value: 'object-left-bottom',
      label: 'Left Bottom',
    },
    {
      value: 'object-top',
      label: 'Center Top',
    },
    {
      value: 'object-center',
      label: 'Center Center',
    },
    {
      value: 'object-bottom',
      label: 'Center Bottom',
    },
    {
      value: 'object-right-top',
      label: 'Right Top',
    },
    {
      value: 'object-right',
      label: 'Right Center',
    },
    {
      value: 'object-right-bottom',
      label: 'Right Bottom',
    },
  ],
  desktop: [
    {
      value: 'md:object-left-top',
      label: 'Left Top',
    },
    {
      value: 'md:object-left',
      label: 'Left Center',
    },
    {
      value: 'md:object-left-bottom',
      label: 'Left Bottom',
    },
    {
      value: 'md:object-top',
      label: 'Center Top',
    },
    {
      value: 'md:object-center',
      label: 'Center Center',
    },
    {
      value: 'md:object-bottom',
      label: 'Center Bottom',
    },
    {
      value: 'md:object-right-top',
      label: 'Right Top',
    },
    {
      value: 'md:object-right',
      label: 'Right Center',
    },
    {
      value: 'md:object-right-bottom',
      label: 'Right Bottom',
    },
  ],
};

const TEXT_ALIGN = {
  mobile: [
    { label: 'Left', value: 'text-left' },
    { label: 'Center', value: 'text-center' },
    { label: 'Right', value: 'text-right' },
  ],
  desktop: [
    { label: 'Left', value: 'md:text-left' },
    { label: 'Center', value: 'md:text-center' },
    { label: 'Right', value: 'md:text-right' },
  ],
};

const PADDING = [
  { label: 'None', value: '' },
  { label: 'Padding: 80px', value: 'p-6 lg:p-20' },
];

const VERTICAL_PADDING = {
  mobile: [
    { label: 'None', value: 'max-md:py-0' },
    { label: 'Top and Bottom - Small', value: 'max-md:py-[17px]' },
    { label: 'Top and Bottom - Default', value: 'max-md:py-contained' },
    { label: 'Top and Bottom - Big', value: 'max-md:py-[80px]' },
    { label: 'Top - Small', value: 'max-md:pt-[17px]' },
    { label: 'Top - Default', value: 'max-md:pt-[3.0rem]' },
    { label: 'Top - Big', value: 'max-md:pt-[80px]' },
    { label: 'Bottom - Small', value: 'max-md:pb-[17px]' },
    { label: 'Bottom - Default', value: 'max-md:pb-[3.0rem]' },
    { label: 'Bottom - Big', value: 'max-md:pb-[80px]' },
  ],
  desktop: [
    { label: 'None', value: 'md:py-0' },
    { label: 'Top and Bottom - Small', value: 'md:py-[1.5rem]' },
    { label: 'Top and Bottom - Default', value: 'md:py-contained' },
    { label: 'Top and Bottom - Big', value: 'md:py-[92px]' },
    { label: 'Top - Small', value: 'md:pt-[1.5rem]' },
    { label: 'Top - Default', value: 'md:pt-[3.0rem]' },
    { label: 'Top - Big', value: 'md:pt-[92px]' },
    { label: 'Bottom - Small', value: 'md:pb-[1.5rem]' },
    { label: 'Bottom - Default', value: 'md:pb-[3.0rem]' },
    { label: 'Bottom - Big', value: 'md:pb-[92px]' },
  ],
};

const FONT_OPTIONS = [
  { label: 'Default', value: '' },
  { label: 'H1', value: 'text-title-h1' },
  { label: 'H2', value: 'text-title-h2' },
  { label: 'H3', value: 'text-title-h3' },
  { label: 'H4', value: 'text-title-h4' },
  { label: 'H5', value: 'text-title-h5' },
  { label: 'H6', value: 'text-title-h6' },
  { label: 'Card title', value: 'card-title' },
  { label: 'Body', value: 'text-body' },
  { label: 'Body small', value: 'text-body-sm' },
  { label: 'Link', value: 'text-link' },
  { label: 'Caption', value: 'text-caption' },
  { label: 'Label', value: 'text-label' },
  { label: 'Label small', value: 'text-label-sm' },
  { label: 'Text nav', value: 'text-nav' },
  { label: 'Superheading', value: 'text-superheading' },
  { label: 'Text button', value: 'btn-text' },
];

export {
  COLORS,
  TEXT_COLORS,
  FLEX_POSITIONS,
  OBJECT_POSITIONS,
  TEXT_ALIGN,
  PADDING,
  VERTICAL_PADDING,
  FONT_OPTIONS
};
