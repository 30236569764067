import { COLORS } from './common';

export default {
  label: 'Cart',
  name: 'cart',
  component: 'group',
  description: 'Cart upsell, free shipping meter, empty cart, totals',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'My Cart',
    },
    {
      label: 'Empty Cart',
      name: 'emptyCart',
      component: 'group',
      description: 'Message, links',
      fields: [
        {
          label: 'Empty Cart Message',
          name: 'message',
          component: 'text',
          defaultValue: 'Your cart is empty',
        },
        {
          label: 'Empty Cart Second Message',
          name: 'messageSecond',
          component: 'text',
        },
        {
          label: 'Links',
          name: 'links',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
            {
              label: 'Button Style',
              name: 'style',
              component: 'select',
              options: [
                { label: 'Primary', value: 'btn-primary' },
                { label: 'Secondary', value: 'btn-secondary' },
                { label: 'Inverse Light', value: 'btn-inverse-light' },
                { label: 'Inverse Dark', value: 'btn-inverse-dark' },
                { label: 'Black', value: 'btn-black' },
                { label: 'Inverse Black', value: 'btn-inverse-black' },
              ],
            },
          ],
          defaultItem: {
            link: { url: '/collections/all', text: 'Continue Shopping' },
            style: 'btn-inverse-black',
          },
          defaultValue: [
            {
              link: { url: '/collections/all', text: 'Continue Shopping' },
              style: 'btn-inverse-black',
            },
          ],
        },
      ],
    },
    {
      label: 'Free Shipping Meter',
      name: 'freeShipping',
      component: 'group',
      description: 'Enable, minimum cart spend, messages',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Minimum Cart Spend',
          name: 'minCartSpend',
          component: 'number',
          description: 'Minimum cart spend to qualify for free shipping',
        },
        {
          label: 'Progress Message',
          name: 'progressMessage',
          component: 'text',
          description:
            'Message when cart has not yet reached minimum spend. Use {{amount}} to display the remaining amount',
        },
        {
          label: 'Qualified Message',
          name: 'qualifiedMessage',
          component: 'text',
          description: 'Message when cart has qualified',
        },
        {
          label: 'Progress Bar Color',
          name: 'progressBarColor',
          component: 'select',
          options: COLORS,
        },
      ],
      defaultValue: {
        enabled: false,
        minCartSpend: 100,
        progressMessage: `You're only {{amount}} away from free shipping!`,
        qualifiedMessage: `You've qualified for free shipping!`,
        progressBarColor: 'var(--primary)',
      },
    },
    {
      label: 'Totals',
      name: 'totals',
      component: 'group',
      description: 'Subtotal text, subtext, checkout text',
      fields: [
        {
          label: 'Subtotal Text',
          name: 'subtotalText',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'text',
        },
        {
          label: 'Checkout Text',
          name: 'checkoutText',
          component: 'text',
        },
      ],
      defaultValue: {
        subtotalText: 'Subtotal',
        subtext: '',
        checkoutText: 'Checkout',
      },
    },
    {
      label: 'Upsell',
      name: 'upsell',
      component: 'group',
      description: 'Enable, message, product',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Progress Message',
          name: 'progressMessage',
          component: 'textarea',
          description:
            'Message when cart has not yet reached minimum spend. Use {{amount}} to display the remaining amount',
          defaultValue: `Add {{amount}} more to unlock the free gift!\nOnly 1 bonus item per order, while supplies last.`,
        },
        {
          label: 'Qualified Message',
          name: 'qualifiedMessage',
          component: 'text',
          description: 'Message when cart has qualified',
          defaultValue: `Congrats! Please select your bonus item.\nLimit one per order, while supplies last.`,
        },
        {
          label: 'Additional Info',
          name: 'additionalInfo',
          component: 'text',
          description: 'Additional Information',
          defaultValue: ``,
        },
        {
          label: 'Unlock threshold',
          name: 'threshold',
          component: 'number',
        },
        {
          label: 'Product',
          name: 'product',
          component: 'productSearch',
        },
        {
          component: 'group',
          name: 'selectorFields',
          label: 'Product Selector Fields',
          description: 'Fields related to the product varaint selector.',
          fields: [
            {
              label: 'Gift Message',
              name: 'giftMessage',
              component: 'text',
            },
            {
              label: 'First Product Option Label',
              name: 'firstOptLabel',
              component: 'text',
            },
            {
              label: 'First Product Option Placeholder',
              name: 'firstOptPlaceholder',
              component: 'text',
            },
            {
              label: 'Second Product Option Label',
              name: 'secondOptLabel',
              component: 'text',
            },
            {
              label: 'Second Product Option Placeholder',
              name: 'secondOptPlaceholder',
              component: 'text',
            },
            {
              label: 'CTA Progress Message',
              name: 'progressMessage',
              component: 'text',
            },
          ]
        }
      ],
      defaultValue: {
        enabled: false,
        progressMessage: `Add {{amount}} more to unlock the free gift!\nOnly 1 bonus item per order, while supplies last.`,
        qualifiedMessage: `Congrats! Please select your bonus item.\nLimit one per order, while supplies last.`,
      },
    },
  ],
};
