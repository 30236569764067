import { useState } from 'react';

import { Image } from '../../snippets';
import { ProductOptions } from '../../templates/product/ProductOptions';
import { useSelectedVariant } from '../../templates/product/useSelectedVariant';

export function OutfitCustomizerItem({
  product,
  selectGroupItem,
  setSelectedVariantOption,
}) {
  const {
    selectedVariant,
    setSelectedOptions,
    selectedOptions,
    selectedMedia,
  } = useSelectedVariant({ product });

  const [isActive, setIsActive] = useState(false);

  return (
    <div className="">
      {!isActive ? (
        <div
          className="flex cursor-pointer items-center gap-4"
          onClick={() => {
            setIsActive(true);
            selectGroupItem(product.title);
          }}
        >
          <Image src={product.featuredImage.src} height={100} width={80} />
          <div>
            <p className="font-bold">{product?.title}</p>
            <p>Colors: {product?.optionsMap?.Color?.length}</p>
          </div>
        </div>
      ) : (
        <div>
          <ProductOptions
            product={product}
            selectedOptions={selectedOptions}
            selectedVariant={selectedVariant}
            setSelectedOptions={setSelectedOptions}
          />

          <button
            className="btn-primary w-full text-[15px]"
            onClick={() => setSelectedVariantOption(selectedVariant)}
          >
            Confirm Options
          </button>
        </div>
      )}
    </div>
  );
}

OutfitCustomizerItem.displayName = 'OutfitCustomizerItem';
