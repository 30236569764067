import { useMemo } from 'react';
import { Markdown, Svg, Image, Link } from '../../snippets';
import { useProductMetafields } from '../../hooks';
import { Schema } from './IconRow.schema';

export function IconRow({ cms, product }) {
  const {
    heading,
    headingAlignment,
    buttonPlacement,
    lineBelowTitle,
    icons,
    section,
    subtext,
    buttonLink,
  } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  const metafields = useProductMetafields({ product });

  const displayHeading = heading || metafields?.custom_fields?.bk_banner_title;
  const stylesLineBelowTitle = lineBelowTitle
    ? 'after:absolute after:left-[0px] after:bottom-[-20px] after:h-[6px] after:w-[74px] after:bg-[#202844] relative'
    : '';
  const displaySubtext =
    subtext || metafields?.custom_fields?.bk_banner_subtitle;
  const displayButtonText =
    buttonLink?.text || metafields?.custom_fields?.bk_link_text;
  const displayButtonLink =
    buttonLink?.url || metafields?.custom_fields?.bk_link_url;

  return (
    <div
      className={`${section?.verticalPaddingLevel} ${section?.verticalPaddingLevelMobile} px-contained px-0 py-[3rem] md:py-[6rem]`}
      style={{ backgroundColor: section?.bgColor, color: section?.textColor }}
    >
      <div
        className={`mx-auto flex flex-col items-center gap-4 text-center md:gap-6 ${maxWidthClass}`}
      >
        {displayHeading && (
          <h2
            className={`h3 ${headingAlignment} max-w-[46rem] ${stylesLineBelowTitle}`}
          >
            {displayHeading}
          </h2>
        )}

        {displaySubtext && (
          <div className="mx-auto max-w-[400px] [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base">
            <Markdown>{displaySubtext}</Markdown>
          </div>
        )}

        {displayButtonLink && displayButtonText && buttonPlacement === 'top' && (
          <div>
            <Link
              aria-label={displayButtonText}
              href={displayButtonLink}
              className="btn-inverse-dark"
            >
              {displayButtonText}
            </Link>
          </div>
        )}

        {icons?.length > 0 && (
          <ul
            className={`mt-4 flex flex-col flex-wrap content-center md:flex-row md:content-normal ${
              section?.spacingOption
            } ${section?.innerFullWidth ? 'w-full' : 'w-auto'}`}
          >
            {icons.map(({ icon, label, description, customIcon }, index) => {
              return (
                <li
                  key={index}
                  className={`flex ${
                    section?.innerFullWidth ? '' : 'max-w-[340px] grow'
                  } basis-1/2 flex-col items-center text-center max-md:max-w-[340px] max-md:py-4 md:p-4 ${
                    section?.columnsDesktop
                  } ${index === 0 ? 'lg:pl-0' : ''}`}
                >
                  {customIcon ? (
                    <div>
                      <Image
                        alt={label}
                        width={section?.iconSize}
                        height={section?.iconSize}
                        src={customIcon.src}
                      />
                    </div>
                  ) : (
                    <Svg
                      className="w-12"
                      src={`/svgs/noprecache/icons/${icon}.svg#${icon} h-[100px] w-[100px]`}
                      style={{ color: section?.iconColor }}
                      title={label || icon}
                      viewBox="0 0 24 24"
                    />
                  )}

                  {label && (
                    <p
                      className={`${
                        section?.iconSize == 50
                          ? 'text-title-h4 mt-[18px] font-montserrat'
                          : 'text-title-h4 mt-3'
                      } `}
                    >
                      {label}
                    </p>
                  )}

                  {description && (
                    <p className="mt-4 text-[.875rem] leading-[1.55]">
                      {description}
                    </p>
                  )}
                </li>
              );
            })}
          </ul>
        )}

        {displayButtonLink && displayButtonText && buttonPlacement === 'bottom' && (
          <div>
            <Link
              aria-label={displayButtonText}
              href={displayButtonLink}
              className="btn-inverse-dark"
            >
              {displayButtonText}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

IconRow.displayName = 'IconRow';
IconRow.Schema = Schema;
