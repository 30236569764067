import { useProductMetafields } from '../../hooks';

export function ProductFabricIcon({ product, isPDP }) {
  const metafields = useProductMetafields({ product });
  const iconText = metafields?.custom_fields?.p_card_fabric_iconography;
  const sizeClasses = isPDP ? 'h-[22px] text-[10px]' : 'h-[20px] text-[7px]';

  return iconText ? (
    <div
      className={`${sizeClasses} text-nowrap whitespace-nowrap flex items-center whitespace-nowrap rounded-full border-[1px] border-mediumGray  px-2 font-bold uppercase text-mediumGray max-md:mr-0 max-md:text-[8px] md:py-1`}
    >
      {iconText}
    </div>
  ) : null;
}

ProductFabricIcon.displayName = 'ProductFabricIcon';
