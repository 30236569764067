import { ArticleHeroSlide } from './ArticleHeroSlide';
import { ArticleHeroSlider } from './ArticleHeroSlider';
import { Schema } from './ArticleHero.schema';

export function ArticleHero({ cms }) {
  const { section, slider, slides } = cms;

  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  const fullBleedClass = section?.fullBleed ? '' : 'px-contained';

  const bottomMargin = section?.bottomMargin ? 'mb-4 md:mb-8 xl:mb-12' : '';

  const heightClassesDesktop =
    section?.desktop?.heightType === 'aspect-ratio'
      ? `${section?.desktop?.aspectRatio} ${section?.desktop?.minHeight} ${section?.desktop?.maxHeight}`
      : section?.desktop?.staticHeight || 'lg:h-[43.75rem]';

  const heightClassesTablet =
    section?.tablet?.heightType === 'aspect-ratio'
      ? `${section?.tablet?.aspectRatio} ${section?.tablet?.minHeight} ${section?.tablet?.maxHeight}`
      : section?.tablet?.staticHeight || 'md:max-lg:h-[37.5rem]';

  const heightClassesMobile =
    section?.mobile?.heightType === 'aspect-ratio'
      ? `${section?.mobile?.aspectRatio} ${section?.mobile?.minHeight} ${section?.mobile?.maxHeight}`
      : section?.mobile?.staticHeight || 'max-md:h-[31.25rem]';

  const heightContainerClasses = `${heightClassesMobile} ${heightClassesTablet} ${heightClassesDesktop}`;

  return (
    <div className={`${fullBleedClass}`}>
      <div
        className={`relative mx-auto flex w-full flex-col bg-offWhite ${heightContainerClasses} ${maxWidthContainerClass} ${bottomMargin}`}
      >
        {slides?.length > 1 && (
          <ArticleHeroSlider
            aboveTheFold={section?.aboveTheFold}
            slider={slider}
            slides={slides}
          />
        )}

        {slides?.length === 1 && (
          <ArticleHeroSlide
            aboveTheFold={section?.aboveTheFold}
            isActiveSlide
            isFirstSlide
            slide={slides[0]}
          />
        )}
      </div>
    </div>
  );
}

ArticleHero.displayName = 'ArticleHero';
ArticleHero.Schema = Schema;
