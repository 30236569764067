import { Markdown, ShopifyRichText } from '../../snippets';

export function ProductDetailTab({
  tab,
  metafields,
}) {
  const { body, header, metafieldKey } =
    tab;

  const scrollToShowcase = () => {
    let element = document.getElementById('product-showcase');
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const isProdDesc = header === 'Product Description';

  const hasLearnMore = !!(
    isProdDesc && metafields?.custom_fields?.showcase_header
  );

  const bodyShopify = metafields?.bylt ? metafields?.bylt[metafieldKey] : null;


  if (!bodyShopify && !body) {
    return null;
  }

  return (
    <div>
      <div
        className={`px-contained pb-[25px] pt-[18px] lg:pb-[30px] lg:pt-[30px] [&_h1]:mb-3 [&_h1]:text-base [&_h2]:mb-3 [&_h2]:text-base [&_h3]:mb-3 [&_h3]:text-base [&_h4]:mb-3 [&_h4]:text-base [&_h5]:mb-3 [&_h5]:text-base [&_h6]:mb-3 [&_h6]:text-base [&_li]:text-xs md:[&_li]:text-sm [&_ol]:!pl-4 [&_ol]:text-base [&_p]:mb-3 [&_p]:text-xs md:[&_p]:text-sm [&_ul]:list-disc [&_ul]:!pl-4 [&_ul]:text-base ${
          hasLearnMore ? '[&_div]:inline [&_p]:inline' : ''
        }`}
      >
        <div className="!block lg:max-w-[50%]">
          {!bodyShopify ? (
            <Markdown>{body}</Markdown>
          ) : (
            <ShopifyRichText shopifyTextObject={bodyShopify} />
          )}

          {hasLearnMore && (
            <p
              className="text-underline ml-2 inline cursor-pointer"
              onClick={scrollToShowcase}
            >
              Learn More
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

ProductDetailTab.displayName = 'ProductDetailTab';
