import { useMemo } from 'react';

export function useCollectionMetafields({ collection }) {
  return useMemo(() => {
    if (!collection || !collection?.metafields) {
      return null;
    }

    return collection?.metafields?.reduce((obj, metafield) => {
      const { namespace, key, value } = metafield;

      if (obj[namespace]) {
        obj[namespace][key] = value;
      } else {
        obj[namespace] = {};
        obj[namespace][key] = value;
      }

      return obj;
    }, {});
  }, [collection]);
}
