import { useCallback, useEffect, useRef, useState } from 'react';
import { sendGraphlEvent, getCookie, nostoMapLineItems, getIdFromRawShopifyId } from './utils';
import { useCustomer, useCartItems } from '@backpackjs/storefront';

export function useDataLayerProduct({ DEBUG, userDataEvent, userProperties }) {
  const productHandleRef = useRef(null);
  const customer = useCustomer();
  const cartItems = useCartItems();

  const [viewedProductVariant, setViewedProductVariant] = useState(null);

  const viewProductGraph = async (productId) => {
    const nostoCookie = getCookie('nosto-session');
    const cartFragment = nostoMapLineItems(cartItems)
    const customerId = getIdFromRawShopifyId(customer?.id, 'Customer')

    const query = `mutation {
        updateSession(by: BY_CID, id: "${nostoCookie}",
          params: {
            ${customer ? `customer: {
              firstName: "${customer?.firstName}"
              lastName: "${customer?.lastName}"
              marketingPermission: ${customer?.acceptsMarketing}
              customerReference: "${customerId}"
            }` : ''}
            event: {
              type: VIEWED_PRODUCT
              target: "${productId}"
            }
            ${cartFragment}
          }
        ) {
          id
        }
      }`;

      await sendGraphlEvent(query);
  }

  const viewProductEvent = useCallback(
    ({ variant, userProperties: _userProperties }) => {
      if (!variant) return;

      const productId = variant.product.id.split('gid://shopify/Product/')[1];

      nostojs((api) => {
        api
          .defaultSession()
          .setResponseMode('JSON_ORIGINAL')
          .viewProduct(productId)
          .setPlacements(api.placements.getPlacements())
          .load()
          .then((data) => {});
      });

      if (typeof window !== 'undefined') {
        viewProductGraph(productId)
      }

      if (DEBUG) console.log(`Nosto: Product View`);
    },
    []
  );

  // Subscribe to PubSub topic for 'view_item' event
  useEffect(() => {
    const viewProduct = PubSub.subscribe(
      'VIEW_PRODUCT_PAGE',
      async (event, variant) => {
        setViewedProductVariant(variant);
      }
    );
    return () => {
      if (viewProduct) {
        PubSub.unsubscribe(viewProduct);
      }
    };
  }, []);

  // Trigger 'user_data' and 'view_item' events on viewedProductVariant change after base data is ready
  useEffect(() => {
    const pageHandle = window.location.pathname.split('/').pop();
    if (
      !userProperties ||
      !viewedProductVariant ||
      productHandleRef.current === pageHandle
    )
      return;

    viewProductEvent({ variant: viewedProductVariant, userProperties });
    productHandleRef.current = pageHandle;
  }, [viewedProductVariant?.product?.id, !!userProperties]);
}
