import { useMemo, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { CollectionFilters, CollectionFiltersChips } from './CollectionFilters';
import { CollectionGrid } from './CollectionGrid';
import { CollectionSort } from './CollectionSort';
import { useCollectionFilters } from './useCollectionFilters';
import { useCollectionProducts } from './useCollectionProducts';
import { useColorSwatches } from '../../hooks';

export function Collection({
  handle,
  products,
  productsReady,
  showHeading,
  title,
  linkFilters,
  toggleButtons
}) {
  const { swatchesMap } = useColorSwatches();
  const settings = useSettings();
  const {
    filters: filtersSettings,
    sort: sortSettings,
    pagination,
    promotion,
  } = { ...settings?.collection };

  const enabledFilters = filtersSettings?.enabled;
  const enabledSort = sortSettings?.enabled;
  const isSearchResults = handle === 'search';

  const promoTiles = useMemo(() => {
    if (!promotion?.campaigns?.length) return null;
    const campaign = promotion.campaigns.find(({ collections, enabled }) => {
      if (!enabled) return false;
      return collections?.some((colHandle) => colHandle.trim() === handle);
    });
    return campaign?.promoTiles || null;
  }, [handle, promotion?.campaigns]);

  const collectionFiltersData = useCollectionFilters({
    enabledFilters,
    products,
    productsReady,
  });

  const collectionProductsData = useCollectionProducts({
    activeFilters: collectionFiltersData.state.activeFilters,
    filtersMap: collectionFiltersData.state.filtersMap,
    handle,
    pagination,
    products,
    productsReady,
    promoTiles,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;

  const stickyTopClass = stickyPromobar
    ? 'max-md:top-[calc(var(--mobile-header-height)+var(--promobar-height)+2.3rem)]'
    : 'max-md:top-[calc(var(--mobile-header-height)+2.3rem)]';

  const desktopStickyTopClass = stickyPromobar
    ? 'md:top-[calc(var(--header-height)+var(--promobar-height)+2.3rem)]'
    : 'md:top-[calc(var(--header-height)+2.3rem)]';

  const mobileStickyClass = `max-lg:sticky ${stickyTopClass} max-lg:bg-white ${isOpen || mobileFiltersOpen ? 'max-lg:z-30' : 'max-lg:z-20'
    }`;

  const [sortMobileClass, setSortMobileClass] = useState('');

  const setSidebarClasses = (val) => {
    setMobileFiltersOpen(val);
    setSortMobileClass(val ? 'max-lg:!z-10' : '');
  };

  const hasToggleButtons = !!(toggleButtons)

  return (
    <div>
      {hasToggleButtons && (
        <div className='lg:hidden py-6 md:px-4'>
          {toggleButtons || null}
        </div>
      )}


      <div
        className={`mx-auto grid w-full grid-cols-2 ${enabledFilters
          ? 'lg:grid-cols-[1fr_4.5fr] lg:gap-x-10'
          : 'lg:grid-cols-1'
          }`}
      >
        <div className=" max-lg:hidden lg:block" />

        {enabledSort && (
          <div
            className={`sticky flex justify-between ${mobileStickyClass} ${desktopStickyTopClass} ${sortMobileClass} bg-white lg:z-10`}
          >
            <div className='flex gap-4'>
              {hasToggleButtons && (
                <div className='max-lg:hidden py-2 pl-4'>
                  {toggleButtons || null}
                </div>
              )}



              <CollectionFiltersChips
                collectionFiltersData={collectionFiltersData}
              />
            </div>

            <div
              className={`max-lg:order-2 max-lg:w-full ${showHeading ? '' : 'lg:col-span-2'
                } ${enabledFilters ? '' : 'max-lg:col-span-2'} `}
            >
              <CollectionSort
                selectedSort={collectionProductsData.state.selectedSort}
                selectSort={collectionProductsData.actions.selectSort}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </div>
          </div>
        )}

        {enabledFilters && (
          <div
            className={`${enabledSort ? 'max-lg:order-[-1]' : 'max-lg:col-span-2'
              } ${mobileStickyClass} ${isOpen ? 'opacity-0' : ''}`}
          >
            <CollectionFilters
              collectionFiltersData={collectionFiltersData}
              collectionCount={products?.length}
              swatchesMap={swatchesMap}
              linkFilters={linkFilters}
              setSidebarClasses={setSidebarClasses}
              isSortSidebarOpen={isOpen}
            />
          </div>
        )}

        <div className="max-lg:order-3 max-lg:col-span-2 max-lg:mt-6">
          <CollectionGrid
            activeFilters={collectionFiltersData.state.activeFilters}
            collectionProductsData={collectionProductsData}
            enabledFilters={enabledFilters}
            isSearchResults={isSearchResults}
            promoTiles={promoTiles}
            swatchesMap={swatchesMap}
          />
        </div>
      </div>
    </div>
  );
}

Collection.displayName = 'Collection';
Collection.defaultProps = {
  handle: undefined,
  products: [],
  productsReady: true,
  showHeading: false,
  title: '',
};
Collection.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  productsReady: PropTypes.bool,
  showHeading: PropTypes.bool,
  title: PropTypes.string,
};
