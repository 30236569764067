import { Schema } from './OneTrustCookieList.schema';

export function OneTrustCookieList({ cms }) {
  const { content, html, section } = cms;
  return  (
    <div
      className={`${section?.hasXPadding ? 'px-contained' : ''} ${
        section?.hasYPadding ? 'py-contained' : ''
      } ${section?.maxWidth} m-auto`}
    >
      <h2 className="text-lg font-bold leading-[32.73px] lg:text-[28px] mb-5">COOKIE LIST</h2>
      <div id="ot-sdk-cookie-policy"></div>
    </div>
  );
}

OneTrustCookieList.displayName = 'OneTrustCookieList';
OneTrustCookieList.Schema = Schema;
