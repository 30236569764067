import { Link, Markdown as MarkdownSnippet } from '../../snippets';
import { Schema } from './Markdown.schema';

export function Markdown({ cms }) {
  const { buttons, centerAllText, useFullWidth, fontSizes, content, section } =
    cms;

  const markdownParagraphWidth = useFullWidth ? '[&_p]:!max-w-full' : '';
  const markdownCustomClasses = `${markdownParagraphWidth} ${fontSizes}`;
  const extraPaddingClasses = section?.additionalBottomPadding
    ? 'md:pb-10'
    : '';

  return (
    <div className="px-contained py-contained">
      <div className={`mx-auto ${section?.maxWidth} ${extraPaddingClasses}`}>
        <MarkdownSnippet
          centerAllText={centerAllText}
          customClasses={markdownCustomClasses}
        >
          {content}
        </MarkdownSnippet>
        {buttons?.length > 0 && (
          <ul className="justify-left mt-4 flex flex-col gap-4 xs:flex-row">
            {buttons.slice(0, 2).map(({ link, style }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-label={link?.text}
                    className={style}
                    href={link?.url}
                    newTab={link?.newTab}
                    type={link?.type}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

Markdown.displayName = 'Markdown';
Markdown.Schema = Schema;
