import { useCallback, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { useDataLayerActions } from '../../hooks';

export function EmailSignup() {
  const { sendSubscribeEvent } = useDataLayerActions();
  const settings = useSettings();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState(null);

  const { enabled, heading, subtext, placeholder, buttonText } = {
    ...settings?.footer?.marketing,
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError(null); // Clear previous errors when a new request starts

      const email = e.target.email.value;

      try {
        const response = await fetch('/api/subscribe', {
          method: 'POST',
          body: JSON.stringify({ email }),
        });

        if (!response.ok) {
          throw new Error('Failed to submit the data. Please try again.');
        } else {
          setIsSuccessful(true);
        }

        sendSubscribeEvent({ email });
      } catch (err) {
        // Capture the error message to display to the user
        setError(err.message);
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    [sendSubscribeEvent]
  );

  return enabled ? (
    <form className="px-0 py-8 lg:border-none lg:p-0" onSubmit={handleSubmit}>
      <h3 className="text-nav text-base text-current">{heading}</h3>

      {subtext && <p className="mt-2 text-[12px] text-current">{subtext}</p>}

      {!isSuccessful && (
        <div className="mt-8 lg:mt-6 flex">
          <input
            className="input-text h-auto rounded-none text-text placeholder:text-xs"
            name="email"
            placeholder={placeholder}
            required
            type="email"
          />

          <button
            disabled={isLoading}
            aria-label={buttonText}
            className="btn-secondary w-auto min-w-[auto] rounded-none border border-gray text-[10px] tracking-wide lg:text-sm"
            type="submit"
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </button>
        </div>
      )}
      {error && <div className="text-red-700">{error}</div>}
      {isSuccessful && (
        <p className="lg:text-white">
          Keep an eye out for a message in your inbox shortly
        </p>
      )}
    </form>
  ) : null;
}

EmailSignup.displayName = 'EmailSignup';
