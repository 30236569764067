import { useCallback, useEffect, useRef, useState } from 'react';

import { mapProductPageVariant } from './utils';

export function useDataLayerProduct({ DEBUG, userDataEvent, userProperties }) {
  const productHandleRef = useRef(null);

  const [viewedProductVariant, setViewedProductVariant] = useState(null);

  const viewProductEvent = useCallback(
    ({ variant, userProperties: _userProperties }) => {
      if (!variant) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list = previousPath?.startsWith('/collections') ? previousPath : '';
      const event = {
        event: 'dl_view_item',
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: variant.priceV2?.currencyCode,
          detail: {
            actionField: { list, action: 'detail' },
            products: [variant].map(mapProductPageVariant(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  const viewProductEventPostscript = useCallback(
    ({ variant, userProperties: _userProperties }) => {
      if (!variant) return;

      try {
        if (!window?.postscript) return;

        const productId =
          variant?.product?.id?.split('gid://shopify/Product/')[1] || '';
        const variantId =
          variant?.id?.split('gid://shopify/ProductVariant/')[1] || '';

        const event = {
          shop_id: '304875',
          url: `https://byltbasics.com/products/${variant?.product?.handle}`,
          search_params: { variant: variantId },
          page_type: 'product',
          referrer: `https://byltbasics.com${window.location.pathname}`,
          resource: {
            category: variant?.product?.productType,
            name: variant?.product.title,
            price_in_cents: parseFloat(variant?.priveV2?.amount) * 100,
            resource_id: productId,
            resource_type: 'product',
            sku: variant?.sku,
            variant_id: variantId,
            vendor: 'BYLT Basics',
          },
        };

        window?.postscript?.event('page_view', event);
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  // Subscribe to PubSub topic for 'dl_view_item' event
  useEffect(() => {
    const viewProduct = PubSub.subscribe(
      'VIEW_PRODUCT_PAGE',
      async (event, variant) => {
        setViewedProductVariant(variant);
      }
    );
    return () => {
      if (viewProduct) {
        PubSub.unsubscribe(viewProduct);
      }
    };
  }, []);

  // Trigger 'dl_user_data' and 'dl_view_item' events on viewedProductVariant change after base data is ready
  useEffect(() => {
    const pageHandle = window.location.pathname.split('/').pop();
    if (
      !userProperties ||
      !viewedProductVariant ||
      productHandleRef.current === pageHandle
    )
      return;
    userDataEvent({ userProperties });
    viewProductEvent({ variant: viewedProductVariant, userProperties });
    viewProductEventPostscript({
      variant: viewedProductVariant,
      userProperties,
    });
    productHandleRef.current = pageHandle;
  }, [viewedProductVariant?.product?.id, !!userProperties]);
}
