import { useMemo } from 'react';

import { Link, Markdown, InveterateAddToCart } from '../../snippets';

export function HalfHeroContent({ aboveTheFold, content }) {
  const {
    alignmentDesktop,
    alignmentMobile,
    buttons,
    color,
    divider = true,
    heading,
    maxWidthDesktop,
    subtext,
    superheading,
    headingFontSize,
    superHeadingFontSize,
    enableInveterateAtc,
  } = { ...content };
  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;

  const headingWithBreaks = useMemo(() => {
    const splitHeading = heading?.split('\n');
    if (splitHeading?.length === 1) return heading;
    return splitHeading?.reduce((acc, line, index, arr) => {
      acc.push(<span key={index}>{line}</span>);
      if (index < arr.length - 1) acc.push(<br key={`br${index}`} />);
      return acc;
    }, []);
  }, [heading]);

  const superheadingStyles = superHeadingFontSize || 'text-superheading';
  const headingStyles = headingFontSize || 'text-title-h2';

  return (
    <div className="max-2xl:px-contained py-contained w-full">
      <div
        className={`mx-auto flex flex-col ${alignmentClasses} ${maxWidthDesktop}`}
        style={{ color }}
      >
        <div>
          {superheading && (
            <p className={`${superheadingStyles} mb-1 normal-case lg:mb-1.5`}>
              {superheading}
            </p>
          )}

          {aboveTheFold ? (
            <h1
              className={`${headingStyles} ${subtext ? 'mb-3.5' : 'mb-0'} ${
                divider
                  ? "relative mb-[40px] after:absolute after:bottom-[-15px] after:left-0 after:h-1 after:w-[30%] after:border-2 after:border-[#29527c] after:content-['']"
                  : ''
              } normal-case`}
            >
              {headingWithBreaks}
            </h1>
          ) : (
            <h2
              className={`${headingStyles} ${subtext ? 'mb-3.5' : 'mb-0'} ${
                divider
                  ? "relative mb-[40px] after:absolute after:bottom-[-15px] after:left-0 after:h-1 after:w-[30%] after:border-2 after:border-[#29527c] after:content-['']"
                  : ''
              } normal-case ${subtext ? 'mb-3.5' : 'mb-0'} `}
            >
              {headingWithBreaks}
            </h2>
          )}
        </div>

        {subtext && (
          <div className="[&_p]:text-body [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:leading-[20px]">
            <Markdown>{subtext}</Markdown>
          </div>
        )}

        {buttons?.length > 0 && (
          <ul className="my-6 flex flex-col justify-center gap-4 xs:flex-row">
            {buttons.slice(0, 2).map(({ link, style }, index) => {
              return (
                <li key={index}>
                  {enableInveterateAtc ? (
                    <InveterateAddToCart
                      buttonText={link?.text}
                      buttonStyle={`${style}`}
                    />
                  ) : (
                    <Link
                      aria-label={link?.text}
                      className={style}
                      href={link?.url}
                      newTab={link?.newTab}
                      type={link?.type}
                    >
                      {link?.text}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

HalfHeroContent.displayName = 'HalfHeroContent';
