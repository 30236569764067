import { useMemo } from 'react';
import { useCountry } from '@backpackjs/storefront';

export function useCartItemPrices({ item }) {
  const { discountAllocations, estimatedCost, id, quantity, variant } = {
    ...item,
  };
  const { country } = useCountry();

  return useMemo(() => {
    const totalAmount = parseFloat(estimatedCost?.totalAmount?.amount || '0');

    // compare at price from variant
    const variantAmount = parseFloat(variant?.priceV2?.amount || '0');
    const variantCompareAtAmount = parseFloat(
      variant?.compareAtPriceV2?.amount || '0'
    );
    const compareAtPriceFromVariant =
      variantCompareAtAmount > variantAmount ? variantCompareAtAmount : 0;

    // compare at price from discount
    const subtotalAmount = parseFloat(
      estimatedCost?.subtotalAmount?.amount || '0'
    );
    const totalDiscount = parseFloat(
      discountAllocations?.[0]?.discountedAmount?.amount || '0'
    );
    const compareAtPriceFromDiscount = totalDiscount
      ? subtotalAmount / quantity
      : 0;

    // prices
    const price = totalAmount;
    const compareAtPrice =
      compareAtPriceFromVariant || compareAtPriceFromDiscount;

    const currencySymbol = country ? country.currency.symbol : '$'
    const currencyCode = country ? country.currency.isoCode : 'USD'

    const isUS = country?.isoCode === 'US'

    const usPrice = `${currencySymbol}${price.toFixed(2).replace('.00', '')}`
    const intPrice = `${currencyCode} ${price.toFixed(2).replace('.00', '')}`

    const usCompareAtPrice = compareAtPrice ? `${currencySymbol}${compareAtPrice.toFixed(2).replace('.00', '')}` : null
    const intCompareAtPrice = compareAtPrice ? `${currencyCode} ${compareAtPrice.toFixed(2).replace('.00', '')}` : null

    return {
      price: isUS ? usPrice : intPrice,
      compareAtPrice: isUS ? usCompareAtPrice : intCompareAtPrice,
    };
  }, [discountAllocations, estimatedCost, id, quantity, country]);
}
