import PropTypes from 'prop-types';

import { Spinner } from '../Spinner';
import { Svg } from '../Svg';

export function QuantitySelector({
  disableDecrement,
  handleDecrement,
  handleIncrement,
  isUpdating,
  productTitle,
  quantity,
  isCart,
}) {
  return (
    <div
      className={`flex w-32 items-center ${!isCart ? 'justify-between' : ''}`}
    >
      <button
        aria-label={`Reduce quantity of ${productTitle} by 1 to ${
          quantity - 1
        }`}
        className={`relative ${
          !isCart ? 'h-[3.125rem] w-1/4 border border-border' : 'h-5 w-5'
        } transition disabled:opacity-50 ${
          disableDecrement ? 'cursor-not-allowed' : 'md:hover:border-black'
        }`}
        disabled={isUpdating || disableDecrement}
        onClick={handleDecrement}
        type="button"
      >
        <Svg
          className={`absolute left-1/2 top-1/2 ${
            !isCart ? 'w-4' : 'w-3'
          } -translate-x-1/2 -translate-y-1/2 text-text`}
          src="/svgs/minus.svg#minus"
          title="Minus"
          viewBox="0 0 24 24"
        />
      </button>

      <div
        className={`relative flex ${
          !isCart ? 'h-[3.125rem] flex-1 border-y border-border' : 'w-12'
        }  items-center justify-center`}
      >
        {isUpdating ? (
          <Spinner color="var(--gray)" width="20" />
        ) : (
          <p className="w-full text-center outline-none">{quantity}</p>
        )}
      </div>

      <button
        aria-label={`Increase quantity of ${productTitle} by 1 to ${
          quantity + 1
        }`}
        className={`relative ${
          !isCart
            ? 'h-[3.125rem] w-1/4 border border-border md:hover:border-black'
            : 'h-5 w-5'
        } transition disabled:opacity-50 `}
        disabled={isUpdating}
        onClick={handleIncrement}
        type="button"
      >
        <Svg
          className={`absolute left-1/2 top-1/2 ${
            !isCart ? 'w-4' : 'w-3'
          } -translate-x-1/2 -translate-y-1/2 text-text`}
          src="/svgs/plus.svg#plus"
          title="Plus"
          viewBox="0 0 24 24"
        />
      </button>
    </div>
  );
}

QuantitySelector.displayName = 'QuantitySelector';
QuantitySelector.defaultProps = {
  disableDecrement: false,
  handleDecrement: undefined,
  handleIncrement: undefined,
  isUpdating: false,
  productTitle: 'product',
  quantity: 1,
};
QuantitySelector.propTypes = {
  disableDecrement: PropTypes.bool,
  handleDecrement: PropTypes.func,
  handleIncrement: PropTypes.func,
  isUpdating: PropTypes.bool,
  productTitle: PropTypes.string,
  quantity: PropTypes.number,
};
