import { useMemo } from 'react';

import { formatImageItemAsMediaItem } from '../../../utilities';

export function useProductItemMedia({
  selectedProduct,
  selectedVariant,
  isLoaded,
}) {
  const isCustom = selectedProduct?.handle?.includes('-custom-pack');

  const fixedMedia = useMemo(() => {
    if(selectedProduct?.media){
      return [...selectedProduct?.media].sort(function (a, b) {
        return a?.alt?.localeCompare(b.alt);
      })
    }

    if(!selectedProduct?.media && selectedProduct?.images){
      return [...selectedProduct?.images].sort(function (a, b) {
        return a?.altText?.localeCompare(b.altText);
      });
    }
  }, [selectedProduct]);

  const mediaMapByColor = useMemo(() => {
    if (!selectedProduct) return null;
    const colorOptions =
      selectedProduct.optionsMap?.Color || selectedProduct?.options[0]?.values;
    const colorKeys = colorOptions.map((color) =>
      color.replace(/\s/g, '-').toLowerCase()
    );

    return colorOptions.reduce((acc, color) => {
      let firstMediaIndex = 0;
      let secondMediaIndex = 1;
      const medias = fixedMedia;
      const colorKey = color.replace(/\s/g, '-').toLowerCase();

      firstMediaIndex = medias.findIndex((item) => {
        const alt = (item.alt || item.altText)
          ?.replace(/\s/g, '-')
          ?.trim()
          ?.toLowerCase()
          ?.split(' [')[0];
        return alt === colorKey;
      });

      firstMediaIndex =
        firstMediaIndex > -1
          ? firstMediaIndex
          : medias.findIndex((item) => {
              const alt = (item.alt || item.altText)
                ?.trim()
                ?.toLowerCase()
                ?.replace('/', '-')
                ?.split(' [')[0];
              return alt?.includes(colorKey);
            });

      const secondMediaAlt = medias[firstMediaIndex + 1]?.alt
        ?.trim()
        .toLowerCase();

      secondMediaIndex =
        secondMediaAlt === colorKey || !colorKeys.includes(secondMediaAlt)
          ? firstMediaIndex + 1
          : -1;

      if (isCustom) {
        firstMediaIndex = 0;
        secondMediaIndex = 0;
      }

      let media = [medias[firstMediaIndex], medias[secondMediaIndex]];
      if (!selectedProduct?.media) {
        // if shopify media transforms is off, shape image item similar to media item
        media = media.map((image) => {
          return formatImageItemAsMediaItem({
            image,
            product: selectedProduct,
          });
        });
      }
      return { ...acc, [color]: media };
    }, {});
  }, [selectedProduct]);

  const selectedMedia = useMemo(() => {
    if (!mediaMapByColor || !selectedVariant) return null;
    return mediaMapByColor[
      selectedVariant.selectedOptionsMap?.Color ||
        selectedVariant?.selectedOptions[0].value
    ];
  }, [mediaMapByColor, selectedVariant?.id]);

  const preDefaultMedia = useMemo(() => {
    return formatImageItemAsMediaItem({
      image: selectedProduct?.featuredImage,
      product: selectedProduct,
    });
  }, []);

  return {
    primaryMedia: !isLoaded ? preDefaultMedia : selectedMedia?.[0],
    hoverMedia: selectedMedia?.[1],
  };
}
