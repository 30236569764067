export function Schema() {
  return {
    category: 'Text',
    label: 'Markdown',
    key: 'markdown',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/markdown-preview.jpg?v=1675730328',
    fields: [
      {
        label: 'Content',
        name: 'content',
        component: 'markdown',
        defaultValue: `**Lorem ipsum dolor sit amet**, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea _commodo consequat_.`,
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        description: 'Max of two buttons',
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Button Style',
            name: 'style',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
        ],
      },
      {
        label: 'Center All Text',
        name: 'centerAllText',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        component: 'toggle',
        name: 'useFullWidth',
        label: 'Use full width',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        label: 'Font Sizes',
        name: 'fontSizes',
        component: 'select',
        options: [
          {
            label:
              'Small (reduces h1 and h2 font sizes to use on support pages)',
            value:
              '[&_h1]:!text-left [&_h1]:text-[27px] [&_h1]:leading-[32px] [&_h1]:md:mb-[34px] [&_h2]:h4 [&_li]:text-sm [&_p]:text-sm [&_a]:break-words',
          },
          {
            label: 'Medium',
            value: '[&_h2]:text-2xl md:[&_h2]:text-[32px] [&_p]:text-sm',
          },
          {
            label: 'Large',
            value: '',
          },
        ],
        defaultValue:
          '[&_h1]:!text-left [&_h1]:text-[27px] [&_h1]:leading-[32px] [&_h1]:md:mb-[34px] [&_h2]:h4 [&_li]:text-sm [&_p]:text-sm [&_a]:break-words',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Max content width',
        fields: [
          {
            label: 'Max Content Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[60rem]',
              },
              {
                label: 'Medium Wide',
                value: 'max-w-[75rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[90rem]',
              },
              {
                label: 'Legal Page',
                value: 'max-w-[90rem]  px-[20px] mx-auto',
              },
              { label: 'Full', value: 'max-w-full' },
            ],
          },
          {
            component: 'toggle',
            name: 'additionalBottomPadding',
            label: 'Add additonal Padding at the bottom',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
        ],
        defaultValue: {
          maxWidth: 'max-w-[60rem]',
        },
      },
    ],
  };
}
