import { useSettings } from '@backpackjs/storefront';
import { useState } from 'react';

export function NotifyMeForm({ variantId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState(null);

  const settings = useSettings();
  const { backInStock } = { ...settings?.product };

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setError(null); // Clear previous errors when a new request starts

    try {
      const formData = new FormData(event.currentTarget);

      const response = await fetch('/api/backInStock', {
        method: 'POST',
        body: JSON.stringify({
          email: formData.get('email'),
          variantId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit the data. Please try again.');
      } else {
        setIsSuccessful(true);
      }
    } catch (err) {
      // Capture the error message to display to the user
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex w-full flex-col gap-y-2 border border-border p-4">
      <form className="flex flex-col" onSubmit={onSubmit}>
        <h5 className="mb-3 text-base text-center uppercase text-[#29527c] opacity-[.5]">
          Notify me when this product is available
        </h5>
        <div className="flex flex-row gap-x-2">
          <input
            className="input-text rounded-sm bg-lightGray py-2 text-text"
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            tabIndex={-1}
            required
          />
          <button
            type="submit"
            disabled={isLoading}
            className="btn-primary relative w-auto min-w-min rounded px-[1.625rem]"
          >
            {isLoading ? 'Loading...' : 'Submit'}
          </button>
        </div>
      </form>
      {error && <div className="text-red-700">{error}</div>}
      {isSuccessful && (
        <div className="md:text-[var(--accent1)]">
          {backInStock.successText}
        </div>
      )}
    </div>
  );
}
