import { useCallback, useEffect, useState } from 'react';
import {
  sendGraphlEvent,
  getCookie,
  nostoMapLineItems,
  getIdFromRawShopifyId,
} from './utils';
import { useCustomer, useCartItems } from '@backpackjs/storefront';

export function useDataLayerCollection({
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const [collectionProducts, setCollectionProducts] = useState(null);
  const [collectionData, setCollectionData] = useState(null);
  const [collectionNostoId, setCollectionNostoId] = useState(null);
  const [clickedCollectionItem, setClickedCollectionItem] = useState(null);
  const customer = useCustomer();
  const cartItems = useCartItems();

  const viewCollectionGraph = async (collection) => {
    const nostoCookie = getCookie('nosto-session');
    const cartFragment = nostoMapLineItems(cartItems);
    const customerId = getIdFromRawShopifyId(customer?.id, 'Customer');

    const query = `mutation {
        updateSession(by: BY_CID, id: "${nostoCookie}",
          params: {
            ${
              customer
                ? `customer: {
              firstName: "${customer?.firstName}"
              lastName: "${customer?.lastName}"
              marketingPermission: ${customer?.acceptsMarketing}
              customerReference: "${customerId}"
            }`
                : ''
            }
            event: {
              type: VIEWED_CATEGORY
              target: "/${collection?.handle}"
            }
            ${cartFragment}
          }
        ) {
          id
        }
      }`;

    await sendGraphlEvent(query);
  };

  const viewCollectionEvent = useCallback(
    ({ products, collection, userProperties: _userProperties }) => {
      if (!products?.length) return;

      nostojs((api) => {
        api
          .defaultSession()
          .setResponseMode('JSON_ORIGINAL')
          .viewCategory(collection.title)
          .setPlacements(api.placements.getPlacements())
          .load()
          .then((data) => {
            if (data?.cmpid) {
              setCollectionNostoId(data?.cmpid);
            }
          });
      });

      if (
        collection?.image?.altText &&
        collection?.image?.altText.includes('Nosto')
      ) {
        nostojs((api) => {
          api
            .defaultSession()
            .recordAttribution(
              'vc',
              this.decodeBase64CollecionId(
                collection?.id.split('gid://shopify/Collection/')[1]
              ),
              collection?.title
            )
            .done();
        });
      }

      if (typeof window !== 'undefined') {
        viewCollectionGraph(collection);
      }

      if (DEBUG) console.log(`Nosto: View Product`);
    },
    []
  );

  const clickCollectionItemEvent = useCallback(
    ({ variant, collection }) => {
      if (!variant) return;

      if (
        collection?.image?.altText &&
        collection?.image?.altText.includes('Nosto')
      ) {
        const productId = variant.product.id.split('gid://shopify/Product/')[1];

        nostojs((api) => {
          api
            .defaultSession()
            .recordAttribution(
              'vp',
              productId,
              collection?.id.split('gid://shopify/Collection/')[1]
            )
            .done();
        });
      }

      if (DEBUG) console.log(`Nosto: Click Product`);
    },
    [userProperties]
  );

  // Subscribe to PubSub topic for 'dl_view_item_list' and 'dl_select_item' events
  useEffect(() => {
    const viewCollection = PubSub.subscribe(
      'VIEW_COLLECTION_PAGE',
      async (event, payload) => {
        setCollectionProducts(payload.products);
        setCollectionData(payload.collection);
      }
    );
    const clickCollectionItem = PubSub.subscribe(
      'CLICK_COLLECTION_ITEM',
      async (event, variant) => {
        setClickedCollectionItem(variant);
      }
    );
    return () => {
      if (viewCollection) {
        PubSub.unsubscribe(viewCollection);
      }
      if (clickCollectionItem) {
        PubSub.unsubscribe(clickCollectionItem);
      }
    };
  }, []);

  // Trigger 'user_data' and view 'view_item_list'
  // events on collection page and after base data is ready
  useEffect(() => {
    if (!collectionProducts?.length || !userProperties) return;
    viewCollectionEvent({
      products: collectionProducts,
      collection: collectionData,
      userProperties,
    });
  }, [collectionProducts, !!userProperties]);

  // Trigger 'select_item' event on clicked collection
  // item and after user event
  useEffect(() => {
    if (!clickedCollectionItem || !userDataEventTriggered) return;
    clickCollectionItemEvent({
      variant: clickedCollectionItem,
      collection: collectionData,
    });
  }, [clickedCollectionItem, userDataEventTriggered]);
}
