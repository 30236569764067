import { Image, Link } from '../../snippets';
import { Schema } from './CollectionTiles.schema';

export function CollectionTiles({ cms }) {
  const { button, heading, section, tiles, headingFontSize } = cms;
  const { desktop, mobile, tablet, bgColor, textColor } = section;

  const heightClassesDesktop =
    desktop?.heightType === 'aspect-ratio'
      ? `${desktop?.aspectRatio} ${desktop?.minHeight} ${desktop?.maxHeight}`
      : desktop?.staticHeight || 'lg:h-[43.75rem]';
  const heightClassesTablet =
    tablet?.heightType === 'aspect-ratio'
      ? `${tablet?.aspectRatio} ${tablet?.minHeight} ${tablet?.maxHeight} md:max-lg:max-h-full md:max-lg:h-full`
      : tablet?.staticHeight || 'md:max-lg:h-[37.5rem]';
  const heightClassesMobile =
    mobile?.heightType === 'aspect-ratio'
      ? `${mobile?.aspectRatio} ${mobile?.minHeight} ${mobile?.maxHeight}`
      : mobile?.staticHeight || 'max-md:h-[31.25rem]';

  const heightContainerClasses = `${heightClassesMobile} ${heightClassesTablet} ${heightClassesDesktop}`;

  const leftTile = tiles[0];

  const headingStyles = headingFontSize || '';

  return (
    <div
      className={`lg:px-contained ${section?.verticalPaddingLevelMobile} ${section?.verticalPaddingLevel}`}
      style={{ backgroundColor: bgColor }}
    >
      {heading && (
        <h2
          className={`${headingStyles} mb-6 px-4 text-center normal-case md:mb-10`}
          style={{ color: textColor }}
        >
          {heading}
        </h2>
      )}

      <div className="mx-auto grid max-w-none grid-cols-1 gap-x-5 gap-y-4 overflow-x-hidden md:grid-cols-2 lg:gap-y-[unset]">
        <div
          className={`relative flex w-full flex-col lg:h-full ${heightContainerClasses} lg:aspect-auto ${
            leftTile?.isExpandedMobile ? '!aspect-auto' : 'max-md:aspect-square'
          }`}
          style={{ color: textColor }}
        >
          {leftTile.image?.src && (
            <Link
              aria-label={leftTile.link?.text}
              href={leftTile?.hideButtons ? leftTile.link?.url : ''}
              newTab={leftTile.link?.newTab}
              type={leftTile.link?.type}
            >
              <div>
                {leftTile?.titlesOutside && (
                  <div
                    className={`absolute z-[1] w-full bg-white max-md:hidden ${leftTile?.outsidePosition} ${leftTile?.textAlign} ${leftTile?.textColor}`}
                  >
                    {leftTile.superheading && (
                      <p
                        className={`${
                          leftTile.superHeadingFontSize
                            ? leftTile.superHeadingFontSize
                            : 'text-superheading'
                        } normal-case max-lg:mb-2 md:mb-4`}
                      >
                        {leftTile.superheading}
                      </p>
                    )}

                    <h3
                      className={`${
                        leftTile.headingFontSize
                          ? leftTile.headingFontSize
                          : 'h2'
                      } normal-case`}
                    >
                      {leftTile.heading}
                    </h3>
                  </div>
                )}

                <div className=" max-md:aspect-square">
                  <Image
                    alt={leftTile.alt}
                    className={`${leftTile.position} max-md:px-[17px] max-md:pt-[17px]`}
                    fill
                    quality={90}
                    sizes="(min-width: 1440px) 1200px, (min-width: 768px) 50vw, 100vw"
                    src={leftTile.image.src}
                  />
                </div>

                <div
                  className={`z-10 m-4 mx-[17px] flex w-auto flex-col gap-y-3 md:absolute md:inset-0 ${
                    leftTile?.textColorMob
                  } md:m-[44px] md:mx-[34px] ${
                    leftTile?.isExpandedMobile
                      ? 'relative max-md:my-0 max-md:bg-white max-md:pt-4'
                      : `absolute max-md:bottom-0 max-md:px-4 max-md:pt-4 ${leftTile?.contentPosition}`
                  }`}
                >
                  <div
                    className={`${leftTile?.textAlign} ${leftTile?.textColor} 
                   ${!leftTile?.titlesOutside ? '' : 'md:hidden'}`}
                  >
                    {leftTile.superheading && (
                      <p
                        className={`${
                          leftTile.superHeadingFontSize
                            ? leftTile.superHeadingFontSize
                            : 'text-superheading'
                        } normal-case max-lg:mb-2 md:mb-4`}
                      >
                        {leftTile.superheading}
                      </p>
                    )}

                    <h3
                      className={`${
                        leftTile.headingFontSize
                          ? leftTile.headingFontSize
                          : 'h2'
                      } normal-case`}
                    >
                      {leftTile.heading}
                    </h3>
                  </div>

                  {leftTile.link?.text && !leftTile?.hideButtons && (
                    <li className="list-none md:mt-[6px]">
                      <Link
                        aria-label={leftTile.link?.text}
                        className={`${leftTile.style} ${leftTile.styleMob}`}
                        href={leftTile.link?.url}
                        newTab={leftTile.link?.newTab}
                        type={leftTile.link?.type}
                      >
                        {leftTile.link?.text}
                      </Link>
                    </li>
                  )}
                </div>
              </div>
            </Link>
          )}
        </div>

        <div
          className="flex h-full w-full flex-col gap-y-4"
          style={{ color: textColor }}
        >
          {tiles.slice(1).map((item, blockIndex) => {
            return (
              <div
                className={`relative ${heightContainerClasses} ${
                  item?.isExpandedMobile ? '!aspect-auto' : ''
                }`}
                key={blockIndex}
              >
                {item.image?.src && (
                  <Link
                    aria-label={item.link?.text}
                    href={item?.hideButtons ? item.link?.url : ''}
                    newTab={item.link?.newTab}
                    type={item.link?.type}
                  >
                    <div>
                      {item?.titlesOutside && (
                        <div
                          className={`absolute z-[1] w-full bg-white max-md:hidden ${item?.outsidePosition} ${item?.textAlign} ${item?.textColor}`}
                        >
                          {item.superheading && (
                            <p
                              className={`${
                                item.superHeadingFontSize
                                  ? item.superHeadingFontSize
                                  : 'text-superheading'
                              } normal-case max-lg:mb-2 md:mb-4`}
                            >
                              {item.superheading}
                            </p>
                          )}

                          <h3
                            className={`${
                              item.headingFontSize ? item.headingFontSize : 'h2'
                            } normal-case`}
                          >
                            {item.heading}
                          </h3>
                        </div>
                      )}

                      <div className=" max-md:aspect-square">
                        <Image
                          alt={item.alt}
                          className={`${item.position}  max-md:px-[17px] max-md:pt-[17px]`}
                          fill
                          sizes="(min-width: 1440px) 1200px, (min-width: 768px) 50vw, 100vw"
                          src={item.image.src}
                        />
                      </div>

                      <div
                        className={`z-10 m-4 mx-[17px] flex w-auto flex-col gap-y-3 md:absolute md:inset-0 ${
                          item?.textColorMob
                        } md:m-[44px] md:mx-[34px] ${
                          item?.isExpandedMobile
                            ? 'relative max-md:my-0 max-md:bg-white max-md:pt-4'
                            : `absolute max-md:bottom-0 max-md:px-4 max-md:pt-4 ${item?.contentPosition}`
                        }`}
                      >
                        <div
                          className={`${item?.textAlign} ${item?.textColor} ${
                            !item?.titlesOutside ? '' : 'md:hidden'
                          }`}
                        >
                          {item.superheading && (
                            <p
                              className={`${
                                item.superHeadingFontSize
                                  ? item.superHeadingFontSize
                                  : 'text-superheading'
                              } normal-case max-lg:mb-2 md:mb-4`}
                            >
                              {item.superheading}
                            </p>
                          )}

                          <h3
                            className={`${
                              item.headingFontSize ? item.headingFontSize : 'h2'
                            } normal-case`}
                          >
                            {item.heading}
                          </h3>
                        </div>

                        {item.link?.text && !item?.hideButtons && (
                          <li className="list-none md:mt-[10px]">
                            <Link
                              aria-label={item.link?.text}
                              className={`${item.style} ${item?.styleMob}`}
                              href={item.link?.url}
                              newTab={item.link?.newTab}
                              type={item.link?.type}
                            >
                              {item.link?.text}
                            </Link>
                          </li>
                        )}
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {button?.text && (
        <div className="mt-10 flex flex-col items-center">
          <Link
            aria-label={button.text}
            className={`${section?.buttonStyle || 'btn-primary'}`}
            href={button.url}
            newTab={button.newTab}
            type={button.type}
          >
            {button.text}
          </Link>
        </div>
      )}
    </div>
  );
}

CollectionTiles.displayName = 'CollectionTiles';
CollectionTiles.Schema = Schema;
