import { useMemo } from 'react';
import { useCountry } from '@backpackjs/storefront';
import { useCountries, useAvailableCountries } from '@backpackjs/storefront';

export function FooterCountrySelector({
  selectClass,
  name,
  placeholder,
  required,
}) {
  const { country, updateCountry, ...updateStatus } = useCountry();
  const countries = useAvailableCountries();

  const countryOptions = useMemo(() => {
    if (!countries?.length) return [];
    const sorted = [...countries].sort((a, b) => a.name.localeCompare(b.name));
    return sorted;
  }, [countries?.length]);

  const currentCountry = useMemo(() => {
    if (!country) return null;

    return country;
  }, [country]);

  const changeSelectedCountry = async (value) => {
    const newCountry = countryOptions.find((el) => el.name === value);

    setCookie('locale', newCountry.isoCode, 30)

    await updateCountry({
      country: newCountry,
    });
  };

  const setCookie = (name, value, days) => {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  return (
    <select
      className={`${selectClass} text-last-center max-w-[250px] rounded-full p-[2.5px] text-center text-sm text-black lg:ml-auto`}
      id={name}
      name={name}
      required={required}
      value={currentCountry?.name}
      onChange={(e) => changeSelectedCountry(e.target.value)}
    >
      {countryOptions?.map((country, index) => (
        <option key={`${country.name}.${index}`} value={country.name}>
          {`${country.name} (${country.currency.isoCode} ${country.currency.symbol})` ||
            placeholder}
        </option>
      ))}
    </select>
  );
}

FooterCountrySelector.displayName = 'CountryField';
