import { Image } from '../../Image';

export function ColorVariantOption({
  color,
  enabledColorNameOnHover,
  onClick,
  selectedVariant,
  swatchesMap,
  initialVariants,
  isGrouped = false,
}) {
  const isActive =
    color ===
    (selectedVariant?.selectedOptionsMap?.Color ||
      selectedVariant?.selectedOptions[0]?.value);
  const swatch = swatchesMap?.[color?.toLowerCase().trim()];
  const hasImage = swatch?.startsWith('http');
  const backgroundColor =
    isActive && swatch === '#FFFFFF'
      ? 'var(--off-white)'
      : swatch || 'var(--off-white)';

  const hasColorAvailability = !isGrouped
    ? initialVariants?.some(
        (el) =>
          el.selectedOptionsMap.Color === color && el.availableForSale === true
      )
    : true;

  const isAvailable = !initialVariants || hasColorAvailability;

  const beforeClasses =
    'before:h-px before:w-[150%] before:rotate-[-135deg] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:z-[1]';
  const afterClasses =
    'after:h-px after:w-[150%] after:rotate-[135deg] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2';

  const unavailableClass = !isAvailable
    ? `${beforeClasses} ${afterClasses} overflow-hidden after:bg-[#999999] before:bg-[#999999]`
    : '';

  return (
    <div className="group/color relative">
      <button
        aria-label={`Select ${color} color variant`}
        className={`relative flex h-4 w-4 items-center justify-center overflow-hidden rounded-[50%] border border-border transition md:hover:border-text ${
          isActive ? 'border-text' : ''
        } ${unavailableClass}`}
        onClick={onClick}
        style={{ backgroundColor }}
        type="button"
      >
        {hasImage && (
          <Image
            alt={color}
            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
            height="24"
            src={swatch}
            width="24"
          />
        )}

        <div
          className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-[50%] border-white transition-[border-width] duration-100 ${
            isActive && isAvailable ? 'border-[2px]' : 'border-[0px]'
          }`}
        />
      </button>

      {enabledColorNameOnHover && (
        <>
          <p className="after pointer-events-none absolute bottom-[calc(100%+10px)] left-[50%] hidden translate-x-[-50%] whitespace-nowrap bg-primary px-[10px] py-[5px] text-xs leading-[14px] text-white opacity-0 transition duration-75 md:block group-hover/color:md:opacity-100">
            {color}
          </p>
          <span className="absolute bottom-[calc(100%+2px)] left-[50%] hidden h-0 translate-x-[-50%] border-l-[0.625rem] border-r-[0.625rem] border-t-[0.625rem] border-solid border-l-transparent border-r-transparent border-t-primary opacity-0 transition duration-75 md:block group-hover/color:md:opacity-100" />
        </>
      )}
    </div>
  );
}

ColorVariantOption.displayName = 'ColorVariantOption';
