import axios from 'axios';

export const fetchVariantMetafields = async (variantId, metafieldsList) => {
  const encodedVariant = window.btoa(variantId);

  const query = `
        query {
            node(id: "${encodedVariant}") {
            ... on ProductVariant {
                    metafields(identifiers: [
                        {key: "model_details", namespace: "custom_fields"},
                    ]) {
                        id
                        key
                        value
                        namespace
            }
          }
        }
      }
    `;

  try {
    const endpoint = `https://bylt-apparel.myshopify.com/api/2024-04/graphql.json`;

    const response = await axios({
      url: endpoint,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token':
          process.env.SHOPIFY_STOREFRONT_API_TOKEN,
      },
      data: JSON.stringify({
        query: query,
      }),
    });

    const metafields = response.data.data.node.metafields;

    if (metafields && metafields.length) {
      return metafields.reduce((obj, metafield) => {
        if (metafield) {
          const { namespace, key, value } = metafield;

          if (obj[namespace]) {
            obj[namespace][key] = value;
          } else {
            obj[namespace] = {};
            obj[namespace][key] = value;
          }

          return obj;
        }
      }, {});
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
