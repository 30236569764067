export function Schema() {
  return {
    category: 'Text',
    label: 'Contact Form Banner',
    key: 'ContactFormBanner',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/contact-form-guide.png?v=1704692356',
    fields: [
      {
        component: 'text',
        name: 'title',
        label: 'Form Title',
        defaultValue: 'Contact Us',
      },
      {
        label: 'Above The Fold',
        name: 'aboveTheFold',
        component: 'toggle',
        description: `Sets the title as H1 and image load as priority`,
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: true,
      },
      {
        label: 'Content',
        name: 'content',
        component: 'markdown',
        defaultValue: `**Lorem ipsum dolor sit amet**, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea _commodo consequat_.`,
      },
      {
        component: 'text',
        name: 'successMessage',
        label: 'Success Message',
        defaultValue: 'Success!',
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        description: 'Max of two buttons',
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 3,
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Button Style',
            name: 'style',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
        ],
      },
      {
        component: 'image',
        name: 'image',
        label: 'Image',
      },
      {
        label: 'Center All Text',
        name: 'centerAllText',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        label: 'Cover Mode Settings',
        name: 'groupMode',
        component: 'group',
        description:
          'Enable or disable and configure the different options to use the cover mode of the banner, this let the banner have a compact design to be used on any page.',
        fields: [
          {
            label: 'Enable / Disable Cover Mode',
            name: 'enableCoverMode',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            component: 'text',
            name: 'coverHeading',
            label: 'Cover Heading',
          },
          {
            label: 'Links',
            name: 'coverLinks',
            component: 'group-list',
            itemProps: {
              label: '{{item.link.text}}',
            },
            fields: [
              {
                component: 'toggle',
                name: 'isAction',
                label: 'Eable/Disable Action button',
                description:
                  'Enable Action Button (if this is enabled the button link will be replaced by an site action)',
                toggleLabels: {
                  true: 'Enable',
                  false: 'Disable',
                },
                defaultValue: false,
              },
              {
                component: 'link',
                name: 'link',
                label: 'Link',
              },
              {
                component: 'markdown',
                name: 'info',
                label: 'Information',
              },
              {
                component: 'select',
                name: 'action',
                label: 'Action',
                options: [
                  {
                    label: 'Open Chat Form',
                    value: 'chat',
                  },
                  {
                    label: 'Open Chat Widget',
                    value: 'widget',
                  },
                ],
              },
            ],
          },
        ],
        defaultValue: {
          enableCoverMode: false,
        },
      },
    ],
  };
}
