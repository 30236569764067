import Script from 'next/script';
import { useCustomer, useCountry, useCountries, useAvailableCountries } from '@backpackjs/storefront';
import { useGlobalE } from './hooks';
import { useEffect } from 'react';

export function GlobalE({ pageTitle, template }) {
  const customer = useCustomer();
  const { country, updateCountry } = useCountry();
  const countries = useAvailableCountries();

  useGlobalE();

  const createReplacementCookie = (cookieDomain) => {
    let replacementExpireParam = parseInt(
      getParameterByName('replacementExpire')
    );

    if (
      replacementExpireParam !== null &&
      replacementExpireParam > Date.now()
    ) {
      var cookieStringifiedValue = encodeURIComponent(
        JSON.stringify({
          glCountry: this.getParameterByName('glCountry'),
          glCurrency: this.getParameterByName('glCurrency'),
        })
      );

      var expirationInUTC = this.ticksToDate(
        replacementExpireParam
      ).toUTCString();

      document.cookie =
        'GE_Replacement=' +
        cookieStringifiedValue +
        ';expires = ' +
        expirationInUTC +
        ';path=/;domain=.' +
        cookieDomain;
    }
  };

  const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  const ticksToDate = (ticks) => {
    return Number.isInteger(ticks)
      ? new Date(ticks / 1e4 + new Date('0001-01-01T00:00:00Z').getTime())
      : null;
  };

  useEffect(() => {
    async function update(foundCountry) {
      await updateCountry({
        country: foundCountry,
      });
    }

    if (countries) {
      const glCountry = getParameterByName('glCountry');

      if(glCountry){
        const foundCountry = countries.find(el=> el.isoCode === glCountry)

        if(foundCountry){
          update(foundCountry)
        }
      }

      const cookieDomain = window.location.hostname;
      createReplacementCookie(cookieDomain);
    }
  }, [countries]);

  return (
    <>
      <Script
        rel="stylesheet"
        type="text/css"
        src="https://crossborder-integration.global-e.com/resources/css/1456/US"
      />
    </>
  );
}

GlobalE.displayName = 'GlobalE';
