import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { MobileMenuItem } from './MobileMenuItem';

export function FooterMenu() {
  const settings = useSettings();
  const { menuItems } = { ...settings?.footer?.menu };

  const triggerAction = (action) => {
    switch (action) {
      case 'equalweb':
        window.interdeal.a11y.openMenu();
        break;

      case 'onetrust':
        window?.OneTrust?.ToggleInfoDisplay();
        break;

      default:
        break;
    }
  };

  return (
    <>
      {/* mobile */}
      <ul className="grid grid-cols-1 text-current lg:hidden lg:grid-cols-3 lg:grid-cols-4 max-lg:pb-4">
        {menuItems?.map((item, menuIndex) => {
          return (
            <li className="px-0" key={menuIndex}>
              <MobileMenuItem item={item} triggerAction={triggerAction} />
            </li>
          );
        })}
      </ul>

      {/* desktop */}
      <ul className="hidden max-w-[865px] auto-cols-auto gap-x-[50px] gap-y-8 text-current lg:grid lg:grid-flow-col">
        {menuItems?.map(({ title, links }, menuIndex) => {
          return (
            <li key={menuIndex}>
              <h3 className="text-title-h5 mb-2 capitalize">{title}</h3>

              <ul className="flex flex-col items-start gap-2">
                {links?.map(
                  ({ link, actionName, isActionButton }, linkIndex) => {
                    const action = actionName || '';
                    const isAction = isActionButton || false;

                    return (
                      <li key={linkIndex} className="text-sm">
                        {!isAction ? (
                          <Link
                            aria-label={link?.text}
                            className="hover-text-underline cursor-pointer text-[12px]"
                            href={link?.url}
                            newTab={link?.newTab}
                            type={link?.type}
                          >
                            {link?.text}
                          </Link>
                        ) : (
                          <button
                            type="button"
                            aria-label={link?.text}
                            className="hover-text-underline cursor-pointer text-left text-[12px]"
                            onClick={() => triggerAction(action)}
                          >
                            {link?.text}
                          </button>
                        )}
                      </li>
                    );
                  }
                )}
              </ul>
            </li>
          );
        })}
      </ul>
    </>
  );
}

FooterMenu.displayName = 'FooterMenu';
