import { useEffect, useState } from 'react';

const getCountries = async ({ firstCountries = [] }) => {
  try {
    // eslint-disable-next-line import/extensions
    const data = await import('./countries.json');
    const countriesList = data.default;
    /*
      const initialDataList = data.default;
      const firstCountriesMap = firstCountries.reduce((acc, value) => {
        acc[value] = {};
        return acc;
      }, {});
      const initialNameList = initialDataList.map((item) => {
        if (firstCountriesMap[item.countryName]) {
          firstCountriesMap[item.countryName] = item;
        }
        return item.countryName;
      });
      const finalDataList = [
        ...Object.values(firstCountriesMap),
        ...initialDataList,
      ];
      const finalNameList = [
        ...Object.keys(firstCountriesMap),
        ...initialNameList,
      ];
      */
    return {
      countriesList
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const useGlobalECountriesList = (props) => {
  const [countriesData, setCountriesData] = useState({countries: null});

  const firstCountries = Array.isArray(props?.firstCountries)
    ? props?.firstCountries
    : [];

  const awaitCountries = async () => {
    const data = await getCountries({ firstCountries });
    setCountriesData({ countries: data.countriesList.localeList });
  };

  useEffect(() => {
    if (countriesData.countries) return;
    awaitCountries();
  }, []);

  return countriesData;
};
