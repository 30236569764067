import { useSettings } from '@backpackjs/storefront';

export function SearchSuggestions({ setRawTerm, term }) {
  const settings = useSettings();
  const { noResultsText } = { ...settings?.search?.results };
  const { enabled, heading, terms } = { ...settings?.search?.suggestions };

  return (
    <div className="mt-4 flex items-center m-auto justify-center">
      {term && false && <h3 className="h5 font-normal">{noResultsText}</h3>}

      {enabled && terms?.length > 0 && (
        <div className="flex items-center m-auto justify-center">
          <h3 className="h5 text-white pr-2 whitespace-nowrap">{heading}</h3>

          <ul className="max-md:overflow-x-scroll flex md:flex-wrap gap-x-2">
            {terms.map((suggestion) => {
              return (
                <li key={suggestion}>
                  <button
                    aria-label={suggestion}
                    onClick={() => setRawTerm(suggestion)}
                    className="rounded-full bg-white py-1 px-2"
                  >
                    <p className="text-base text-primary whitespace-nowrap">{suggestion}</p>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

SearchSuggestions.displayName = 'SearchSuggestions';
