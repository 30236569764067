import { useCartCount } from '@backpackjs/storefront';

import { Svg } from '../Svg';

export function CartHeader({ closeCart, heading }) {
  const cartCount = useCartCount();

  return (
    <div className="justify-left relative flex h-[var(--cart-header-height)] items-center border-b border-b-border px-4">
      <h3 className="text-title-h5 text-center">
        {heading || 'Shopping Cart'}
      </h3>

      <button
        aria-label="Close cart"
        className="absolute right-4 top-1/2 -translate-y-1/2"
        onClick={closeCart}
        type="button"
      >
        <Svg
          className="w-5 text-text"
          src="/svgs/close.svg#close"
          title="Close button"
          viewBox="0 0 24 24"
        />
      </button>
    </div>
  );
}

CartHeader.displayName = 'CartHeader';
