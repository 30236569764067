import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper';
import { useSettings, useCustomer, useCartCount, useCartItems } from '@backpackjs/storefront';

import { Link } from '../Link';
import { MenuNestedSidebar } from './MenuNestedSidebar';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';
import { Image } from '../Image';

export function MenuSidebar({
  handleCloseSidebar,
  menuSidebarOpen,
  nestedSidebarContent,
  handleNestedSidebar,
}) {
  const settings = useSettings();
  const customer = useCustomer();

  const {
    actions: { openCart, openSearch },
  } = useGlobalContext();
  const cartCount = useCartCount();
  const cartItems = useCartItems();
  const routeProduct = cartItems?.find(item => item?.variant?.product?.handle === 'routeins')
  const finalCartCount = routeProduct ? cartCount - 1 : cartCount

  const {
    links: additionalLinks,
    menuItems,
    productsSlider,
    imageBanners,
  } = { ...settings?.header?.menu };
  const { products, heading: productsHeading } = {
    ...productsSlider,
  };

  return (
    <div
      className={`fixed left-0 top-0 z-[60] h-full w-full lg:hidden ${
        menuSidebarOpen ? 'pointer-events-auto' : 'pointer-events-none'
      }`}
    >
      {/* Overlay */}
      <div
        role="button"
        aria-hidden="true"
        aria-label="Close menu sidebar"
        tabIndex="-1"
        className={`absolute left-0 top-0 hidden h-full w-full bg-[rgba(0,0,0,0.3)] transition duration-200 md:block ${
          menuSidebarOpen ? 'opacity-100' : 'opacity-0'
        }`}
        onClick={handleCloseSidebar}
      />

      <aside
        className={`absolute right-full top-0 flex h-full w-full flex-col overflow-hidden bg-background transition md:max-w-[var(--sidebar-width)] ${
          menuSidebarOpen ? 'translate-x-full' : '-translate-x-0'
        }`}
      >
        {menuSidebarOpen && (
          <>
            <div className="relative flex h-[var(--sidebar-header-height)] items-center justify-center px-[17px]">
              <button
                aria-label="Close menu sidebar"
                className="absolute left-4 top-[60%] -translate-y-1/2"
                onClick={handleCloseSidebar}
                type="button"
              >
                <Svg
                  className="w-8"
                  src="/svgs/mobile-nav-close.svg#mobile-nav-close"
                  title="Close"
                  viewBox="0 0 34 34"
                />
              </button>

              <Link
                aria-label="Go to account page"
                href={customer ? '/account/orders' : '/account/login'}
                onClick={handleCloseSidebar}
                className="absolute right-[60px] top-[60%] -translate-y-1/2"
              >
                <Svg
                  className="w-[27px]"
                  src={customer ? '/svgs/account-blue.svg#icon-account' : '/svgs/icon-account.svg#icon-account'}
                  title="Account"
                  viewBox="0 0 24 24"
                />
              </Link>

              <div className="absolute right-4 top-[60%] flex -translate-y-1/2 items-center justify-center">
                <button
                  aria-label="Open cart"
                  className="w-[26px]"
                  onClick={() => {
                    handleCloseSidebar();
                    openCart();
                  }}
                  type="button"
                >
                  <Svg
                    className="w-full text-text"
                    src="/svgs/icon-cart.svg#icon-cart"
                    title="Cart"
                    viewBox="0 0 24 24"
                  />
                </button>

                {finalCartCount > 0 && (
                  <span
                    aria-hidden
                    className="text-label-sm absolute right-[-12px] top-[-4px] flex h-[23px] w-4 w-[23px] items-center justify-center whitespace-nowrap rounded rounded-[100%] border-2 border-white bg-text pl-px text-center font-semibold text-white"
                    onClick={openCart}
                  >
                    {finalCartCount || 0}
                  </span>
                )}
              </div>
            </div>

            <div className="search mb-[30px] px-[17px]">
              <button
                className="flex w-full border border-lightGray bg-lightGray px-[11px]"
                onClick={() => {
                  handleCloseSidebar();
                  openSearch();
                }}
              >
                <Svg
                  className="h-[46px] w-5"
                  src="/svgs/search.svg#search"
                  title="Search"
                  viewBox="0 0 30 30"
                />
                <span className="block px-[px] py-[13px] text-left text-sm text-primary">
                  Search
                </span>
              </button>
            </div>

            <div className="relative w-full flex-1 overflow-x-hidden">
              <div
                className={`scrollbar-hide h-full w-full overflow-y-auto ${
                  nestedSidebarContent ? 'invisible' : 'visible'
                }`}
              >
                <div className="pl-[22px]">
                  <span className="text-sm font-bold text-mediumGray">
                    {' '}
                    SHOP{' '}
                  </span>
                </div>

                <nav className="mb-8 flex px-[22px]">
                  <ul className="w-full flex-col">
                    {menuItems?.map((item, index) => {
                      const hasContent =
                        item.links?.length > 0 || item.imageLinks?.length > 0;

                      return (
                        <li
                          key={index}
                          className="flex min-h-[3.5rem] w-full border-b border-b-border"
                        >
                          {hasContent ? (
                            <button
                              aria-label={item.menuItem.text}
                              className="flex h-14 w-full items-center justify-between gap-5 py-[22px]"
                              onClick={() => handleNestedSidebar(index)}
                              type="button"
                            >
                              <p className="text-nav flex-1 text-left text-base">
                                {item.menuItem.text}
                              </p>

                              <Svg
                                className="w-4"
                                src="/svgs/chevron-right.svg#chevron-right"
                                title="Arrow Next"
                                viewBox="0 0 24 24"
                              />
                            </button>
                          ) : (
                            <Link
                              aria-label={item.menuItem.text}
                              className="text-nav flex h-14 w-full items-center py-[22px] text-base"
                              href={item.menuItem.url}
                              onClick={handleCloseSidebar}
                              newTab={item.menuItem.newTab}
                              type={item.menuItem.type}
                            >
                              {item.menuItem.text}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </nav>

                {additionalLinks?.length > 0 && (
                  <ul className="mb-8 flex flex-col gap-1 px-[22px]">
                    <div className="pb-5">
                      <span className="text-sm font-bold text-mediumGray">
                        {' '}
                        FEATURED{' '}
                      </span>
                    </div>

                    {additionalLinks.map(({ link }, index) => {
                      return (
                        <li key={index}>
                          <Link
                            aria-label={link?.text}
                            href={link?.url}
                            onClick={handleCloseSidebar}
                            newTab={link?.newTab}
                            type={link?.type}
                            className="text-nav block pb-3.5 text-base"
                          >
                            {link?.text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}

                {imageBanners?.length > 0 && (
                  <div className="mb-8">
                    <Swiper
                      modules={[A11y]}
                      slidesPerView={1}
                      grabCursor
                      className="mb-5"
                    >
                      {imageBanners.map((banner, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <div className="relative">
                              {banner?.image?.src && (
                                <Image
                                  alt={banner?.alt}
                                  className="h-[290px] w-full object-cover"
                                  crop="center"
                                  height={225}
                                  src={banner?.image?.src}
                                  width="400"
                                />
                              )}

                              <div className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transform text-center">
                                <p className="w-full pb-[10px] text-center text-sm font-medium text-white">
                                  {banner.subCaption}
                                </p>
                                <p className="w-full pb-[20px] text-center text-[37px] font-bold text-white">
                                  {banner.caption}
                                </p>
                                <Link
                                  aria-label={banner.link.text}
                                  className={`${banner?.style} w-full max-w-[75%]`}
                                  href={banner.link.url}
                                  onClick={handleCloseSidebar}
                                  newTab={banner.link.newTab}
                                  type={banner.link.type}
                                >
                                  {banner.link.text}
                                </Link>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                )}
              </div>

              <MenuNestedSidebar
                handleCloseSidebar={handleCloseSidebar}
                handleNestedSidebar={handleNestedSidebar}
                nestedSidebarContent={nestedSidebarContent}
              />
            </div>
          </>
        )}
      </aside>
    </div>
  );
}

MenuSidebar.displayName = 'MenuSidebar';
