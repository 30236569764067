import { useCallback, useEffect, useState } from 'react';
import { store } from '@backpackjs/storefront';

export function useProductInventoriesFromHandles({
  handles = [],
  withQuantity = true,
  fetchOnMount = true,
}) {
  const [inventories, setInventories] = useState(null);
  const [inventoriesByHandle, setInventoriesByHandle] = useState(null);

  const fetchProductInventoryByHandle = store.recoil.useRecoilCallback(
    ({ snapshot }) =>
      async (handle) => {
        try {
          const { product } = await snapshot.getPromise(
            store.state.fetchProductInventoryByHandle({
              handle,
              withQuantity,
            })
          );
          return product;
        } catch (error) {
          return null;
        }
      },

    [withQuantity]
  );

  const generateInventoryMapByHandle = useCallback(async (_handles) => {
    const inventoryPromises = _handles.map(async (handle) => {
      if (typeof handle !== 'string' || !handle.length) return null;
      return fetchProductInventoryByHandle(handle);
    });
    const _inventories = await Promise.all(inventoryPromises);
    const _inventoriesByHandle = _inventories?.reduce((acc, inventory) => {
      if (!inventory) return acc;
      return {
        ...acc,
        [inventory.handle]: inventory,
      };
    }, {});
    setInventories(_inventories);
    setInventoriesByHandle(_inventoriesByHandle);
  }, []);

  useEffect(() => {
    if (!fetchOnMount || !Array.isArray(handles) || !handles?.length)
      return undefined;
    generateInventoryMapByHandle(handles);
    return () => {
      setInventories(null);
      setInventoriesByHandle(null);
    };
  }, [fetchOnMount, JSON.stringify(handles)]);

  return { inventories, inventoriesByHandle };
}
