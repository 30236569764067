import { useMemo } from 'react';

export function useVariantMetafields({ variant }) {
  return useMemo(() => {
    if (!variant || !variant?.metafields) {
      return null;
    }

    return variant.metafields.reduce((obj, metafield) => {
      const { namespace, key, value } = metafield;

      if (obj[namespace]) {
        obj[namespace][key] = value;
      } else {
        obj[namespace] = {};
        obj[namespace][key] = value;
      }

      return obj;
    }, {});
  }, [variant]);
}
