import { CollectionMenuSidebar } from '../Collection/CollectionMenuSidebar';
import { SearchFilterDropdown } from './SearchFilterDropdown';

export function SearchMobileFilters({
  activeFilters,
  addFilter,
  clearFilters,
  filters,
  removeFilter,
  isSideBarOpen,
  setIsSidebarOpen,
  trackOnClick,
}) {
  return (
    <>
      <CollectionMenuSidebar
        isOpen={isSideBarOpen}
        setIsOpen={setIsSidebarOpen}
        title="Filters"
      >
        <div className="sticky top-0 z-[1] border-b border-border bg-background p-4 pt-5">
          <div className="mb-4 flex hidden justify-between gap-2">
            {activeFilters.length > 0 && (
              <button
                className="text-label text-main-underline"
                onClick={clearFilters}
                type="button"
              >
                Clear All
              </button>
            )}
          </div>
        </div>

        <ul>
          {filters.map((filter, index) => {
            if (!filter.values.length) return null;

            return (
              <li key={index}>
                <SearchFilterDropdown
                  filter={filter}
                  activeFilters={activeFilters}
                  addFilter={addFilter}
                  removeFilter={removeFilter}
                  isGrid={true}
                  trackOnClick={trackOnClick}
                />
              </li>
            );
          })}
        </ul>
      </CollectionMenuSidebar>
    </>
  );
}

SearchMobileFilters.displayName = 'SearchMobileFilters';
