import { useGlobalContext } from '../../contexts';
import { useMemo, useEffect } from 'react';
import { Svg } from '../Svg';
import { Link } from '../Link';
import { useCartItems, useCartTotals, useSettings } from '@backpackjs/storefront';

export function CashbackModal({}) {
  const {
    state: { modalOpen },
    actions: { closeModal },
  } = useGlobalContext();

  const settings = useSettings();
  const cartItems = useCartItems()
  const cartTotals = useCartTotals();

  const { cashback } = {...settings?.apps}
  const redeemLink = cashback?.redeemTermsLink

  const cartProductsLabel = useMemo(() => {
    if(cartItems.length < 1){
      return 'No products'
    }

    return `${cartItems.length} products`
  }, [cartItems])

  const cashbackPercentage = (cashback?.cashbackPercentage || 0) / 100

  const cashbackTotal = (parseFloat(cartTotals?.total || 0) * cashbackPercentage)?.toFixed(2) || 0

  return (
    <div className="max-w-[350px]">
      <div className="mb-1 flex justify-center">
        <Svg
          className="h-[53px] text-text"
          src="/svgs/cashback/cashback.svg#cashback"
          title="Cashback"
          viewBox="0 0 55 54"
        />
      </div>

      <h3 className="text-center text-[30px] leading-[36px]">
        Reward Your Way
      </h3>

      <p className="mb-6 text-center text-lg font-medium">
        No points. No coupons. Just cash.
      </p>

      <div className="mb-8 flex justify-between gap-1">
        <div className="w-[30%] text-center">
          <Svg
            className="mx-auto mb-2 h-[33px]"
            src="/svgs/cashback/cart.svg#cart"
            viewBox="0 0 41 33"
          />
          <p>Purchase with CashBack</p>
        </div>

        <div className="flex items-center">
          <Svg
            className="h-[15px] text-text"
            src="/svgs/cashback/next-arrow.svg#next-arrow"
            viewBox="0 0 17 10"
          />
        </div>

        <div className="w-[30%] text-center">
          <Svg
            className="mx-auto mb-2 h-[35px]"
            src="/svgs/cashback/email.svg#email"
            viewBox="0 0 31 35"
          />

          <p>Open your confirmation email</p>
        </div>

        <div className="flex items-center">
          <Svg
            className="h-[15px] text-text"
            src="/svgs/cashback/next-arrow.svg#next-arrow"
            viewBox="0 0 17 10"
          />
        </div>

        <div className="w-[30%] text-center">
          <Svg
            className="mx-auto mb-2 h-[33px]"
            src="/svgs/cashback/cash.svg#cash"
            viewBox="0 0 47 35"
          />

          <p>Redeem your cash</p>
        </div>
      </div>

      <div className="relative mb-6 rounded-[10px] bg-[#F5F4F6] py-4">
        <div className="absolute left-[25px] top-[-10px] rounded-[10px] bg-[#D1FAE5] px-[12px] py-[2px]">
          <p className="text-xs font-medium text-[#065F46]">
            {cashback?.cashbackPercentage}% CashBack Eligible
          </p>
        </div>

        <div className="flex justify-between border-b border-border px-4 pb-3">
          <p>{cartProductsLabel}</p>
        </div>

        <div className="flex justify-between px-4 pt-3">
          <p className="font-bold">Cashback</p>
          <p className="font-bold text-[#065F46]">${cashbackTotal}</p>
        </div>
      </div>

      <div className="mb-4">
        <p className="text-xs text-[#6B7280]">
          Choose how to get your reward: Visa Prepaid Reward Card or Gift Card.
          It will be usable once the return period has passed.
        </p>

        <p className="text-xs text-[#6B7280]">
          After a qualifying purchase, you have 6 months to claim your reward.
        </p>
      </div>

      <div className="text-center">
        <div className="flex justify-center items-center gap-1">
          <Svg
            className="h-[12px]"
            src="/svgs/cashback/safe.svg#safe"
            viewBox="0 0 10 12"
          />
          <p className="text-[10px]">Safe & secure</p>
        </div>

        <div className="flex justify-center items-center gap-1">
          <p className="text-[10px] text-[#6B7280]">Promotion Powered by Fondue</p>
        </div>

        {redeemLink && (
          <Link aria-label={redeemLink?.text} href={redeemLink?.url}>
            <p className="text-underline text-[10px]">{redeemLink?.text}</p>
          </Link>
        )}
      </div>
    </div>
  );
}

CashbackModal.displayName = 'CashbackModal';
