import { useMemo } from 'react';

import { Image } from '../Image';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
import { Svg } from '../Svg';
import { QuantitySelector } from '../QuantitySelector';
import { useCartItem } from './useCartItem';
import { useCartItemImage } from './useCartItemImage';
import { useCartItemPrices } from './useCartItemPrices';

export function CartItemKit({ closeCart, item, childIDs, childsData }) {
  const { quantity, variant } = item;

  const { isRemovingItem, handleRemoveKitProducts } = useCartItem({
    item,
    childIDs,
  });

  const { price, compareAtPrice } = useCartItemPrices({ item });

  const image = useCartItemImage({ item });

  const url = useMemo(() => {
    return `/products/${variant.product.handle}?variant=${variant.id
      .split('/')
      .pop()}`;
  }, [variant.id]);

  const parentProduct = JSON.parse(
    item?.attributes?.find((el) => el.key === '_parentProduct')?.value
  );

  const kitProducts = !!(childsData && childsData.length > 0) ? childsData : JSON.parse(
    item?.attributes?.find((el) => el.key === '_childProducts')?.value
  );

  const rawParentPrice = parentProduct?.price
    ? parentProduct?.price
    : parentProduct.variants[0]?.price;

  const parentPrice = `$${(parseFloat(rawParentPrice) || 0)
    ?.toFixed(2)
    .replace('.00', '')}`;

  const rawParentCompare = parentProduct?.compareAtPrice
    ? parentProduct?.compareAtPrice
    : parentProduct?.variants[0]?.compareAtPrice;

  const parentCompareAtPrice = `$${(parseFloat(rawParentCompare) || 0)
    ?.toFixed(2)
    .replace('.00', '')}`;

  const selectedChilds = Array.isArray(kitProducts) && kitProducts?.length ? kitProducts : Object.values(kitProducts)
  
  return (
    <div>
      <div className="px-4 pt-2">
        <Link
          aria-label={`View ${parentProduct?.title}`}
          href={url}
          onClick={closeCart}
        >
          <h3 className="color-primary text-xs">{parentProduct?.title}</h3>
        </Link>
      </div>

      <div className="relative grid grid-cols-[auto_1fr] items-center gap-3 p-4">
        <div className="flex min-h-[6.25em] flex-col justify-between gap-4">
          {selectedChilds.map((childItem) => {
            return (
              <div
                className="relative grid grid-cols-[auto_1fr] items-center gap-3"
                key={childItem.product.handle}
              >
                <Link
                  aria-label={`View ${childItem.product.title}`}
                  href={url}
                  onClick={closeCart}
                  tabIndex="-1"
                >
                  {childItem?.image?.src && (
                    <Image
                      alt={childItem?.product?.title}
                      className="bg-offWhite"
                      crop="center"
                      height={Math.floor(88 / (image.width / image.height))}
                      src={childItem?.image?.src}
                      width="88"
                    />
                  )}
                </Link>

                <div>
                  <h3 className="color-primary text-xs">
                    {childItem?.product?.title}
                  </h3>

                  {variant.title !== 'Default Title' && (
                    <p className="text-[9.6px] leading-none  text-mediumGray">
                      {childItem.title}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex min-h-[6.25em] flex-col justify-between gap-4">
          <button
            aria-label={`Remove ${variant.product.title} from cart`}
            className="absolute right-[16px] top-[-12px] w-3"
            onClick={handleRemoveKitProducts}
            type="button"
          >
            <Svg
              src="/svgs/close.svg#close"
              title="Close"
              viewBox="0 0 24 24"
            />
          </button>

          <div className="absolute bottom-[20px] right-[16px] flex flex-1 flex-col flex-wrap content-end justify-end gap-x-2 text-end">
            {parentCompareAtPrice && (
              <p className="text-mediumGray line-through">
                {parentCompareAtPrice}
              </p>
            )}
            <p>{parentPrice}</p>
          </div>
        </div>

        {isRemovingItem && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)] text-mediumGray">
            <Spinner width="20" />
          </div>
        )}
      </div>
    </div>
  );
}

CartItemKit.displayName = 'CartItemKit';
