import { COLORS } from '../../settings/common';

export function Schema() {
  return {
    category: 'Navigation',
    label: 'Left Pane Navigation',
    key: 'left-pane-navigation',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/Screenshot_2023-11-02_at_8.57.10_AM.png?v=1698933452',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: '',
      },
      {
        label: 'Links',
        name: 'links',
        component: 'group-list',
        description: 'Max of four links',
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 4,
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Logo',
            name: 'logo',
            component: 'image',
          },
          {
            label: 'Logo Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
        ],
        defaultItem: {
          link: { text: 'Shop Now', url: '' },
          logo: '',
        },
        defaultValue: [
          {
            link: { text: 'Shop Now', url: '' },
            logo: '',
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Above the fold, background color, text color, icon color, full width',
        fields: [
          {
            label: 'Above The Fold',
            name: 'aboveTheFold',
            component: 'toggle',
            description: `Sets the heading as H1`,
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Text Color',
            name: 'textColor',
            component: 'select',
            options: COLORS,
          },
        ],
        defaultValue: {
          aboveTheFold: false,
          textColor: 'var(--text)',
        },
      },
    ],
  };
}
