import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { Svg } from '../Svg';

export function FooterSocial() {
  const settings = useSettings();
  const { heading, links } = { ...settings?.footer?.social };

  return links?.length > 0 ? (
    <div className="text-current">
      {heading && <h3 className="text-nav mb-3">{heading}</h3>}

      <ul className="flex flex-wrap gap-3.5">
        {links.map(({ platform, url }, index) => {
          return (
            <li key={index}>
              <Link aria-label={platform} href={url} newTab>
                <Svg
                  className="w-6 text-current transition lg:hover:opacity-80"
                  src={`/svgs/noprecache/social/${platform}.svg#${platform}`}
                  title={platform}
                  viewBox="0 0 24 24"
                />
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}

FooterSocial.displayName = 'FooterSocial';
