import { useSettings, useLocalizedVariant } from '@backpackjs/storefront';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { ProductStars } from '../../ProductStars';
import { Image } from '../../Image';
import { useProductMetafields } from '../../../hooks';

// eslint-disable-next-line import/no-cycle
import { ProductOptions } from '../../../templates/product/ProductOptions';
import { useSelectedVariant } from '../../../templates/product/useSelectedVariant';
import { QuantitySelector } from '../../QuantitySelector';
import { AddToCart } from '../../AddToCart';

export function QuickShopModalContent({ product, initialVariant }) {
  const {
    selectedVariant,
    setSelectedOptions,
    selectedOptions,
    selectedMedia,
  } = useSelectedVariant({ product, initialVariant, isQuickShop: true });

  const { localized } = useLocalizedVariant({ variant: selectedVariant });

  const settings = useSettings();

  const enabledQuantitySelector = settings?.product?.quantitySelector?.enabled;

  const compareAtPrice = localized?.compareAtPriceV2?.amount
    ? +localized.compareAtPriceV2.amount
    : +selectedVariant?.compareAtPriceV2?.amount || 0;

  const price = localized?.priceV2?.amount
    ? +localized.priceV2.amount
    : +selectedVariant?.priceV2?.amount || 0;

  const currencySymbol = localized ? localized?.locale?.currencySymbol : '$';

  const hasDiscount = !!(
    compareAtPrice && selectedVariant?.compareAtPrice !== selectedVariant?.price
  );

  const { enabledStarRating } = { ...settings?.product?.reviews };

  const firstMediaImage = selectedMedia?.[0]?.preview?.image;

  const [showMore, setShowMore] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isSizeSelected, setIsSizeSelected] = useState(false);
  const [isThirdSelected, setIsThirdSelected] = useState(product?.options?.length > 2 ? false : true);
  const isAtcReady = isSizeSelected && isThirdSelected

  const atcValidationStep = useMemo(() => {
    if(isSizeSelected) return 1

    return 0
  }, [isSizeSelected, isThirdSelected])

  const metafields = useProductMetafields({ product });

  const handleDecrement = useCallback(() => {
    if (quantity === 1) return;
    setQuantity(quantity - 1);
  }, [quantity]);

  const handleIncrement = useCallback(() => {
    setQuantity(quantity + 1);
  }, [quantity]);

  useEffect(() => {
    if (!enabledQuantitySelector) return undefined;
    return () => {
      setQuantity(1);
    };
  }, [enabledQuantitySelector]);

  useEffect(() => {
    if (!selectedVariant && !product) return;

    if (!product?.optionsMap?.Size) {
      setIsSizeSelected(true);
    }
  }, [selectedVariant]);

  return (
    <div className="flex flex-col lg:flex-row">
      {firstMediaImage && (
        <Image
          alt={firstMediaImage?.alt ?? product.title}
          width={440}
          height={560}
          sizes="(min-width: 1440px) 900px, (min-width: 768px) 50vw, 100vw"
          src={firstMediaImage?.src}
          srcSetSizes={[480, 960, 1280]}
          quality={-75}
          className="max-h-[35rem]"
        />
      )}
      <div className="flex w-full flex-col gap-2.5 lg:pl-8">
        <h1 className="h3">{product.title}</h1>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <div className="flex gap-2">
              {compareAtPrice && hasDiscount && (
                <p className="text-[17px] font-bold line-through">
                  ${compareAtPrice}
                </p>
              )}
              <p
                className={`${
                  compareAtPrice && hasDiscount && 'text-sale'
                } text-[17px] font-bold`}
              >
                {currencySymbol}
                {price}
              </p>
            </div>
          </div>
          {enabledStarRating && (
            <ProductStars
              legacyResourceId={product?.legacyResourceId}
              stampedData={metafields?.stamped}
            />
          )}
        </div>
        <div className="flex flex-col gap-5 border border-border p-4 max-md:hidden">
          <h5>Product Description</h5>
          <span>
            {showMore
              ? product.description
              : product.description.substring(0, 174)}
            <button
              className={`${showMore ? 'ml-2' : 'ml-1'} underline`}
              type="button"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Show Less' : 'Learn More'}
            </button>
          </span>
        </div>

        <ProductOptions
          product={product}
          selectedOptions={selectedOptions}
          selectedVariant={selectedVariant}
          setSelectedOptions={setSelectedOptions}
          setIsSizeSelected={setIsSizeSelected}
          setIsThirdSelected={setIsThirdSelected}
          isSizeSelected={isSizeSelected}
          hasSizeValidation
        />

        <div className="flex items-center gap-4">
          {enabledQuantitySelector && (
            <QuantitySelector
              disableDecrement={quantity === 1}
              handleDecrement={handleDecrement}
              handleIncrement={handleIncrement}
              productTitle={product.title}
              quantity={quantity}
            />
          )}

          <div className="flex-1">
            <AddToCart
              isPdp
              quantity={quantity}
              selectedVariant={selectedVariant}
              isAtcValidated={isAtcReady}
              validationStep={atcValidationStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
