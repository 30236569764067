import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';

export function CartEmpty({ closeCart }) {
  const settings = useSettings();
  const { links, message, messageSecond } = { ...settings?.cart?.emptyCart };

  return (
    <ul className="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-5 px-5">
      <div className="pb-2 text-center">
        <h3 className="text-center font-montserrat font-bold text-black">{message}</h3>
        <p className="text-sm text-black">{messageSecond}</p>
      </div>

      {links?.map(({ link, style }, index) => {
        return (
          <li key={index} className="w-[60%]">
            <Link
              aria-label={link?.text}
              className={`${style} w-full`}
              href={link?.url}
              newTab={link?.newTab}
              onClick={closeCart}
              type={link?.type}
            >
              {link?.text}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

CartEmpty.displayName = 'CartEmpty';
