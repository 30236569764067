import { useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCollectionByHandle } from '@backpackjs/storefront';
import { Link, ProductItem, Spinner, Svg } from '../../snippets';
import { Schema } from './CollectionSlider.schema';
import { useColorSwatches } from '../../hooks';

export function CollectionSlider({ cms }) {
  const {
    button,
    heading,
    productItem,
    collectionHandle,
    section,
    slider,
    textColor,
  } = cms;

  const { swatchesMap } = useColorSwatches();

  const [swiper, setSwiper] = useState(null);

  const { sliderStyle } = { ...slider };
  const slidesPerViewDesktop = slider?.slidesPerViewDesktop || 4;
  const slidesPerViewTablet = slider?.slidesPerViewTablet || 3.4;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 1.4;
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-none'
      : 'max-w-[var(--content-max-width)]';

  const containedClassX = !isFullBleedAndCentered ? 'px-contained' : '';

  const handle = collectionHandle?.url.split('/collections').pop();

  const { collection } = useCollectionByHandle({ handle });

  return (
    <div
      className={`${section?.verticalPaddingLevelMobile} ${section?.verticalPaddingLevel}`}
    >
      <div className="m-auto flex flex-col items-center">
        <h2 className="h3 px-4 text-center" style={{ color: textColor }}>
          {heading}
        </h2>

        {collection && (
          <Swiper
            centeredSlides={
              isFullBleedAndCentered &&
              collection?.products?.length >= slidesPerViewMobile * 2
            }
            className={`relative !mt-6 mt-8 w-full md:mt-7 ${containedClassX} ${maxWidthClass} ${
              sliderStyle === 'fullBleedWithGradient'
                ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
                : ''
            }`}
            grabCursor
            loop={true}
            modules={[Navigation]}
            onSwiper={setSwiper}
            slidesOffsetAfter={0}
            slidesOffsetBefore={0}
            slidesPerView={slidesPerViewMobile}
            spaceBetween={20}
            breakpoints={{
              768: {
                centeredSlides:
                  isFullBleedAndCentered &&
                  collection?.products?.length >= slidesPerViewTablet * 2,
                slidesPerView: slidesPerViewTablet,
                spaceBetween: 13,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
              1024: {
                centeredSlides:
                  isFullBleedAndCentered &&
                  collection?.products?.length >= slidesPerViewDesktop * 2,
                slidesPerView: slidesPerViewDesktop,
                spaceBetween: 13,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
            }}
          >
            {swiper &&
              collection?.products?.map((product, index) => {
                return index < 12 && (
                  <SwiperSlide key={index}>
                    <ProductItem
                      enabledColorNameOnHover={
                        productItem?.enabledColorNameOnHover
                      }
                      enabledColorSelector={productItem?.enabledColorSelector}
                      enabledQuickShop={productItem?.enabledQuickShop}
                      enabledStarRating={productItem?.enabledStarRating}
                      handle={product?.handle}
                      product={product}
                      index={index}
                      swatchesMap={swatchesMap}
                      imageLoadingMode={productItem?.imageLoadingMode}
                    />
                  </SwiperSlide>
                );
              })}

            <div
              className={`z-1 absolute left-0 right-0 top-[35%] px-2 md:top-[40%] ${
                collection?.products?.length > slidesPerViewMobile
                  ? 'block'
                  : 'hidden'
              }  ${
                collection?.products?.length > slidesPerViewTablet
                  ? 'md:block'
                  : 'md:hidden'
              } ${
                collection?.products?.length > slidesPerViewDesktop
                  ? 'lg:block'
                  : 'lg:hidden'
              }`}
            >
              <div
                className={`relative mx-auto ${maxWidthClass} ${
                  isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                }`}
              >
                <button
                  aria-label="See previous products"
                  type="button"
                  className="swiper-button-prev left-0 !h-[46px] !w-[46px] bg-white drop-shadow-[0_7px_29px_rgba(100,100,111,.2)] after:hidden"
                  onClick={() => swiper.slidePrev()}
                >
                  <Svg
                    className="w-[1rem] text-black"
                    src="/svgs/chevron-left.svg#chevron-left"
                    title="Arrow Left"
                    viewBox="0 0 24 24"
                  />
                </button>

                <button
                  aria-label="See next products"
                  type="button"
                  className="swiper-button-next right-0 !h-[46px] !w-[46px] bg-white drop-shadow-[0_7px_29px_rgba(100,100,111,.2)] after:hidden"
                  onClick={() => swiper.slideNext()}
                >
                  <Svg
                    className="w-[1rem] text-black"
                    src="/svgs/chevron-right.svg#chevron-right"
                    title="Arrow Right"
                    viewBox="0 0 24 24"
                  />
                </button>
              </div>
            </div>
          </Swiper>
        )}

        {!swiper && (
          <div className="relative flex min-h-[300px] items-center justify-center lg:min-h-[600px]">
            <Spinner
              width="32"
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        )}

        {button?.text && (
          <div className="mt-10">
            <Link
              aria-label={button.text}
              className={`${section?.buttonStyle || 'btn-primary'}`}
              href={button.url}
              newTab={button.newTab}
              type={button.type}
            >
              {button.text}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

CollectionSlider.displayName = 'CollectionSlider';
CollectionSlider.Schema = Schema;
