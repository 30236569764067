import { useEffect, useState } from 'react';

export function LoopCreditBar({}) {
  const [loopCredit, setLoopCredit] = useState(null);
  const [loopCurrency, setLoopCurrency] = useState(null);


  useEffect(() => {
    const onPageLoad = () => {
      const savedParam = localStorage.getItem('loopOnstoreParams');

      if (savedParam) {
        const parsedParams = JSON.parse(savedParam);
        const creditInDollars = (parsedParams?.loop_total / 100).toFixed(2);
        setLoopCredit(creditInDollars);
        setLoopCurrency(parsedParams?.loop_currency || 'USD');
      }
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const hasLoop = !!loopCredit;

  const loopTextLabel = `BYLT Credit:${' '}${loopCredit} ${loopCurrency}`;

  return (
    hasLoop && (
      <div
        className={`fixed bottom-0 z-30 flex h-[var(--promobar-height)] w-full items-center justify-center overflow-hidden bg-[#25293b]`}
      >
        <p className="text-sm font-bold uppercase text-white">
          {loopTextLabel}
        </p>
      </div>
    )
  );
}

LoopCreditBar.displayName = 'LoopCreditBar';
