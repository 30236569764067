import { COLORS, VERTICAL_PADDING } from '../../settings/common';

export function Schema() {
  return {
    category: 'Text',
    label: 'Icon Row',
    key: 'icon-row',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/icon-row-preview.jpg?v=1675730317',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Icon Row Heading',
      },
      {
        label: 'Heading Alignment',
        name: 'headingAlignment',
        component: 'select',
        options: [
          {
            label: 'Left',
            value: 'mr-auto ml-0',
          },
          {
            label: 'Center',
            value: 'mx-auto',
          },
        ],
        default: 'mx-auto',
      },
      {
        label: 'Show line below title',
        name: 'lineBelowTitle',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        default: false,
      },
      {
        label: 'Subtext',
        name: 'subtext',
        component: 'markdown',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      {
        component: 'link',
        name: 'buttonLink',
        label: 'Link',
        defaultValue: {
          text: 'Buy now',
          url: '/collections/all-men',
        },
      },
      {
        label: 'Button Placement',
        name: 'buttonPlacement',
        component: 'select',
        options: [
          {
            label: 'Top',
            value: 'top',
          },
          {
            label: 'Bottom',
            value: 'bottom',
          },
        ],
        default: 'top',
      },
      {
        label: 'Icons',
        name: 'icons',
        component: 'group-list',
        itemProps: {
          label: '{{item.label}}',
        },
        fields: [
          {
            label: 'Icon',
            name: 'icon',
            component: 'image',
          },
          {
            component: 'image',
            name: 'customIcon',
            label: 'Icon Image',
          },
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'text',
          },
        ],
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        description: 'Max of two buttons',
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Button Style',
            name: 'style',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Background color, text color, icon color, full width',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Text Color',
            name: 'textColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Number Of Columns In Desktop',
            name: 'columnsDesktop',
            component: 'select',
            options: [
              { label: '2', value: 'md:basis-1/2' },
              { label: '3', value: 'md:basis-1/3' },
              { label: '4', value: 'md:basis-1/4' },
              { label: '5', value: 'md:basis-1/5' },
              { label: '6', value: 'md:basis-1/6' },
            ],
            defaultValue: 'md:basis-1/6',
          },
          {
            label: 'Icon Size',
            name: 'iconSize',
            component: 'select',
            options: [
              { label: 'Small', value: 50 },
              { label: 'Default', value: 100 },
            ],
            defaultValue: 100,
          },
          {
            label: 'Icon Color',
            name: 'iconColor',
            component: 'select',
            options: COLORS,
          },
          {
            component: 'select',
            name: 'spacingOption',
            label: 'Spacing Option',
            description: 'A select field',
            options: [
              {
                label: 'Center',
                value: 'justify-center',
              },
              {
                label: 'Around',
                value: 'justify-around',
              },
              {
                label: 'Between',
                value: 'justify-between',
              },
              {
                label: 'Evenly',
                value: 'justify-evenly',
              },
            ],
            defaultValue: 'noValue',
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Inner Icons Full Width',
            name: 'innerFullWidth',
            component: 'toggle',
            description: 'Removes max width of this inner icons elements',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Use only on Bundle Kits',
            name: 'isBundleKitOnly',
            component: 'toggle',
            description:
              'Enable this banner to be used only on the bundle and kit products, this will only work if the section is used on the PDP',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding Desktop',
            name: 'verticalPaddingLevel',
            component: 'select',
            options: VERTICAL_PADDING.desktop,
            defaultValue: 'md:py-contained',
          },
          {
            label: 'Vertical Padding Mobile',
            name: 'verticalPaddingLevelMobile',
            component: 'select',
            options: VERTICAL_PADDING.mobile,
            defaultValue: 'max-md:py-contained',
          },
        ],
        defaultValue: {
          bgColor: 'var(--off-white)',
          textColor: 'var(--text)',
          iconColor: 'var(--primary)',
          fullWidth: false,
        },
      },
    ],
  };
}
