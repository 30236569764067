import { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useBlog } from '@backpackjs/storefront';

import { BlogGridItem } from './BlogGridItem';
import { Schema } from './BlogGrid.schema';

export function BlogGrid({ cms }) {
  const {
    articles,
    desktopColumns,
    verticalPaddingLevel,
    verticalPaddingLevelMobile,
  } = cms;
  const blog = useBlog();
  const router = useRouter();
  const desktopGridColumns = desktopColumns || 'md:grid-cols-3';

  const [filteredArticles, setFilteredArticles] = useState(blog?.articles);

  const categoryParam = router.query.category;

  useEffect(() => {
    if (categoryParam) {
      const filtered = blog?.articles?.filter(({ category }) => {
        return (
          category?.toLowerCase().trim() === categoryParam?.toLowerCase().trim()
        );
      });
      setFilteredArticles(filtered);
    } else {
      setFilteredArticles(blog?.articles);
    }
  }, [blog?.articles, categoryParam]);

  const selectedArticles = useMemo(() => {
    if (articles?.length > 0) return articles;

    return filteredArticles;
  }, [filteredArticles, articles]);

  return (
    <div className={`max-2xl:px-contained py-contained ${verticalPaddingLevel} ${verticalPaddingLevelMobile}`}>
      {selectedArticles?.length ? (
        <ul
          className={`mx-auto grid max-w-[var(--content-max-width)] grid-cols-2 gap-x-5 gap-y-8 lg:gap-10 ${desktopGridColumns}`}
        >
          {selectedArticles.map((article, index) => {
            return (
              article?.title && (
                <li key={index}>
                  <BlogGridItem article={article} />
                </li>
              )
            );
          })}
        </ul>
      ) : (
        <div className="flex min-h-[12.5rem] items-center justify-center text-center">
          <p>No posts found under this category.</p>
        </div>
      )}
    </div>
  );
}

BlogGrid.displayName = 'BlogGrid';
BlogGrid.Schema = Schema;
