import Script from 'next/script';

import {
  useDataLayerAccount,
  useDataLayerCart,
  useDataLayerCollection,
  useDataLayerCustomer,
  useDataLayerInit,
  useDataLayerProduct,
  useDataLayerSearch,
  useDataLayerSubscribe,
} from './hooks';

// Envs to set:
// * NEXT_PUBLIC_GA4_TAG_ID // enables GA4 analytics, e.g. G-XXXXXXXXXX
// * NEXT_PUBLIC_SITE_TITLE // provides backup brand name

const DEBUG = false;

export function DataLayer({ pageTitle, template }) {
  const { generateUserProperties, userProperties } = useDataLayerInit({
    DEBUG,
    pageTitle,
    template,
  });

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    DEBUG,
    userProperties,
  });

  useDataLayerAccount({
    DEBUG,
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    DEBUG,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSubscribe({
    DEBUG,
    userDataEventTriggered,
  });

  return (
    <>
      {process.env.NEXT_PUBLIC_GA4_TAG_ID && (
        <Script
          id="gtag-script"
          type="text/javascript"
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA4_TAG_ID}`}
        />
      )}

      <Script
        id="gtag-config"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            if (${!!process.env.NEXT_PUBLIC_GA4_TAG_ID}) {
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GA4_TAG_ID}');
            }
          `,
        }}
      />

      <Script
        id="postie"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            ;(function (z, i, p, c, o, d, e) {
              z[c] ||
                ((z[c] = function () {
                  ;(z[c].q = z[c].q || []).push(arguments)
                }),
                (z[c].q = z[c].q || []),
                (d = i.createElement(p)),
                (d.async = 1),
                (d.src = o),
                (e = i.getElementsByTagName(p)[0]),
                e.parentNode.insertBefore(d, e))
            })(
              window,
              document,
              'script',
              'letterpress',
              '//scripts.postie.com/dmlmdtsp/lp.1.js'
            )
          `,
        }}
      />
    </>
  );
}

DataLayer.displayName = 'DataLayer';
