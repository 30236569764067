import { useState } from 'react';
import { A11y, Autoplay, EffectFade, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Svg } from '../../snippets';

import { HeroSlide } from './HeroSlide';

export function HeroSlider({ aboveTheFold, slider, slides }) {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const { autoplay, delay, effect, pagination, navigation, loop, paginationStyle } = slider;

  return (
    <Swiper
      a11y={{ enabled: true }}
      autoplay={
        autoplay
          ? {
              delay: delay || 5000,
              disableOnInteraction: false,
            }
          : false
      }
      loop={loop}
      className={`h-full w-full pagination-rounded ${paginationStyle}`}
      effect={effect}
      fadeEffect={{
        crossFade: true,
      }}
      grabCursor
      onSlideChange={(swiper) => setVisibleIndex(swiper.realIndex)}
      modules={[A11y, Autoplay, EffectFade, Pagination, Navigation]}
      pagination={{
        el: `.hero-pagination`,
        type: 'bullets',
        clickable: true,
      }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        disabledClass: 'cursor-not-allowed opacity-20',
      }}
      slidesPerView={1}
    >
      {slides.map((slide, index) => {
        return (
          <SwiperSlide key={index}>
            <HeroSlide
              aboveTheFold={aboveTheFold}
              isActiveSlide={index === visibleIndex}
              isFirstSlide={index === 0}
              slide={slide}
            />
          </SwiperSlide>
        );
      })}

      <div className={`hero-pagination swiper-pagination ${pagination ? '' : '!hidden'}`} />

      {navigation && (
        <div className="z-1 absolute left-0 right-0 top-[50%]">
          <div className="relative mx-auto  min-[90rem]:max-w-full">
            <div className="swiper-button-prev left-0 top-[calc(50%-1.6875rem)] flex !h-[3.5rem] !w-[3.5rem] rounded-full after:hidden">
              <Svg
                className="w-[31px] text-white"
                src="/svgs/chevron-left.svg#chevron-left"
                title="Arrow Left"
                viewBox="0 0 24 24"
              />
            </div>

            <div className="swiper-button-next !right-[0px] top-[calc(50%-1.6875rem)] flex !h-[3.5rem] !w-[3.5rem] rounded-full after:hidden">
              <Svg
                className="w-[31px] text-white"
                src="/svgs/chevron-right.svg#chevron-right"
                title="Arrow Right"
                viewBox="0 0 24 24"
              />
            </div>
          </div>
        </div>
      )}
    </Swiper>
  );
}

HeroSlider.displayName = 'HeroSlider';
