import PropTypes from 'prop-types';

import { ReviewStars } from '../ReviewStars';

export function ProductStars({ legacyResourceId, stampedData }) {
  const reviewAggregate = {
    rating: stampedData?.reviews_average
      ? parseFloat(stampedData?.reviews_average)
      : 0,
    total: stampedData?.reviews_count || 0,
  };

  const reviewsText =
    reviewAggregate?.total === '1'
      ? `${reviewAggregate?.total} Review`
      : `${reviewAggregate?.total} Reviews`;

  const handleReviewsScroll = () => {
    const reviewsSection = document.getElementById('product-reviews');

    if (reviewsSection) {
      window.scrollTo({
        top: reviewsSection?.offsetTop - 150,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div
      className="flex min-h-[1rem] flex-wrap items-center gap-1 pt-0.5 leading-[19px]"
      onClick={() => handleReviewsScroll()}
    >
      <ReviewStars rating={reviewAggregate?.rating || 5} size="large" />

      {reviewAggregate?.total !== 0 && (
        <p className="pt-0.5 text-[13px] leading-[19px] text-mediumDarkGray">
          {reviewsText}
        </p>
      )}
    </div>
  );
}

ProductStars.displayName = 'ProductStars';
ProductStars.propTypes = {
  legacyResourceId: PropTypes.string,
};
