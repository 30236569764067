import { COLORS } from './common';

export default {
  label: 'Header',
  name: 'header',
  component: 'group',
  description: 'Menu, promobar',
  fields: [
    {
      label: 'Promobar',
      name: 'promobar',
      component: 'group',
      description: 'Enable, messages, colors, slider settings',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Autohide',
          name: 'autohide',
          component: 'toggle',
          description:
            'Hides promobar after scrolling away from top of the page',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Effect Between Transitions',
          name: 'effect',
          component: 'select',
          description: 'Refresh page to observe change',
          options: [
            { label: 'Fade', value: 'fade' },
            { label: 'Horizontal Slide', value: 'slide-horizontal' },
            { label: 'Vertical Slide', value: 'slide-vertical' },
          ],
          defaultValue: 'fade',
        },
        {
          label: 'Autoplay Delay',
          name: 'delay',
          component: 'number',
          description: 'Delay between transitions (in ms)',
          defaultValue: 5000,
        },
        {
          label: 'Speed',
          name: 'speed',
          component: 'number',
          description: 'Duration of transition between slides (in ms)',
          defaultValue: 500,
        },
        {
          label: 'Background Color',
          name: 'bgColor',
          component: 'select',
          options: COLORS,
          defaultValue: 'var(--primary)',
        },
        {
          label: 'Use Custom Background Color',
          name: 'isCustomColor',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'Custom Color',
          name: 'customColor',
          component: 'color',
        },
        {
          label: 'Text Color',
          name: 'color',
          component: 'select',
          options: COLORS,
          defaultValue: 'var(--white)',
        },
        {
          label: 'Messages',
          name: 'messages',
          component: 'group-list',
          itemProps: {
            label: '{{item.message}}',
          },
          fields: [
            {
              label: 'Message',
              name: 'message',
              component: 'textarea',
            },
            {
              label: 'Link (optional)',
              name: 'link',
              component: 'link',
              description: 'Link wrapping entire message',
            },
          ],
          defaultItem: {
            message: 'Free shipping on orders over $100. Shop Now',
            link: { url: '/', text: '' },
          },
        },
        {
          label: 'Right Corner Link (optional)',
          name: 'rightLink',
          component: 'link',
          description:
            'An optional Link located at the right corner of the promobar',
        },
        {
          label: 'Left Corner Link (optional)',
          name: 'leftLink',
          component: 'link',
          description:
            'An optional Link located at the left corner of the promobar',
        },
      ],
    },
    {
      label: 'Menu',
      name: 'menu',
      component: 'group',
      description: 'Menu items, logo position, products slider, links',
      fields: [
        {
          label: 'Menu Items',
          name: 'menuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.menuItem.text}}',
          },
          fields: [
            {
              label: 'Menu Item',
              name: 'menuItem',
              component: 'link',
            },
            {
              label: 'Mobile Header Banner',
              name: 'image',
              component: 'image',
            },
            {
              label: 'Banner Alt',
              name: 'alt',
              component: 'text',
              description: 'Brief description of image',
            },
            {
              label: 'Mobile Header Color',
              name: 'mobileHeadColor',
              component: 'select',
              options: COLORS,
            },
            {
              label: 'Submenu Side Links',
              name: 'links',
              component: 'group-list',
              itemProps: {
                label: '{{item.link.text}}',
              },
              fields: [
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                },
              ],
              defaultItem: {},
            },
            {
              label: 'Submenu Groups',
              name: 'centerlinks',
              component: 'group-list',
              itemProps: {
                label: '{{item.link.text}}',
              },
              fields: [
                {
                  label: 'SubGroups',
                  name: 'subGroups',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.mainLink.text}}',
                  },
                  fields: [
                    {
                      label: 'Main Link',
                      name: 'mainLink',
                      component: 'link',
                    },
                    {
                      label: 'Links',
                      name: 'secondaryLinks',
                      component: 'list',
                      field: {
                        component: 'link',
                      },
                    },
                  ],
                  defaultItem: {},
                  validate: {
                    maxItems: 2,
                  },
                },
              ],
              defaultItem: {},
            },
            {
              label: 'Submenu Images',
              name: 'imageLinks',
              component: 'group-list',
              itemProps: {
                label: '{{item.caption}}',
              },
              fields: [
                {
                  label: 'Image Alt',
                  name: 'alt',
                  component: 'text',
                  description: 'Brief description of image',
                },
                {
                  label: 'Image',
                  name: 'image',
                  component: 'image',
                },
                {
                  label: 'Caption',
                  name: 'caption',
                  component: 'text',
                },
                {
                  label: 'Sub Caption',
                  name: 'subCaption',
                  component: 'text',
                },
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                },
              ],
              defaultItem: {},
            },
            {
              label: 'Desktop Menu Bottom Links',
              name: 'deskBottomLinks',
              description: 'Bottom links on the MegaMenu',
              component: 'group',
              fields: [
                {
                  label: 'Heading',
                  name: 'heading',
                  component: 'text',
                },
                {
                  label: 'Links',
                  name: 'links',
                  component: 'list',
                  field: {
                    component: 'link',
                  },
                },
                {
                  label: 'Right Link',
                  name: 'rightLink',
                  component: 'link',
                },
                {
                  label: 'Text Color',
                  name: 'color',
                  component: 'select',
                  options: COLORS,
                  defaultValue: 'var(--white)',
                },
                {
                  label: 'Background Color',
                  name: 'bgColor',
                  component: 'select',
                  options: COLORS,
                  defaultValue: 'var(--primary)',
                },
                {
                  label: 'Use Custom Background Color',
                  name: 'isCustomColor',
                  component: 'toggle',
                  toggleLabels: {
                    true: 'On',
                    false: 'Off',
                  },
                  defaultValue: false,
                },
                {
                  label: 'Custom Color',
                  name: 'customColor',
                  component: 'color',
                },
              ],
            },
          ],
          defaultItem: {
            menuItem: { text: 'Shop', url: '/collections/all' },
          },
        },
        {
          label: 'Logo Position (desktop)',
          name: 'logoPositionDesktop',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: [
            { label: 'Left', value: 'left' },
            { label: 'Center', value: 'center' },
          ],
          defaultValue: 'left',
        },
        {
          component: 'toggle',
          name: 'showCartCountWhenEmpty',
          label: 'Show Cart Count Number When is Empty',
          toggleLabels: {
              true: 'Yes',
              false: 'No',
          },
          defaultValue: false,
        },
        {
          label: 'Products Slider',
          name: 'productsSlider',
          description: 'Visible only in menu sidebar',
          component: 'group',
          fields: [
            {
              label: 'Heading',
              name: 'heading',
              component: 'text',
            },
            {
              label: 'Products',
              name: 'products',
              component: 'group-list',
              itemProps: {
                label: '{{item.product.handle}}',
              },
              fields: [
                {
                  label: 'Product',
                  name: 'product',
                  component: 'productSearch',
                },
              ],
            },
          ],
        },
        {
          label: 'Featured Mobile Links',
          name: 'links',
          description: 'Visible only in menu sidebar',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
        },
        {
          label: 'Featured Mobile Banners',
          name: 'imageBanners',
          description: 'Visible only in menu sidebar',
          component: 'group-list',
          itemProps: {
            label: '{{item.caption}}',
          },
          fields: [
            {
              label: 'Image Alt',
              name: 'alt',
              component: 'text',
              description: 'Brief description of image',
            },
            {
              label: 'Image',
              name: 'image',
              component: 'image',
            },
            {
              label: 'Caption',
              name: 'caption',
              component: 'text',
            },
            {
              label: 'Sub Caption',
              name: 'subCaption',
              component: 'text',
            },
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
            {
              label: 'Button Style',
              name: 'style',
              component: 'select',
              options: [
                { label: 'Primary', value: 'btn-primary' },
                { label: 'Secondary', value: 'btn-secondary' },
                { label: 'Inverse Light', value: 'btn-inverse-light' },
                { label: 'Inverse Dark', value: 'btn-inverse-dark' },
              ],
              defaultValue: 'btn-inverse-light',
            },
          ],
          defaultItem: {},
        },
      ],
    },
  ],
};
