import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';

export function FooterLegal() {
  const settings = useSettings();
  const { links, copyrightNotice } = { ...settings?.footer?.legal };

  return (
    <div className="flex flex-col gap-x-4 gap-y-1 px-4 pt-2 text-xs text-current lg:flex-row lg:p-0 lg:text-sm text-center lg:text-left">
      <p>
        Copyright &copy; {new Date().getFullYear()} {copyrightNotice}
      </p>

      <ul className="flex flex-wrap gap-x-4 gap-y-1">
        {links?.map(({ link }, index) => {
          return (
            <li key={index} className="flex">
              <p
                className={`pr-4 ${index === 0 ? 'hidden' : 'block'} lg:block`}
              >
                |
              </p>
              <Link
                aria-label={link?.text}
                href={link?.url}
                newTab={link?.newTab}
                type={link?.type}
              >
                <p className="hover-text-underline text-current">
                  {link?.text}
                </p>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

FooterLegal.displayName = 'FooterLegal';
