import { useState } from 'react';
import { Link, ContactUsForm, Markdown } from '../../snippets';
import { Schema } from './ContactFormBanner.schema';

export function ContactFormBanner({ cms }) {
  const {
    title,
    aboveTheFold,
    buttons,
    centerAllText,
    content,
    successMessage,
    image,
    groupMode,
  } = cms;

  const hasCover = groupMode?.enableCoverMode || false;

  const [isFormVisble, setIsFormVisible] = useState(!hasCover);

  return (
    <div className="px-contained py-contained">
      {hasCover && (
        <div className={`${isFormVisble ? 'mb-10' : ''}`}>
          <p className="mb-[40px] text-center text-[28px] font-bold leading-[32px]">
            {groupMode?.coverHeading}
          </p>

          <div className="flex flex-col items-center justify-center gap-[40px] lg:flex-row">
            {groupMode?.coverLinks?.map((coverLink, index) => {
              const link = coverLink?.link;

              return (
                <div key={index}>
                  {coverLink.isAction ? (
                    <button
                      className="btn-secondary max-w-[205px] cursor-pointer"
                      onClick={() => {
                        if (coverLink.action === 'chat') {
                          setIsFormVisible(!isFormVisble);

                          setTimeout(function () {
                            const element =
                              document.getElementById('contact-form');
                            if (!element) {
                              return;
                            }
                            const offset = 140;
                            const bodyRect =
                              document.body.getBoundingClientRect().top;
                            const elementRect =
                              element.getBoundingClientRect().top;
                            const elementPosition = elementRect - bodyRect;
                            const offsetPosition = elementPosition - offset;

                            window.scrollTo({
                              top: offsetPosition,
                              behavior: 'smooth',
                            });
                          }, 500);
                        } else if (!window?.GorgiasChat?.isOpen()) {
                          window?.GorgiasChat?.open();
                        } else {
                          window?.GorgiasChat?.close();
                        }
                      }}
                    >
                      {link?.text}
                    </button>
                  ) : (
                    <Link
                      aria-label={link.text}
                      className="btn-secondary cursor-pointer"
                      href={link.url}
                      newTab={link.newTab}
                      type={link.type}
                    >
                      {link.text}
                    </Link>
                  )}

                  <div className="mt-2.5 text-center text-sm [&_p]:mb-0">
                    <Markdown>{coverLink?.info}</Markdown>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {isFormVisble && (
        <ContactUsForm
          title={title}
          aboveTheFold={aboveTheFold}
          content={content}
          buttons={buttons}
          isTextCenter={centerAllText}
          successMessage={successMessage}
          sideImage={image}
        />
      )}
    </div>
  );
}

ContactFormBanner.displayName = 'ContactFormBanner';
ContactFormBanner.Schema = Schema;
