import { useMemo, useState } from 'react';
import equal from 'fast-deep-equal';
import { Link } from '../../../snippets';

export function ProductOptionValue({
  groupingProductsMapBySize,
  inventory,
  isLoading,
  isSelected,
  name,
  product,
  selectedOptions,
  setSelectedOptions,
  value,
  externalClasses,
  isChild,
  isCustomPackHelper,
  setIsSizeSelected,
  isFromGrouping = false,
  setChild,
  childVariants = [],
}) {
  const [firstClick, setFirstClick] = useState(false);

  const newSelectedOptions = useMemo(() => {
    if (isCustomPackHelper) {
      const optionName = selectedOptions?.[name]
        ? name
        : product?.options[1]?.name;
      return selectedOptions
        ? {
            ...selectedOptions,
            [optionName]: value,
          }
        : null;
    }

    return selectedOptions
      ? {
          ...selectedOptions,
          [name]: value,
        }
      : null;
  }, [name, selectedOptions, value]);

  const variantFromOptionValue = useMemo(() => {
    if (!newSelectedOptions) return null;
    return product.variants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
  }, [newSelectedOptions, product.variants]);

  const variantFromFamilyGroup = useMemo(() => {
    if (!newSelectedOptions) return null;
    return childVariants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
  }, [newSelectedOptions, product.variants]);

  const optionValueIsAvailable =
    inventory?.variants?.[variantFromOptionValue?.id]?.availableForSale ||
    false;

  const optionGroupedIsAvailable = variantFromFamilyGroup?.availableForSale;

  const disabled = !isFromGrouping
    ? isLoading || !variantFromOptionValue
    : false;

  const validClass = !disabled ? 'md:hover:border-text' : 'cursor-not-allowed';

  const unavailableByCustom = isCustomPackHelper ? disabled : !disabled;

  const beforeClasses =
    'before:h-px before:w-[100%] before:rotate-[-135deg] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:bg-mediumGray before:z-[1]';
  const afterClasses =
    'after:h-px after:w-[100%] after:rotate-[135deg] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:bg-mediumGray';

  const groupUnavailableClass = optionGroupedIsAvailable
    ? ''
    : `${beforeClasses} ${afterClasses} text-gray overflow-hidden`;

  const unavailableClass = !isFromGrouping
    ? !optionValueIsAvailable && unavailableByCustom
      ? `${beforeClasses} ${afterClasses} text-gray overflow-hidden`
      : ''
    : groupUnavailableClass;

  const selectedClass =
    isChild && isSelected
      ? 'border-text bg-primary text-white'
      : isSelected && firstClick
      ? 'border-text bg-primary text-white'
      : '';

  // GROUPING FEATURE
  const selectedVariantFromOptions = useMemo(() => {
    if (!groupingProductsMapBySize || !newSelectedOptions) return null;

    return groupingProductsMapBySize[newSelectedOptions.Size]?.variants?.find(
      ({ selectedOptionsMap }) => {
        return equal(newSelectedOptions, selectedOptionsMap);
      }
    );
  }, [groupingProductsMapBySize, newSelectedOptions]);

  const url = useMemo(() => {
    if (!selectedVariantFromOptions) return null;

    let params = '';
    if (typeof window !== 'undefined') {
      const { search } = window.location;
      params = new URLSearchParams(search);
      params.set('variant', selectedVariantFromOptions.legacyResourceId);
    }

    return `/products/${selectedVariantFromOptions.product.handle}?${params}`;
  }, [selectedVariantFromOptions]);

  return !isFromGrouping ? (
    <button
      aria-label={value}
      className={`relative h-10 w-auto min-w-[2.5rem] border border-border transition ${validClass} ${unavailableClass} ${selectedClass} ${externalClasses} rounded-sm`}
      disabled={disabled}
      data-wair-option-value={value}
      onClick={() => {
        if (!firstClick) {
          setFirstClick(true);
          setSelectedOptions(newSelectedOptions);
          if (setIsSizeSelected) {
            setIsSizeSelected(true);
          }
        } else {
          if (isSelected) return;
          setSelectedOptions(newSelectedOptions);
        }
      }}
      type="button"
      style={{ minWidth: '2.5rem' }}
    >
      {value}
    </button>
  ) : (
    <button
      aria-label={value}
      className={`relative h-10 w-auto min-w-[2.5rem] border border-border transition ${validClass} ${unavailableClass} ${selectedClass} ${externalClasses} rounded-sm`}
      //disabled={disabled}
      data-wair-option-value={value}
      onClick={() => {
        if (!firstClick) {
          setFirstClick(true);
          setChild(newSelectedOptions);
          setSelectedOptions(newSelectedOptions);
          if (setIsSizeSelected) {
            setIsSizeSelected(true);
          }
        } else {
          if (isSelected) return;
          setChild(newSelectedOptions);
          setSelectedOptions(newSelectedOptions);
        }
      }}
      type="button"
      style={{ minWidth: '2.5rem' }}
    >
      {value}
    </button>
  );
}

ProductOptionValue.displayName = 'ProductOptionValue';
