import Script from 'next/script';
import { AppLoaderGorgias } from './AppLoaderGorgias';
import { LoopHandler } from '../LoopHandler';

export function AppLoader({}) {
  return (
    <div>
      {/*Equalweb script*/}
      <Script
        id="equalweb-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `window.interdeal = { "sitekey": "ba107c93b53273ab6df5812374f2bec0", "Position": "Left", "Menulang": "EN", "domains": { "js": "https://cdn.equalweb.com/", "acc": "https://access.equalweb.com/" }, "btnStyle": { "vPosition": [ "80%", null ], "scale": [ "0.6", "0.6" ], "color": { "main": "#25293b" }, "icon": { "type": 11, "shape": "circle", "outline": true } } }; (function(doc, head, body){ var coreCall = doc.createElement('script'); coreCall.src = interdeal.domains.js + 'core/4.6.1/accessibility.js'; coreCall.defer = true; coreCall.integrity = 'sha512-aAp5m6pQO8f6HIIF3V/7pXeoo9/VWZTij6e6vH0KoI8PJCEN4EVKvslxF8elAIMTd1znMDg0qwrelAJfDOu6KA=='; coreCall.crossOrigin = 'anonymous'; coreCall.setAttribute('data-cfasync', true ); body? body.appendChild(coreCall) : head.appendChild(coreCall); })(document, document.head, document.body);`,
        }}
      />

      {/*Gorgias scripts*/}
      <Script
        src="https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=18758"
        type="text/javascript"
        async
      />
      
      <AppLoaderGorgias />

      <LoopHandler />
    </div>
  );
}

AppLoader.displayName = 'AppLoader';
