import { useState, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Accordion } from './Accordion';
import { Schema } from './AccordionsTabs.schema';
import { Svg, Image } from '../../snippets';

export function AccordionsTabs({ cms }) {
  const { accordions, heading, tabs, headerBgColor, headerTextColor } = cms;
  const [mainSwiper, setMainSwiper] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [index, setIndex] = useState(0);

  const selectedAccordions = useMemo(() => {
    if (tabs?.length < 1) return [];

    return tabs[selectedTab]?.accordions;
  }, [selectedTab]);

  const pagination = tabs ? Math.ceil(tabs?.length / 2) : 0

  return (
    <div className="mx-auto my-4 max-w-[90rem] px-[32px] md:my-8 lg:px-[40px] xl:my-12">
      <div className="w-full">
        {heading && <h2 className="mb-4 text-center">{heading}</h2>}

        {tabs?.length > 0 && (
          <div className="relative mb-[10px] lg:mb-[33px]">
            <Swiper
              className="relative"
              grabCursor
              onActiveIndexChange={(swiper) => {
                setIndex(swiper.activeIndex)
              }}
              modules={[Navigation]}
              onSwiper={setMainSwiper}
              slidesPerView={2}
              spaceBetween={0}
              breakpoints={{
                768: {
                  slidesPerView: tabs?.length,
                  spaceBetween: 0,
                },
              }}
            >
              {tabs.map(({ heading, alt, image }, index) => {
                return (
                  <SwiperSlide className="w-full" key={index}>
                    <div
                      className={`flex h-[152px] cursor-pointer flex-col justify-center gap-1 border border-border text-center ${
                        selectedTab === index
                          ? `bg-black text-white`
                          : `text-[${headerTextColor}]`
                      }`}
                      key={index}
                      onClick={() => setSelectedTab(index)}
                    >
                      <div className="mx-auto flex aspect-[1/1] w-[37.5px]  md:w-[47px]">
                        {image && image.src && (
                          <Image
                            alt={alt}
                            width="47"
                            height="47"
                            src={image.src}
                            className={selectedTab === index ? 'invert' : ''}
                          />
                        )}
                      </div>

                      <h2 className="h6 mx-auto max-w-[150px] text-center font-bold uppercase">
                        {heading}
                      </h2>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {tabs.length && (
              <div className="z-1 absolute left-0 right-0 top-[calc(70%)] md:px-8 md:hidden xl:px-14">
                <div className="relative mx-auto min-[90rem]:max-w-full">
                  <div
                    onClick={() => mainSwiper.slidePrev()}
                    className={`swiper-button-prev left-[-1.75rem] top-[calc(50%-1.6875rem)] !h-[28px] !w-[28px] after:hidden md:-left-[3.25rem] ${
                      index === 0 ? '!hidden' : '!flex'
                    }`}
                  >

                    <Svg
                      className="w-[48px] text-black"
                      src="/svgs/chevron-left.svg#chevron-left"
                      title="Arrow Left"
                      viewBox="0 0 24 24"
                    />
                  </div>


                  <div
                    onClick={() => mainSwiper.slideNext()}
                    className={`swiper-button-next right-[-1.75rem] top-[calc(50%-1.6875rem)] !h-[28px] !w-[28px]  after:hidden md:-right-[3.25rem] ${
                      index > pagination ? '!hidden' : '!flex'
                    }`}
                  >
                    <Svg
                      className="w-[48px] text-black"
                      src="/svgs/chevron-right.svg#chevron-right"
                      title="Arrow Right"
                      viewBox="0 0 24 24"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <ul className="grid grid-cols-1 gap-[20px] border border-border px-[8px] py-[12px]">
          {selectedAccordions?.map((accordion, index) => {
            return (
              <li key={index}>
                <Accordion
                  accordion={accordion}
                  headerBgColor={headerBgColor}
                  headerTextColor={headerTextColor}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

AccordionsTabs.displayName = 'Accordions With Tabs';
AccordionsTabs.Schema = Schema;
