import { useState } from 'react';
import { Spinner } from '../Spinner';
import axios from 'axios';

export function InveterateCancelContent({ customer }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const cancelAccount = async () => {
    setIsLoading(true);

    try {
      const customerID = customer?.id?.split('gid://shopify/Customer/')[1];
      const endpoint = `https://public.inveterateapi.com/v2.0/storefront/members/${customerID}/cancellation`;

      const response = await axios({
        url: endpoint,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Inveterate-Api-Key': 'd7b6fc64c7230112f2e208b64f249f92',
        },
      });

      setIsLoading(false);
      setIsSuccess(true)
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="text-center">
        {isSuccess ? (
          <p className="pb-4">Successfully confirmed cancellation</p>
        ) : (
          <p className="pb-4">Are you sure to cancel your membership?</p>
        )}

        <button className="btn-secondary" disabled={(isLoading || isSuccess)}>
          {isLoading ? (
            <Spinner width={16} />
          ) : (
            <div className="text-center" onClick={cancelAccount}>
              <span>Confirm</span>
            </div>
          )}
        </button>
      </div>
    </div>
  );
}

InveterateCancelContent.displayName = 'InveterateCancelContent';
