import { useCallback, useEffect, useState, useMemo } from 'react';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import {
  fetchProductsFromHandles,
  fetchVariantMetafields,
} from '../../../utilities';
import { ProductColorOptionValue } from './ProductColorOptionValue';
import { ProductOptionValue } from './ProductOptionValue';
import { useGlobalContext } from '../../../contexts';
import { useSwatchesDesing } from '../../../hooks';
import { SizeGuideModal, WairPlacement } from '../../../snippets';

export function ProductOptions({
  product,
  selectedOptions,
  selectedVariant,
  setSelectedOptions,
  externalClasses,
  metafields,
  setIsSizeSelected,
  setIsThirdSelected,
  isSizeSelected,
  hasSizeValidation = false,
}) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });

  const [variantMetafields, setVariantMetafields] = useState(null);

  const [groupingProductsMapByColor, setGroupingProductsMapByColor] =
    useState(null);

  const handleSizeGuideClick = useCallback(() => {
    // example modal
    openModal(<SizeGuideModal product={product} />);
  }, []);

  useEffect(() => {
    if (!product?.grouping?.isTransformed) return;

    const fetchProductsForMap = async () => {
      const products = await fetchProductsFromHandles(
        product.grouping.products
      );
      setGroupingProductsMapByColor(
        products?.reduce((acc, groupProduct) => {
          const color = groupProduct.optionsMap?.Color?.[0];
          if (!color) return acc;
          return {
            ...acc,
            [color]: groupProduct,
          };
        }, {})
      );
    };
    fetchProductsForMap();
  }, [product?.id]);

  const variantGroups = product?.metafields
    // grab only the relevant metafields
    ?.filter(
      (meta) =>
        meta?.namespace === 'custom_fields' &&
        (meta?.key.includes('color_headline') || meta?.key.includes('colors'))
    )
    // sort on key to have headline above color array
    .sort((prev, curr) => prev.key - curr.key)
    // reduce the array of objects to one object and turn string of colors to an array
    .reduce(
      (obj, { key, value }) =>
        Object.assign(obj, {
          [key]: value.includes(',') ? value.split(',') : [value],
        }),
      {}
    );

  useEffect(() => {
    if (!selectedVariant) return;

    const fetchMetafieldsData = async () => {
      const metafields = await fetchVariantMetafields(selectedVariant?.id);

      setVariantMetafields(metafields);
    };
    fetchMetafieldsData();
  }, [selectedVariant]);

  const modelInfo =
    variantMetafields?.custom_fields?.model_details ||
    metafields?.custom_fields?.model_details ||
    '';

  const sizeValidationClasses = '';

  //Swatches size classes
  const sizeSwatchDesign = useSwatchesDesing({ product });

  return (
    <div className="flex flex-col gap-2.5 md:gap-5">
      {product?.options?.map(({ id, name, values }, optionIndex) => {
        return (
          <div
            key={id}
            className={`border-border md:border md:p-4 ${externalClasses}`}
          >
            {name === 'Size' || name === 'Inseam' || name !== 'Color' ? (
              <>
                <div className="mb-2 flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <h3 className="pdp-option-label-b">{name}:</h3>
                    {isSizeSelected && (
                      <p className="pdp-option-label-g">
                        {selectedOptions?.[name]}
                      </p>
                    )}
                  </div>
                </div>

                <ul
                  className={`mb-3.5 flex flex-wrap gap-4 ${sizeSwatchDesign}`}
                  data-wair-option-name={name}
                >
                  {values?.map((value) => {
                    const isSelected = selectedOptions?.[name] === value;

                    return (
                      <li key={value}>
                        <ProductOptionValue
                          inventory={inventory}
                          isLoading={!inventoryFetched}
                          isSelected={isSelected}
                          name={name}
                          product={product}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          setIsSizeSelected={(e) =>
                            optionIndex > 1
                              ? setIsThirdSelected(e)
                              : setIsSizeSelected(e)
                          }
                          value={value}
                        />
                      </li>
                    );
                  })}
                </ul>

                <div className="flex justify-between max-md:mb-2">
                  {name === 'Size' && (
                    <div>
                      <p className="text-sm leading-none ">
                        {modelInfo}
                      </p>
                    </div>
                  )}

                  {name === 'Size' && (
                    <div className="flex gap-2">
                      <button
                        className="flex flex-row gap-2.5 text-sm leading-none underline"
                        onClick={handleSizeGuideClick}
                        type="button"
                      >
                        Size Guide
                      </button>

                      <p className="leading-none">|</p>

                      <WairPlacement product={product}/>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="mb-2 flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <h3 className="pdp-option-label-b">
                      {variantGroups?.color_headline ?? name}:
                    </h3>
                    <p className="pdp-option-label-g">
                      {!variantGroups?.alternate_colors?.find(
                        (color) =>
                          color === selectedOptions?.[name].toLowerCase()
                      ) &&
                      !variantGroups?.alternate_colors_2?.find(
                        (color) =>
                          color === selectedOptions?.[name].toLowerCase()
                      )
                        ? selectedOptions?.[name]
                        : ''}
                    </p>
                  </div>
                </div>

                <ul
                  className={`mb-2 flex flex-wrap gap-4 ${sizeValidationClasses}`}
                >
                  {values
                    ?.filter(
                      (value) =>
                        !variantGroups?.alternate_colors?.find(
                          (v) => v === value.toLowerCase()
                        ) &&
                        !variantGroups?.alternate_colors_2?.find(
                          (v) => v === value.toLowerCase()
                        )
                    )
                    .map((value) => {
                      const isSelected = selectedOptions?.[name] === value;

                      return (
                        <li key={value}>
                          <ProductColorOptionValue
                            groupingProductsMapByColor={
                              groupingProductsMapByColor
                            }
                            inventory={inventory}
                            isLoading={!inventoryFetched}
                            isSelected={isSelected}
                            name={name}
                            product={product}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            value={value}
                            isSizeSelected={isSizeSelected}
                          />
                        </li>
                      );
                    })}
                </ul>

                {variantGroups?.alternate_color_headline && (
                  <>
                    <div className="mb-2 flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <h3 className="pdp-option-label-b">
                          {variantGroups?.alternate_color_headline}:
                        </h3>
                        <p className="pdp-option-label-g">
                          {variantGroups?.alternate_colors?.find(
                            (color) =>
                              color === selectedOptions?.[name].toLowerCase()
                          ) && selectedOptions?.[name]}
                        </p>
                      </div>
                    </div>

                    <ul className="mb-2 flex flex-wrap gap-4">
                      {values
                        ?.filter((value) => {
                          return variantGroups?.alternate_colors?.find(
                            (v) => v === value.toLowerCase()
                          );
                        })
                        .map((value) => {
                          const isSelected = selectedOptions?.[name] === value;

                          return (
                            <li key={value}>
                              <ProductColorOptionValue
                                groupingProductsMapByColor={
                                  groupingProductsMapByColor
                                }
                                inventory={inventory}
                                isLoading={!inventoryFetched}
                                isSelected={isSelected}
                                name={name}
                                product={product}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                value={value}
                                isSizeSelected={isSizeSelected}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  </>
                )}

                {variantGroups?.alternate_color_headline_2 && (
                  <>
                    <div className="mb-2 flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <h3 className="pdp-option-label-b">
                          {variantGroups?.alternate_color_headline_2}:
                        </h3>
                        <p className="pdp-option-label-g">
                          {variantGroups?.alternate_colors_2?.find(
                            (color) =>
                              color === selectedOptions?.[name].toLowerCase()
                          ) && selectedOptions?.[name]}
                        </p>
                      </div>
                    </div>

                    <ul className="mb-2 flex flex-wrap gap-4">
                      {values
                        ?.filter((value) =>
                          variantGroups?.alternate_colors_2?.find(
                            (v) => v === value.toLowerCase()
                          )
                        )
                        .map((value) => {
                          const isSelected = selectedOptions?.[name] === value;

                          return (
                            <li key={value}>
                              <ProductColorOptionValue
                                groupingProductsMapByColor={
                                  groupingProductsMapByColor
                                }
                                inventory={inventory}
                                isLoading={!inventoryFetched}
                                isSelected={isSelected}
                                name={name}
                                product={product}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                value={value}
                                isSizeSelected={isSizeSelected}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

ProductOptions.displayName = 'ProductOptions';
