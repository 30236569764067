import { useState } from 'react';
import { Markdown, Svg } from '../../snippets';

export function Accordion({ accordion, headerBgColor, headerTextColor }) {
  const { body, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div>
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="flex min-h-[30px] w-full items-center justify-between gap-x-4 border-l-2 border-black pl-[14px] text-left"
        onClick={() => setIsOpen(!isOpen)}
        style={{ backgroundColor: headerBgColor, color: headerTextColor }}
        type="button"
      >
        <h3 className="text-title-h5 flex-1 leading-none">{header}</h3>

        {isOpen ? (
          <Svg
            className="w-4 text-current"
            src="/svgs/minus.svg#minus"
            title="Minus"
            viewBox="0 0 24 24"
          />
        ) : (
          <Svg
            className="w-4 text-current"
            src="/svgs/plus.svg#plus"
            title="Plus"
            viewBox="0 0 24 24"
          />
        )}
      </button>

      <div
        className={`px-0 py-4 text-sm ${
          isOpen ? 'block' : 'hidden'
        } [&_a]:text-[#3273dc]`}
      >
        <Markdown>{body}</Markdown>
      </div>
    </div>
  );
}

Accordion.displayName = 'Accordion';
