import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import equal from 'fast-deep-equal';

import { formatImageItemAsMediaItem } from '../../utilities';

export function useSelectedVariant({
  product,
  initialVariant = null,
  isQuickShop = false,
  isChild = false
}) {
  const { query } = useRouter();

  const hasOneVariant = product?.variants?.length === 1;
  const hasMultiColors = product?.optionsMap?.Color?.length > 1;

  const productMedia = useMemo(() => {
    return (
      product?.media ||
      // if shopify media transforms is off, shape image item similar to media item
      product?.images?.map((image) => {
        return formatImageItemAsMediaItem({ image, product });
      })
    );
  }, [product?.id]);

  const [selectedVariant, setSelectedVariant] = useState(
    initialVariant || (hasOneVariant ? product?.variants?.[0] : null)
  );
  const [selectedOptions, setSelectedOptions] = useState(
    initialVariant?.selectedOptionsMap ||
      (hasOneVariant ? product?.variants?.[0]?.selectedOptionsMap : null)
  );
  const [selectedMedia, setSelectedMedia] = useState(
    hasMultiColors ? null : productMedia
  );

  const isGiftCard = product?.productType === 'Gift Cards';

  // set initial selected options on mount unless has one variant
  const setInitialSelectedOptionsOnProductMount = useCallback(() => {
    if (initialVariant?.selectedOptionsMap || isChild) return;

    const params = new URLSearchParams(window.location.search);
    const queriedVariantId = params.get('variant');
    let queriedVariant = null;
    if (queriedVariantId) {
      queriedVariant = product.variants?.find(
        ({ legacyResourceId }) => legacyResourceId === queriedVariantId
      );
    }
    if (queriedVariant) {
      setSelectedOptions(queriedVariant.selectedOptionsMap);
    } else {
      const firstAvailableVariant = product.variants?.find(
        ({ availableForSale }) => availableForSale
      );

      if (firstAvailableVariant) {
        setSelectedOptions(firstAvailableVariant.selectedOptionsMap);
      } else {
        // If no variants are available, fall back to the first variant
        setSelectedOptions(product.variants?.[0]?.selectedOptionsMap || null);
      }
    }
  }, [initialVariant, query.handle, product.id]);

  // set selected variant from selected options unless has one variant
  const setSelectedVariantFromSelectedOptions = useCallback(() => {
    if (!selectedOptions) {
      setSelectedVariant(null);
      return;
    }

    const selectedVariantFromOptions = product.variants?.find(
      ({ selectedOptionsMap }) => {
        return equal(selectedOptions, selectedOptionsMap);
      }
    );
    setSelectedVariant(selectedVariantFromOptions || null);
  }, [query.handle, product.id, selectedOptions, selectedVariant?.id]);

  // set variant url param on selected variant change unless has one variant
  const setVariantUrlParamOnSelectedVariantChange = useCallback(() => {
    if (product.variants?.length === 1 || !selectedVariant || isQuickShop || isChild)
      return;

    const { origin, search } = window.location;

    const params = new URLSearchParams(search);
    params.set('variant', selectedVariant.legacyResourceId);

    const updatedUrl = `${origin}/products/${selectedVariant.product.handle}?${params}`;

    window.history.replaceState(window.history.state, '', updatedUrl);
  }, [isQuickShop, query.handle, product.id, selectedVariant?.id]);

  // set selected media on selected variant change
  const setSelectedMediaOnSelectedVariantChange = useCallback(() => {
    // filter media by color via possible alt tag if product is not grouped and has multiple colors
    if (product.optionsMap?.Color && selectedVariant) {
      const selectedColor = selectedVariant.selectedOptionsMap?.Color?.replace(
        /\s/g,
        '-'
      )?.toLowerCase();

      let createdMedia = productMedia.filter((item) => {
        const alt = item.alt?.trim()?.toLowerCase()?.split(' [')[0];
        return alt === selectedColor;
      });

      if (createdMedia.length < 1) {
        createdMedia = productMedia.filter((item) => {
          const alt = item.alt?.trim()?.toLowerCase()?.replace('/', '-')?.split(' [')[0];

          return alt.includes(selectedColor);
        });
      }

      if (createdMedia.length < 1) {
        createdMedia = productMedia.filter((item) => {
          const alt = item.alt?.replace(/\s/g, '-').toLowerCase();
          return alt.includes(selectedColor);
        });
      }

      const mediaVideo = productMedia.find(
        (el) => el.mediaContentType === 'VIDEO' && el.alt == 'product-video'
      );
      if (mediaVideo) {
        createdMedia.splice(2, 0, mediaVideo);
      }

      if (
        createdMedia?.length &&
        selectedVariant?.selectedOptions?.length > 2
      ) {
        const thirdOpt = selectedVariant?.selectedOptions[2];
        const formatedOpt = `[${thirdOpt.name.toLowerCase()} - ${thirdOpt.value.toLowerCase()}]`;
        const updatedMedia = createdMedia?.filter((el) =>
          el?.alt?.includes(formatedOpt)
        );

        if(updatedMedia?.length > 0){
          createdMedia = updatedMedia
        }
      }

      setSelectedMedia(createdMedia);
    } else if (isGiftCard) {
      const foundMedia = productMedia.filter(
        (el) => el.alt === selectedVariant?.image?.altText
      );

      setSelectedMedia(foundMedia);
    } else if (!hasMultiColors) {
      setSelectedMedia(productMedia);
    }
  }, [
    hasMultiColors,
    query.handle,
    product.id,
    productMedia,
    selectedMedia,
    selectedVariant?.id,
  ]);

  useEffect(() => {
    setInitialSelectedOptionsOnProductMount();
  }, [product.id]);

  useEffect(() => {
    setSelectedVariantFromSelectedOptions();
  }, [selectedOptions]);

  useEffect(() => {
    setVariantUrlParamOnSelectedVariantChange();
  }, [selectedVariant?.id]);

  useEffect(() => {
    setSelectedMediaOnSelectedVariantChange();
  }, [selectedVariant?.id]);

  return {
    selectedOptions,
    selectedVariant,
    setSelectedOptions,
    selectedMedia,
  };
}
