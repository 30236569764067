import { useMemo } from 'react';

export function ShopifyRichText({ shopifyTextObject }) {
  const shopifyToHTML = (nodes) => {
    let html = '';

    if (nodes.type === 'root' && nodes.children.length > 0) {
      html += shopifyToHTML(nodes.children);
    } else {
      if (nodes.length) {
        nodes?.forEach((node) => {
          switch (node.type) {
            case 'heading':
              html += genHeading(node);
              break;

            case 'paragraph':
              html += genParagraph(node);
              break;

            case 'list':
              html += genList(node);
              break;

            case 'list-item':
              html += genListItem(node);
              break;

            case 'link':
              html += genLink(node);
              break;

            case 'text':
              html += genText(node);
              break;

            default:
              break;
          }
        });
      }
    }

    return html;
  };

  const genHeading = (node) => {
    if (node?.children) {
      return `<h${node?.level}>${shopifyToHTML(node?.children)}</h${
        node?.level
      }>`;
    }
  };

  const genParagraph = (node) => {
    if (node?.children) {
      return `<p>${shopifyToHTML(node?.children)}</p>`;
    }
  };

  const genList = (node) => {
    if (node?.children) {
      if (node?.listType === 'ordered') {
        return `<ol>${shopifyToHTML(node?.children)}</ol>`;
      } else {
        return `<ul>${shopifyToHTML(node?.children)}</ul>`;
      }
    }
  };

  const genListItem = (node) => {
    if (node?.children) {
      return `<li>${shopifyToHTML(node?.children)}</li>`;
    }
  };

  const genLink = (node) => {
    return `<a href="${node?.url}" title="${node?.title}" target="${
      node?.target
    }">${shopifyToHTML(node?.children)}</a>`;
  };

  const genText = (node) => {
    if (node?.bold) {
      return `<strong>${node?.value}</strong>`;
    }
    if (node?.italic) {
      return `<em>${node?.value}</em>`;
    }
    return node?.value;
  };

  const parsedContent = useMemo(() => {
    if (!shopifyTextObject) return '';

    const nodes = JSON.parse(shopifyTextObject);
    const converted = shopifyToHTML(nodes);
    return converted || '';
  }, [shopifyTextObject]);

  return <div dangerouslySetInnerHTML={{ __html: parsedContent }}></div>;
}

ShopifyRichText.displayName = 'ShopifyRichText';
