import { useCallback, useEffect, useState, useMemo } from 'react';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import { fetchProductsFromHandles } from '../../../utilities';
import { ProductColorOptionValue } from './ProductColorOptionValue';
import { ProductOptionValue } from './ProductOptionValue';
import { useGlobalContext } from '../../../contexts';
import { useProductMetafields, useSwatchesDesing } from '../../../hooks';
import { SizeGuideModal, WairPlacement } from '../../../snippets';

export function ProductCustomOptions({
  product,
  selectedOptions,
  selectedVariant,
  setSelectedOptions,
  setCustomOptions,
  childProduct,
}) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });

  const { inventory: childInventory, success: childInventoryFetched } =
    useProductInventoryByHandle({
      handle: childProduct?.handle,
      withInventory: false,
    });

  const metafields = useProductMetafields({ product });

  const bundleType = metafields
    ? metafields?.custom_fields?.bundle_type
    : '3-bundle';

  const [customSelectedOptions, setCustomSelectedOptions] = useState([]);
  const [isSizeSelected, setIsSizeSelected] = useState(false);

  let renderOptions = [0, 1, 2];

  switch (bundleType) {
    case '2-bundle':
      renderOptions = [0, 1];
      break;

    case '3-bundle':
      renderOptions = [0, 1, 2];
      break;

    case '4-bundle':
      renderOptions = [0, 1, 2, 3];
      break;

    case '5-bundle':
      renderOptions = [0, 1, 2, 3, 4];
      break;

    default:
      renderOptions = [0, 1, 2];
      break;
  }

  const customSelecetdOptionsArray = renderOptions.map((option) => {
    return {
      key: option,
      value: '',
    };
  });

  useEffect(() => {
    setCustomSelectedOptions(customSelecetdOptionsArray);
  }, []);

  const handleCustomSelectedClick = (index, value, options) => {
    const newItems = [...customSelectedOptions];
    const variant = childProduct?.variants.find(
      (el) => JSON.stringify(el.selectedOptionsMap) === JSON.stringify(options)
    );

    if (variant && childInventory.variants?.[variant?.id]?.availableForSale) {
      newItems[index] = {
        value: value,
        selectedOptions: options,
        metafields: customDiscountCode,
      };

      setCustomSelectedOptions(newItems);
      setCustomOptions(newItems, customSelecetdOptionsArray.length);
    }
  };

  // Update customSelectOptions when the selectedVariant is updated ////
  useEffect(() => {
    const updatedItems = customSelectedOptions.map((el) => {
      if (el.value !== '') {
        const newValues = { ...el };
        newValues.selectedOptions = { ...selectedOptions };
        newValues.selectedOptions.Color = el.value;

        const variant = childProduct?.variants.find(
          (el) =>
            JSON.stringify(el.selectedOptionsMap) ===
            JSON.stringify(newValues.selectedOptions)
        );

        if (
          !variant ||
          !childInventory.variants?.[variant?.id]?.availableForSale
        ) {
          return {
            value: '',
          };
        }

        return newValues;
      } else {
        return el;
      }
    });

    setCustomSelectedOptions(updatedItems);
    setCustomOptions(updatedItems);
  }, [selectedVariant]);

  const customDiscountAssigner = () => {
    const code = product?.tags?.find((el) =>
      el.includes('settings_custom-discount')
    );

    if (code) {
      return [
        { key: code.split('settings_custom-discount_')[1], value: 'Yes' },
      ];
    } else {
      switch (bundleType) {
        case '2-bundle':
          return [{ key: '2 Item Bundle', value: 'Yes' }];

        case '3-bundle':
          return [{ key: 'Shirt Bundle', value: 'Yes' }];

        case '4-bundle':
          return [{ key: 'Shirt 4 Bundle', value: 'Yes' }];

        case '5-bundle':
          return [{ key: 'Shirt 5 Bundle', value: 'Yes' }];

        default:
          return [{ key: 'Shirt Bundle', value: 'Yes' }];
      }
    }
  };

  const customDiscountCode = customDiscountAssigner();

  const [groupingProductsMapByColor, setGroupingProductsMapByColor] =
    useState(null);

  const handleSizeGuideClick = useCallback(() => {
    // example modal
    openModal(<SizeGuideModal product={childProduct} />);
  }, []);

  useEffect(() => {
    if (!product?.grouping?.isTransformed) return;

    const fetchProductsForMap = async () => {
      const products = await fetchProductsFromHandles(
        product.grouping.products
      );
      setGroupingProductsMapByColor(
        products?.reduce((acc, groupProduct) => {
          const color = groupProduct.optionsMap?.Color?.[0];
          if (!color) return acc;
          return {
            ...acc,
            [color]: groupProduct,
          };
        }, {})
      );
    };
    fetchProductsForMap();
  }, [product?.id]);

  //Swatches size classes
  const sizeSwatchDesign = useSwatchesDesing({ product: childProduct });

  return (
    <div className="flex flex-col">
      {childProduct?.options?.map(({ id, name, values }, index) => {
        const isSize = name === 'Size';
        const isColor = name === 'Color';
        const productOrGroupingValues = values;

        return isColor ? (
          <div
            className="color_options order-last md:border md:border-border md:px-4"
            key={id}
          >
            <h3 className="h5 pt-4 font-bold normal-case leading-6">
              Step {childProduct?.options?.length} Pick your {name}
            </h3>
            {renderOptions?.map((bundleOption, colorIndex) => {
              return (
                <div
                  className="border-b border-border py-4 last:border-none"
                  key={bundleOption}
                >
                  <div className="mb-2 flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <h3 className="h5 font-normal normal-case leading-6">
                        {name} {colorIndex + 1}:
                      </h3>
                      <p className="text-base text-mediumGray">
                        {customSelectedOptions[bundleOption]?.value}
                      </p>
                    </div>
                  </div>

                  <ul
                    className={`flex flex-wrap gap-4 ${
                      isSizeSelected
                        ? '[&_.custom-hidden]:hidden'
                        : 'pointer-events-none [&_::after]:hidden [&_::before]:hidden'
                    }`}
                    data-wair-option-name={name}
                  >
                    {productOrGroupingValues?.map((value, index) => {
                      const isSelected =
                        customSelectedOptions[bundleOption]?.value === value;
                      let convertedSelectedOptions = selectedOptions;
                      const parentOptions = JSON.stringify(
                        Object.keys(product.optionsMap)
                      );
                      const childOptions = JSON.stringify(
                        Object.keys(childProduct.optionsMap)
                      );

                      if (parentOptions != childOptions) {
                        const parentValues = Object.values(selectedOptions);

                        let fix = {};

                        Object.keys(childProduct.optionsMap)?.map(
                          (el, index) => {
                            fix[el] = parentValues[index]
                              ? parentValues[index]
                              : parentValues[index];
                          }
                        );

                        convertedSelectedOptions = fix;
                      }

                      return (
                        <ProductColorOptionValue
                          groupingProductsMapByColor={
                            groupingProductsMapByColor
                          }
                          inventory={childInventory}
                          isLoading={!childInventoryFetched}
                          isSelected={isSelected}
                          name={name}
                          product={childProduct}
                          selectedOptions={convertedSelectedOptions}
                          setSelectedOptions={handleCustomSelectedClick}
                          isCustom={true}
                          customIndex={bundleOption}
                          value={value}
                          key={index}
                        />
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            key={id}
            className="py-4 first:border-t first:border-t-border md:mb-5 md:border md:border-border md:px-4"
          >
            <div className="mb-2 flex items-start justify-between gap-2">
              <div className="flex items-center gap-2">
                <div>
                  <h3 className="h5 mb-1 font-bold	normal-case leading-6">
                    Step {index} Pick your {name}
                  </h3>
                  <div className="flex">
                    <h3 className="h5 block pr-1	font-normal normal-case leading-6">
                      {name}
                    </h3>
                    <p className="hidden text-base text-mediumGray">
                      {selectedOptions?.[name]}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <ul 
              className={`flex flex-wrap gap-4 ${sizeSwatchDesign}`} 
              data-wair-option-name={name}
            >
              {productOrGroupingValues?.map((value) => {
                const optionName = selectedOptions?.[name]
                  ? name
                  : product?.options[1]?.name;
                const isSelected = selectedOptions?.[optionName] === value;

                return (
                  <li
                    className={productOrGroupingValues.length > 5 ? '' : ''}
                    key={value}
                  >
                    <ProductOptionValue
                      inventory={childInventory}
                      isLoading={!childInventoryFetched}
                      isSelected={isSelected}
                      name={name}
                      product={product}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                      value={value}
                      isCustomPackHelper={true}
                      setIsSizeSelected={setIsSizeSelected}
                      externalClasses="w-full"
                    />
                  </li>
                );
              })}
            </ul>

            {isSize && (
              <div className="mt-3.5 flex justify-end gap-2">
                <button
                  className="underline text-sm leading-none"
                  onClick={handleSizeGuideClick}
                  type="button"
                >
                  Size Guide
                </button>

                <p className="leading-none">|</p>

                <WairPlacement product={product} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

ProductCustomOptions.displayName = 'ProductCustomOptions';
