import { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { useSettings } from '@backpackjs/storefront';

const trimWord = (word) => {
  if (!word) return '';
  let trimmedWord = word.trim();
  if (!/[a-z0-9]/.test(trimmedWord[0])) trimmedWord = trimmedWord.slice(1);
  if (!/[a-z0-9]/.test(trimmedWord[trimmedWord.length - 1]))
    trimmedWord = trimmedWord.slice(0, -1);
  return trimmedWord;
};

export function useSearchAutocomplete({ term, mounted = true }) {
  const settings = useSettings();

  const [autocompleteFuse, setAutocompleteFuse] = useState(null);
  const [autocompleteResults, setAutocompleteResults] = useState([]);

  const { enabled } = { ...settings?.search?.autocomplete };
  const propertyKeys = ['title', 'Color'];

  const setAutocompleteFuseOnMount = useCallback(async () => {
    try {
      if (autocompleteFuse) return;

      const productsRes = await fetch('/json/products-list.json');
      const productsList = await productsRes.json();
      const termsTable = productsList.reduce((table, product) => {
        propertyKeys.forEach((key) => {
          if (key === 'Color') {
            const colors = product.optionsMap?.Color;
            if (!colors) return;
            colors.forEach((color) => {
              if (table[color]) {
                table[color] += 1;
              } else {
                table[color] = 1;
              }
            });
          } else {
            const property = product[key];
            if (!property) return;

            property.split(' ').forEach((item, index, arr) => {
              const word = item.toLowerCase();

              if (!/[a-z0-9]/.test(word)) return;

              const trimmedWord = trimWord(word);
              if (table[trimmedWord]) {
                table[trimmedWord] += 1;
              } else {
                table[trimmedWord] = 1;
              }
              const nextWord = arr[index + 1]?.toLowerCase();
              if (nextWord && /[a-z0-9]/.test(nextWord)) {
                const twoWords = `${word} ${nextWord}`;
                const trimmedTwoWords = trimWord(twoWords);
                if (table[trimmedTwoWords]) {
                  table[trimmedTwoWords] += 1;
                } else {
                  table[trimmedTwoWords] = 1;
                }
              }
            });
          }
        });
        return table;
      }, {});

      const sortedList = [...Object.keys(termsTable)].sort((a, b) => {
        return termsTable[a] - termsTable[b];
      });
      const formattedList = sortedList.map((suggestion) => ({
        suggestion,
      }));

      setAutocompleteFuse(
        new Fuse(formattedList, {
          keys: ['suggestion'],
          ignoreLocation: true,
          minMatchCharLength: 3,
          threshold: 0.3,
        })
      );
    } catch (error) {
      console.error(error.message);
    }
  }, [autocompleteFuse, propertyKeys]);

  useEffect(() => {
    if (!enabled || !mounted) return;
    setAutocompleteFuseOnMount();
  }, [enabled, mounted, propertyKeys]);

  useEffect(() => {
    if (!enabled || !autocompleteFuse) return;
    if (!term) {
      setAutocompleteResults([]);
      return;
    }
    const results = autocompleteFuse.search(term);
    setAutocompleteResults(results);
  }, [autocompleteFuse, enabled, term]);

  return { autocompleteResults };
}
