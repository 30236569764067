import { useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';

import { Image } from '../Image';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
import { Svg } from '../Svg';
import { QuantitySelector } from '../QuantitySelector';
import { useCartItem } from './useCartItem';
import { useCartItemImage } from './useCartItemImage';
import { useCartItemPrices } from './useCartItemPrices';
import { useSettings } from '@backpackjs/storefront';
import { useGlobalContext } from '../../contexts';
import { CashbackModal } from '../CashbackModal';

export function CartItem({ closeCart, item, isMinimized = false, isCashback = false }) {
  const { quantity, variant } = item;

  const {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  } = useCartItem({ item });

  const {
    actions: { openModal },
  } = useGlobalContext();

  const settings = useSettings();

  const { asPath } = useRouter();

  const { price, compareAtPrice } = useCartItemPrices({ item });

  const image = useCartItemImage({ item });

  const isGwpFree = item?.attributes?.some(el=> el.key === '_threshold')

  const isPremium = !!(item?.sellingPlanAllocation?.sellingPlan?.id)

  const url = useMemo(() => {
    if (variant.product.handle === 'routeins' || isGwpFree || isPremium) return asPath;

    return `/products/${variant.product.handle}?variant=${variant.id
      .split('/')
      .pop()}`;
  }, [variant.id]);

  const bundleMetafield = item?.attributes?.find((el) => el.value === 'Yes');
  const customPropertyLabel = bundleMetafield ? 'Bundle & Save' : '';
  const productTitle = variant?.product?.title?.split('<!')[0]
    ? variant.product.title.split('<!')[0]
    : variant.product.title;

  const { cashback } = {...settings.apps}
  const cashbackLabel = cashback?.cartMessage

  const handleCashbackClick = useCallback(() => {
    openModal(
      <CashbackModal />, {className: "!max-w-[350px]"}
    );
  }, []);

  const enableQtySelector = !isGwpFree && !isMinimized &&!isPremium;

  return (
    <div className="relative grid grid-cols-[auto_1fr] items-center gap-3 p-4 ">
      <Link
        aria-label={`View ${variant.product.title}`}
        href={url}
        onClick={closeCart}
        tabIndex="-1"
      >
        {image?.src && (
          <Image
            alt={variant.product.title}
            className="bg-offWhite"
            crop="center"
            height={Math.floor(88 / (image.width / image.height))}
            src={image.src}
            width="88"
          />
        )}
      </Link>

      <div className="flex min-h-[6.25em] flex-col justify-between gap-4">
        <div className="relative pr-6">
          <Link
            aria-label={`View ${variant.product.title}`}
            href={url}
            onClick={closeCart}
          >
            <h3 className="color-primary text-xs">{productTitle}</h3>
          </Link>

          {variant.title !== 'Default Title' && (
            <p className="text-xs text-darkGray">{variant.title}</p>
          )}

          <button
            aria-label={`Remove ${variant.product.title} from cart`}
            className="absolute right-0 top-0 w-[13px] md:w-3"
            onClick={handleRemove}
            type="button"
          >
            <Svg
              src="/svgs/trash.svg#trash"
              title="Close"
              viewBox="0 0 448 512"
            />
          </button>
        </div>

        <div className="flex items-center justify-between gap-3">
          <div>
            {enableQtySelector && (
              <QuantitySelector
                handleDecrement={handleDecrement}
                handleIncrement={handleIncrement}
                isUpdating={isUpdatingItem}
                productTitle={variant.product.title}
                quantity={quantity}
                isCart
              />
            )}

            {bundleMetafield && (
              <p className="text-[9.6px] leading-none text-mediumGray">
                {customPropertyLabel}
              </p>
            )}

            {isCashback && (
              <p className="text-[9.6px] leading-none text-mediumGray">
                {cashbackLabel} <span className="cursor-pointer text-underline" onClick={() => handleCashbackClick()}>Learn more.</span>
              </p>
            )}
          </div>

          <div className="flex flex-1 flex-col flex-wrap content-end justify-end gap-x-2 text-end">
            {compareAtPrice && (
              <p className="text-mediumGray line-through">{compareAtPrice}</p>
            )}
            <p className="whitespace-nowrap">{price}</p>
          </div>
        </div>
      </div>

      {isRemovingItem && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)] text-mediumGray">
          <Spinner width="20" />
        </div>
      )}
    </div>
  );
}

CartItem.displayName = 'CartItem';
