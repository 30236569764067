import { COLORS, VERTICAL_PADDING } from '../../settings/common';

export function Schema({ product }) {
  if (!product) return null;

  return {
    category: 'Product',
    label: 'Product Detail Accordions',
    key: 'product-detail-accordions',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/product-detail-accordions-preview.jpg?v=1676403778',
    fields: [
      {
        label: 'Accordions',
        name: 'accordions',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Metafield Key',
            name: 'metafieldKey',
            component: 'text',
          },
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            label: 'Default Open',
            name: 'defaultOpen',
            component: 'toggle',
            description: 'Sets accordion to be open by default',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            component: 'toggle',
            name: 'enableLearnMore',
            label: 'Enable Learn More Button',
            toggleLabels: {
              true: 'Enable',
              false: 'Disable',
            },
            defaultValue: false,
          }
        ],
        defaultValue: [
          {
            metafieldKey: 'product_description',
            header: 'Product Description',
            body: '',
            defaultOpen: true,
          },
          {
            metafieldKey: 'accordion_product_features',
            header: 'Product Features',
            body: '',
            defaultOpen: false,
          },
          {
            metafieldKey: 'accordion_fabric_care',
            header: 'Fabric & Care',
            body: '',
            defaultOpen: false,
          },
        ],
        defaultItem: {
          header: 'Details',
          body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          defaultOpen: false,
        },
      },
      {
        label: 'Accordion Header Background Color',
        name: 'headerBgColor',
        component: 'select',
        options: COLORS,
        defaultValue: 'var(--off-white)',
      },
      {
        label: 'Accordion Header Text Color',
        name: 'headerTextColor',
        component: 'select',
        options: COLORS,
        defaultValue: 'var(--text)',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Button style, full width',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width from contained styles',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding Desktop',
            name: 'verticalPaddingLevel',
            component: 'select',
            options: VERTICAL_PADDING.desktop,
            defaultValue: 'md:py-contained',
          },
          {
            label: 'Vertical Padding Mobile',
            name: 'verticalPaddingLevelMobile',
            component: 'select',
            options: VERTICAL_PADDING.mobile,
            defaultValue: 'max-md:py-contained',
          },
        ],
        defaultValue: {
          fullWidth: true,
        },
      },
    ],
  };
}
