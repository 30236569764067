import { useSettings } from '@backpackjs/storefront';
import { useGlobalContext } from '../../../contexts';
import { QuickShopModalContent } from './QuickShopModalContent';

export function QuickShop({ selectedProduct, selectedVariant }) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  const settings = useSettings();
  const { quickShop } = { ...settings?.product };

  const handleQuickShopClick = () => {
    openModal(
      <QuickShopModalContent
        product={selectedProduct}
        initialVariant={selectedVariant}
      />
    );
  };

  return selectedVariant ? (
    <div className="mt-5 hidden opacity-0 transition group-hover:opacity-100 md:block lg:mt-6 w-full">
      <button
        type="button"
        className={`mx-auto w-auto min-w-[auto] w-full max-w-[12.5rem] ${quickShop.buttonStyle}`}
        onClick={handleQuickShopClick}
      >
        {quickShop.quickShopText}
      </button>
    </div>
  ) : null;
}

QuickShop.displayName = 'QuickShop';
