import { useState, useEffect } from 'react';
import { ProductDetailAccordion } from './ProductDetailAccordion';
import { Schema } from './ProductDetailAccordions.schema';
import { useProductMetafields } from '../../hooks';

export function ProductDetailAccordions({ cms, product, childProduct }) {
  const { accordions, headerBgColor, headerTextColor, section } = cms;

  const metafields = useProductMetafields({ product: childProduct ? childProduct : product });
  
  const [accordionIndex, setAccordionIndex] = useState({
    active: false,
    index: -1,
  });

  const containedClass = `${section?.verticalPaddingLevel} ${
    section?.verticalPaddingLevelMobile
  } ${section?.fullWidth ? '' : 'px-contained'}`;

  /*
  useEffect(() => {
    if (!accordions || accordions.length < 1) return;

    accordions.map((accordion, index) => {
      const { defaultOpen } = accordion;

      if (defaultOpen) {
        const defaulAccordionState = {
          ...accordionIndex,
          index,
          active: true,
        };

        setAccordionIndex(defaulAccordionState);
      }
    });
  }, []);
  */

  return accordions?.length ? (
    <ul className={`grid grid-cols-1 ${containedClass}`}>
      {accordions.map((accordion, index) => {
        const selectedIndex =
          index === accordionIndex.index && accordionIndex.active;

        return (
          <li key={index}>
            <ProductDetailAccordion
              accordion={accordion}
              headerBgColor={headerBgColor}
              headerTextColor={headerTextColor}
              metafields={metafields}
              accordionState={accordionIndex}
              setAccordionIndex={setAccordionIndex}
              isOpen={selectedIndex}
              index={index}
            />
          </li>
        );
      })}
    </ul>
  ) : null;
}

ProductDetailAccordions.displayName = 'ProductDetailAccordions';
ProductDetailAccordions.Schema = Schema;
