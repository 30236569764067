import {
  COLORS,
  FLEX_POSITIONS,
  VERTICAL_PADDING,
  FONT_OPTIONS,
  TEXT_ALIGN,
  TEXT_COLORS
} from '../../settings/common';

export default {
  label: 'Login',
  name: 'login',
  component: 'group',
  fields: [
    {
      label: 'Login Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Create Account Text',
      name: 'createText',
      component: 'text',
    },
    {
      label: 'Create Account Link Text',
      name: 'createLinkText',
      component: 'text',
    },
    {
      label: 'Forgot Password Text',
      name: 'forgotText',
      component: 'text',
    },

    {
      label: 'Unidentified Customer Error Text',
      name: 'unidentifiedCustomerText',
      component: 'text',
    },
    {
      label: 'Side Image',
      name: 'image',
      component: 'image',
    },
    {
      label: 'Side Super Heading',
      name: 'sideSuperHeading',
      component: 'text',
    },
    {
      label: 'Side Heading',
      name: 'sideHeading',
      component: 'text',
    },
    {
      label: 'Side Details',
      name: 'sideDetails',
      component: 'text',
    },
    {
      label: 'Link',
      name: 'sideLink',
      component: 'link',
    },
    {
      label: 'Text Color',
      name: 'textColor',
      component: 'select',
      options: TEXT_COLORS.mobile,
    },
    {
      label: 'Text Align',
      name: 'textAlign',
      component: 'select',
      options: TEXT_ALIGN.mobile,
    },
    {
      label: 'Text Position (tablet/desktop)',
      name: 'positionDesktop',
      component: 'select',
      options: FLEX_POSITIONS.desktop,
    },
    {
      label: 'Text Position (mobile)',
      name: 'positionMobile',
      component: 'select',
      options: FLEX_POSITIONS.mobile,
    },
    {
      label: 'Button Style',
      name: 'buttonStyle',
      component: 'select',
      options: [
        { label: 'Primary', value: 'btn-primary' },
        { label: 'Secondary', value: 'btn-secondary' },
        { label: 'Inverse Light', value: 'btn-inverse-light' },
        { label: 'Inverse Dark', value: 'btn-inverse-dark' },
      ],
    },
  ],
  defaultValue: {
    heading: 'Registered Customers',
    forgotText: 'Forgot Password',
    createText: `New Customers`,
    createLinkText: 'Create Account',
    unidentifiedCustomerText: 'The email and password do not match',
  },
};
