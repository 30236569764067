import { useCustomer } from '@backpackjs/storefront';
import { useMemo, useEffect, useState } from 'react';
import axios from 'axios';

export function useInveterate() {
  const customer = useCustomer();

  const isClient = typeof window !== 'undefined';

  const [customerCashback, setCustomerCashback] = useState(null);

  const inveterateObj = isClient ? window?.inveterate?.properties : null;

  const inveterateTiers = useMemo(() => {
    if (isClient) {
      const tiersList = inveterateObj?.tierDetails;

      const tiersWithBenefits = tiersList?.map((tier) => {
        const rawBenefits = inveterateObj?.tierBenefits.find(
          (ben) => ben.segmentId === tier.segmentId
        );
        let newTier = { ...tier };
        const arrayBenefits = Object.values(rawBenefits.benefits)?.filter(
          (el) => el.enabled === true
        );
        newTier.benefits = arrayBenefits;

        return newTier;
      });

      return tiersWithBenefits;
    }

    return [];
  }, [inveterateObj]);

  const premiumTier = useMemo(() => {
    if (!inveterateTiers || !inveterateTiers.length) return null;

    const tier = inveterateTiers.find((el) => el.type === 'PAID');

    return tier;
  }, [inveterateTiers]);

  const freeTier = useMemo(() => {
    if (!inveterateTiers || !inveterateTiers.length) return null;

    const tier = inveterateTiers.find((el) => el.type === 'FREE');

    return tier;
  }, [inveterateTiers]);

  const premiumBenefits = useMemo(() => {
    if (!premiumTier) return [];

    const benefits = premiumTier.benefits?.map((el) => {
      return el.name;
    });

    return benefits;
  }, [premiumTier]);

  const freeBenefits = useMemo(() => {
    if (!freeTier) return [];

    const benefits = freeTier.benefits?.map((el) => {
      return el.name;
    });

    return benefits;
  }, [freeTier]);

  const isCustomerPremiumMember = useMemo(() => {
    if (!customer && !premiumTier) return false;

    const tierTag =
      customer?.tags?.find((tag) => tag.startsWith('inveterate-tier#')) || '';

    const isPremium = tierTag?.includes(premiumTier?.segmentId);

    return isPremium;
  }, [customer, premiumTier]);

  const isBasicMember = useMemo(() => {
    if (!customer && !premiumTier) return false;

    const tierTag =
      customer?.tags?.find((tag) => tag.startsWith('inveterate-tier#')) || '';

    const isBasic = tierTag?.includes(freeTier?.segmentId);

    return isBasic;
  }, [customer, freeTier]);

  useEffect(() => {
    if (!customer) return;

    const getCustomerCashback = async () => {
      if (!customer || !isCustomerPremiumMember) return null;

      try {
        const customerID = customer?.id?.split('gid://shopify/Customer/')[1];
        const endpoint = `https://public.inveterateapi.com/v2.0/storefront/members/${customerID}/credits`;

        const response = await axios({
          url: endpoint,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Inveterate-Api-Key': 'd7b6fc64c7230112f2e208b64f249f92',
          },
        });

        if (response?.data?.data && response?.data?.data?.length) {
          const lastCashback = response?.data?.data?.pop();
          setCustomerCashback(lastCashback || null);
        }
      } catch (error) {
        return null;
      }
    };

    getCustomerCashback();
  }, [customer, isCustomerPremiumMember]);

  return {
    inveterateTiers,
    premiumTier,
    freeTier,
    premiumBenefits,
    freeBenefits,
    isCustomerPremiumMember,
    isBasicMember,
    inveterateObj,
    customerCashback,
  };
}
