import { useCallback, useEffect, useRef, useState } from 'react';

import { mapProductItemProduct, mapProductItemVariant } from './utils';

export function useDataLayerCollection({
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const collectionHandleRef = useRef(null);

  const [collectionProducts, setCollectionProducts] = useState(null);
  const [clickedCollectionItem, setClickedCollectionItem] = useState(null);

  const viewCollectionEvent = useCallback(
    ({ products, userProperties: _userProperties }) => {
      if (!products?.length) return;
      const list = window.location.pathname.startsWith('/collections')
        ? window.location.pathname
        : '';
      const event = {
        event: 'dl_view_item_list',
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: products[0].variants?.[0]?.priceV2?.currencyCode,
          impressions: products.slice(0, 7).map(mapProductItemProduct(list)),
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? []
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  const clickCollectionItemEvent = useCallback(
    ({ variant }) => {
      if (!variant) return;
      const list = variant.list || '';
      const event = {
        event: 'dl_select_item',
        user_properties: userProperties,
        ecommerce: {
          currencyCode: variant.priceV2?.currencyCode,
          click: {
            actionField: {
              list,
              action: 'click',
            },
            products: [variant].map(mapProductItemVariant(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? []
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    [userProperties]
  );

  // Subscribe to PubSub topic for 'dl_view_item_list' and 'dl_select_item' events
  useEffect(() => {
    const viewCollection = PubSub.subscribe(
      'VIEW_COLLECTION_PAGE',
      async (event, payload) => {
        setCollectionProducts(payload.products);
      }
    );
    const clickCollectionItem = PubSub.subscribe(
      'CLICK_COLLECTION_ITEM',
      async (event, variant) => {
        setClickedCollectionItem(variant);
      }
    );
    return () => {
      if (viewCollection) {
        PubSub.unsubscribe(viewCollection);
      }
      if (clickCollectionItem) {
        PubSub.unsubscribe(clickCollectionItem);
      }
    };
  }, []);

  // Trigger 'dl_user_data' and view 'dl_view_item_list'
  // events on collection page and after base data is ready
  useEffect(() => {
    const pageHandle = window.location.pathname.split('/').pop();
    if (
      !collectionProducts?.length ||
      !userProperties ||
      collectionHandleRef.current === pageHandle
    )
      return;
    userDataEvent({ userProperties });
    viewCollectionEvent({ products: collectionProducts, userProperties });
    collectionHandleRef.current = pageHandle;
  }, [collectionProducts, !!userProperties]);

  // Trigger 'dl_select_item' event on clicked collection
  // item and after user event
  useEffect(() => {
    if (!clickedCollectionItem || !userDataEventTriggered) return;
    clickCollectionItemEvent({ variant: clickedCollectionItem });
  }, [clickedCollectionItem, userDataEventTriggered]);
}
