import { useMemo } from 'react';

import { Link, InveterateAddToCart } from '../../snippets';
import { HeroKlaviyoForm } from './HeroKlaviyoForm';

export function HeroContent({
  aboveTheFold,
  isActiveSlide,
  isFirstSlide,
  slide,
}) {
  const { button, content, text } = slide;
  const {
    colorDesktop,
    colorMobile,
    heading,
    hideHeadingDesktop,
    hideHeadingMobile,
    subheading,
    superheading,
    textSize,
    klaviyoForm,
  } = { ...text };
  const {
    alignmentMobile,
    alignmentDesktop,
    darkOverlay,
    maxWidthMobile,
    maxWidthDesktop,
    positionMobile,
    positionDesktop,
  } = {
    ...content,
  };
  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;
  const positionClasses = `${positionMobile} ${positionDesktop}`;
  const maxWidthContentClasses = `${maxWidthMobile} ${maxWidthDesktop}`;
  const darkOverlayClass = darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : '';
  const hiddenHeadingMobileClass =
    'max-md:absolute max-md:z-[-1] max-md:opacity-0 max-md:pointer-events-none';
  const hiddenHeadingDesktopClass =
    'md:absolute md:z-[-1] md:opacity-0 md:pointer-events-none';
  const hiddenHeadingClasses = `${
    hideHeadingMobile ? hiddenHeadingMobileClass : ''
  } ${hideHeadingDesktop ? hiddenHeadingDesktopClass : ''}`;
  const hiddenButtonClasses = `${
    button?.hideButtonsMobile ? 'max-md:hidden' : ''
  } ${button?.hideButtonsDesktop ? 'md:hidden' : ''}`;
  const textColorClasses = `${colorMobile} ${colorDesktop}`;

  const headingWithBreaks = useMemo(() => {
    const splitHeading = heading?.split('\n');
    if (splitHeading?.length === 1) return heading;
    return splitHeading?.reduce((acc, line, index, arr) => {
      acc.push(<span key={index}>{line}</span>);
      if (index < arr.length - 1) acc.push(<br key={`br${index}`} />);
      return acc;
    }, []);
  }, [heading]);

  const headingTextSizes = textSize || 'h1';

  let buttonAlignmentMobile = '';

  switch (alignmentMobile) {
    case 'text-left items-start':
      buttonAlignmentMobile = 'max-md:pl-0 max-md:pr-12';
      break;
    case 'text-center items-center':
      buttonAlignmentMobile = 'max-md:px-6';
      break;
    case 'text-right items-end':
      buttonAlignmentMobile = 'max-md:pl-12 max-md:pr-0';
      break;
    default:
      break;
  }

  const isInveterate = !!button?.enableInveterateAtc;

  return (
    <div
      className={`pointer-events-none absolute inset-0 flex h-full w-full p-4 md:py-8 lg:px-20 xl:p-12 ${positionClasses} ${darkOverlayClass} py-12 md:py-0 lg:!px-20`}
    >
      <div
        className={`pointer-events-auto relative flex flex-col gap-[20px] md:gap-[18px] ${alignmentClasses} ${maxWidthContentClasses} ${textColorClasses}`}
      >
        <div className={hiddenHeadingClasses}>
          {superheading && (
            <p className="text-superheading mb-[8px] normal-case">
              {superheading}
            </p>
          )}

          {aboveTheFold && isFirstSlide ? (
            <h1 className={`${headingTextSizes}`}>{headingWithBreaks}</h1>
          ) : (
            <h2 className={`${headingTextSizes}`}>{headingWithBreaks}</h2>
          )}

          {subheading && <p className="mt-4">{subheading}</p>}
        </div>

        {button?.buttons?.length > 0 && (
          <ul
            className={`flex w-full flex-col justify-center gap-[10px] px-[24px] md:w-fit md:flex-row ${buttonAlignmentMobile} md:px-0 ${hiddenButtonClasses} xs:min-w-[450px]`}
          >
            {button?.buttons?.slice(0, 2).map(({ link, style }, index) => {
              return (
                <li className="w-full" key={index}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  {isInveterate ? (
                    <InveterateAddToCart
                      buttonText={link?.text}
                      buttonStyle={`${style} w-full md:w-fit`}
                    />
                  ) : (
                    <Link
                      aria-label={link?.text}
                      className={`${style} w-full md:w-fit`}
                      href={button.clickableSlide ? null : link?.url}
                      newTab={link?.newTab}
                      type={link?.type}
                    >
                      {link?.text}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        )}

        {klaviyoForm && <HeroKlaviyoForm klaviyoForm={klaviyoForm} />}
      </div>

      {isActiveSlide && button?.clickableSlide && (
        <Link
          aria-label={button.buttons?.[0]?.link?.text}
          className="pointer-events-auto absolute inset-0 z-[1] h-full w-full"
          href={button.buttons?.[0]?.link?.url}
          type={button.buttons?.[0]?.link?.type}
        />
      )}
    </div>
  );
}

HeroContent.displayName = 'HeroContent';
