import { useGlobalContext } from '../../contexts';
import { useState, useEffect } from 'react';
import { fetchSizeGuide } from '../../utilities';
import { Spinner } from '../Spinner';
import { ShopifyRichText } from '../ShopifyRichText';
import { useProductMetafields } from '../../hooks';

export function SizeGuideModal({ product }) {
  const {
    state: { modalOpen },
    actions: { closeModal },
  } = useGlobalContext();

  const [sizeGuidePage, setSizeGuidePage] = useState(null);
  const [sizeGuidePageCm, setSizeGuidePageCm] = useState(null);
  const [guideInUse, setGuideInUse] = useState('in');

  const metafields = useProductMetafields({ product });

  useEffect(() => {
    if (!product) return;

    const fetchSizePagesData = async () => {
      const sizeTagCm = product?.tags.find((el) =>
        el.includes('meta-size-chart-cm')
      );

      const sizeTag = product?.tags.find(
        (el) =>
          el.includes('meta-size-chart') && !el.includes('meta-size-chart-cm')
      );

      const sizePageHandle = sizeTag
        ? sizeTag.split('meta-size-chart-')[1]
        : `${product?.handle}-size-chart`;

      const page = await fetchSizeGuide(sizePageHandle);
      setSizeGuidePage(page);

      const sizePageCmHandle = sizeTagCm?.split('meta-size-chart-cm-')[1];
      const pageCm = await fetchSizeGuide(sizePageCmHandle);
      setSizeGuidePageCm(pageCm);
    };

    fetchSizePagesData();
  }, [product]);

  const selectedGuidePage =
    guideInUse === 'in' ? sizeGuidePage : sizeGuidePageCm;

  const hasMultipleGuides = !!(sizeGuidePage && sizeGuidePageCm);

  const chartClasses =
    '[&_h1]:text-[50px] md:[&_h1]:text-[70px] [&_h1]:mx-[10px] [&_p]:my-[16px] [&_p]:text-base [&_table]:border-separate [&_td]:text-center [&_td]:text-base [&_td]:align-top [&_img]:m-auto';

  const selectedClasses = 'bg-[#363636] text-white';
  const notSelectedClasses = 'bg-[#EAEAEA] text-black';

  const sizeGuideCustomTitle = metafields?.bylt?.size_guide_custom_text;
  const sizeGuideCustomTitle2 = metafields?.bylt?.size_guide_custom_text_2;
  const hasCustomTitle = !!sizeGuideCustomTitle;
  const hasCustomTitle2 = !!sizeGuideCustomTitle2;

  return (
    <div>
      {sizeGuidePage ? (
        <div className="pt-8">
          <h2 className="relative mt-[-34px] w-full py-2 text-center text-[1.5rem] font-bold leading-none text-[#363636] md:mt-[-48px]">
            {product.title} Size Guide
          </h2>

          {hasCustomTitle && (
            <div className="py-[1.67em] text-center text-base leading-none text-[#4a4a4a] [&_strong]:font-bold">
              <ShopifyRichText shopifyTextObject={sizeGuideCustomTitle} />
            </div>
          )}

          {hasCustomTitle2 && (
            <div className={`py-[1.67em] text-center text-base leading-none text-[#4a4a4a] [&_strong]:font-bold ${hasCustomTitle ? 'pt-0' : ''}`}>
              <ShopifyRichText shopifyTextObject={sizeGuideCustomTitle2} />
            </div>
          )}

          {hasMultipleGuides && (
            <div className="flex w-full justify-center">
              <div className="flex w-min justify-center gap-0 rounded bg-[#D9D9D9]">
                <button
                  className={`rounded px-8 py-2 ${
                    guideInUse === 'in' ? selectedClasses : notSelectedClasses
                  }`}
                  onClick={() => setGuideInUse('in')}
                >
                  IN
                </button>

                <button
                  className={`rounded px-8 py-2 ${
                    guideInUse === 'cm' ? selectedClasses : notSelectedClasses
                  }`}
                  onClick={() => setGuideInUse('cm')}
                >
                  CM
                </button>
              </div>
            </div>
          )}

          <div
            className={chartClasses}
            dangerouslySetInnerHTML={{ __html: selectedGuidePage }}
          />
        </div>
      ) : (
        <div>
          <Spinner
            width="42"
            className="absolute left-1/2 top-1/2 -translate-x-1/2"
          />
        </div>
      )}
    </div>
  );
}

SizeGuideModal.displayName = 'SizeGuideModal';
