import { useCallback, useState } from 'react';
import {
  useCartAddItem,
  useCartAddItems,
  useProductInventoryByHandle,
  useSettings,
} from '@backpackjs/storefront';

import { useGlobalContext } from '../contexts';

export function useAddToCart({
  addToCartText: addToCartTextOverride = '',
  isCartUpsell = false,
  quantity = 1,
  selectedVariant = null,
  metafields = [],
  customSelectedOptions = [],
  isCustom = false,
  isCustomReady = false,
  selectedKitVariants = null,
  multipleSelectedVariants = [],
  isAtcValidated = true,
  validationStep = 0,
  isKit = false,
}) {
  const {
    actions: { openCart, openModal },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();
  const { cartAddItems, ...addItemsStatus } = useCartAddItems();
  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });
  const settings = useSettings();

  const [isAdding, setIsAdding] = useState(false);

  const isLoading = !inventoryFetched;
  const variantInventory = inventory?.variants?.[selectedVariant?.id];
  const variantIsSoldOut =
    !isLoading && variantInventory && !variantInventory.availableForSale;
  const variantIsPreorder =
    !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const enabledNotifyMe = settings?.product?.backInStock?.enabled;

  let buttonText = '';
  if (variantIsPreorder) {
    buttonText = settings?.product?.addToCart?.preorderText || 'Preorder';
  } else if (variantIsSoldOut) {
    buttonText = enabledNotifyMe
      ? settings?.product?.backInStock?.notifyMeText || 'Notify Me'
      : isAtcValidated
      ? settings?.product?.addToCart?.soldOutText || 'Sold Out'
      : 'Select A Size';
  } else {
    buttonText =
      addToCartTextOverride ||
      settings?.product?.addToCart?.addToCartText ||
      'Add To Cart';

    if (!isAtcValidated) {
      const currentOption =
        selectedVariant?.selectedOptions?.length > 1
          ? selectedVariant?.selectedOptions?.[validationStep + 1]?.name
          : selectedVariant?.selectedOptions?.[validationStep]?.name || 'Size';
        
      const helper = /[aeiou]/i.test(currentOption?.charAt(0)) ? 'An' : 'A'

      buttonText =
        validationStep + 1 !== selectedVariant?.selectedOptions?.length
          ? `Select ${helper} ${currentOption}`
          : 'Select A Size';
    }
  }
  if (isCustom) {
    buttonText = isCustomReady ? 'Add To Cart' : 'Select Your Options';
  }
  if (isKit) {
    buttonText = isAtcValidated ? 'Add To Cart' : 'Select Your Options'
  }

  const handleAddToCart = useCallback(async () => {
    if (!selectedVariant?.id || isLoading || isAdding) return;
    setIsAdding(true);
    await cartAddItem({
      merchandiseId: selectedVariant.id,
      quantity,
      attributes: metafields,
    });
    setIsAdding(false);
    if (!isCartUpsell) openCart();
  }, [isAdding, isCartUpsell, isLoading, quantity, selectedVariant?.id]);

  const handleNotifyMe = useCallback(
    async (component) => {
      if (!selectedVariant?.id || isLoading) return;
      openModal(component);
    },
    [isLoading, selectedVariant?.id]
  );

  const handleCustomAddToCart = useCallback(async () => {
    if (!selectedVariant?.id || isLoading || isAdding) return;

    setIsAdding(true);

    for (let index = 0; index < customSelectedOptions.length; index++) {
      await cartAddItem({
        merchandiseId: customSelectedOptions[index].variant.id,
        quantity,
        attributes: customSelectedOptions[index].metafields,
      });
    }

    setIsAdding(false);

    if (!isCartUpsell) openCart();
  }, [
    isAdding,
    isCartUpsell,
    isLoading,
    quantity,
    selectedVariant?.id,
    customSelectedOptions,
  ]);

  const handleKitAddToCart = useCallback(async () => {
    if (!selectedVariant?.id || isLoading || isAdding || !selectedKitVariants)
      return;

    setIsAdding(true);

    const lineItems = Object.values(selectedKitVariants)?.map((el, index) => {
      const exMeta = [{ key: '_kitFirst', value: 'true' }];

      return {
        merchandiseId: el.id,
        quantity: 1,
        attributes: index === 0 ? [...metafields, ...exMeta] : metafields,
      };
    });

    await cartAddItems(lineItems);
    setIsAdding(false);
    if (!isCartUpsell) openCart();
  }, [
    isAdding,
    isCartUpsell,
    isLoading,
    quantity,
    selectedVariant?.id,
    selectedKitVariants,
  ]);

  const handleAddMultipleToCart = useCallback(async () => {
    if (multipleSelectedVariants.length < 1) return;

    setIsAdding(true);

    const lineItems = multipleSelectedVariants.map((el, index) => {
      return {
        merchandiseId: el.id,
        quantity: 1,
        attributes: metafields,
      };
    });

    await cartAddItems(lineItems);
    setIsAdding(false);
    if (!isCartUpsell) openCart();
  }, [
    isAdding,
    isCartUpsell,
    isLoading,
    quantity,
    selectedVariant?.id,
    multipleSelectedVariants,
  ]);

  return {
    state: {
      buttonText,
      isAdding,
      isLoading,
      isNotifyMe: variantIsSoldOut && enabledNotifyMe,
      isSoldOut: variantIsSoldOut,
      subtext: settings?.product?.addToCart?.subtext,
    },
    actions: {
      handleAddToCart,
      handleCustomAddToCart,
      handleNotifyMe,
      handleKitAddToCart,
      handleAddMultipleToCart,
    },
  };
}
