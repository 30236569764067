import { VERTICAL_PADDING } from '../../settings/common';

export function Schema() {
  return {
    category: 'Media',
    label: 'Image Grid',
    key: 'image-grid',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/social-feed-preview.jpg?v=1675730338',
    fields: [
      {
        label: 'Images',
        name: 'images',
        description: 'Grid requires four images',
        component: 'group-list',
        itemProps: {
          label: '{{item.alt}}',
        },
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'SubHeading',
            name: 'subheading',
            component: 'text',
          },
        ],
        defaultItem: {
          alt: 'Man in white short sleeve shirt',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
          },
          heading: 'Dustin May',
          subheading: 'Los Angeles Dodgers',
        },
        defaultValue: [
          {
            alt: 'Man in white short sleeve shirt',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
            },
            heading: 'Dustin May',
            subheading: 'Los Angeles Dodgers',
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Padding, max content width',
        fields: [
          {
            label: 'Horizontal Padding',
            name: 'hasXPadding',
            component: 'toggle',
            description: 'Adds default horizontal padding around section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'hasYPadding',
            component: 'toggle',
            description: 'Adds default vertical padding around section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding Desktop',
            name: 'verticalPaddingLevel',
            component: 'select',
            options: VERTICAL_PADDING.desktop,
            defaultValue: 'md:py-contained',
          },
          {
            label: 'Vertical Padding Mobile',
            name: 'verticalPaddingLevelMobile',
            component: 'select',
            options: VERTICAL_PADDING.mobile,
            defaultValue: 'max-md:py-contained',
          },
          {
            label: 'Max Content Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[60rem]',
              },
              {
                label: 'Medium Wide',
                value: 'max-w-[75rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[90rem]',
              },
              { label: 'Full', value: 'max-w-full' },
            ],
          },
        ],
        defaultValue: {
          hasXPadding: false,
          hasYPadding: false,
          maxWidth: 'max-w-full',
        },
      },
    ],
  };
}
