import { useState, useEffect } from 'react';
import { ProductDetailTab } from './ProductDetailTab';
import { Schema } from './ProductDetailTabs.schema';
import { useProductMetafields } from '../../hooks';

export function ProductDetailTabs({ cms, product, childProduct }) {
  const { tabs, section } = cms;

  const metafields = useProductMetafields({
    product: childProduct ? childProduct : product,
  });

  const [tabIndex, setTabIndex] = useState(0);

  const containedClass = `${section?.verticalPaddingLevel} ${
    section?.verticalPaddingLevelMobile
  } ${section?.fullWidth ? '' : 'px-contained'}`;

  return tabs?.length ? (
    <div>
      <div className="px-contained grid grid-cols-1 lg:grid-cols-[55%_43%] lg:grid-rows-[auto_1fr] xl:grid-cols-[66%_calc(34%-1.5rem)]">
        <div className="flex justify-between">
          {tabs.map((tab, index) => {
            return (
              <button className="p-1 lg:p-4" onClick={() => setTabIndex(index)}>
                <p className={`text-base font-bold border-b-2 transition-all ${
                  index === tabIndex ? 'border-black' : 'border-transparent'
                }`}>
                  {tab.header}
                </p>
              </button>
            );
          })}
        </div>
      </div>

      <ul className={`grid grid-cols-1 ${containedClass}`}>
        {tabs[tabIndex] && (
          <div>
            <ProductDetailTab
              tab={tabs[tabIndex]}
              metafields={metafields}
            />
          </div>
        )}
      </ul>
    </div>
  ) : null;
}

ProductDetailTabs.displayName = 'ProductDetailTabs';
ProductDetailTabs.Schema = Schema;
