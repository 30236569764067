import { useState, useEffect } from 'react';

import {
  Link,
  Image,
  Spinner,
  Markdown as MarkdownSnippet,
} from '../../snippets';

import { useAddToCart } from '../../hooks';
import { OutfitCustomizerOption } from './OutfitCustomizerOption';

export function OutfitCustomizerOptions({
  customizerOptions,
  setSelectedVariantOption,
  selectedVariants,
}) {
  const [selectedGroup, setSelectedGroup] = useState({
    active: false,
    index: -1,
  });
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [formatedSelectedVariants, setFormatedSelectedVariants] = useState([]);

  const lastStep = customizerOptions.length + 1;

  const isReadyToCheckout = selectedVariants
    ? Object.keys(selectedVariants)?.length === customizerOptions?.length
    : false;

  const {
    state: { isAdding },
    actions: { handleAddMultipleToCart },
  } = useAddToCart({
    multipleSelectedVariants: formatedSelectedVariants,
  });

  useEffect(() => {
    const formated = Object.values(selectedVariants);
    setFormatedSelectedVariants(formated);
  }, [selectedVariants]);

  return (
    <div className="">
      {customizerOptions.map((option, index) => {
        return (
          <div key={index}>
            <div
              className="text-md group flex cursor-pointer border-b border-border p-5 font-bold hover:border-b-[#202844] hover:border-t-[#202844] hover:bg-[#202844] hover:text-white md:text-[32px]"
              onClick={() =>
                setSelectedGroup({
                  ...selectedGroup,
                  index,
                  active:
                    index !== selectedGroup.index
                      ? true
                      : !selectedGroup.active,
                })
              }
            >
              <span className="mr-2 inline-block flex h-6 w-6 items-center justify-center self-center rounded-full bg-primary text-center text-sm text-white group-hover:bg-white group-hover:text-[#202844]">
                {index + 1}{' '}
              </span>

              {selectedVariants && selectedVariants[index] && (
                <span className="pr-2 text-green-700">✓</span>
              )}

              <span>{option.optionHeading}</span>
            </div>

            {index === selectedGroup.index && selectedGroup.active && (
              <div>
                <div className="p-5 text-lg font-bold">
                  {selectedCollection
                    ? `Style: ${selectedCollection?.styleName}`
                    : `${option.optionHeading} Style`}
                </div>
                <div className="no-scrollbar max-h-[22rem] overflow-y-scroll">
                  {option?.styleGroup.map((group) => {
                    return (
                      <OutfitCustomizerOption
                        group={group}
                        setSelectedVariantOption={(val) => {
                          setSelectedVariantOption(val, index);
                          setSelectedGroup({
                            ...selectedGroup,
                            index: index + 1,
                            active: true,
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        );
      })}

      <div className="text-md group flex cursor-pointer border-b border-border p-5 font-bold hover:border-b-[#202844] hover:border-t-[#202844] hover:bg-[#202844] hover:text-white md:text-[32px]">
        <span className="mr-2 inline-block flex h-6 w-6 items-center justify-center self-center rounded-full bg-primary text-center text-sm text-white group-hover:bg-white group-hover:text-[#202844]">
          {lastStep}
        </span>

        {isReadyToCheckout && <span className="pr-2 text-green-700">✓</span>}

        <span>Checkout</span>
      </div>

      {isReadyToCheckout && (
        <div className="p-5">
          <button
            className="btn-primary w-full"
            onClick={() => handleAddMultipleToCart()}
          >
            {isAdding ? <Spinner width="20" /> : <p> Add to Cart</p>}
          </button>
        </div>
      )}
    </div>
  );
}

OutfitCustomizerOptions.displayName = 'OutfitCustomizerOptions';
