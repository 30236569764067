import { useMemo, useEffect, useState } from 'react';
import {
  useCart,
  useCartTotals,
  useProductByHandle,
  useSettings,
  useProductInventoryByHandle,
} from '@backpackjs/storefront';
import { Image } from '../Image';
import { Spinner } from '../Spinner';
import { useAddToCart } from '../../hooks';
import { useCartItem } from './useCartItem';
import { useGlobalContext } from '../../contexts';

export function CartUpsell({ routeProduct }) {
  const cart = useCart();
  const cartTotals = useCartTotals();
  const settings = useSettings();
  const {
    progressMessage,
    qualifiedMessage,
    additionalInfo,
    threshold,
    product: productFromCms,
    selectorFields,
  } = { ...settings?.cart?.upsell };

  const { product: fullProduct } = useProductByHandle({
    handle: productFromCms?.handle,
  });

  // Gets inventory for fullProduct, filters fullProduct variants to return only product variants with inventory, creates a colorSizeMap to use in select element.
  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: fullProduct?.handle,
    withInventory: false,
  });
  const isLoadingInventory = !inventoryFetched;
  const variantsArray =
    !isLoadingInventory && inventory?.variants
      ? Object.values(inventory?.variants)
      : null;
  const filteredVariants = variantsArray?.filter(
    (variant) => variant?.availableForSale === true
  );
  const filteredVariantsIds = filteredVariants?.map((variant) => variant.id);
  const filteredProductVariantsWithInventory = fullProduct?.variants?.filter(
    (variant) => filteredVariantsIds?.includes(variant.id)
  );
  const colorSizeMap = filteredProductVariantsWithInventory?.reduce(
    (acc, product) => {
      const { Color, Size } = product.selectedOptionsMap;
      if (acc[Color]) {
        acc[Color].push(Size);
      } else {
        acc[Color] = [Size];
      }
      return acc;
    },
    {}
  );

  const {
    actions: { updateCheckoutButtonStatus },
  } = useGlobalContext();

  const selectedVariant = fullProduct?.variants?.[0];
  const [currentVariant, setCurrentVariant] = useState(null);
  const [isVariantSelectEnabled, setIsVariantSelectEnabled] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  const metafields = [
    {
      key: '_threshold',
      value: `${threshold}`,
    },
    {
      key: '_tier',
      value: `0`,
    },
  ];

  const {
    state: { buttonText, isAdding, isLoading, isNotifyMe, isSoldOut },
    actions: { handleAddToCart },
  } = useAddToCart({
    isCartUpsell: true,
    selectedVariant: currentVariant,
    metafields,
  });

  const showUpsell = !!selectedVariant?.id;
  const image = currentVariant
    ? currentVariant?.image
    : selectedOptions?.Color
    ? fullProduct?.variants?.find(
        (variant) =>
          variant?.selectedOptionsMap.Color === selectedOptions?.Color
      )?.image
    : fullProduct?.images?.[0];

  const productInCart = useMemo(() => {
    if (!showUpsell) return null;

    return cart?.lines?.find(
      (item) => item?.variant?.product?.handle === productFromCms?.handle
    );
  }, [cart?.updatedAt, productFromCms?.handle, showUpsell]);

  const { handleRemove } = useCartItem({ item: productInCart });

  let cursorClass = '';
  if (isAdding) cursorClass = 'cursor-default';
  else if (isLoading) cursorClass = 'cursor-wait';

  const routePrice = routeProduct
    ? routeProduct?.estimatedCost?.subtotalAmount?.amount
    : null;

  const giftsPrices = useMemo(() => {
    const totalGiftPrice = cart?.lines
      .filter((item) => item?.variant?.product?.handle === 'bylt-gift-card')
      ?.reduce((accumulator, item) => accumulator + (parseFloat(item?.variant?.priceV2?.amount) * item?.quantity), 0);

    return totalGiftPrice  || 0
  }, [cart?.lines]);

  const progress = useMemo(() => {
    if (!showUpsell) return null;

    const total =
      parseFloat(cartTotals.total) - (routePrice ? parseFloat(routePrice) : 0) - giftsPrices;
    const fraction = total / threshold;
    const remainder = threshold - total;

    if (fraction >= 1) {
      return {
        percent: 100,
        message: qualifiedMessage,
        upsellUnlocked: true,
      };
    }

    return {
      percent: fraction * 100,
      message: progressMessage?.replace(
        '{{amount}}',
        `$${remainder.toFixed(2).replace('.00', '')}`
      ),
      upsellUnlocked: false,
    };
  }, [
    cartTotals.total,
    threshold,
    progressMessage,
    qualifiedMessage,
    showUpsell,
  ]);

  const lockClasses = progress?.upsellUnlocked
    ? ''
    : 'bg-[rgba(222,222,222,.5215686275)] border-[rgba(222,222,222,.5215686275)] opacity-70';

  const messageWithBreaks = useMemo(() => {
    const splitMessage = progress?.message?.split('\n');
    if (splitMessage?.length === 1) return progress?.message;
    return splitMessage?.reduce((acc, line, index, arr) => {
      acc.push(<span key={index}>{line}</span>);
      if (index < arr.length - 1) acc.push(<br key={`br${index}`} />);
      return acc;
    }, []);
  }, [progress?.message]);

  const handleUnlock = (val) => {
    setIsVariantSelectEnabled(val);
  };

  const handleVariantSelect = (option) => {
    const parsedOption = JSON.parse(option);
    const currentOptions = { ...selectedOptions };

    currentOptions[Object.keys(parsedOption)[0]] =
      Object.values(parsedOption)[0];

    setSelectedOptions(currentOptions);

    if (currentOptions) {
      const variant = fullProduct?.variants?.find(
        (el) =>
          JSON.stringify(el.selectedOptionsMap) ===
          JSON.stringify(currentOptions)
      );

      if (variant) {
        setCurrentVariant(variant);
      } else {
        setCurrentVariant(null);
      }
    }
  };

  const handleRemoveButton = () => {
    handleRemove();
    setSelectedOptions({});
    setCurrentVariant(null);
  };

  useEffect(() => {
    if (isVariantSelectEnabled) {
      if (!currentVariant && !productInCart) {
        const label = selectorFields?.progressMessage || 'Select an Option';
        updateCheckoutButtonStatus(label);
      }

      if (currentVariant && !productInCart) {
        updateCheckoutButtonStatus(buttonText);
      }

      if (productInCart) {
        updateCheckoutButtonStatus(null);
      }
    } else {
      updateCheckoutButtonStatus(null);
    }
  }, [currentVariant, productInCart, isVariantSelectEnabled]);

  return showUpsell ? (
    <div className="flex flex-col gap-2 p-4">
      <div className="">
        <p className="text-center text-xs font-bold">{messageWithBreaks}</p>

        <div className="flex items-center">
          <div className="mr-1 h-[5px] w-full flex-1 overflow-hidden rounded bg-[rgba(222,222,222,.5215686275)] transition">
            <div
              className="h-[5px] max-w-full rounded bg-primary"
              style={{ width: `${progress.percent}%` }}
            />
          </div>
          <span className="text-xs font-bold">${threshold}.00</span>
        </div>

        <div className={`mt-4 rounded border px-2 ${lockClasses}`}>
          <div className="flex items-center justify-center gap-4 py-2">
            <div tabIndex="-1" className="transparent">
              {image?.src && (
                <Image
                  alt={fullProduct?.title}
                  height={Math.floor(40 / (image.width / image.height))}
                  src={image.src}
                  width="80"
                  className="h-20 w-auto mix-blend-multiply"
                />
              )}
            </div>

            <div className="flex max-w-[25rem] flex-1 flex-col">
              <p className="text-xs font-bold">{fullProduct?.title}</p>
              <p className="text-[9px]">{additionalInfo}</p>
            </div>

            <div className="flex items-center justify-between gap-4">
              {!isVariantSelectEnabled && !productInCart ? (
                <button
                  type="button"
                  disabled={!progress.upsellUnlocked}
                  className={`text-centered rounded-full border border-[2px] p-[.4rem_.725rem] text-xs ${
                    progress.upsellUnlocked
                      ? 'cursor-pointer bg-primary text-white'
                      : 'cursor-not-allowed'
                  }`}
                  onClick={() => {
                    if (!progress.upsellUnlocked) return;
                    handleUnlock(true);
                  }}
                >
                  {!progress.upsellUnlocked ? (
                    <span className="bg-[url('/icons/icon-locked.png')] bg-[auto_87%] bg-left bg-no-repeat pl-[18px]">
                      Locked
                    </span>
                  ) : (
                    'Select'
                  )}
                </button>
              ) : (
                <button
                  id="upsell-button"
                  aria-label={buttonText}
                  className="hidden"
                  disabled={
                    !productInCart
                      ? !currentVariant || isSoldOut || isNotifyMe
                      : false
                  }
                  onClick={() => {
                    if (!productInCart) {
                      handleAddToCart();
                      handleUnlock(false);
                      setCurrentVariant(null);
                    } else {
                      handleRemoveButton();
                    }
                  }}
                  type="button"
                >
                  {isAdding ? (
                    <div className="flex h-4 items-center justify-center px-6">
                      <Spinner width="12" color="gray" />
                    </div>
                  ) : (
                    <p className="transition">
                      {currentVariant
                        ? buttonText
                        : !productInCart
                        ? 'Select an Option'
                        : 'X Remove'}
                    </p>
                  )}
                </button>
              )}
            </div>
          </div>

          {isVariantSelectEnabled && (
            <div>
              {selectorFields?.giftMessage && (
                <p className="pb-2 text-center text-xs font-bold">
                  {selectorFields?.giftMessage}
                </p>
              )}

              <div className="mt-2">
                {fullProduct?.options?.map((option) => {
                  if (option.name !== 'Size') {
                    return (
                      <div
                        key={option.id}
                        className="mb-2 flex justify-between"
                      >
                        <p className="text-primarty font-bold uppercase">
                          {selectorFields?.firstOptLabel
                            ? selectorFields?.firstOptLabel
                            : option.name}
                        </p>

                        <select
                          name={option.name}
                          className="w-[40%] cursor-pointer rounded border border-2 border-primary"
                          onChange={(e) => handleVariantSelect(e.target.value)}
                        >
                          {selectorFields?.firstOptPlaceholder ? (
                            <option>
                              {selectorFields?.firstOptPlaceholder}
                            </option>
                          ) : (
                            <option>Select a {option.name}</option>
                          )}

                          {option?.values?.map((opVal, index) => {
                            return (
                              <option
                                key={index}
                                value={JSON.stringify({ [option.name]: opVal })}
                              >
                                {opVal}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    );
                  }

                  if (option.name === 'Size') {
                    return (
                      <div
                        key={option.id}
                        className="mb-2 flex justify-between"
                      >
                        <p className="text-primarty font-bold uppercase">
                          {selectorFields?.secondOptLabel
                            ? selectorFields?.secondOptLabel
                            : option.name}
                        </p>

                        <select
                          name={option.name}
                          className="w-[40%] cursor-pointer rounded border border-2 border-primary"
                          onChange={(e) => handleVariantSelect(e.target.value)}
                          value={
                            JSON.stringify({
                              [option.name]: selectedOptions[option.name],
                            }) || ''
                          }
                        >
                          {selectorFields?.secondOptPlaceholder ? (
                            <option>
                              {selectorFields?.secondOptPlaceholder}
                            </option>
                          ) : (
                            <option>Select a {option.name}</option>
                          )}

                          {colorSizeMap?.[selectedOptions?.Color]?.map(
                            (opVal, index) => {
                              return (
                                <option
                                  key={index}
                                  value={JSON.stringify({
                                    [option.name]: opVal,
                                  })}
                                >
                                  {opVal}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
}

CartUpsell.displayName = 'CartUpsell';
