import { Image } from '../../snippets';

export function ProductCompareMedia({
  aboveTheFold,
  images,
  selectedOptionIndex,
  children,
}) {
  const image = images[selectedOptionIndex];

  return (
    <div className="absolute inset-0 flex h-full w-full flex-col">
      <div className="h-full">
        <div className="relative h-full w-full overflow-hidden md:hidden">
          {image?.imageMobile?.src && (
            <Image
              alt={image.alt}
              className={`${image.positionMobile} !object-contain`}
              fill
              sizes="100vw"
              priority={aboveTheFold}
              src={image.imageMobile.src}
            />
          )}
        </div>

        <div className="relative hidden h-full w-full overflow-hidden md:block">
          {image?.imageDesktop?.src && (
            <Image
              alt={image.alt}
              className={`${image.positionDesktop} !object-contain`}
              fill
              priority={aboveTheFold}
              sizes="50vw"
              src={image.imageDesktop.src}
            />
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

ProductCompareMedia.displayName = 'ProductCompareMedia';
