import { useState, useMemo } from 'react';

import { Markdown, Svg, ShopifyRichText } from '../../snippets';

export function ProductDetailAccordion({
  accordion,
  headerBgColor,
  headerTextColor,
  metafields,
  //isOpen,
  setAccordionIndex,
  accordionState,
  index,
}) {
  const { body, defaultOpen, header, enableLearnMore, metafieldKey } =
    accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const scrollToShowcase = () => {
    let element = document.getElementById('product-showcase');
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const isProdDesc = header === 'Product Description';

  const hasLearnMore = !!(
    isProdDesc && metafields?.custom_fields?.showcase_header
  );

  const bodyShopify = metafields?.bylt ? metafields?.bylt[metafieldKey] : null;

  const newAccordionState = {
    ...accordionState,
    index,
    active: index === accordionState.index ? !accordionState.active : true,
  };

  if (!bodyShopify && !body) {
    return null;
  }

  return (
    <div>
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className={`border-b border-border ${
          isOpen ? 'border-b-0 !pb-0' : ''
        }  px-contained  flex w-full items-center justify-between py-[25px] ${
          index === 0
            ? 'max-md:border-t lg:pb-[54px] lg:pt-[22px]'
            : 'lg:py-[54px]'
        }`}
        onClick={() => setIsOpen(!isOpen)}
        style={{ backgroundColor: headerBgColor, color: headerTextColor }}
        type="button"
      >
        <h3 className="text-[16px] font-bold leading-[16px] transition-all md:hover:opacity-50 lg:text-[20px]">
          {header}
        </h3>

        {isOpen ? (
          <Svg
            className="w-[26px] text-current"
            src="/svgs/custom-minimize.svg#minimize"
            title="Minus"
            viewBox="0 0 55 55"
          />
        ) : (
          <Svg
            className="w-[26px] text-current"
            src="/svgs/custom-expand.svg#expand"
            title="Plus"
            viewBox="0 0 55 55"
          />
        )}
      </button>

      <div
        className={`px-contained border-b  border-border pb-[25px] pt-[18px]  lg:pb-[54px] lg:pt-[30px] [&_h1]:mb-3 [&_h1]:text-base [&_h2]:mb-3 [&_h2]:text-base [&_h3]:mb-3 [&_h3]:text-base [&_h4]:mb-3 [&_h4]:text-base [&_h5]:mb-3 [&_h5]:text-base [&_h6]:mb-3 [&_h6]:text-base [&_li]:text-xs md:[&_li]:text-base [&_ol]:!pl-4 [&_ol]:text-base [&_p]:mb-3 [&_p]:text-xs md:[&_p]:text-base [&_ul]:list-disc [&_ul]:!pl-4 [&_ul]:text-base ${
          isOpen ? 'block border-t-0' : 'hidden'
        } ${hasLearnMore ? '[&_div]:inline [&_p]:inline' : ''}`}
      >
        <div className="!block lg:max-w-[50%]">
          {!bodyShopify ? (
            <Markdown>{body}</Markdown>
          ) : (
            <ShopifyRichText shopifyTextObject={bodyShopify} />
          )}

          {hasLearnMore && (
            <p
              className="text-underline ml-2 inline cursor-pointer"
              onClick={scrollToShowcase}
            >
              Learn More
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

ProductDetailAccordion.displayName = 'ProductDetailAccordion';
