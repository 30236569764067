export function Schema() {
  return {
    category: 'Integrations',
    label: 'Yotpo Reward',
    key: 'yotpoReward',
    fields: [
      {
        label: 'Content Settings',
        name: 'content',
        component: 'group',
        description: 'Content alignment, text alignment',
        fields: [
          {
            component: 'toggle',
            name: 'isTitleEnabled',
            label: 'Enable Title',
            toggleLabels: {
                true: 'Yes',
                false: 'No',
            },
            defaultValue: true,
          }
        ],
        defaultValue: {
          isTitleEnabled: true
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Padding, max content width',
        fields: [
          {
            label: 'Horizontal Padding',
            name: 'hasXPadding',
            component: 'toggle',
            description: 'Adds default horizontal padding around section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'hasYPadding',
            component: 'toggle',
            description: 'Adds default vertical padding around section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Max Content Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[60rem]',
              },
              {
                label: 'Medium Wide',
                value: 'max-w-[75rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[90rem]',
              },
              { label: 'Full', value: 'max-w-full' },
            ],
          },
        ],
        defaultValue: {
          hasXPadding: false,
          hasYPadding: false,
          maxWidth: 'max-w-full',
        },
      },
    ],
  };
}
