import { Link, Image } from '../../snippets';
import { Schema } from './CollectionLinkFilters.schema';

export function CollectionLinkFilters({ cms }) {
  const { filterLinks } = cms;

  return (
    <div className="border-t border-border px-0 py-[30px] md:px-[17px]">
      <div className={`mx-auto`}>
        {filterLinks?.length > 0 && (
          <ul className="justify-left flex gap-4 max-md:flex-row max-md:flex-wrap md:flex-col">
            {filterLinks.map(({ link, label, image }, index) => {
              return (
                <li
                  key={index}
                  className="flex items-center gap-4 max-md:flex-[0_47%]"
                >
                  {image && image.src && (
                    <Image alt={label} height="40" width="40" src={image.src} />
                  )}

                  <Link
                    aria-label={link?.text}
                    href={link?.url}
                    newTab={link?.newTab}
                    type={link?.type}
                    className="text-sm font-bold text-black hover:text-primary hover:underline"
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

CollectionLinkFilters.displayName = 'Collection Link Filters';
CollectionLinkFilters.Schema = Schema;
