import { useGlobalContext } from '../../contexts';
import { useState } from 'react';
import { Image } from '../Image';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
import {
  useSettings,
  useCountry,
  useCartAddItem,
} from '@backpackjs/storefront';
import { useInveterate } from '../../hooks';

export function InveterateModal() {
  const settings = useSettings();
  const { country } = useCountry();
  const { enabled, modal } = { ...settings?.apps?.inveterate };
  const { cartAddItem } = useCartAddItem();
  const {
    actions: { closeModal, openCart },
  } = useGlobalContext();
  const { premiumTier } = useInveterate();
  const [isAdding, setIsAdding] = useState(false);

  const benefits = modal?.benefits || [];
  const isUS = country?.isoCode === 'US';
  const modalBtnLink = !modal?.isBtnAtc ? modal?.buttonLink?.url : null;

  const clickAction = () => {
    if (!modal?.isBtnAtc) return;

    addInveterateTierIntoCart();
  };

  const addInveterateTierIntoCart = async () => {
    try {
      setIsAdding(true)
    
      await cartAddItem({
        merchandiseId: premiumTier.productVariantId,
        quantity: 1,
        attributes: [],
        sellingPlanId: '1265336422',
      });

      setIsAdding(false)
      closeModal()
      openCart()
    } catch (error) {}
  };

  return (
    <div className="w-[100vw] max-w-[80vw] md:max-w-[450px] ">
      {modal?.bgImage && (
        <Image
          alt={modal.bgImage.altText}
          fill
          priority={true}
          sizes="50vw"
          src={modal.bgImage.src}
        />
      )}

      <div
        className={`absolute left-0 top-0 flex h-full w-full flex-col p-10 
          ${modal?.contentStyle} ${modal?.textColor}`}
      >
        <div>
          <h3 className="mb-2 text-xl font-extrabold leading-[20px]">
            {modal?.heading}
          </h3>

          <p className="mb-4 font-medium">{modal?.details}</p>

          <p className="mb-4 text-[25px] font-extrabold">$50 / <s>$85</s></p>

          <div>
            <ul>
              {benefits?.map((el, index) => {
                return (
                  <li className="font-medium" key={index}>
                    {el}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div>
          <Link
            className={`${modal?.buttonStyle} mb-4 w-full`}
            href={modalBtnLink}
            onClick={() => clickAction()}
            disabled={!isUS}
          >
            {!isAdding ? (
              <span>{modal?.buttonLink?.text}</span>
            ) : (
              <Spinner width="20" />
            )}
          </Link>

          {modal?.enableAccountLink && (
            <p className="font-medium">
              Already have and account?{' '}
              <Link className="text-underline" href="/account/login">
                Sign in
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

InveterateModal.displayName = 'InveterateModal';
