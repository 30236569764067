import React from 'react';

import { Image, Link, Svg } from '../../snippets';
import { Schema } from './TwoTiles.schema';

export function TwoTiles({ cms }) {
  const { section, tiles } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div className="px-contained py-contained">
      <div
        className={`${maxWidthClass} mx-auto grid gap-x-5 gap-y-5 md:grid-cols-2 lg:gap-x-8`}
      >
        {tiles?.slice(0, 2).map((item, index) => {
          const headingStyles = item?.headingFontSize
          ? item.headingFontSize
          : '';
          
          const descriptionStyles = item?.descriptionFontSize
            ? item.descriptionFontSize
            : 'text-base';
          return (
            <div key={index} className="w-full">
              <div
                className={`relative mb-4 bg-offWhite ${section?.aspectRatio}`}
              >
                <Link
                  aria-label={item.heading}
                  href={item.link?.url}
                  newTab={item.link?.newTab}
                  tabIndex="-1"
                  type={item.link?.type}
                >
                  {item?.image?.src && (
                    <Image
                      alt={item.alt}
                      className={`${item.position}`}
                      fill
                      sizes="(min-width: 1024px) 40vw, 100vw"
                      src={item.image.src}
                    />
                  )}
                </Link>
              </div>

              <div className="flex flex-col items-start">
                <Link
                  aria-label={item.heading}
                  href={item.link?.url}
                  newTab={item.link?.newTab}
                  type={item.link?.type}
                >
                  <div className="group flex">
                    <h3 className={`${headingStyles} normal-case`}>{item.heading}</h3>

                    <span className="ml-[0.75rem] block w-[1.25rem] transition-transform lg:w-[1.5rem] lg:group-hover:translate-x-2">
                      <Svg
                        src="/svgs/arrow-right.svg#arrow-right"
                        title="Arrow"
                        viewBox="0 0 24 24"
                      />
                    </span>
                  </div>
                </Link>

                {item.description && (
                  <p className={`${descriptionStyles} normal-case mt-1`}>{item.description}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

TwoTiles.displayName = 'TwoTiles';
TwoTiles.Schema = Schema;
