import { useSettings } from '@backpackjs/storefront';

import { CollectionDropdownFilter } from './CollectionDropdownFilter';
import { CollectionMobileFilters } from './CollectionMobileFilters';

export function CollectionFilters({
  collectionCount,
  collectionFiltersData,
  swatchesMap,
  linkFilters,
  setSidebarClasses,
  isSortSidebarOpen = false,
}) {
  const settings = useSettings();
  const { optionsMaxCount, showCount, sticky } = {
    ...settings?.collection?.filters,
  };
  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;
  const stickyTopClass = stickyPromobar
    ? 'md:top-[calc(var(--header-height)+var(--promobar-height)+3.5rem)]'
    : 'md:top-[calc(var(--header-height)+3.5rem)]';
  const {
    state: { activeFilters, filters, filtersMap },
    actions: { addFilter, removeFilter, clearFilters },
  } = collectionFiltersData;

  return (
    <div
      className={`flex max-h-[70vh] flex-col gap-5 overflow-y-auto lg:sticky ${
        sticky ? stickyTopClass : ''
      } ${isSortSidebarOpen ? 'hidden' : ''}`}
    >
      {/* desktop */}

      <div className="max-lg:hidden">
        <p className="font-sm pb-[19px] font-bold uppercase text-black">
          Filters
        </p>
        {linkFilters || null}
      </div>

      <ul
        className={`border-border max-lg:hidden ${
          filters.length ? 'border-b' : ''
        }`}
      >
        {filters.map((filter, index) => {
          if (
            !filter.values.length ||
            (filter.values.length === 1 &&
              filter.values[0].count === collectionCount)
          )
            return null;

          return (
            <li key={index}>
              <CollectionDropdownFilter
                activeFilters={activeFilters}
                addFilter={addFilter}
                defaultOpen={filter.defaultOpenDesktop}
                filter={filter}
                optionsMaxCount={optionsMaxCount}
                removeFilter={removeFilter}
                showCount={showCount}
                swatchesMap={swatchesMap}
              />
            </li>
          );
        })}
      </ul>

      {/* mobile */}
      <div className="lg:hidden">
        <CollectionMobileFilters
          activeFilters={activeFilters}
          addFilter={addFilter}
          clearFilters={clearFilters}
          collectionCount={collectionCount}
          filters={filters}
          filtersMap={filtersMap}
          optionsMaxCount={optionsMaxCount}
          removeFilter={removeFilter}
          showCount={showCount}
          swatchesMap={swatchesMap}
          linkFilters={linkFilters}
          setSidebarOpen={setSidebarClasses}
        />
      </div>
    </div>
  );
}

CollectionFilters.displayName = 'CollectionFilters';
