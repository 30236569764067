import axios from 'axios';

export const fetchSizeGuide = async (pageHandle) => {
  if(!pageHandle) return null

  const handle = `${pageHandle}`

  const query = `
        query {
          pageByHandle(handle: "${handle}") {
            id
              title
              handle
              body
          }
        }
      `;

  try {
    const endpoint = `https://bylt-apparel.myshopify.com/api/2024-04/graphql.json`;

    const response = await axios({
      url: endpoint,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token':
          process.env.SHOPIFY_STOREFRONT_API_TOKEN,
      },
      data: JSON.stringify({
        query: query,
      }),
    });

    const page = response?.data?.data?.pageByHandle?.body;

    if (page) {
      return page
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
