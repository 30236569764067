import { ColorVariantOptions } from './ColorVariantOptions';

export function ColorVariantSelector({
  enabledColorNameOnHover,
  initialProduct,
  selectedVariant,
  setProductFromColorSelector,
  setVariantFromColorSelector,
  swatchesMap,
  productUrl
}) {
  
  const hasMultipleColors = initialProduct?.optionsMap?.Color?.length > 0;

  return hasMultipleColors && selectedVariant ? (
    <div className="mt-2">
      <ColorVariantOptions
        enabledColorNameOnHover={enabledColorNameOnHover}
        initialProduct={initialProduct}
        selectedVariant={selectedVariant}
        setProductFromColorSelector={setProductFromColorSelector}
        setVariantFromColorSelector={setVariantFromColorSelector}
        swatchesMap={swatchesMap}
        productUrl={productUrl}
      />
    </div>
  ) : null;
}

ColorVariantSelector.displayName = 'ColorVariantSelector';
