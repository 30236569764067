import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useGlobalContext } from '../../contexts';

export function LoopHandler() {
  const router = useRouter();
  const query = router.query

  const {
    state: { loopSelected },
    actions: { enableLoop },
  } = useGlobalContext();

  useEffect(() => {
    const savedParam = localStorage.getItem('loopOnstoreParams');

    if(!savedParam){
    
      if (!query?.loop_total)  return

      const loopObject = {
        loop_return_id: query?.loop_return_id,
        loop_currency: query?.loop_currency,
        loop_total: query?.loop_total,
        loop_base: query?.loop_base,
        loop_credit: query?.loop_credit,
        loop_domain: query?.loop_domain,
        loop_subdomain: query?.loop_subdomain,
        loop_redirect_uri: query?.loop_redirect_uri,
        loop_customer_name: query?.loop_customer_name,
        utm_redirect: query?.utm_redirect,
      };
  
      localStorage.setItem('loopOnstoreParams', JSON.stringify(loopObject))
      enableLoop()
    }
  }, [query]);

  useEffect(() => {
    const savedParam = localStorage.getItem('loopOnstoreParams');

    if(savedParam){
      enableLoop()
    }
  }, [router])

  useEffect(() => {
    if(loopSelected){
      const riseButton = document.querySelector('.GiftWizard-gift-button')
      riseButton?.setAttribute("disabled", "");
    } else {
      const riseButton = document.querySelector('.GiftWizard-gift-button')
      riseButton?.removeAttribute("disabled");
    }
  },[loopSelected])

  return null;
}

LoopHandler.displayName = 'LoopHandler';
