import { Image, Link, Markdown, InveterateAddToCart } from '../../snippets';
import { Schema } from './TwoTilesWithLogo.schema';

export function TwoTilesWithLogo({ cms }) {
  const { section, tiles } = cms;

  const containerClasses = section?.fullWidth
    ? 'mx-auto'
    : 'mx-auto max-w-[60rem]';

  return (
    <div className="px-contained py-contained">
      <h2 className="mb-[40px] mt-[32px] text-center">Membership Plans</h2>

      <div
        className={`grid grid-cols-1 gap-[20px] lg:grid-cols-2 ${containerClasses}`}
      >
        {tiles?.slice(0, 2).map((item, index) => {
          const hasInveterate = item?.inveterate?.enableInveterate;
          const invAction = item?.inveterate?.action;

          return (
            <div
              className="flex flex-col items-center rounded-[5px] border border-border p-[20px] shadow-[0_4px_8px_rgba(0,0,0,0.1)]"
              key={index}
            >
              {item.image && (
                <Image
                  className="my-[20px]"
                  src={item.image.src}
                  alt={item.alt}
                  height={80}
                  width={80}
                />
              )}

              <div className="flex flex-nowrap items-center justify-center">
                <h2>
                  <Markdown>{item.heading}</Markdown>
                </h2>
                <span className="ml-[10px] inline-block text-center text-[0.75rem] font-bold leading-[1em] tracking-[2px]">
                  PER
                  <br />
                  YEAR
                </span>
              </div>

              <h4>{item.subheading}</h4>

              {!hasInveterate ? (
                <>
                  {item?.link && (
                    <Link className="btn-primary" href={item?.link?.url}>
                      {item?.link?.text}
                    </Link>
                  )}
                </>
              ) : (
                <InveterateAddToCart
                  containerClasses="mt-[20px] mb-[30px]"
                  buttonStyle="btn-secondary relative w-full"
                  mode={invAction}
                />
              )}

              {!!item.description && (
                <div className="my-[20px]">
                  <Markdown>{item.description}</Markdown>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

TwoTilesWithLogo.displayName = 'TwoTilesWithLogo';
TwoTilesWithLogo.Schema = Schema;
