import { Link, Image } from '../../snippets';
import { Schema } from './CollectionToggleButtons.schema';
import { useRouter } from 'next/router';

export function CollectionToggleButtons({ cms }) {
  const { buttons } = cms;

  const { asPath } = useRouter();

  return (
    <div className="px-0 h-full">
      <div className={`mx-auto h-full`}>
        {buttons?.length > 0 && (
          <ul className="justify-left flex gap-4 items-center h-full">
            {buttons.map((link, index) => {
              const inUse = link?.url === asPath
              const selectedClasses = inUse ? 'underline font-bold' : 'font-normal'
              return (
                <li
                  key={index}
                  className="flex items-center gap-4"
                >
                  <Link
                    aria-label={link?.text}
                    href={link?.url}
                    newTab={link?.newTab}
                    type={link?.type}
                    className={`text-base text-black hover:text-primary hover:underline ${selectedClasses}`}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

CollectionToggleButtons.displayName = 'CollectionToggleButtons';
CollectionToggleButtons.Schema = Schema;
