import { useState, useMemo } from 'react';
import {
  useSettings,
  useCountry,
  useCartAddItem,
  useCartItems,
} from '@backpackjs/storefront';
import { useInveterate } from '../../hooks';
import { Spinner } from '../Spinner';
import { useGlobalContext } from '../../contexts';
import { InveterateFreeTierModal } from './InveterateFreeTierModal';

export function InveterateAddToCart({
  buttonText = 'Join Now',
  buttonStyle = 'btn-primary relative w-full',
  containerClasses = 'mt-2',
  mode = 'premium',
}) {
  const { country } = useCountry();
  const settings = useSettings();
  const isUS = country?.isoCode === 'US';
  const { premiumTier, isBasicMember, isCustomerPremiumMember } =
    useInveterate();
  const { inveterate } = { ...settings?.apps };
  const { cartAddItem } = useCartAddItem();
  const cartItems = useCartItems();
  const {
    actions: { openCart, openModal },
  } = useGlobalContext();

  const [isAdding, setIsAdding] = useState(false);

  const isInveterateEnabled = inveterate?.enabled || false;
  const isVisible = !!(isInveterateEnabled && isUS);

  const hasPremiumInCart = cartItems?.some(
    (item) => item?.variant?.product?.handle === 'inveterate-subscription'
  );

  const displayButtonText = isCustomerPremiumMember
    ? 'Member'
    : hasPremiumInCart
    ? 'Added!'
    : buttonText;
  const basicDisplayButton =
    isBasicMember || isCustomerPremiumMember ? 'Member' : buttonText;

  const addInveterateTierIntoCart = async () => {
    try {
      setIsAdding(true);
      const sellingPlan = inveterate?.sellingPlanId
        ? `gid://shopify/SellingPlan/${inveterate?.sellingPlanId}`
        : 'gid://shopify/SellingPlan/1265336422';

      const variantId = inveterate?.premiumVariantId
        ? `gid://shopify/ProductVariant/${inveterate?.premiumVariantId}`
        : premiumTier?.productVariantId;

      await cartAddItem({
        merchandiseId: variantId,
        quantity: 1,
        attributes: [],
        sellingPlanId: sellingPlan,
      });

      setIsAdding(false);
      openCart();
    } catch (error) {
      setIsAdding(false);
    }
  };

  const triggerFreeTierModal = () => {
    openModal(<InveterateFreeTierModal />, {
      className: 'aspect-[3/4] md:aspect-square w-auto',
      hasBlur: true,
    });
  };

  return (
    isVisible && (
      <>
        {mode === 'premium' ? (
          <div className={containerClasses}>
            <button
              className={`${buttonStyle} inv-atc-btn`}
              onClick={() => addInveterateTierIntoCart()}
              disabled={hasPremiumInCart || isCustomerPremiumMember}
            >
              {!isAdding ? (
                <span>{displayButtonText}</span>
              ) : (
                <Spinner width="20" />
              )}
            </button>
          </div>
        ) : (
          <div className={containerClasses}>
            {!isCustomerPremiumMember && (
              <button
                className={`${buttonStyle} inv-atc-btn`}
                onClick={() => triggerFreeTierModal()}
                disabled={isBasicMember || isCustomerPremiumMember}
              >
                <span>{basicDisplayButton}</span>
              </button>
            )}
          </div>
        )}
      </>
    )
  );
}

InveterateAddToCart.displayName = 'InveterateAddToCart';
