import Script from 'next/script';
import { useCountry } from '@backpackjs/storefront';

export function StorefrontScripts() {
  const { country } = useCountry();

  return (
    <>
      {process.env.NEXT_PUBLIC_GTM_CONTAINER_ID && (
        <Script
          id="gtm-script"
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');`,
          }}
        />
      )}

      {false && (
        <Script
          type="text/javascript"
          src="https://unpkg.com/tapcart-embed@5.0.0/dist/tc-plugins.iife.js"
          async
        />
      )}

      {false && (
        <Script
          type="text/javascript"
          src="https://cdn.tapcart.com/mobile-download-banner/banner-min.js?marketingCopy=Download+our+App&ctaEvent=OPEN&title=The%20All-New%20BYLT%20App&androidLive=true&iosLive=true&appStoreId=6448637922&playStoreId=co.tapcart.app.id_6o5KOCYMjt&shop=bylt-apparel.myshopify.com"
          async
          strategy="lazyOnload"
        />
      )}

      <Script
        type="text/javascript"
        src="https://na-library.klarnaservices.com/lib.js"
        data-client-id="3101763d-8404-5843-abdf-81bcacd8add0"
        async
        strategy="lazyOnload"
      />

      <Script
        id="postscript"
        type="text/javascript"
        src="https://sdk.postscript.io/sdk.bundle.js?shopId=304875"
        async
      />

      <Script
        type="text/javascript"
        strategy="lazyOnload"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=wXmTyh`}
      />

      <Script
        type="text/javascript"
        strategy="lazyOnload"
        src={`//www.klaviyo.com/media/js/public/klaviyo_subscribe.js`}
      />

      {/*Onetrust scripts*/}
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script="6e80e697-534d-4280-9cd6-295d17e5a036"
        strategy="beforeInteractive"
      />

      {/*GE Scripts*/}
      {country && (
        <Script
          dangerouslySetInnerHTML={{
            __html: `var GLBE_PARAMS = {
              countryCode: "${country?.isoCode}",
              currencyCode: "${country?.currency?.isoCode}",
              isExternal: true
            }`,
          }}
        />
      )}

      {country && (
        <Script
          src="https://crossborder-integration.global-e.com/resources/js/app?shop=bylt-apparel.myshopify.com"
          type="text/javascript"
          strategy="lazyOnload"
        />
      )}

      {/*Amped Scripts*/}
      <Script
        src="https://app.amped.io/snippet/account_vvq10q8"
        type="text/javascript"
        strategy="lazyOnload"
      />

      <Script
        type="text/javascript"
        src="https://d1mopl5xgcax3e.cloudfront.net/js/bylt-apparel.inveterate.js"
      />
    </>
  );
}

StorefrontScripts.displayName = 'StorefrontScripts';
