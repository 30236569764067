import {
  useCartTotals,
  useCartCount,
  useCartCheckout,
  useSettings,
  useCountry,
} from '@backpackjs/storefront';
import { useGlobalContext } from '../../contexts';
import { useLoopCheckout, useInveterate } from '../../hooks';

export function CartTotals({routeProduct, useLoop}) {
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const { cartCheckout } = useCartCheckout();
  const settings = useSettings();
  const { checkoutText, subtext, subtotalText } = { ...settings?.cart?.totals };
  const { country } = useCountry();
  const { initLoopCheckout, loopCheckoutText } = useLoopCheckout([])
  const {
    customerCashback
  } = useInveterate();

  const currencySymbol = country ? country.currency.symbol : '$';
  const currencyCode = country ? ` ${country.currency.isoCode}` : 'USD';

  const isUS = country?.isoCode === 'US';

  const routePrice = routeProduct ? routeProduct?.estimatedCost?.subtotalAmount?.amount : null
  const finalCartCount = routeProduct ? cartCount - 1 : cartCount
  const loopCheckoutState = useLoop ? loopCheckoutText : null

  const {
    state: { checkoutButtonState },
  } = useGlobalContext();

  const handleCheckoutBtn = () => {
    if (checkoutButtonState) {
      const btn = document.getElementById('upsell-button');
      btn.click();
    } else {
      const savedParam = localStorage.getItem('loopOnstoreParams');

      if(savedParam && useLoop) {
        initLoopCheckout(savedParam)
      } else {
        cartCheckout();
      }
    }
  };

  return (
    <div
      className={`flex-col gap-4 border-t border-t-border p-4 ${
        finalCartCount ? 'flex' : 'hidden'
      }`}
    >
      <div>
        <div className="flex justify-between text-base font-bold text-black">
          <p>{subtotalText || 'Subtotal'}</p>
          <p>
            {currencySymbol}
            {isUS ? '' : ' '}
            {(parseFloat(cartTotals?.total || '0') - (routePrice ? parseFloat(routePrice) : 0))
              .toFixed(2)
              .replace('.00', '')}
            {currencyCode}
          </p>
        </div>

        {subtext && <p className="mt-1 text-sm">{subtext}</p>}

        {!!(customerCashback) && <p className="mt-1 text-sm">You have ${customerCashback?.newBalance} in store credit</p>}
      </div>

      <button
        aria-label="Go to checkout page"
        className="btn-secondary h-[62px] w-full"
        onClick={handleCheckoutBtn}
        type="button"
      >
        {loopCheckoutState || checkoutButtonState || checkoutText || 'Checkout'}
      </button>
    </div>
  );
}

CartTotals.displayName = 'CartTotals';
