import { useEffect } from 'react';

import { Svg } from '../Svg';
import { useBodyScrollLock } from '../../hooks';

export function CollectionMenuSidebar({ children, isOpen, setIsOpen, title }) {
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();

  useEffect(() => {
    if (isOpen) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
  }, [isOpen]);

  return (
    <div
      className={`${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } position fixed inset-0 z-[100] flex h-full w-full flex-col bg-white transition lg:!hidden`}
    >
      {isOpen && (
        <>
          <div className="relative grid h-14 grid-cols-[1fr_auto] items-center justify-center gap-4 border-b border-border px-4">


            <h2 className="h4 text-left uppercase font-bold">{title}</h2>

            <button
                className=""
                onClick={() => setIsOpen(false)}
                type="button"
              >
                <Svg
                  className="w-[1.25rem] text-black"
                  src="/svgs/close.svg#close"
                  title="Close"
                  viewBox="0 0 24 24"
                />
              </button>
          </div>

          <div className="scrollbar-hide relative flex-1 overflow-y-auto">
            {children}
          </div>
        </>
      )}
    </div>
  );
}

CollectionMenuSidebar.displayName = 'CollectionMenuSidebar';
