import { useGlobalContext } from '../../contexts';
import { useCallback } from 'react';
import axios from 'axios';
import { InveterateCancelContent } from './InveterateCancelContent';

export function InveterateAccountButtons({ customer }) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  const requestPaymentUpdate = async () => {
    try {
      const customerID = customer?.id?.split('gid://shopify/Customer/')[1];
      const endpoint = `https://public.inveterateapi.com/v2.0/storefront/members/${customerID}/payment-method`;

      await axios({
        url: endpoint,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-Inveterate-Api-Key': 'd7b6fc64c7230112f2e208b64f249f92',
        },
      });
    } catch (error) {}
  };

  const handlePaymentUpdate = useCallback(async () => {
    await requestPaymentUpdate();

    openModal(
      <div className="flex justify-center">
        <div className="text-center">
          <p>Payment method update request sent successfully!</p>
          <p>
            If the customer exists, they will receive an email to update their
            payment method.
          </p>
        </div>
      </div>,
      {
        className: '!max-w-[350px]',
      }
    );
  }, [customer]);

  const handleMembershipCancel = useCallback(() => {
    openModal(<InveterateCancelContent customer={customer} />, {
      className: '!max-w-[350px]',
    });
  }, [customer]);

  return (
    <>
      <p
        className="cursor-pointer text-white underline max-lg:pb-1 max-lg:text-right"
        onClick={handleMembershipCancel}
      >
        Cancel Membership
      </p>

      <p
        className="cursor-pointer text-white underline max-lg:text-right"
        onClick={handlePaymentUpdate}
      >
        Update Payment Method
      </p>
    </>
  );
}

InveterateAccountButtons.displayName = 'InveterateAccountButtons';
