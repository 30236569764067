import PropTypes from 'prop-types';

import { BackInStockModal } from '../BackInStockModal';
import { Spinner } from '../Spinner';
import { useAddToCart, useInveterate, useLoopCheckout } from '../../hooks';
import { useEffect } from 'react';
import { useGlobalContext } from '../../contexts';

export function AddToCart({
  addToCartText,
  className,
  isPdp,
  isCustom,
  isCustomReady,
  quantity,
  selectedVariant,
  metafields,
  customSelectedOptions,
  isKit = false,
  selectedKitVariants,
  isAtcValidated = true,
  validationStep = 0,
}) {
  const {
    state: { buttonText, isAdding, isLoading, isNotifyMe, isSoldOut, subtext },
    actions: {
      handleAddToCart,
      handleNotifyMe,
      handleCustomAddToCart,
      handleKitAddToCart,
    },
  } = useAddToCart({
    addToCartText,
    quantity,
    selectedVariant,
    metafields,
    isCustom,
    isCustomReady,
    customSelectedOptions,
    isKit,
    selectedKitVariants,
    isAtcValidated,
    validationStep,
  });

  const {
    state: { loopSelected },
    actions: { updatePpdAtcButtonStatus },
  } = useGlobalContext();
  const { isCustomerPremiumMember } = useInveterate();

  const {isBlockedByLoop} = useLoopCheckout({product: selectedVariant?.product})

  let cursorClass = '';
  if (isAdding) cursorClass = 'cursor-default';
  else if (isLoading) cursorClass = 'cursor-wait';

  const isDisabledByLoop = (loopSelected && isBlockedByLoop)

  const isPremiumGated =
    !!(selectedVariant?.product?.tags?.some(
      (el) => el === 'isEarlyAccess' || el === 'isPremium'
    ) && !isCustomerPremiumMember) || false;

  const isKitDisabled = !isAtcValidated;

  const isNormalDisabled = (isSoldOut && !isNotifyMe) || !isAtcValidated || isDisabledByLoop;

  const isButtonDisabled = !isCustom
    ? !isKit
      ? isNormalDisabled
      : isKitDisabled
    : !isCustomReady;

  useEffect(() => {
    updatePpdAtcButtonStatus(buttonText);
  }, [buttonText, selectedVariant]);

  const atcButtonText = isPremiumGated ? 'Early Access' : buttonText;

  return (
    <div>
      <button
        id="atc-btn"
        aria-label={buttonText}
        className={`${
          isNotifyMe ? 'btn-inverse-dark' : 'btn-primary'
        } relative w-full ${cursorClass} ${className}`}
        disabled={isPremiumGated || isButtonDisabled}
        onClick={() => {
          if (isPremiumGated) {
            return;
          }

          if (isKit) {
            handleKitAddToCart();
            return;
          }

          if (isCustom && isCustomReady) {
            handleCustomAddToCart();
          } else {
            if (isNotifyMe) {
              handleNotifyMe(
                <BackInStockModal selectedVariant={selectedVariant} />
              );
            } else {
              handleAddToCart();
            }
          }
        }}
        type="button"
      >
        {isAdding ? (
          <Spinner width="20" />
        ) : (
          <p className={`transition ${isLoading ? 'opacity-30' : ''}`}>
            {atcButtonText}
          </p>
        )}
      </button>

      {isPdp && subtext && (
        <p className="mt-1 text-center text-xs">{subtext}</p>
      )}
    </div>
  );
}

AddToCart.displayName = 'AddToCart';
AddToCart.defaultProps = {
  addToCartText: '',
  className: '',
  isPdp: false,
  quantity: 1,
  selectedVariant: null,
};
AddToCart.propTypes = {
  addToCartText: PropTypes.string,
  className: PropTypes.string,
  isPdp: PropTypes.bool,
  quantity: PropTypes.number,
  selectedVariant: PropTypes.object,
};
