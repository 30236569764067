import { ReviewStars } from '../../snippets';
import { useInView } from 'react-intersection-observer';

export function ProductReviewItem({ review }) {
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      {inView && (
        <div className="text-center">
          <div className="mb-[60px] text-center">
            <div className="mb-[18px] flex justify-center">
              <ReviewStars rating={review?.reviewRating} size="large" />
            </div>
            <h6 className="h4 mb-[15px] text-[20px]">{review.reviewTitle}</h6>
            <p className="text-base">{review.reviewMessage}</p>
          </div>

          <p className="text-sm">{review.reviewDate}</p>
          <p className="mt-5 text-sm font-bold">{review.author}</p>
          <p className="text-sm text-secondary">Verified Buyer</p>

          <hr className="mt-[47px] w-full border border-offWhite" />

          <div className="flex h-full w-full flex-wrap gap-4 pb-5 pt-[0.875rem]">
            {review?.reviewOptionsList.map((option) => (
              <div key={option.message} className="flex flex-col gap-px">
                <strong className="w-5/6 text-left text-[0.688rem] leading-4">
                  {option.message}
                </strong>
                <div className=" flex flex-row gap-px">
                  {[...Array(5).keys()].map((i) => (
                    <div
                      key={i}
                      className={`h-2.5 w-5 ${
                        i <= option.value ? 'bg-darkGray' : 'bg-stone-200'
                      }`}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
