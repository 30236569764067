import Script from 'next/script';

import {
  useDataLayerAccount,
  useDataLayerCart,
  useDataLayerCollection,
  useDataLayerCustomer,
  useDataLayerInit,
  useDataLayerProduct,
  useDataLayerSearch,
  useDataLayerSubscribe,
} from './hooks';

// Debug via Elevar's Data Layer Listener, add to the console:
// Turn on: window.ElevarGtmSuiteListener.utils.debug(true)
// Turn off: window.ElevarGtmSuiteListener.utils.debug(false)

// Envs to set:
// * NEXT_PUBLIC_SITE_TITLE // provides backup brand name

const DEBUG = false; // local debugging (logs whether passed or failed with elevar)

export function DataLayerWithElevar() {
  const { generateUserProperties, userProperties } = useDataLayerInit({
    DEBUG,
  });

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    DEBUG,
    userProperties,
  });

  useDataLayerAccount({
    DEBUG,
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    DEBUG,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    DEBUG,
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSubscribe({
    DEBUG,
    userDataEventTriggered,
  });

  return (
    <>
      {/* Non-Shopify Attribution Tracking */}
      {/* Placeholder scripts; replace with project specific Elevar scripts generated from Elevar admin */}
      <Script id="elevar-script" type="module" strategy="beforeInteractive">
        {`
          try {
            const settings = {};
            const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/bd6fe8643a8393ef9893c73b70c60354dc41c535/config.js")).default;
            const scriptUrl = config.script_src_custom_pages;
      
            if (scriptUrl) {
              const { handler } = await import(scriptUrl);
              await handler(config, settings);
            }
          } catch (error) {
            console.error("Elevar Error:", error);
          }
        `}
      </Script>




      {/* Non Elevar Tracking Script */}
      <Script
        id="postie"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            ;(function (z, i, p, c, o, d, e) {
              z[c] ||
                ((z[c] = function () {
                  ;(z[c].q = z[c].q || []).push(arguments)
                }),
                (z[c].q = z[c].q || []),
                (d = i.createElement(p)),
                (d.async = 1),
                (d.src = o),
                (e = i.getElementsByTagName(p)[0]),
                e.parentNode.insertBefore(d, e))
            })(
              window,
              document,
              'script',
              'letterpress',
              '//scripts.postie.com/dmlmdtsp/lp.1.js'
            )
          `,
        }}
      />
    </>
  );
}

DataLayerWithElevar.displayName = 'DataLayerWithElevar';
