import { useMemo } from 'react';

import { Svg } from '../Svg';

export function SearchFilterOption({
  activeFilters,
  addFilter,
  count,
  name,
  id,
  removeFilter,
  showCount,
  value,
  trackOnClick,
}) {
  const isActive = useMemo(() => {
    return activeFilters?.some(filter => filter === `${id}:${name}`);
  }, [activeFilters, value]);

  let swatch = null;


  const checkmarkColor = useMemo(() => {
    if (!swatch) return 'text-black';
    return isLightHexColor(swatch) ? 'text-black' : 'text-white';
  }, [swatch]);

  const nonColorBackground = isActive ? 'var(--black)' : 'var(--off-white)';

  return (
    <button
      aria-label={`Add ${value} to filters`}
      className={`group flex items-center gap-3 text-left text-base transition max-md:h-11 max-md:w-full max-md:items-center max-md:px-4 md:gap-2 hover:md:text-text ${
        isActive ? 'font-bold text-text' : 'text-black'
      }`}
      onClick={() => {
        if (isActive) {
          removeFilter(`${id}:${name}`);
          trackOnClick(`${id}:${name}`);
        } else {
          addFilter(`${id}:${name}`);
          trackOnClick(`${id}:${name}`);
        }
      }}
      type="button"
    >
      <div
        className={`relative flex h-5 w-5 items-center justify-center overflow-hidden rounded border border-border transition md:h-[18px] md:w-[18px] group-hover:md:border-text ${
          isActive ? 'border-text' : ''
        }`}
        style={{
          nonColorBackground,
        }}
      >
        <div
          className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 border transition-[border-width] duration-100 ${
            isActive ? 'border-[0px] md:border-[0px]' : 'border-black'
          }`}
        />

        <Svg
          src="/svgs/checkmark.svg#checkmark"
          viewBox="0 0 24 24"
          className={`pointer-events-none w-6 transition md:w-5 ${checkmarkColor} ${
            isActive ? 'opacity-100' : 'opacity-0'
          }`}
        />
      </div>

      <p className="flex-1 text-sm capitalize">
        {value}{' '}
        <span className={`hidden text-xs ${showCount ? 'inline' : 'hidden'}`}>
          ({count})
        </span>
      </p>
    </button>
  );
}

SearchFilterOption.displayName = 'SearchFilterOption';
