export function Schema() {
  return {
    category: 'Integrations',
    label: 'Shoppable Instagram',
    key: 'shoppableInstagram',
    fields: [
      {
        label: 'Content Settings',
        name: 'content',
        component: 'group',
        description: 'Content alignment, text alignment',
        fields: [
          {
            label: 'Content Alignment',
            name: 'contentAlign',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'items-start' },
              { label: 'Center', value: 'items-center' },
              { label: 'Right', value: 'items-end' },
            ],
          },
          {
            label: 'Text Alignment',
            name: 'textAlign',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'text-left' },
              { label: 'Center', value: 'text-center' },
              { label: 'Right', value: 'text-right' },
            ],
          },
        ],
        defaultValue: {
          contentAlign: 'items-start',
          textAlign: 'text-left',
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Padding, max content width',
        previewSrc:
          'https://cdn.shopify.com/s/files/1/1464/5034/files/Screenshot_2023-11-03_at_10.34.58_AM.png?v=1699025714',
        fields: [
          {
            label: 'Horizontal Padding',
            name: 'hasXPadding',
            component: 'toggle',
            description: 'Adds default horizontal padding around section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'hasYPadding',
            component: 'toggle',
            description: 'Adds default vertical padding around section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Max Content Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[60rem]',
              },
              {
                label: 'Medium Wide',
                value: 'max-w-[75rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[90rem]',
              }
            ],
          },
        ],
        defaultValue: {
          hasXPadding: false,
          hasYPadding: false,
          fullWidth: true,
          maxWidth: 'max-w-[45rem]',
        },
      },
    ],
  };
}
