import { CollectionHeroContent } from './CollectionHeroContent';
import { Image } from '../../snippets';
import { Schema } from './CollectionHero.schema';

export function CollectionHero({ cms }) {
  const { content, image, section, text } = cms;
  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const isFullBleed = section?.fullBleed;
  const heightContainerClasses = `${section?.heightMobile} ${section?.heightTablet} ${section?.heightDesktop}`;

  return (
    <div className={`${isFullBleed ? '' : 'px-contained'}`}>
      <div
        className={`relative mx-auto ${heightContainerClasses} ${maxWidthContainerClass}`}
      >
        <div
          className="absolute inset-0 h-full w-full overflow-hidden md:hidden"
          style={{
            backgroundColor: image?.imageMobile?.src
              ? 'var(--off-white)'
              : section?.bgColor,
          }}
        >
          {image?.imageMobile?.src && (
            <Image
              alt={image.alt}
              className={`${image.positionMobile}`}
              fill
              priority
              sizes="100vw"
              src={image.imageMobile.src}
            />
          )}
        </div>

        <div
          className="absolute inset-0 hidden h-full w-full overflow-hidden md:block"
          style={{
            backgroundColor: image?.imageDesktop?.src
              ? 'var(--off-white)'
              : section?.bgColor,
          }}
        >
          {image?.imageDesktop?.src && (
            <Image
              alt={image.alt}
              className={`${image.positionDesktop}`}
              fill
              priority
              sizes="100vw"
              src={image.imageDesktop.src}
            />
          )}
        </div>

        <CollectionHeroContent content={content} text={text} />
      </div>
    </div>
  );
}

CollectionHero.displayName = 'CollectionHero';
CollectionHero.Schema = Schema;
