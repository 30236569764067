import { useMemo } from 'react';
import {
  useCartCount,
  useCartTotals,
  useSettings,
  useCountry,
} from '@backpackjs/storefront';

export function FreeShippingMeter({routeProduct}) {
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const settings = useSettings();
  const { country } = useCountry();
  const isUS = country?.isoCode === 'US';
  const {
    enabled,
    minCartSpend,
    progressBarColor,
    progressMessage,
    qualifiedMessage,
  } = { ...settings?.cart?.freeShipping };
  const showMeter = enabled;
  
  const routePrice = routeProduct ? routeProduct?.estimatedCost?.subtotalAmount?.amount : null
  const progress = useMemo(() => {
    if (!showMeter) return null;

    const total = (parseFloat(cartTotals.total) - (routePrice ? parseFloat(routePrice) : 0));
    const fraction = total / minCartSpend;
    const remainder = minCartSpend - total;

    if (fraction >= 1) {
      return {
        percent: 100,
        message: qualifiedMessage,
      };
    }

    return {
      percent: fraction * 100,
      message: progressMessage?.replace(
        '{{amount}}',
        `$${remainder.toFixed(2).replace('.00', '')}`
      ),
    };
  }, [
    cartTotals.total,
    minCartSpend,
    progressMessage,
    qualifiedMessage,
    showMeter,
  ]);

  return showMeter && isUS ? (
    <div className="border-b border-b-border pt-4">
      <div className="mb-3 flex items-center justify-center">
        <img
          alt="delivery-icon"
          src="/icons/delivery-icon.png"
          height="16"
          width="16"
          className="mr-[5px]"
        />
        <p className="text-center text-xs font-bold">{progress.message}</p>
      </div>

      <div className="h-[5px] w-full overflow-hidden bg-lightGray">
        <div
          className="h-full w-full origin-left transition"
          style={{
            transform: `scaleX(${progress.percent}%)`,
            backgroundColor: progressBarColor,
          }}
        />
      </div>
    </div>
  ) : null;
}

FreeShippingMeter.displayName = 'FreeShippingMeter';
