import { Fragment, useEffect, useState, useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { Select } from '../Select';
import { SearchItem } from './SearchItem';
import { useDataLayerActions, useColorSwatches } from '../../hooks';
import { ProductItem } from '../ProductItem';
import { SearchFilters } from './SearchFilters';
import { SearchMobileFilters } from './SearchMobileFilters';
import { useGlobalContext } from '../../contexts';

export function SearchResults({
  closeSearch,
  collectionResults,
  productResults,
  pagesResults,
  filtersResults,
  activeFilters,
  addFilter,
  removeFilter,
  changeSort,
  searchProductsNext,
  rawTerm,
  trackOnClick,
  trackOnClickFilter,
}) {
  const settings = useSettings();
  const { sendViewSearchResultsEvent } = useDataLayerActions();
  const { swatchesMap } = useColorSwatches();

  const { productsEnabled, collectionsEnabled } = {
    ...settings?.search?.results,
  };

  const pagesEnabled = true;

  const { pagination, productItem } = { ...settings?.collection };
  const [maxResults, setMaxResults] = useState(16);
  const [showAllPages, setShowAllPages] = useState(false);
  const [showAllCollections, setShowAllCollections] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState({});

  const filtersEnabled = true;
  const hasFilters = !!(
    filtersEnabled &&
    filtersResults &&
    filtersResults.length
  );

  const [isSideBarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: productResults,
    });
  }, [productResults, sendViewSearchResultsEvent]);

  useEffect(() => {
    setMaxResults(12);
  }, [rawTerm]);

  const options = [
    {
      value: 'relevance',
      label: 'Relevance',
    },
    {
      value: 'low-high',
      label: 'Price Low-High',
    },
    {
      value: 'high-low',
      label: 'Price High-Low',
    },
  ];

  const {
    state: { loopSelected }
  } = useGlobalContext();

  return (
    <div className="scrollbar-hide relative flex flex-1 flex-col gap-4 overflow-y-auto px-4 py-4 pb-8">
      {pagesEnabled && pagesResults?.length > 0 && (
        <div className="mb-0 px-0 md:px-4">
          <div className="flex justify-between">
            <h3 className="h5 mb-3">Pages:</h3>

            {pagesResults.length > 3 && (
              <div className="cursor-pointer">
                {showAllPages ? (
                  <p
                    className="h5"
                    onClick={() => {
                      setShowAllPages(false);
                    }}
                  >
                    See Less
                  </p>
                ) : (
                  <p
                    className="h5"
                    onClick={() => {
                      setShowAllPages(true);
                    }}
                  >
                    See All
                  </p>
                )}
              </div>
            )}
          </div>

          <ul className="items-start gap-3 max-md:flex max-md:flex-row max-md:overflow-x-scroll md:grid md:grid-cols-3">
            {pagesResults
              ?.slice(0, showAllPages ? 99 : 3)
              ?.map((item, index) => {
                return (
                  <li key={index} className="rounded bg-border p-3 md:p-[20px]">
                    <Link
                      aria-label={item.title}
                      href={`/pages/${item.handle}`}
                      onClick={() => trackOnClick(item, index, 'pages', 'Page Clicked')}
                    >
                      <p className="min-w-[30vw] whitespace-nowrap text-base font-bold">
                        {item.title}
                      </p>
                    </Link>

                    <p>Learn More</p>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      {collectionsEnabled && collectionResults?.length > 0 && (
        <div className="mb-0 px-0 md:px-4">
          <div className="flex justify-between">
            <h3 className="h5 mb-3">Collections:</h3>

            {collectionResults.length > 3 && (
              <div className="cursor-pointer">
                {showAllCollections ? (
                  <p
                    className="h5"
                    onClick={() => {
                      setShowAllCollections(false);
                    }}
                  >
                    See Less
                  </p>
                ) : (
                  <p
                    className="h5"
                    onClick={() => {
                      setShowAllCollections(true);
                    }}
                  >
                    See All
                  </p>
                )}
              </div>
            )}
          </div>

          <ul className="items-start gap-3 max-md:flex max-md:flex-row max-md:overflow-x-scroll md:grid md:grid-cols-3">
            {collectionResults
              ?.slice(0, showAllCollections ? 99 : 3)
              ?.map((item, index) => {
                return (
                  <li key={index} className="rounded bg-border p-3 md:p-[20px]">
                    <Link
                      aria-label={item.title}
                      href={`/collections/${item.handle}`}
                      onClick={() => trackOnClick(item, index, 'collections', 'Collection Clicked')}
                    >
                      <p className="min-w-[30vw] whitespace-nowrap text-base font-bold">
                        {item.title}
                      </p>
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      )}

      <div>
        <div className="md:flex md:justify-between">
          <h3 className="h5 px-0 py-3 md:px-4">
            Products:{' '}
            <span className="text-body md:text-base">
              Showing {productResults?.length} results
            </span>
          </h3>

          <div className="max-md:grid max-md:grid-cols-2 max-md:gap-2">
            <div
              className="h5 px-0 py-3 md:hidden"
              onClick={() => setIsSidebarOpen(true)}
            >
              Filters +
            </div>

            <Select
              onSelect={(option) => {
                changeSort(option.value);
                setSelectedSortOption(option);
              }}
              options={options}
              placeholder="Sort By"
              selectedOption={selectedSortOption}
              placeholderClass="text-text text-end w-full"
              additionalPlaceholderClass="h5 whitespace-nowrap"
              optionListClass="py-0 hover:[&_button]:bg-primary hover:[&_button]:text-white [&_button]:px-1 [&_button]:py-0.5"
            />
          </div>
        </div>

        <div
          className={`w-full ${
            hasFilters ? 'md:grid md:grid-cols-[1fr_4.5fr]' : ''
          }`}
        >
          <div>
            {hasFilters && (
              <SearchFilters
                filters={filtersResults}
                activeFilters={activeFilters}
                addFilter={addFilter}
                removeFilter={removeFilter}
                trackOnClick={trackOnClickFilter}
              />
            )}
          </div>

          {productsEnabled && productResults?.length > 0 && (
            <div>
              <ul className="mx-auto grid w-full grid-cols-2 gap-x-4 gap-y-12 px-0 md:grid-cols-4 md:gap-x-9 md:px-4" id="searchGrid">
                {productResults.slice(0, maxResults).map((product, index) => {
                  const key = product?.id || product?.handle || index;
                  const isHidden = !!(product?.handle === 'bylt-gift-card' && loopSelected)

                  return !isHidden && (
                    product && (
                      <Fragment key={key}>
                        <li key={`collection-tile-${key}`}>
                          <ProductItem
                            enabledColorNameOnHover={
                              productItem?.enabledColorNameOnHover
                            }
                            enabledColorSelector={
                              productItem?.enabledColorSelector
                            }
                            enabledStarRating={productItem?.enabledStarRating}
                            handle={product.handle}
                            index={index}
                            isSearchResults
                            product={product}
                            swatchesMap={swatchesMap}
                            onClick={() => trackOnClick(product, index, 'products', 'Product Clicked')}
                          />
                        </li>
                      </Fragment>
                    )
                  );
                })}
              </ul>

              {productResults?.length > maxResults && (
                <div className="flex justify-center pt-8">
                  <button
                    className="btn"
                    onClick={() => {
                      setMaxResults(100);
                      searchProductsNext();
                      setTimeout(() => {
                        document?.getElementById('searchGrid')?.focus()
                      }, 500);
                    }}
                  >
                    Show More
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <SearchMobileFilters
        activeFilters={activeFilters}
        addFilter={addFilter}
        clearFilters={() => {}}
        filters={filtersResults}
        removeFilter={removeFilter}
        isSideBarOpen={isSideBarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        trackOnClick={trackOnClickFilter}
      />
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
