import { useState } from 'react';
import { Link, Image, Markdown as MarkdownSnippet } from '../../snippets';
import { Schema } from './OutfitCustomizer.schema';
import { OutfitCustomizerOptions } from './OutfitCustomizerOptions';

export function OutfitCustomizer({ cms }) {
  const {
    buttons,
    centerAllText,
    content,
    instructionsLabel,
    instructions,
    sideImage,
    mainButtonLabel,
    mainButtonStyle,
    customizerOptions,
    section,
  } = cms;

  const markdownClasses = `[&_p]:text-sm [&_p]:leading-6 [&_p]:text-black [&_p]:tracking-wider [&_li]:pl-3 [&_li]:text-white [&_li]:text-[10px]  before:[&_li]:w-[0.9375rem] before:[&_li]:h-[0.9375rem] before:[&_li]:left-[5px] before:[&_li]:translate-y-[34%] before:[&_li]:rounded-full before:[&_li]:bg-primary before:[&_li]:content-[' '] before:[&_li]:absolute before:[&_li]:z-[-1]`;

  const [isCustomizing, setIsCustomizing] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState({});

  const variantSelectTrigger = (variant, index) => {
    let obj = { ...selectedVariants };
    obj[index] = variant;

    setSelectedVariants(obj);
  };

  return (
    <div className="px-contained py-contained">
      <div
        className={`mx-auto grid w-full grid-cols-1 gap-6 md:grid-cols-[55%_1fr] md:gap-20 ${section?.maxWidth}`}
      >
        <div className="relative w-full pt-[135%]">
          <Image alt="OC Image" className="" fill src={sideImage.src} />
        </div>

        {isCustomizing ? (
          <div className="relative w-full">
            <OutfitCustomizerOptions
              customizerOptions={customizerOptions}
              selectedVariants={selectedVariants}
              setSelectedVariantOption={(val, index) =>
                variantSelectTrigger(val, index)
              }
            />
          </div>
        ) : (
          <div className="relative w-full">
            <h1 className="h3 mb-5 uppercase">Outfit Customizer</h1>

            <div className="mb-10">
              <MarkdownSnippet
                centerAllText={centerAllText}
                customClasses={`${markdownClasses}`}
              >
                {content}
              </MarkdownSnippet>
            </div>

            <h2 className="h3 mb-5">{instructionsLabel}</h2>

            <div className="mb-10">
              <MarkdownSnippet
                centerAllText={centerAllText}
                customClasses={`${markdownClasses}`}
              >
                {instructions}
              </MarkdownSnippet>
            </div>

            <button
              className={`w-full text-sm md:text-[15px] ${mainButtonStyle}`}
              onClick={() => setIsCustomizing(true)}
            >
              {mainButtonLabel}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

OutfitCustomizer.displayName = 'OutfitCustomizer';
OutfitCustomizer.Schema = Schema;
