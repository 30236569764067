import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useState } from 'react';
import { Image, Link, ReviewStars, Svg } from '../../snippets';
import { Schema } from './TestimonialSlider.schema';

export function TestimonialSlider({ cms }) {
  const { heading, link, section, testimonialSlides: blocks } = { ...cms };
  const {
    bgColor,
    fullWidth,
    reviewStarColor,
    sliderPaginationBulletColor,
    textColor,
    reviewStar,
  } = {
    ...section,
  };
  const maxWidthClass = fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  const [swiper, setSwiper] = useState(null);

  return (
    <div
      className="md:px-contained py-12 lg:py-16"
      style={{ backgroundColor: bgColor }}
    >
      <div
        className={`${maxWidthClass} relative mx-auto flex flex-col items-center lg:px-14`}
      >
        <h2 className="px-4 text-center" style={{ color: textColor }}>
          {heading}
        </h2>

        {blocks?.length > 0 && (
          <Swiper
            className="!static mt-10 w-full"
            onSwiper={setSwiper}
            grabCursor
            loop
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true,
              renderBullet(_, className) {
                return `<span class="${className}" style="background-color: ${sliderPaginationBulletColor}"></span>`;
              },
            }}
            modules={[Pagination, Navigation]}
            slidesPerView={1}
            spaceBetween={16}
          >
            {blocks.map((item, index) => {
              const rating = item?.rating ? parseFloat(item.rating) : 5;

              return (
                <SwiperSlide key={index}>
                  <div
                    className="mx-auto flex max-w-lg flex-col items-center text-center "
                    style={{
                      color: textColor,
                    }}
                  >
                    {reviewStar && (
                      <ReviewStars rating={rating} color={reviewStarColor} />
                    )}
                    <h3 className="my-4">{item.title}</h3>

                    {item.body && <p>{item.body}</p>}

                    {item.author ||
                      (item.authorLogo &&
                        (!item.authorLogo ? (
                          <p className="mt-4 text-base font-normal">
                            {item.author}
                          </p>
                        ) : (
                          <Image
                            key={item?.alt}
                            alt={item?.alt}
                            className="relative h-[50px] w-auto"
                            height={50}
                            width={50}
                            src={item?.authorLogo?.src}
                          />
                        )))}
                  </div>
                </SwiperSlide>
              );
            })}

            <div className="swiper-pagination !static mt-6" />

            <button
              aria-label="See previous testimonial"
              type="button"
              className={`swiper-button-prev 'lg:!flex' left-0 !h-[3.5rem] !w-[3.5rem] rounded-full bg-white after:hidden`}
              onClick={() => swiper.slidePrev()}
            >
              <Svg
                className="w-[1.25rem] text-black"
                src="/svgs/arrow-left.svg#arrow-left"
                title="Arrow Left"
                viewBox="0 0 24 24"
              />
            </button>

            <button
              aria-label="See next testimonial"
              type="button"
              className="swiper-button-next right-0 !h-[3.5rem] !w-[3.5rem] rounded-full bg-white after:hidden lg:!flex"
              onClick={() => swiper.slideNext()}
            >
              <Svg
                className="w-[1.25rem] text-black"
                src="/svgs/arrow-right.svg#arrow-right"
                title="Arrow Right"
                viewBox="0 0 24 24"
              />
            </button>
          </Swiper>
        )}

        {link?.text && (
          <div className="mt-10">
            <Link
              aria-label={link.text}
              className="btn-primary"
              href={link.url}
              newTab={link.newTab}
              type={link.type}
            >
              {link.text}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

TestimonialSlider.displayName = 'TestimonialSlider';
TestimonialSlider.Schema = Schema;
