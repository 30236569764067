import { useMemo } from 'react';

import { Image } from '../../Image';
import { isLightHexColor } from '../../../utilities';
import { Svg } from '../../Svg';

export function CollectionFilterOption({
  activeFilters,
  addFilter,
  count,
  isColor,
  isIcon,
  name,
  removeFilter,
  showCount,
  swatchesMap,
  value,
  type,
  isGrid,
}) {
  const isActive = useMemo(() => {
    return Object.entries(activeFilters).some(([key, values]) => {
      return key === name && values.some((v) => v === value);
    });
  }, [activeFilters, value]);

  const originalFilterName = isIcon
    ? `${type.toLowerCase()}-${value.toLowerCase()}`
    : '';

  let swatch = null;
  let hasImage = false;

  if (isColor) {
    swatch = swatchesMap[value.toLowerCase().trim()];
    hasImage = swatch?.startsWith('http');
  }

  const checkmarkColor = useMemo(() => {
    if (!isColor) return 'text-white';
    if (!swatch) return 'text-black';
    return isLightHexColor(swatch) ? 'text-black' : 'text-white';
  }, [isColor, swatch]);

  const colorBackground = swatch || 'var(--off-white)';
  const nonColorBackground = isActive ? 'var(--black)' : 'var(--off-white)';
  const svgClasses = 'fill-[transparent] stroke-[10px] ';

  return (
    <button
      aria-label={`Add ${value} to filters`}
      className={`group flex items-center gap-3 text-left text-base transition max-lg:px-4 max-md:h-11 max-md:w-full max-md:items-center md:gap-2 hover:md:text-text ${
        isActive ? 'font-bold text-text' : 'text-black'
      } ${isGrid ? 'max-md:h-12' : 'max-md:h-11'}
      ${isGrid && isActive ? 'bg-[#d3d3d3]' : ''}`}
      onClick={() => {
        if (isActive) {
          removeFilter({ key: name, value });
        } else {
          addFilter({ key: name, value });
        }
      }}
      type="button"
    >
      {!isIcon ? (
        <div
          className={`relative flex h-5 w-5 items-center justify-center overflow-hidden rounded border border-border transition md:h-[18px] md:w-[18px] group-hover:md:border-text ${
            isActive ? 'border-text' : ''
          } ${isColor ? 'overflow-hidden rounded-full' : ''}`}
          style={{
            backgroundColor: isColor ? colorBackground : nonColorBackground,
          }}
        >
          {hasImage && (
            <Image
              alt={value}
              className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover ${
                isColor ? 'overflow-hidden rounded-full' : ''
              }`}
              height="24"
              width="24"
              src={swatch}
            />
          )}

          <div
            className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 border transition-[border-width] duration-100 ${
              isActive ? 'border-[0px] md:border-[0px]' : 'border-black'
            } ${isColor ? 'overflow-hidden rounded-full' : ''}`}
          />

          <Svg
            src="/svgs/checkmark.svg#checkmark"
            viewBox="0 0 24 24"
            className={`pointer-events-none w-6 transition md:w-5 ${checkmarkColor} ${
              isActive ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </div>
      ) : (
        <div
          className={`${
            isActive ? 'bg-primary' : ''
          } flex h-10 w-10 items-center justify-center rounded-full`}
        >
          <div className="z-10 flex h-[30.37px] w-[30.37px] shrink-0 items-center justify-center overflow-hidden rounded-full border-[0.5px] border-[gray]">
            <Svg
              src={`/svgs/noprecache/filters/${originalFilterName}.svg#Artwork`}
              viewBox="0 0 24 24"
              className={`pointer-events-none mx-auto w-10 shrink-0 ${svgClasses} ${
                isActive ? '[&_use]:stroke-[gray]' : '[&_use]:stroke-[black]'
              }`}
            />
          </div>
        </div>
      )}

      <p className="flex-1 text-sm capitalize">
        {value}{' '}
        <span className={`text-xs ${showCount ? 'inline' : 'hidden'}`}>
          ({count})
        </span>
      </p>
    </button>
  );
}

CollectionFilterOption.displayName = 'CollectionFilterOption';
