import { Schema } from './Divider.schema';

export function Divider({ cms }) {
  const { text, section } = cms;

  return (
    <div
      className={`mx-auto flex flex-row items-center gap-3 ${
        section?.maxWidth
      } ${section?.hasXPadding ? 'max-2xl:px-contained' : ''} ${
        section?.hasXPaddingMob ? 'max-lg:px-contained' : ''
      } ${section?.hasYPadding ? 'py-contained' : ''}`}
    >
      <h2 className="text-title-h4 w-fit self-center">{text}</h2>
      <hr className="my-8 flex-1 border-[1px] border-border" />
    </div>
  );
}

Divider.displayName = 'Divider';
Divider.Schema = Schema;
