import { Schema } from './DevSearchAlgoliaSettings.schema';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';
import algoliasearch from 'algoliasearch';

export function DevSearchAlgoliaSettings({ cms }) {
  const settings = useSettings();

  const searchSettings = { ...settings?.search };

  const searchClient = algoliasearch(
    'CD375UH922',
    '67df8190024381f894b64d1bdf0c1d7a'
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const buttonText = isLoading
    ? 'Updating Index...'
    : 'Sync Pages From Settings';

  const generatelocalPages = () => {
    setIsLoading(true);
    setIsSuccess(false);
    try {
      const pageResults = searchSettings?.indexes?.pageResults?.map((el) => {
        const pageHandle = el.url.split('/pages/')[1];
        return {
          title: el.text,
          type: el.type,
          handle: pageHandle,
          url: el.url,
          objectID: pageHandle,
        };
      });

      const index = searchClient.initIndex('byltsearch_pack_pages');

      if (searchSettings?.indexes?.pageResults) {
        index.saveObjects(pageResults).then(({ objectIDs }) => {
          setIsLoading(false);
          setIsSuccess(true);
        });
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-contained py-contained m-auto max-w-[1400px] border border-border">
      <h2 className="mb-5">Search Setting Section</h2>
      <h4 className="my-5">Algolia Page Index Options</h4>
      <p className="my-5 max-w-[500px]">
        This will let you sync define pages indexes from Site Settings / Search
        / Search Indexes setting, this will only add the pages to the algolia
        index but if you need to remove some one or update one you will need to
        do that on the algolia dashboard
      </p>

      {isSuccess && (
        <p className="my-5">The Index has been updated sucessfully</p>
      )}

      <div className="flex gap-4">
        <button className="btn-secondary" onClick={() => generatelocalPages()}>
          {buttonText}
        </button>

        <button className="btn-secondary hidden">
          Sync All Pages from Pack
        </button>
      </div>
    </div>
  );
}

DevSearchAlgoliaSettings.displayName = 'DevSearchAlgoliaSettings';
DevSearchAlgoliaSettings.Schema = Schema;
