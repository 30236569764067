import { useRef, useState } from 'react';
import { Link, ReviewStars, Svg } from '../../snippets';

export function ProductReviewsForm({ product }) {
  const [reviewRating, setReviewRating] = useState(0);
  const [facebookLink, setFacebookLink] = useState(null);
  const [twitterLink, setTwitterLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState(null);
  const [reviewImage, setReviewImage] = useState(null)

  const formRef = useRef(null);

  const customOptions = [
    {
      label: 'Size',
      ticks: ['Runs Small', 'True To Size', 'Runs Large'],
    },
    {
      label: 'Your Size In Tops',
      ticks: ['Small', 'Medium', 'Large', 'X-Large', 'XX-Large'],
    },
    {
      label: 'Your Size In Bottoms',
      ticks: ['Small', 'Medium', 'Large', 'X-Large', 'XX-Large'],
    },
    {
      label: 'Size',
      ticks: ['Thin', 'Normal', 'Athletic', 'Built', 'Round'],
    },
    {
      label: 'Size',
      ticks: ['Uncomfortable', 'Average', 'Very Comfortable'],
    },
  ];

  async function onSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    setError(null); // Clear previous errors when a new request starts

    try {
      const formData = new FormData(e.currentTarget);

      const endpoint = `https://stamped.io/api/reviews3?apiKey=pubkey-1X7z6vt3Qh6TR0xL50Qe63hmW381iT&sId=10618`;

      const response = await fetch(endpoint, {
        method: 'POST',
        'Content-Type': 'application/x-www-form-urlencoded',
        body: new URLSearchParams({
          productId: formData.get('productId'),
          author: formData.get('author'),
          email: formData.get('email'),
          reviewRating: formData.get('reviewRating'),
          reviewTitle: formData.get('reviewTitle'),
          reviewMessage: formData.get('reviewMessage'),
          'customFormOption-20660': formData.get('customFormOption-20660'),
          'customFormOption-20661': formData.get('customFormOption-20661'),
          'customFormOption-20662': formData.get('customFormOption-20662'),
          'customFormOption-20663': formData.get('customFormOption-20663'),
          'customFormOption-20664': formData.get('ormOption-20664'),
          reviewRecommendProduct: formData.get('reviewRecommendProduct'),
          productName: formData.get('productName'),
          productSKU: formData.get('productSKU'),
          productDescription: formData.get('productDescription'),
          productImageUrl: formData.get('productImageUrl'),
          productUrl: formData.get('productUrl'),
          reviewSource: 'api',
        }),
      });

      setFacebookLink(
        `https://www.facebook.com/dialog/feed?app_id=222664514734026&display=popup&link=https://stamped.io/facebookpost?caption=★${formData.get(
          'reviewRating'
        )} ${formData.get('author')}&name=★${formData.get(
          'reviewRating'
        )} ${formData.get('author')}&description=${formData.get(
          'author'
        )}&link=https%3A%2F%2Fbyltbasics.com%2Fproducts%2F${formData.get(
          'productUrl'
        )}&picture=${formData.get('productImageUrl')}&name=${formData.get(
          'author'
        )}&redirect_uri=https://stamped.io/shares?productId=4710060851302`
      );

      setTwitterLink(
        `https://twitter.com/intent/tweet?text=★${formData.get(
          'reviewRating'
        )} ${formData.get(
          'author'
        )}&url=https://byltbasics.com/products/drop-cut-shirt`
      );

      if (!response.ok) {
        throw new Error('Failed to submit the data. Please try again.');
      } else {
        setIsSuccessful(true);
      }
    } catch (err) {
      // Capture the error message to display to the user
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleFile = (e) => {
    setReviewImage(e.target.files[0])
  }

  return (
    <div className="mt-10 w-full max-md:px-5">
      {isSuccessful ? (
        <div className="flex flex-col items-center gap-4 border border-lightGray py-7 text-center">
          <p className="text-xl">Thank you for Submitting a review!</p>
          <p className="text-base">
            Your input is very much appreciated. Share it with your friends so
            they can enjoy it too!
          </p>
          <div className="flex gap-2.5">
            <Link
              className="flex w-fit rounded-none border border-[#ccc] bg-[#eee] px-[20px] py-[7px] text-base"
              href={facebookLink}
            >
              <Svg
                className="mr-3 w-4"
                src="/svgs/noprecache/social/facebook-alt.svg#facebook-alt"
                title="facebook"
                viewBox="0 0 24 24"
              />
              Facebook
            </Link>
            <Link
              className="flex w-fit rounded-none border border-[#ccc] bg-[#eee] px-[20px] py-[7px] text-base"
              href={twitterLink}
            >
              <Svg
                className="mr-3 w-4 text-black"
                src="/svgs/noprecache/social/twitter.svg#twitter"
                title="twitter"
                viewBox="0 0 24 24"
              />
              Twitter
            </Link>
          </div>
        </div>
      ) : (
        <form
          className="flex w-full flex-col gap-1 border border-lightGray p-4 md:px-8"
          onSubmit={onSubmit}
          ref={formRef}
        >
          <input
            type="hidden"
            value={product.legacyResourceId}
            name="productId"
          />
          <input type="hidden" value={product.title} name="productName" />
          <input type="hidden" value={product.handle} name="productSKU" />
          <input
            type="hidden"
            value={product.description}
            name="productDescription"
          />
          <input
            type="hidden"
            value={product.featuredImage?.src}
            name="productImageUrl"
          />
          <input
            type="hidden"
            value={process.env.SHOPIFY_DOMAIN + product.handle}
            name="productUrl"
          />

          <fieldset className="mb-2.5 flex gap-4 max-md:flex-col">
            <label className="flex flex-col gap-1" htmlFor="author">
              <span>Name</span>
              <input
                id="author"
                className="w-72 rounded-[0.25rem] border border-gray bg-lightGray p-1 max-md:w-full"
                type="text"
                name="author"
                placeholder="John Smith"
                autoComplete="name"
                required
              />
            </label>

            <label className="flex flex-col gap-1" htmlFor="email">
              <span>Email</span>
              <input
                id="email"
                className="w-72 rounded-[0.25rem] border border-gray bg-lightGray p-1 max-md:w-full"
                type="email"
                name="email"
                placeholder="john.smith@example.com"
                autoComplete="email"
                required
              />
            </label>
          </fieldset>

          <fieldset className="mb-2.5 flex gap-4">
            <label className="flex flex-col gap-1" htmlFor="email">
              <span>Rating</span>
              <input
                id="reviewRating"
                type="hidden"
                name="reviewRating"
                value={reviewRating}
                required
              />
              <ReviewStars
                rating={reviewRating}
                setRating={setReviewRating}
                size="xlarge"
              />
            </label>
          </fieldset>

          <fieldset className="mb-2.5 flex flex-col gap-4">
            <label className="flex flex-col gap-1" htmlFor="reviewTitle">
              <span>Title of Review</span>
              <input
                id="reviewTitle"
                className="w-full rounded-[0.25rem] border border-gray bg-lightGray p-1"
                type="text"
                name="reviewTitle"
                placeholder="Give your review a title"
                autoComplete="reviewTitle"
                required
              />
            </label>

            <label className="flex flex-col gap-1" htmlFor="email">
              <span>How was your overall experience?</span>
              <textarea
                name="reviewMessage"
                className="w-full rounded-[0.25rem] border border-gray bg-lightGray p-1"
                id="reviewMessage"
                cols="30"
                rows="10"
                required
              />
            </label>
          </fieldset>

          <fieldset className="mb-2.5 flex w-full flex-col gap-4 md:w-[35%]">
            {[...Array(5).keys()].map((i) => (
              <label
                className="mb-4 flex flex-col gap-1"
                htmlFor={`customFormOption-2066${i}`}
                key={`customFormOption-2066${i}`}
              >
                <span>{customOptions[i].label}</span>
                <input
                  id={`customFormOption-2066${i}`}
                  type="range"
                  min={1}
                  max={5}
                  name={`customFormOption-2066${i}`}
                  className="reviews-input-range"
                />

                <div className="pointer-events-none z-[1] mt-[-14px] flex w-full justify-between">
                  {customOptions[i].ticks.map((tick, index) => (
                    <div
                      className="relative flex flex-col justify-center text-center"
                      key={index}
                    >
                      <div className="reviews-input-track" />

                      <small
                        key={tick}
                        className={`absolute bottom-[-20px] whitespace-nowrap text-[12.8px] ${
                          index === 0 ||
                          index === customOptions[i].ticks.length - 1
                            ? ''
                            : 'left-[50%] translate-x-[-50%]'
                        } ${
                          index === customOptions[i].ticks.length - 1
                            ? 'left-[50%] translate-x-[-90%]'
                            : ''
                        }`}
                      >
                        {tick}
                      </small>
                    </div>
                  ))}
                </div>
              </label>
            ))}
          </fieldset>

          <fieldset className="mb-2.5 flex flex-col gap-4">
            <label className="flex flex-col gap-1" htmlFor="reviewTitle">
              <span>Do you recommend this product?</span>
              <div className="flex gap-2 align-middle">
                <input
                  type="radio"
                  name="reviewRecommendProduct"
                  value="true"
                />
                Yes
              </div>
              <div className="flex gap-2 align-middle">
                <input
                  type="radio"
                  name="reviewRecommendProduct"
                  value="false"
                />
                No
              </div>
            </label>
          </fieldset>

          <div className="mb-[30px] flex flex-row gap-3 max-md:mt-[10px] max-md:flex-col max-md:justify-end md:justify-end">
            <div className="btn-inverse-black relative w-fit cursor-pointer px-[20px] py-[8px] max-md:min-w-full">
              <input
                type="file"
                name="file[filename]"
                className="absolute top-0 h-full w-full cursor-pointer opacity-0"
                accept="image/*"
                onChange={(e) => handleFile(e)}
              ></input>
              <Svg
                className="mr-2 w-4"
                src="/svgs/camera.svg#camera"
                title="Write"
                viewBox="0 0 24 24"
              />
              <span>{reviewImage ? 'Photo Added' : 'Add Photos'}</span>
            </div>

            <button
              type="submit"
              className="btn-black w-fit px-[20px] py-[8px] max-md:min-w-full"
            >
              {isLoading ? 'Loading...' : 'Submit Your Review'}
            </button>
          </div>
        </form>
      )}
      {error && <div className="text-red-700">{error}</div>}
    </div>
  );
}
