import { COLORS } from '../../settings/common';

export function Schema() {
  return {
    category: 'Text',
    label: 'Accordions with Tabs',
    key: 'AccordionsTabs',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/accordions-tabs.png?v=1705337082',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Accordions Heading',
      },
      {
        label: 'Tabs',
        name: 'tabs',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            component: 'image',
            name: 'image',
            label: 'Tab Image',
          },
          {
            label: 'Default Selected',
            name: 'defaultSelected',
            component: 'toggle',
            description: 'Sets tab to be selected by default',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Accordions',
            name: 'accordions',
            component: 'group-list',
            itemProps: {
              label: '{{item.header}}',
            },
            fields: [
              {
                label: 'Header',
                name: 'header',
                component: 'text',
              },
              {
                label: 'Body',
                name: 'body',
                component: 'markdown',
              }
            ],
            defaultValue: [
              {
                header: 'Excepteur sint occaecat cupidatat non proident?',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              },
            ],
            defaultItem: {
              header: 'Excepteur sint occaecat cupidatat non proident?',
              body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            },
          },
        ],
        defaultValue: [
          {
            heading: 'Excepteur sint occaecat cupidatat non proident?',
            defaultSelected: false,
          },
        ],
        defaultItem: {
          heading: 'Excepteur sint occaecat cupidatat non proident?',
          defaultSelected: false,
        },
      },
      {
        label: 'Accordion Header Background Color',
        name: 'headerBgColor',
        component: 'select',
        options: COLORS,
        defaultValue: 'var(--off-white)',
      },
      {
        label: 'Accordion Header Text Color',
        name: 'headerTextColor',
        component: 'select',
        options: COLORS,
        defaultValue: 'var(--text)',
      },
    ],
  };
}
