import { useCallback, useEffect, useState } from 'react';
import { ColorVariantOption } from './ColorVariantOption';
import { Link } from '../../../snippets';
import { useSettings } from '@backpackjs/storefront';

export function ColorVariantOptions({
  enabledColorNameOnHover,
  initialProduct,
  selectedVariant,
  setVariantFromColorSelector,
  swatchesMap,
  productUrl,
}) {
  const [variantMapByColor, setVariantMapByColor] = useState(null);

  const colorOptions = initialProduct?.grouping?.isTransformed
    ? initialProduct.grouping.optionsMap?.Color
    : initialProduct.optionsMap?.Color;

  const generateVariantMapByColor = useCallback(() => {
    setVariantMapByColor(
      initialProduct.variants?.reduce((acc, variant) => {
        const variantColor =
          variant.selectedOptionsMap?.Color ||
          variant.selectedOptions[0]?.value;
        if (acc[variantColor]) return acc;
        return {
          ...acc,
          [variantColor]: variant,
        };
      }, {})
    );
  }, [initialProduct]);

  const handleOptionClick = useCallback(
    (color) => {
      setVariantFromColorSelector(variantMapByColor?.[color]);
    },
    [initialProduct, variantMapByColor]
  );

  useEffect(() => {
    generateVariantMapByColor();
  }, [initialProduct]);

  const isGrouped = initialProduct?.grouping?.isTransformed
  const settings = useSettings();
  const isColorReductionEnabled =
    settings?.collection?.productItem?.isColorReduction || false;

  const desktopBaseCount = 6;
  const mobileBaseCound = 4;

  const desktopHiddenCount = colorOptions?.length - desktopBaseCount;
  const mobileHiddenCount = colorOptions?.length - mobileBaseCound;

  const colorSwatchesSytles = isColorReductionEnabled ? 'max-lg:[&>*:nth-child(n+5)]:hidden lg:[&>*:nth-child(n+7)]:hidden' : ''

  return (
    <ul className={`flex flex-wrap gap-1 ${colorSwatchesSytles}`}>
      {colorOptions.map((color, index) => {
        return (
          <li key={index}>
            <ColorVariantOption
              color={color}
              enabledColorNameOnHover={enabledColorNameOnHover}
              onClick={() => handleOptionClick(color)}
              selectedVariant={selectedVariant}
              swatchesMap={swatchesMap}
              initialVariants={initialProduct?.variants}
              isGrouped={isGrouped}
            />
          </li>
        );
      })}

      {isColorReductionEnabled && (
        <li className="!flex items-center">
          {colorOptions?.length > desktopBaseCount && (
            <Link aria-label={initialProduct?.title} href={productUrl}>
              <p className="hidden text-[11px] leading-none text-[#444447] lg:block hover:underline">
                +{desktopHiddenCount} more
              </p>
            </Link>
          )}

          {colorOptions?.length > mobileBaseCound && (
            <Link aria-label={initialProduct?.title} href={productUrl}>
              <p className="text-[11px] leading-none text-[#444447] lg:hidden ">
                +{mobileHiddenCount} more
              </p>
            </Link>
          )}
        </li>
      )}
    </ul>
  );
}

ColorVariantOptions.displayName = 'ColorVariantOptions';
