import { Accordions } from './Accordions';
import { AccordionsTabs } from './AccordionsTabs';
import { ArticleHero } from './ArticleHero';
import { BlogCategories } from './BlogCategories';
import { BlogGrid } from './BlogGrid';
import { Collection } from './Collection';
import { CollectionHero } from './CollectionHero';
import { CollectionSlider } from './CollectionSlider';
import { CollectionTiles } from './CollectionTiles';
import { Divider } from './Divider/Divider';
import { FormBuilder } from './FormBuilder';
import { HalfHero } from './HalfHero';
import { Hero } from './Hero';
import { Html } from './Html';
import { IconRow } from './IconRow';
import { Image } from './Image';
import { ImageGrid } from './ImageGrid/ImageGrid';
import { ImageTiles } from './ImageTiles';
import { LeftPaneNavigation } from './LeftPaneNavigation';
import { Markdown } from './Markdown';
import { PressSlider } from './PressSlider';
import { ProductCompare } from './ProductCompare';
import { ProductDetailAccordions } from './ProductDetailAccordions';
import { ProductHalfHero } from './ProductHalfHero';
import { ProductReviews } from './ProductReviews';
import { ProductsSlider } from './ProductsSlider';
import { SearchResults } from './SearchResults';
import { ShoppableInstagram } from './ShoppableInstagram';
import { SocialImagesGrid } from './SocialImagesGrid';
import { TabbedThreeTiles } from './TabbedThreeTiles';
import { TestimonialSlider } from './TestimonialSlider';
import { TextBlock } from './TextBlock';
import { ThreeTiles } from './ThreeTiles';
import { TwoTiles } from './TwoTiles';
import { Video } from './Video';
import { VideoEmbed } from './VideoEmbed';
import { YotpoReward } from './YotpoReward';
import { CollectionLinkFilters } from './CollectionLinkFilters';
import { SubCollectionSlider } from './SubCollectionSlider';
import { OutfitCustomizer } from './OutfitCustomizer';
import { ContactFormBanner } from './ContactFormBanner';
import { OneTrustCookieList } from './OneTrustCookieList';
import { TrackingOrder } from './TrackingOrder'
import { FabricFitSlider } from './FabricFitSlider'
import { CollectionToggleButtons } from './CollectionToggleButtons'
import { TwoTilesWithLogo } from './TwoTilesWithLogo';
import { ProductDetailTabs } from './ProductDetailTabs';
import { DevSearchAlgoliaSettings } from './DevSearchAlgoliaSettings'

export default [
  Accordions,
  AccordionsTabs,
  ArticleHero,
  BlogCategories,
  BlogGrid,
  Collection,
  CollectionHero,
  CollectionSlider,
  CollectionTiles,
  Divider,
  FormBuilder,
  HalfHero,
  Hero,
  Html,
  IconRow,
  Image,
  ImageGrid,
  ImageTiles,
  LeftPaneNavigation,
  Markdown,
  PressSlider,
  ProductCompare,
  ProductDetailAccordions,
  ProductHalfHero,
  ProductReviews,
  ProductsSlider,
  SearchResults,
  ShoppableInstagram,
  SocialImagesGrid,
  TabbedThreeTiles,
  TestimonialSlider,
  TextBlock,
  ThreeTiles,
  TwoTiles,
  Video,
  VideoEmbed,
  YotpoReward,
  CollectionLinkFilters,
  SubCollectionSlider,
  OutfitCustomizer,
  ContactFormBanner,
  OneTrustCookieList,
  TrackingOrder,
  FabricFitSlider,
  CollectionToggleButtons,
  TwoTilesWithLogo,
  ProductDetailTabs,
  DevSearchAlgoliaSettings,
];
