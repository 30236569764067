import Script from 'next/script';
import { useCustomer } from '@backpackjs/storefront';
import { Schema } from './YotpoReward.schema';

export function YotpoReward({ cms }) {
  const { content, section } = cms;

  const customer = useCustomer();
  const customerId = customer
    ? customer?.id?.split('gid://shopify/Customer/')[1]
    : '';

  return (
    <>
      <div
        className={`${section?.hasXPadding ? 'px-contained' : ''} ${
          section?.hasYPadding ? 'py-contained' : ''
        } ${section?.maxWidth} m-auto`}
      >
        {content?.isTitleEnabled && (
          <h2 className="mx-[43px] text-lg font-bold leading-[32.73px] lg:text-[28px]">
            BYLT REWARDS
          </h2>
        )}

        <div>
          {customer && (
            <>
              <div
                id="swell-customer-identification"
                data-authenticated="true"
                data-id={customerId}
                data-email={customer?.email}
                data-tags={JSON.stringify(customer?.tags)}
                className="hidden"
              />
              <div
                id="yotpo-customer-identification"
                data-authenticated="true"
                data-id={customerId}
                data-email={customer?.email}
                data-tags={JSON.stringify(customer?.tags)}
                className="hidden"
              />
            </>
          )}

          <Script
            src="https://cdn-widgetsrepository.yotpo.com/v1/loader/gbVBjzv4w4JLCRzsVK6QWg"
            strategy="lazyOnload"
          />

          <div
            className="yotpo-widget-instance"
            data-yotpo-instance-id="35512"
          ></div>
        </div>
      </div>
    </>
  );
}

YotpoReward.displayName = 'YotpoReward';
YotpoReward.Schema = Schema;
