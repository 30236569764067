import { VERTICAL_PADDING } from "../../settings/common";

export function Schema({ blog }) {
  if (!blog) return null;

  return {
    category: 'Blog',
    label: 'Blog Grid',
    key: 'blog-grid',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/blog-preview.jpg?v=1675732824',
    fields: [
      {
        label: 'Articles List',
        name: 'articles',
        description: 'This is an optiona field, if this is used the default article list will be replaced with this one.',
        component: 'group-list',
        itemProps: {
          label: '{{item.title}}',
        },
        fields: [
          {
            label: 'Article Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Title',
            name: 'title',
            component: 'text',
            description: 'Title of the article',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Author',
            name: 'author',
            component: 'text',
          },
          {
            label: 'Excerpt',
            name: 'excerpt',
            component: 'text',
          },
        ]
      },
      {
        component: 'select',
        name: 'desktopColumns',
        label: 'Desktop Columns',
        options: [
          {
            label: '2',
            value: 'md:grid-cols-2'
          },
          {
            label: '3',
            value: 'md:grid-cols-3'
          },
          {
            label: '4',
            value: 'md:grid-cols-4'
          },
          {
            label: '5',
            value: 'md:grid-cols-5'
          }
        ],
        defaultValue: 'md:grid-cols-3',
      },
      {
        label: 'Vertical Padding Desktop',
        name: 'verticalPaddingLevel',
        component: 'select',
        options: VERTICAL_PADDING.desktop,
        defaultValue: 'md:py-contained',
      },
      {
        label: 'Vertical Padding Mobile',
        name: 'verticalPaddingLevelMobile',
        component: 'select',
        options: VERTICAL_PADDING.mobile,
        defaultValue: 'max-md:py-contained',
      },
    ],
  };
}
