import {
  useCartItems,
  useSettings,
  useCountry,
  useCartAddItem,
} from '@backpackjs/storefront';

import { CartEmpty } from './CartEmpty';
import { CartHeader } from './CartHeader';
import { CartItem } from './CartItem';
import { CartItemKit } from './CartItemKit';
import { CartTotals } from './CartTotals';
import { CartUpsell } from './CartUpsell';
import { FreeShippingMeter } from './FreeShippingMeter';
import { useGlobalContext } from '../../contexts';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export function Cart() {
  const cartItems = useCartItems();
  const settings = useSettings();
  const {
    state: { cartOpen, loopSelected },
    actions: { closeCart, toggleLoop, enableLoop },
  } = useGlobalContext();
  const { country } = useCountry();
  const isUS = country?.isoCode === 'US';

  const enabledUpsell = settings?.cart?.upsell?.enabled && isUS;

  const routeProduct = cartItems?.find(
    (item) => item?.variant?.product?.handle === 'routeins'
  );

  const hasItems = routeProduct
    ? cartItems?.length > 1
      ? true
      : false
    : cartItems?.length > 0
    ? true
    : false;

  const [loopCredit, setLoopCredit] = useState(null);
  const [loopCurrency, setLoopCurrency] = useState(null);

  useEffect(() => {
    const savedParam = localStorage.getItem('loopOnstoreParams');

    if (savedParam) {
      const parsedParams = JSON.parse(savedParam);

      const creditInDollars = (parsedParams?.loop_total / 100).toFixed(2);
      setLoopCredit(creditInDollars);
      setLoopCurrency(parsedParams?.loop_currency || 'USD');
      if(cartOpen){
        enableLoop();
      }
    }
  }, [cartOpen]);

  const hasLoop = !!(loopCredit && cartItems?.length && hasItems);

  const { cartAddItem } = useCartAddItem();
  const { cashback } = { ...settings?.apps };
  const cashbackProduct = cashback?.cashbackProduct;
  const router = useRouter();

  const validateCashback = async (items) => {
    const type = cashback?.promoType;
    const hasCashback = items.find(
      (el) => el.variant.product.id === cashbackProduct.id
    );
    const lineItemId = `gid://shopify/ProductVariant/${cashback?.variantId}`;

    try {
      if (type === 'site') {
        if (!hasCashback) {
          await cartAddItem({
            merchandiseId: lineItemId,
            quantity: 1,
            attributes: [],
          });
        }
      } else {
        if (router?.query?.cashback === cashback?.utmParam && !hasCashback) {
          await cartAddItem({
            merchandiseId: lineItemId,
            quantity: 1,
            attributes: [],
          });
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (cashback?.enabled && cashbackProduct) {
      validateCashback(cartItems);
    }
  }, [cartItems, cashback, cartOpen]);

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full flex-col justify-between overflow-hidden bg-background transition md:max-w-[var(--sidebar-width)] ${
        cartOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      }`}
    >
      {cartOpen && (
        <>
          <CartHeader closeCart={closeCart} heading={settings?.cart?.heading} />

          <FreeShippingMeter routeProduct={routeProduct} />

          <ul className="scrollbar-hide relative flex-1 overflow-y-auto bg-[var(--light-gray)]">
            {cartItems?.length && hasItems ? (
              cartItems.map((item) => {
                const isRoute = item?.variant?.product?.handle === 'routeins';
                const isKit = item?.attributes?.find(
                  (el) => el.key === '_isKit'
                );
                const isKitFirst =
                  isKit &&
                  item?.attributes?.find((el) => el.key === '_kitFirst');
                const familtyID = item?.attributes?.find(
                  (el) => el.key === '_familyID'
                );

                let childIDs = [];
                let childsData = [];

                if (isKitFirst && !familtyID) {
                  const firstProduct = item?.attributes?.find(
                    (el) => el.key === '_kitFirst'
                  )
                    ? item
                    : null;

                  childIDs = cartItems
                    .filter(
                      (items) =>
                        items.attributes.some((el) => el.key === '_isKit') &&
                        items.attributes.some(
                          (el) => el.value === firstProduct.attributes[1]?.value
                        )
                    )
                    ?.map((child) => {
                      return child.id;
                    });
                } else if (isKitFirst && familtyID) {
                  const childs = cartItems?.filter((item) => {
                    const itemFamiltyID = item.attributes.find(
                      (el) => el.key === '_familyID'
                    );
                    return !!(itemFamiltyID?.value === familtyID.value);
                  });

                  childIDs = childs?.map((child) => {
                    return child.id;
                  });

                  childsData = childs?.map((child) => {
                    return child.variant;
                  });
                }

                let isHidden = isKit ? (isKitFirst ? '' : 'hidden') : '';
                isHidden = isRoute ? 'hidden' : isHidden;

                const isCashback =
                  item.variant.product.id === cashbackProduct?.id;
                const isMinimized = isCashback; //This can be used in a future to other cart items with similar behavior.

                return (
                  <li
                    key={item.id}
                    className={`border-b border-b-border bg-white ${isHidden}`}
                  >
                    {!!(isKit && !isHidden) && (
                      <CartItemKit
                        item={item}
                        childIDs={childIDs}
                        closeCart={closeCart}
                        childsData={childsData}
                      />
                    )}

                    {!isKit && (
                      <CartItem
                        item={item}
                        closeCart={closeCart}
                        isMinimized={isMinimized}
                        isCashback={isCashback}
                      />
                    )}
                  </li>
                );
              })
            ) : (
              <CartEmpty closeCart={closeCart} />
            )}
          </ul>

          {enabledUpsell && (
            <CartUpsell closeCart={closeCart} routeProduct={routeProduct} />
          )}

          {hasLoop && (
            <div className="p-4 flex justify-between border border-border rounded items-center">
              <p className="font-bold">BYLT Credit: {loopCredit} {loopCurrency}</p>

              <button
                className={`text-centered rounded-full border border-primary  p-[.4rem_.725rem] text-xs ${
                  loopSelected
                    ? 'bg-primary text-white'
                    : 'bg-white text-primary'
                }`}
                onClick={() => toggleLoop()}
              >
                {loopSelected ? 'X Remove' : 'Use credit'}
              </button>
            </div>
          )}

          <CartTotals routeProduct={routeProduct} useLoop={loopSelected} />
        </>
      )}
    </aside>
  );
}

Cart.displayName = 'Cart';
