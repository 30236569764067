import { useSettings } from '@backpackjs/storefront';
import { CollectionFiltersSummary } from './CollectionFiltersSummary';

export function CollectionFiltersChips({
  collectionFiltersData,
}) {
  const settings = useSettings();
  const { sticky } = {
    ...settings?.collection?.filters,
  };
  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;
  const stickyTopClass = stickyPromobar
    ? 'md:top-[calc(var(--header-height)+var(--promobar-height)+3.5rem)]'
    : 'md:top-[calc(var(--header-height)+3.5rem)]';
  const {
    state: { activeFilters, filters, filtersMap },
    actions: { addFilter, removeFilter, clearFilters },
  } = collectionFiltersData;

  return (
    <div
      className={`flex flex-col gap-5 max-lg:hidden lg:sticky ${
        sticky ? stickyTopClass : ''
      }`}
    >
      {/* desktop */}
      <div className="hidden lg:block">
        <CollectionFiltersSummary
          activeFilters={activeFilters}
          clearFilters={clearFilters}
          filtersMap={filtersMap}
          removeFilter={removeFilter}
        />
      </div>
    </div>
  );
}

CollectionFiltersChips.displayName = 'CollectionFilters';
