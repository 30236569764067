import {
  useCartAddDiscountUrl,
  useStorefrontInit,
} from '@backpackjs/storefront';

import {
  Cart,
  DataLayer,
  DataLayerWithElevar,
  Footer,
  Header,
  Modal,
  Overlay,
  SchemaMarkup,
  Search,
  StorefrontHead,
  AppLoader,
  NostoLayer,
  GlobalE,
  LoopCreditBar,
} from '../snippets';
import { GlobalContextProvider } from '../contexts';
import {
  usePromobar,
  useScrollRestoration,
  useSetViewportHeightCssVar,
  useShopifyAnalytics,
  useThemeColors,
} from '../hooks';

function StorefrontLayout({ ContentForLayout, ...props }) {
  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // main padding top w/ or w/o promobar
  const { mainClassName } = usePromobar();

  // set css var for viewport height
  useSetViewportHeightCssVar();

  // set css vars for theme colors
  useThemeColors();

  // restore scroll position on page back
  useScrollRestoration();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  return (
    <GlobalContextProvider>
      <StorefrontHead />

      {/* for Elevar integration use DataLayerWithElevar instead */}
      <DataLayerWithElevar {...props} />
      
      <NostoLayer {...props} />

      <GlobalE {...props} />

      <SchemaMarkup {...props} />

      <Header />

      <main className={mainClassName}>
        <ContentForLayout {...props} />
      </main>

      <LoopCreditBar />

      <Footer />

      <Overlay />

      <Cart />

      <Search />

      <Modal />

      <AppLoader />
    </GlobalContextProvider>
  );
}

export default StorefrontLayout;
