import { useMemo } from 'react';
import equal from 'fast-deep-equal';

import { ColorOptionIcon } from './ColorOptionIcon';
import { useRouter } from 'next/router';

export function ColorOptionButton({
  inventory,
  isLoading,
  isSelected,
  newSelectedOptions,
  product,
  setSelectedOptions,
  swatch,
  value,
  isCustom,
  customIndex,
  isSizeSelected = true,
  isGroup,
  fallbackChildProduct,
  groupedVariants = [],
}) {
  const variantFromOptionValue = useMemo(() => {
    if (!newSelectedOptions) return null;
    return product.variants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
  }, [newSelectedOptions, product.variants]);

  const familyVariants = !isSizeSelected
    ? isGroup
      ? groupedVariants?.filter((el) => el.selectedOptionsMap.Color === value)
      : product?.variants?.filter((el) => el.selectedOptionsMap.Color === value)
    : [];

  const hasFamilyAvailable = !isSizeSelected
    ? isGroup
      ? familyVariants?.some(
          (el) => el.availableForSale === true
        )
      : familyVariants?.some(
          (el) => inventory?.variants?.[el.id]?.availableForSale === true
        )
    : true;

  const optionValueIsAvailable = isSizeSelected
    ? inventory?.variants?.[variantFromOptionValue?.id]?.availableForSale ||
      false
    : hasFamilyAvailable;

  const disabled = isLoading || !variantFromOptionValue;

  const disabledByGroup = isGroup && disabled;

  const customHiddenClass =
    isCustom && !optionValueIsAvailable ? 'custom-hidden' : '';

  const router = useRouter();

  return (
    <button
      aria-label={value}
      className={`group/color relative ${customHiddenClass}`}
      disabled={isGroup ? false : disabled}
      onClick={() => {
        if (isSelected) return;

        if (disabledByGroup && fallbackChildProduct) {
          const variant = fallbackChildProduct?.variants?.find(
            (el) => el.selectedOptionsMap.Color === value
          );
          if (variant) {
            const variantId = variant?.id?.split(
              'gid://shopify/ProductVariant/'
            )[1];
            router.push(
              `/products/${product.grouping.products[1]}?variant=${variantId}`
            );
          }
        }

        if (isCustom) {
          setSelectedOptions(customIndex, value, newSelectedOptions);
        } else {
          setSelectedOptions(newSelectedOptions);
        }
      }}
      type="button"
    >
      <ColorOptionIcon
        disabled={isGroup ? false : disabled}
        isUnavailable={!optionValueIsAvailable && !disabled}
        isSelected={isSelected}
        swatch={swatch}
        value={value}
      />
      <span className="after pointer-events-none absolute bottom-[calc(100%+10px)] left-[50%] hidden translate-x-[-50%] whitespace-nowrap bg-primary px-[10px] py-[5px] text-xs leading-[14px] text-white opacity-0 transition duration-75 md:block group-hover/color:md:opacity-100">
        {value}
      </span>
      <span className="absolute bottom-[calc(100%+2px)] left-[50%] hidden h-0 translate-x-[-50%] border-l-[0.625rem] border-r-[0.625rem] border-t-[0.625rem] border-solid border-l-transparent border-r-transparent border-t-primary opacity-0 transition duration-75 md:block group-hover/color:md:opacity-100" />
    </button>
  );
}

ColorOptionButton.displayName = 'ColorOptionButton';
