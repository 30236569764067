import { useEffect, useCallback, useRef, useState } from 'react';
import { useCart, useCartCount, useCartItems } from '@backpackjs/storefront';
import { nanoid } from 'nanoid';

import { mapLineItem } from './utils';
import { useGlobalContext } from '../../../contexts';

export function useDataLayerCart({
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const cart = useCart();

  const {
    state: { cartOpen },
  } = useGlobalContext();



  const viewCartEvent = useCallback(
    ({ cart: _cart, userProperties: _userProperties }) => {
      if (!_cart) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        (window.location.pathname.startsWith('/collections') &&
          window.location.pathname) ||
        (previousPath?.startsWith('/collections') && previousPath) ||
        '';

      const nostoLineItems = _cart?.lines?.map((el) => {
        return {
          name: el.name,
          price_currency_code: _cart.estimatedCost?.totalAmount?.currencyCode,
          product_id: el.product_id,
          quantity: el.quantity,
          sku_id: el.id,
          unit_price: el.price
        }
      })
  
      nostojs((api) => {
        api.defaultSession().setCart({
          items: nostoLineItems
        })
      })

      
      if (DEBUG) console.log(`Nosto Cart Update`);
    },
    []
  );

  // Trigger 'view_cart' event when cart is opened
  useEffect(() => {
    if (!cartOpen || !userDataEventTriggered) return;

    viewCartEvent({ cart, userProperties });
  }, [cartOpen, userDataEventTriggered, cart?.updatedAt]);
}
