import { useMemo } from 'react';

export function useSwatchesDesing({ product }) {
  return useMemo(() => {
    if (!product) return null;
    
    const altSwatchDesign =
      'grid max-md:gap-2 grid-cols-[repeat(4,_auto)] lg:grid-cols-[repeat(5,_auto)] [&_button]:w-full [&_a]:w-full';

    if (product?.tags?.includes('setting_size-swatches-alt-small-width')) {
      //size-alt-small
      return `grid grid-cols-[repeat(8,_auto)] [&_button]:w-full`;
    }

    if (product?.tags?.includes('setting_size-swatches-alt-auto-width')) {
      return `${altSwatchDesign} size-alt-dynamic`;
    }

    if (product?.tags?.includes('setting_size-swatches-alt-width')) {
      //size-alt-design
      return altSwatchDesign;
    }

    return '';
  }, [product]);
}
