import { ProductHalfHeroVideo } from './ProductHalfHeroVideo';
import { Image } from '../../snippets';
import { useMemo } from 'react';

export function ProductHalfHeroMedia({
  aboveTheFold,
  media,
  videoAlt,
  metafields,
}) {
  const { image, video } = { ...media };
  const hasMetafieldsInUse = metafields?.bylt?.showcase_image ? true : false;

  const videoURL = useMemo(() => {
    if (video?.enableVideoMetafield) return metafields?.bylt?.showcase_video;

    return video?.srcDesktop || '';
  }, [video]);

  const imageObj = useMemo(() => {
    if (image?.enableImageMetafield) {
      const images = metafields?.bylt?.showcase_image;

      const alt = metafields?.bylt?.showcase_image_alt_text;

      if (images)
        return {
          src: JSON.parse(images)[0],
          alt: alt ? JSON.parse(alt)[0] : image.alt,
        };
    }

    return { src: image.imageDesktop.src, alt: image.alt } || null;
  }, [image]);

  return (
    <div className="absolute inset-0 h-full w-full">
      <div className="relative h-full w-full overflow-hidden md:hidden">
        {video?.srcMobile ||
          (videoURL && (
            <ProductHalfHeroVideo
              autoplay={video.autoplay}
              posterSrc={video.posterMobile?.src}
              sound={video.sound}
              videoAlt={videoAlt}
              videoSrc={video.srcMobile || videoURL}
            />
          ))}

        {hasMetafieldsInUse ? (
          <div>
            {imageObj && imageObj?.src && !videoURL && (
              <Image
                alt={imageObj.alt}
                className={`${image.positionDesktop}`}
                fill
                priority={aboveTheFold}
                sizes="50vw"
                src={imageObj.src}
              />
            )}
          </div>
        ) : (
          <div>
            {image?.imageMobile?.src && !video?.srcMobile && (
              <Image
                alt={image.alt}
                className={`${image.positionMobile}`}
                fill
                sizes="100vw"
                priority={aboveTheFold}
                src={image.imageMobile.src}
              />
            )}
          </div>
        )}
      </div>

      <div className="relative hidden h-full w-full overflow-hidden md:block">
        {videoURL && (
          <ProductHalfHeroVideo
            autoplay={video.autoplay}
            posterSrc={video.posterDesktop?.src}
            sound={video.sound}
            videoAlt={videoAlt}
            videoSrc={videoURL}
          />
        )}

        {imageObj && imageObj?.src && !videoURL && (
          <Image
            alt={imageObj.alt}
            className={`${image.positionDesktop}`}
            fill
            priority={aboveTheFold}
            sizes="50vw"
            src={imageObj.src}
          />
        )}
      </div>
    </div>
  );
}

ProductHalfHeroMedia.displayName = 'ProductHalfHeroMedia';
