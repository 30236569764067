import { useCallback, useEffect, useState, useMemo } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import { ReviewStars, Spinner, Svg } from '../../snippets';
import { useProductMetafields } from '../../hooks';
import { Schema } from './ProductReviews.schema';
import { ProductReviewsForm } from './ProductReviewForm';
import { ProductReviewItem } from './ProductReviewItem';

export function ProductReviews({ cms, product, childProduct }) {
  const { button, heading, subheading, section, slider, textColor } = cms;
  const { legacyResourceId } = childProduct
    ? { ...childProduct }
    : { ...product };
  const { sliderStyle } = { ...slider };

  const [swiper, setSwiper] = useState(null);
  const [paginationSwiper, setPaginationSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [pagIndexRef, setPagIndexRef] = useState(0);
  const [reviewAggregate, setReviewAggregate] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isFullReviewsReady, setIsFullReviewsReady] = useState(false);
  const metafields = useProductMetafields({
    product: childProduct || product,
  });

  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });

  const fetchReviewAggregate = useCallback(async (productId, isFull) => {
    try {
      if (!productId) return;

      const endpoint = `https://stamped.io/api/widget/reviews?productId=${productId}&storeUrl=${
        process.env.SHOPIFY_DOMAIN
      }&take=${isFull ? '50' : '3'}`;

      const response = await axios.get(endpoint);
      const data = response ? response?.data : null;

      if (data) {
        if (data?.error) {
          throw new Error(data?.error?.message);
        }

        setReviewAggregate(data, false);

        if (isFull) {
          setIsFullReviewsReady(true);
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }, []);

  useEffect(() => {
    fetchReviewAggregate(legacyResourceId, false);
  }, [legacyResourceId]);

  useEffect(() => {
    if (!inView) return;

    fetchReviewAggregate(legacyResourceId, true);
  }, [inView]);

  const stampedData = useMemo(() => {
    if (!metafields) return fetchReviewAggregate;

    return {
      rating: parseFloat(metafields?.stamped?.reviews_average),
      total: metafields?.stamped?.reviews_count,
    };
  }, [metafields, fetchReviewAggregate]);

  const slidesPerViewDesktop = slider?.slidesPerViewDesktop || 3;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 3;

  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';

  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-none'
      : 'max-w-[var(--content-max-width)]';

  const totalReviewPages = reviewAggregate
    ? Math.ceil(reviewAggregate?.total / 3)
    : 0;

  const reviewsPagination = reviewAggregate?.data
    ? [...Array(Math.ceil(reviewAggregate.data.length / 3)).keys()]
    : [];

  const handleNextSlide = () => {
    if (!isFullReviewsReady) return;

    swiper.slideNext();
  };

  const reviewsText =
    stampedData?.total === '1'
      ? `${stampedData?.total} Review`
      : `${stampedData?.total} Reviews`;

  return (
    <div
      className={`bg-offWhite py-[40px] max-md:pt-[50px] ${
        !isFullBleedAndCentered ? 'lg:px-contained' : ''
      }`}
      ref={ref}
    >
      <div className="m-auto flex flex-col items-center" id="product-reviews">
        <h2 className="h4 px-4 text-center" style={{ color: textColor }}>
          {heading}
        </h2>

        {subheading && <p className="text-sm">{subheading}</p>}
        <div className="mt-[42px] flex gap-1.5 align-middle text-text">
          <ReviewStars rating={stampedData?.rating || 5} size="large" />
          {reviewAggregate?.total !== 0 && (
            <p className="text-[15px]">{reviewsText}</p>
          )}
        </div>

        {button && (
          <div className="mt-[14px]">
            <button
              aria-label={button}
              className={`${section?.buttonStyle || 'btn-primary'}`}
              type="button"
              onClick={() => setShowForm(!showForm)}
            >
              <Svg
                className="mr-2 w-4"
                src="/svgs/pen-square.svg#pen-square"
                title="Write"
                viewBox="0 0 24 24"
              />
              {button}
            </button>
          </div>
        )}

        {reviewAggregate && (
          <>
            {showForm && <ProductReviewsForm product={product} />}
            <Swiper
              centeredSlides={
                isFullBleedAndCentered &&
                reviewAggregate.data.length >= slidesPerViewMobile * 2
              }
              className={`relative w-full pb-[51px] pt-[59px] md:pb-[41px] ${maxWidthClass} ${
                sliderStyle === 'fullBleedWithGradient'
                  ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
                  : ''
              }`}
              grabCursor
              loop={
                isLoop && reviewAggregate.data.length >= slidesPerViewMobile * 2
              }
              modules={[Navigation]}
              onSwiper={setSwiper}
              onActiveIndexChange={(swiper) => {
                if (!paginationSwiper) return;

                const fixedInded = Math.round(swiper.activeIndex / 3);
                setActiveIndex(swiper.activeIndex);
                paginationSwiper.slideTo(fixedInded);
                setPaginationIndex(fixedInded);
              }}
              slidesOffsetAfter={0}
              slidesOffsetBefore={0}
              slidesPerView={slidesPerViewMobile}
              slidesPerGroup={1}
              spaceBetween={16}
              autoHeight
              breakpoints={{
                1024: {
                  centeredSlides:
                    isFullBleedAndCentered &&
                    reviewAggregate.data.length >= slidesPerViewDesktop * 2,
                  loop:
                    isLoop &&
                    reviewAggregate.data.length >= slidesPerViewDesktop * 2,
                  slidesPerView: slidesPerViewDesktop,
                  spaceBetween: 20,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                  slidesPerGroup: 3,
                  autoHeight: false,
                },
              }}
            >
              {swiper &&
                reviewAggregate.data.map((review, index) => {
                  return (
                    <SwiperSlide
                      className="gap flex min-h-[37.5rem] flex-col items-center bg-white px-[29px] pt-[90px] lg:px-[50px]"
                      key={index}
                    >
                      <ProductReviewItem review={review} />
                    </SwiperSlide>
                  );
                })}

              {reviewAggregate.data.length > slidesPerViewDesktop && (
                <div className="z-1 absolute left-0 right-0 top-[calc(50%-28px)] md:px-8 xl:px-14">
                  <div
                    className={`relative mx-auto ${maxWidthClass} ${
                      isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                    }`}
                  >
                    <div
                      className={`swiper-button-prev left-0 top-[calc(50%)] !h-[3.5rem] !w-[3.5rem] bg-white after:hidden ${
                        !isFullBleedAndCentered ? 'xl:-left-[4.6875rem]' : ''
                      } ${paginationIndex === 0 ? '!hidden' : '!flex'}`}
                      onClick={() => swiper.slidePrev()}
                    >
                      <Svg
                        className="w-6 text-black"
                        src="/svgs/chevron-left.svg#chevron-left"
                        title="Arrow Left"
                        viewBox="0 0 24 24"
                      />
                    </div>

                    <div
                      className={`swiper-button-next right-0 top-[calc(50%)] !h-[3.5rem] !w-[3.5rem] bg-white after:hidden ${
                        !isFullBleedAndCentered ? 'xl:-right-[4.6875rem]' : ''
                      } ${
                        paginationIndex + 1 === reviewsPagination?.length
                          ? '!hidden'
                          : '!flex'
                      }`}
                      onClick={() => handleNextSlide()}
                    >
                      <Svg
                        className="w-6 text-black"
                        src="/svgs/chevron-right.svg#chevron-right"
                        title="Arrow Right"
                        viewBox="0 0 24 24"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Swiper>

            <Swiper
              className={`relative w-[80%] px-4 lg:w-1/4 ${
                reviewsPagination?.length < 10
                  ? '[&_.swiper-wrapper]:justify-center'
                  : ''
              }`}
              modules={[Navigation]}
              onSwiper={setPaginationSwiper}
              onActiveIndexChange={(swiper) => {
                setPagIndexRef(swiper.activeIndex);
              }}
              slidesPerView={10}
              centeredSlides
              centeredSlidesBounds
            >
              {paginationSwiper &&
                isFullReviewsReady &&
                reviewsPagination.map((index) => {
                  return (
                    <SwiperSlide key={index} className="w-fit">
                      <button
                        aria-label={`Slide to review group ${index + 1}`}
                        onClick={() => {
                          swiper?.slideTo(index * 3);
                          setPaginationIndex(index);
                        }}
                        type="button"
                        className={`${
                          paginationIndex === index ? 'font-bold underline' : ''
                        } w-full`}
                      >
                        {index + 1}
                      </button>
                    </SwiperSlide>
                  );
                })}

              <div className="z-1 absolute left-0 right-0">
                <div className="relative mx-auto">
                  <div
                    className={`swiper-button-prev left-[-5px] top-[calc(50%-0.6875rem)] bg-white text-black after:hidden ${
                      pagIndexRef <= 5 || reviewsPagination?.length < 10
                        ? 'hidden'
                        : ''
                    }`}
                    onClick={() => paginationSwiper.slidePrev()}
                  >
                    {`<`}
                  </div>

                  <div
                    className={`swiper-button-next right-[-5px] top-[calc(50%-0.6875rem)] bg-white text-black after:hidden ${
                      paginationIndex + 1 === reviewsPagination?.length ||
                      reviewsPagination?.length < 10
                        ? 'hidden'
                        : ''
                    }`}
                    onClick={() => paginationSwiper.slideNext()}
                  >
                    {`>`}
                  </div>
                </div>
              </div>
            </Swiper>
          </>
        )}

        {!swiper && (
          <div className="relative flex min-h-[20rem] items-center justify-center">
            <Spinner
              width="32"
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        )}
      </div>
    </div>
  );
}

ProductReviews.displayName = 'ProductReviews';
ProductReviews.Schema = Schema;
