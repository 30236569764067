import { Link } from '../Link';
import { InveterateModal } from '../InveterateModal';
import { useCustomer, useSettings, useCountry } from '@backpackjs/storefront';
import { useGlobalContext } from '../../contexts';
import { useInveterate } from '../../hooks';
import { useEffect } from 'react'

export function InveteratePdpButton({}) {
  const customer = useCustomer();
  const { country } = useCountry();
  const settings = useSettings();
  const isUS = country?.isoCode === 'US';
  const {
    actions: { openModal, closeModal },
  } = useGlobalContext();
  const { isCustomerPremiumMember } = useInveterate();

  const { enabled, pdpCalloutEnabled } = { ...settings?.apps?.inveterate };

  const isInveterateEnabled = enabled || false
  const isPremiumMember = isCustomerPremiumMember;
  const isVisible = !!(isUS && !isPremiumMember && isInveterateEnabled && pdpCalloutEnabled);

  const openInveterateModal = () => {
    openModal(<InveterateModal />, {
      className: 'aspect-[3/4] md:aspect-square w-auto',
      hasBlur: true,
    });
  };

  return (
    isVisible && (
      <div className="mt-2">
        <button
          className="btn-inverse-black  relative w-full"
          onClick={() => openInveterateModal()}
        >
          Join BYLT+ Premium - $50
        </button>

        {!customer?.id && (
          <div className="mt-4 flex justify-end gap-2">
            <p className="text-right">Already a member?</p>

            <Link
              className="underline"
              href="/account/login"
              aria-label="Account Page"
            >
              Log In
            </Link>
          </div>
        )}
      </div>
    )
  );
}

InveteratePdpButton.displayName = 'InveteratePdpButton';
