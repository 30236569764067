import { useVariantPrices } from '../../hooks';

export function ProductItemPrice({
  selectedVariant,
  customPrice,
  customComparePrice,
  localized,
}) {
  //console.log(selectedVariant)
  const { price, compareAtPrice } = useVariantPrices({
    variant: localized || selectedVariant,
  });

  const placeHolderPrice = selectedVariant?.price;

  const formatedCustomPrice = customPrice ? `$${customPrice}` : null;

  const formatedCustomComparePrice = customComparePrice
    ? `$${customComparePrice}`
    : null;

  return (
    <div className="mt-1 flex flex-1 flex-wrap gap-x-1">
      <p
        className={`min-h-[1.25rem] text-sm ${
          (compareAtPrice || customComparePrice) && 'text-sale'
        }`}
      >
        {formatedCustomPrice || price || placeHolderPrice}
      </p>
      {(compareAtPrice || customComparePrice) && (
        <p className="text-sm text-mediumGray line-through">
          {formatedCustomComparePrice || compareAtPrice}
        </p>
      )}
    </div>
  );
}

ProductItemPrice.displayName = 'ProductItemPrice';
