const brand = process.env.NEXT_PUBLIC_SITE_TITLE || 'Storefront Name';

export const mapProductItemProduct =
  (list = '') =>
  (product, index = 0) => {
    try {
      if (!product) return null;
      const firstVariant = product.variants?.[0];

      return {
        id: firstVariant?.sku || '',
        name: product.title || '',
        brand: product.vendor || brand,
        category: product.productType || '',
        variant: firstVariant?.title || '',
        price: `${firstVariant?.priceV2?.amount || ''}`,
        list,
        product_id: product.legacyResourceId || '',
        variant_id: firstVariant?.legacyResourceId || '',
        compare_at_price: `${firstVariant?.compareAtPriceV2?.amount || ''}`,
        image: product.featuredImage?.src || '',
        position: index + 1,
      };
    } catch (error) {
      console.error('DataLayer:mapProductItemProduct error', error.message);
      console.error('DataLayer:mapProductItemProduct product', product);
      return null;
    }
  };

export const mapProductItemVariant =
  (list = '') =>
  (variant, index = 0) => {
    try {
      if (!variant) return null;

      return {
        id: variant.sku || '',
        name: variant.product.title || '',
        brand: variant.product.vendor || brand,
        category: variant.product.productType || '',
        variant: variant.title || '',
        price: `${variant.priceV2?.amount || ''}`,
        list,
        product_id: variant.product.legacyResourceId || '',
        variant_id: variant.legacyResourceId || '',
        compare_at_price: `${variant.compareAtPriceV2?.amount || ''}`,
        image: variant.image?.src || '',
        position: (variant.index ?? index) + 1,
      };
    } catch (error) {
      console.error('DataLayer:mapProductItemVariant error', error.message);
      console.error('DataLayer:mapProductItemVariant variant', variant);
      return null;
    }
  };

export const mapProductPageVariant =
  (list = '') =>
  (variant) => {
    try {
      if (!variant) return null;

      return {
        id: variant.sku || '',
        name: variant.product.title || '',
        brand: variant.product.vendor || brand,
        category: variant.product.productType || '',
        variant: variant.title || '',
        price: `${variant.priceV2?.amount || ''}`,
        list,
        product_id: variant.product.id?.split('/').pop() || '',
        variant_id: variant.legacyResourceId || '',
        compare_at_price: `${variant.compareAtPriceV2?.amount || ''}`,
        image: variant.image?.src || '',
        inventory: `${variant.quantityAvailable || ''}`,
      };
    } catch (error) {
      console.error('DataLayer:mapProductPageVariant error', error.message);
      console.error('DataLayer:mapProductPageVariant variant', variant);
      return null;
    }
  };

const decodedId = (id) => {
  try {
    if (!id) return undefined;
    return window.atob(id);
  } catch (error) {
    return id;
  }
};

export const mapLineItem =
  (list = '') =>
  (item, index = 0) => {
    try {
      const { quantity, variant } = { ...item };
      if (!variant) return null;

      return {
        id: variant.sku || '',
        name: variant.product?.title || '',
        brand,
        category: variant.product?.productType || '',
        variant: variant.title || '',
        price: `${variant.priceV2?.amount || ''}`,
        quantity: `${quantity || ''}`,
        list,
        product_id: decodedId(variant.product?.id)?.split('/').pop() || '',
        variant_id: decodedId(variant.id)?.split('/').pop() || '',
        compare_at_price: `${variant.compareAtPriceV2?.amount || ''}`,
        image: variant.image?.src || '',
        position: (item.index || index) + 1,
      };
    } catch (error) {
      console.error('DataLayer:mapLineItem error', error.message);
      console.error('DataLayer:mapLineItem item', item);
      return null;
    }
  };

export const fetchCustomerOrders = async ({ accessToken }) => {
  try {
    if (!accessToken) return null;

    const url = `/api/customer/orders`;
    const request = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first: 100,
        country: 'US',
        accessToken,
      }),
    });

    if (!request?.ok) {
      return null;
    }

    const data = await request.json();
    const { ok, response } = data;

    if (!ok) {
      return null;
    }
    return response?.orders;
  } catch (error) {
    console.error('DataLayer:fetchCustomerOrders error', error.message);
    return null;
  }
};

export const sendGraphlEvent = async (nostoQuery) => {
  const apikey = process.env.NEXT_PUBLIC_NOSTO_API_KEY

  try {
    const body = nostoQuery;

    const request = await fetch('https://api.nosto.com/v1/graphql', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/graphql',
        Authorization: 'Basic ' + window.btoa(':' + apikey),
      }),
      mode: 'cors',
      body,
    });

    const data = await request.json();

    if (!data) {
      return null;
    }

    return data;
  } catch (error) {}
};

export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    let date = new Date();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() + days * oneDayInMilliseconds);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = (name) => {
  const found = document.cookie.split(';').find((el) => {
    let split = el.split('=');
    if (split[0].trim() === name) {
      return true;
    }
  });

  if (!found) {
    return null;
  }

  const foundValue = found?.split('=')[1];

  return foundValue;
};

export const nostoMapLineItems = (items) => {
  if (items?.length) {
    let innerQuery = '';

    items.map((el, index) => {
      innerQuery += `{
      productId: "${getIdFromRawShopifyId(
        el?.variant?.product?.id,
        'Product'
      )}",
      skuId: "${getIdFromRawShopifyId(el?.variant?.id, 'ProductVariant')}",
      name: "${el?.variant?.product?.title}",
      unitPrice: ${el?.variant?.priceV2?.amount},
      priceCurrencyCode: "${el?.variant?.priceV2?.currencyCode}",
      quantity: ${el?.quantity}
    }${index + 1 < items.length ? ',' : ''}`;
    });

    const query = `cart: {
    items: [
      ${innerQuery}
    ]
  }`;

    return query;
  }

  return '';
};

export const getIdFromRawShopifyId = (rawId, type) => {
  if (!rawId || !type) {
    return null;
  }

  const guidStructure = `gid://shopify/${type}/`;
  const splittedContent = rawId?.split(guidStructure);

  if (splittedContent.length > 1) {
    const [gid, cleanId] = splittedContent;

    return cleanId;
  }
};
