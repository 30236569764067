import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Spinner } from '../Spinner';
import { ThreeTilesTile } from './ThreeTilesTile';

export function ThreeTilesRow({
  aspectRatio,
  maxWidthClass,
  textColor,
  tiles,
}) {
  const [swiper, setSwiper] = useState(null);

  return tiles?.length > 0 ? (
    <div className={`mx-auto ${maxWidthClass}`}>
      {/* mobile/tablet */}
      <div className={`relative lg:hidden ${!swiper ? 'min-h-[25rem]' : ''}`}>
        <Swiper
          grabCursor
          onSwiper={setSwiper}
          slidesOffsetAfter={16}
          slidesOffsetBefore={16}
          slidesPerView={1.4}
          spaceBetween={16}
          breakpoints={{
            768: {
              slidesPerView: 2.4,
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
              spaceBetween: 20,
            },
          }}
        >
          {swiper &&
            tiles.slice(0, 3).map((item, index) => {
              return (
                <SwiperSlide className="w-full" key={index}>
                  <ThreeTilesTile
                    aspectRatio={aspectRatio}
                    item={item}
                    textColor={textColor}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>

        {!swiper && (
          <Spinner
            width="32"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        )}
      </div>

      {/* desktop */}
      <div className="hidden grid-cols-3 gap-x-5 lg:grid">
        {tiles.slice(0, 3).map((item, blockIndex) => {
          return (
            <div key={blockIndex}>
              <ThreeTilesTile
                aspectRatio={aspectRatio}
                item={item}
                textColor={textColor}
              />
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}

ThreeTilesRow.displayName = 'ThreeTilesRow';
