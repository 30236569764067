import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { SearchAutocomplete } from './SearchAutocomplete';
import { SearchSuggestions } from './SearchSuggestions';
import { Svg } from '../Svg';

export function SearchInput({
  closeSearch,
  rawTerm,
  searchOpen,
  setRawTerm,
  hasResults,
}) {
  const inputRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    if (!searchOpen) return;
    inputRef.current.focus();
  }, [searchOpen]);

  return (
    <div className="border-b border-b-border bg-[#25293BE5] p-8 pt-0">
      <div className="relative m-auto flex max-w-[600px] justify-between gap-3 rounded-full border border-border pl-3 pr-4">
        <Svg
          className="w-5 text-border"
          src="/svgs/search.svg#search"
          title="Search"
          viewBox="0 0 24 24"
        />

        <input
          aria-label="Search here"
          className="min-w-0 flex-1 bg-transparent py-1 text-base text-border outline-none"
          onChange={(e) => setRawTerm(e.target.value)}
          onKeyDown={(e) => setRawTerm(e.target.value)}
          placeholder="Product, Color, Style..."
          ref={inputRef}
          value={rawTerm}
        />

        <button
          aria-label="Clear search"
          className={`${rawTerm ? '' : 'invisible'}`}
          onClick={() => setRawTerm('')}
          type="button"
        >
          <Svg
            className="w-4 text-border"
            src="/svgs/close.svg#close"
            title="Close"
            viewBox="0 0 24 24"
          />
        </button>
      </div>

      {hasResults ? (
        <SearchAutocomplete
          searchOpen={searchOpen}
          setRawTerm={setRawTerm}
          term={rawTerm}
        />
      ) : (
        <SearchSuggestions term={rawTerm} setRawTerm={setRawTerm} />
      )}
    </div>
  );
}

SearchInput.displayName = 'SearchInput';
