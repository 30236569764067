import { useEffect } from 'react';
import Script from 'next/script';

export function WairPlacement({ product }) {
  useEffect(() => {
    if (!product && typeof window === 'undefined') return;

    const predictProduct = product;

    window.addEventListener('onPredictLoad', (event) => {
      event.detail.predict.render({
        brandKey: 'bylt-apparel.myshopify.com',
        product: predictProduct
          ? {
              key: predictProduct.handle,
              name: predictProduct.title,
              image: predictProduct?.featuredImage?.src,
            }
          : null,
      });
    });
  }, [product]);

  return (
    <>
      {/*Wair Scripts*/}
      <Script
        src="https://predict-v4.getwair.com/default/predict.js"
        type="text/javascript"
      />
      <div
        className="predict-placement"
        data-predict-product-key={product?.handle}
        data-predict-product-name={product?.title}
        data-predict-product-img={product?.featuredImage?.src}
      ></div>
    </>
  );
}

WairPlacement.displayName = 'WairPlacement';
