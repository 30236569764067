import { useState } from 'react';

import { SearchFilterOption } from './SearchFilterOption';
import { Svg } from '../Svg';

export function SearchFilterDropdown({
  filter,
  activeFilters,
  isGrid,
  addFilter,
  removeFilter,
  trackOnClick
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [maxOptions, setMaxOptions] = useState(filter?.values?.length);
  const totalSelectedOptions = 0 || 0;

  const listClasses = isGrid
    ? 'flex-row flex-wrap gap-2 px-4 pb-4'
    : 'flex-col';

  const listItemClasses = isGrid
    ? 'border border-border rounded-2xl py-1'
    : 'max-md:border-b max-md:border-border max-md:last:border-none';

  return (
    <div className="border-border max-md:border-b md:border-t">
      <button
        aria-label={filter?.name}
        className="relative flex h-14 w-full items-center justify-between gap-4 text-left max-md:px-4"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <div className="flex flex-1 items-center">
          <h3 className="text-nav">{filter?.name}</h3>

          {totalSelectedOptions > 0 && (
            <p className="ml-1 text-xs text-mediumGray">
              ({totalSelectedOptions})
            </p>
          )}
        </div>

        <Svg
          className={`w-4 text-text ${isOpen ? 'rotate-180' : ''}`}
          src="/svgs/chevron-down.svg#chevron-down"
          title="Chevron"
          viewBox="0 0 24 24"
        />
      </button>

      <div
        className={`flex-col md:items-start md:gap-2 md:pb-4 ${
          isOpen ? 'flex' : 'hidden'
        }`}
      >
        <ul className={`flex ${listClasses} md:items-start md:gap-2`}>
          {filter?.values
            ?.slice(0, maxOptions)
            .map(({ count, name }, index) => {
              return (
                <li key={index} className={listItemClasses}>
                  <SearchFilterOption
                    activeFilters={activeFilters}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    showCount={true}
                    count={count}
                    name={name}
                    value={name}
                    id={filter?.key}
                    trackOnClick={trackOnClick}
                  />
                </li>
              );
            })}
        </ul>

        {maxOptions < filter?.values?.length && (
          <button
            type="button"
            className="h-6 text-left text-sm font-bold uppercase max-md:h-11 max-md:px-4 md:text-xs"
            aria-label="Show all options"
            onClick={() => setMaxOptions(filter?.values?.length)}
          >
            + {filter?.values?.length - maxOptions} More
          </button>
        )}
      </div>
    </div>
  );
}

SearchFilterDropdown.displayName = 'SearchFilterDropdown';
