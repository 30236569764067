import { useState } from 'react';
import { ProductCompareContent } from './ProductCompareContent';
import { ProductCompareMedia } from './ProductCompareMedia';
import { Schema } from './ProductCompare.schema';

export function ProductCompare({ cms }) {
  const { section, content, media } = cms;
  const {
    aspectDesktop,
    aspectMobile,
    aspectTablet,
    fill,
    images,
    mediaOrderDesktop,
    mediaOrderMobile,
  } = media;

  const {
    layout,
    fullBleed,
    fullWidth,
    verticalPadding,
    bgColor,
    aboveTheFold,
  } = section;

  const { heading, productSelector } = content;

  const maxWidthContainerClass = fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  const fillClass = fill
    ? 'h-full'
    : `md:before:float-left ${aspectTablet} ${aspectDesktop}`;

  const mediaOrderClasses = `${
    mediaOrderMobile === '2' ? 'order-2' : 'order-1'
  } ${mediaOrderDesktop === '2' ? 'md:order-2' : 'md:order-1'}`;

  const contentOrderClasses = `${
    mediaOrderMobile === '2' ? 'order-1' : 'order-2'
  } ${mediaOrderDesktop === '2' ? 'md:order-1' : 'md:order-2'}`;

  const mediaBgColorClass =
    bgColor === 'var(--background)' || bgColor === 'var(--white)'
      ? 'bg-offWhite'
      : '';

  // For toggle feature
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  return (
    <div
      className={`${fullBleed ? '' : 'px-contained'} ${
        verticalPadding ? 'py-contained' : ''
      }`}
      style={{ backgroundColor: bgColor }}
    >
      <div
        className={`relative mx-auto grid grid-cols-1 items-center md:${layout} ${maxWidthContainerClass} md:mx-auto md:max-w-[1085px]`}
      >
        <div
          className={`relative w-full max-md:before:float-left ${aspectMobile} ${fillClass} ${mediaOrderClasses} ${mediaBgColorClass}`}
        >
          <ProductCompareMedia
            aboveTheFold={aboveTheFold}
            images={images?.productImages}
            selectedOptionIndex={selectedOptionIndex}
            videoAlt={heading}
          >
            <div className="center mt-8 flex flex-row justify-center gap-2">
              {productSelector?.productOptions?.map(({ toggleText }, index) => (
                <button
                  className={` ${
                    index === selectedOptionIndex
                      ? 'btn-simple max-md:min-w-[160px]'
                      : 'btn-simple-light max-md:min-w-[160px]'
                  }`}
                  key={toggleText}
                  type="button"
                  onClick={() => setSelectedOptionIndex(index)}
                >
                  {toggleText}
                </button>
              ))}
            </div>
          </ProductCompareMedia>
        </div>

        <div
          className={`flex w-full items-center md:before:float-left ${aspectTablet} ${aspectDesktop} ${contentOrderClasses}`}
        >
          <ProductCompareContent
            aboveTheFold={aboveTheFold}
            content={content}
            selectedOptionIndex={selectedOptionIndex}
          />
        </div>
      </div>
    </div>
  );
}

ProductCompare.displayName = 'ProductCompare';
ProductCompare.Schema = Schema;
