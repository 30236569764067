import { useCallback } from 'react';
import { useCartAddItem } from '@backpackjs/storefront';
import { useGlobalContext } from '../contexts';

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export function useRiseGiftCard({ selectedVariant }) {
  const { cartAddItem } = useCartAddItem();
  const {
    actions: { openCart },
  } = useGlobalContext();

  const registerRiseProduct = ({ product }) => {
    if (
      window !== undefined &&
      product &&
      product.vendor &&
      product.tags.find((el) => el == 'gift-card')
    ) {
      const atcButton = document.querySelector(
        'button.Rise-add-to-cart-button'
      );

      const atcSectin = document.getElementById('qty-selector');
      if (atcSectin) {
        atcSectin.classList.add('hidden');
      }

      if (!window.Rise) {
        const riseScript = document.createElement('script');
        riseScript.type = 'text/javascript';
        riseScript.defer = true;
        riseScript.src =
          'https://str.rise-ai.com/?shop=' + 'bylt-apparel' + '.myshopify.com';

        const lastScript = Array.from(
          document.getElementsByTagName('script')
        ).pop();

        lastScript.insertAdjacentElement('afterend', riseScript);
      }

      window['Rise'] = {
        is_product_page: true,
        cart: {
          // imitate the cart token (required by Rise)
          token: uuidv4(),
        },
        using_add_to_cart_flow: false,
        is_floating_cart_theme: true,
        product: {
          id: product.id.split('gid://shopify/Product/')[1],
        },
        full_product: {
          available: true,
        },
        lineItemProps: {
          gift: {
            _gift_id: uuidv4(),
            name: String,
            email: String,
            message: String,
          },
        },

        onGiftAdded: (lineItemProps) => addToCart(lineItemProps),
      };

      window.sessionStorage.setItem('cartToken', window['Rise'].cart.token);
    }
  };

  const addToCart = useCallback(
    async (lineItemProps) => {
      if (!selectedVariant?.id) return;

      let metafields = [
        { key: 'id', value: window['Rise'].product.id },
        { key: '_gift_id', value: lineItemProps.gift._gift_id || '' },
        { key: 'Name', value: lineItemProps.gift.name || '' },
        { key: 'Message', value: lineItemProps.gift.message || '' },
      ];

      await cartAddItem({
        merchandiseId: selectedVariant.id,
        quantity: 1,
        attributes: metafields,
      });

      openCart();
    },
    [selectedVariant?.id]
  );

  return { registerRiseProduct };
}
