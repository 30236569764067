import { useEffect, useState } from 'react';

export function AppLoaderGorgias({}) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      var initGorgiasChatPromise = window.GorgiasChat
        ? window.GorgiasChat.init()
        : new Promise(function (resolve) {
            window.addEventListener('gorgias-widget-loaded', function () {
              resolve();
            });
          });

      initGorgiasChatPromise.then(function () {
        var timer = setInterval(function () {
          var chatButtonHead = document
            .querySelector('#gorgias-chat-container')
            ?.querySelector('#chat-button')
            ?.contentWindow.document.querySelector('head');
          if (!chatButtonHead?.children.length) {
            return;
          }
          clearInterval(timer);

          var chatButtonIframe = document.querySelector('#chat-button');
          chatButtonIframe.style = 'display:none;';
          var myButton = document.createElement('button');

          if (GorgiasChat.isBusinessHours()) {
            myButton.style = ` 
            position: fixed; 
            right: 43px; 
            bottom: 26px; 
            z-index: 60; 
            border-radius: 100px; 
            border: none;
            cursor: pointer;
            background-color: transparent;
            `;
            myButton.id = 'gorgias-bussiness-button';
            myButton.classList.add('gorgias-chat-button-custom')
          } else {
            myButton.style = ` 
            position: fixed; 
            right: 43px; 
            bottom: 26px; 
            z-index: 60; 
            border-radius: 100px; 
            border: none;
            cursor: pointer;
            background-color: transparent;
            `;
            myButton.id = 'gorgias-out-button';
            myButton.classList.add('gorgias-chat-button-custom')
          }

          // onClick, behave like a chat opener
          myButton.onclick = function () {
            if (GorgiasChat.isOpen()) {
              GorgiasChat.close();
            } else {
              GorgiasChat.open();
            }
          };
          document.body.appendChild(myButton);
        }, 50);
      });
    }
  }, []);

  return (
    <></>
  );
}

AppLoaderGorgias.displayName = 'AppLoaderGorgias';
