import { useGlobalContext } from '../../contexts';
import { useState } from 'react';
import { Image } from '../Image';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
import { useSettings, useCountry, useCustomer } from '@backpackjs/storefront';
import { useInveterate } from '../../hooks';
import axios from 'axios';
import { useRouter } from 'next/router';

export function InveterateFreeTierModal() {
  const settings = useSettings();
  const { country } = useCountry();
  const customer = useCustomer();
  const { enabled, freeModal } = { ...settings?.apps?.inveterate };
  const {
    actions: { closeModal },
  } = useGlobalContext();
  const { isCustomerPremiumMember, freeTier } = useInveterate();
  const [isAdding, setIsAdding] = useState(false);
  const [isSucess, setIsSuccess] = useState(false);
  const router = useRouter();

  const benefits = freeModal?.benefits || [];
  const isUS = country?.isoCode === 'US';

  const byltFreeTierLogo =
    'https://cdn.shopify.com/s/files/1/1464/5034/files/BYLT_Basic.png?v=1712937850';

  const clickAction = () => {
    registerFreeTierCustomer();
  };

  const registerFreeTierCustomer = async () => {
    try {
      setIsAdding(true);

      const customerID = customer?.id?.split('gid://shopify/Customer/')[1];
      const endpoint = 'https://public.inveterateapi.com/v2.0/admin/members';

      const bodyToSend = {
        customerId: customerID,
        segmentId: freeTier?.segmentId,
        overrideSpendThreshold: true,
        keepInSpendBasedTier: true,
      };

      const response = await axios({
        url: endpoint,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Inveterate-Api-Key': 'd7b6fc64c7230112f2e208b64f249f92',
        },
        data: bodyToSend,
      });

      setIsAdding(false);
      if (response) {
        setIsSuccess(true);
      }

      router.reload();
    } catch (error) {
      setIsAdding(false);
    }
  };

  const isDisabled = !!(!isUS || isCustomerPremiumMember || !customer);

  return (
    <div className="w-[100vw] max-w-[80vw] md:max-w-[450px] ">
      {freeModal?.bgImage && (
        <Image
          alt={freeModal.bgImage.altText}
          fill
          priority={true}
          sizes="50vw"
          src={freeModal.bgImage.src}
        />
      )}

      <div
        className={`absolute left-0 top-0 flex h-full w-full flex-col p-10 
            ${freeModal?.contentStyle} ${freeModal?.textColor}`}
      >
        <div className={freeModal?.textAlign}>
          <Image
            className="mx-auto my-[20px]"
            src={byltFreeTierLogo}
            alt="Basics Rewards Logo"
            height={80}
            width={80}
          />

          <h2 className="mb-4">{freeModal?.heading}</h2>

          <h4 className="mb-4">{freeModal?.details}</h4>

          <div>
            <ul>
              {benefits?.map((el, index) => {
                return (
                  <li className="mb-2 font-medium" key={index}>
                    {el}
                  </li>
                );
              })}
            </ul>
          </div>

          {isSucess && (
            <div className="mb-4">
              <h4>{freeModal?.sucessMessage}</h4>
            </div>
          )}
        </div>

        <div>
          {isSucess ? (
            <button
              className={`${freeModal?.buttonStyle} mb-4 w-full`}
              onClick={() => closeModal()}
            >
              Close
            </button>
          ) : (
            <button
              className={`${freeModal?.buttonStyle} mb-4 w-full`}
              onClick={() => clickAction()}
              disabled={isDisabled}
            >
              {!isAdding ? (
                <span>{freeModal?.buttonText}</span>
              ) : (
                <Spinner width="20" />
              )}
            </button>
          )}

          {freeModal?.enableAccountLink && (
            <p className="font-medium">
              Already have and account?{' '}
              <Link className="text-underline" href="/account/login">
                Sign in
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

InveterateFreeTierModal.displayName = 'InveterateFreeTierModal';
