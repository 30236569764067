import { Link, Image, Svg } from '../../snippets';
import { Schema } from './SubCollectionSlider.schema';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useCollectionMetafields } from '../../hooks';
import { fetchCollectionsFromHandles } from '../../utilities';

export function SubCollectionSlider({ cms, collection }) {
  const { subCollection, section, enableMetafields } = cms;
  const metafields = useCollectionMetafields({ collection });

  const [swiper, setSwiper] = useState(null);

  // Metafield Powered Section
  const [fullCollections, setFullCollections] = useState([]);

  const metafieldHandles = metafields?.custom?.subcollections_new_
    ? JSON.parse(metafields?.custom?.subcollections_new_)
    : null;

  const fetchFullCmsCollections = useCallback(async () => {
    if (metafieldHandles && metafieldHandles.length) {
      const res = await fetchCollectionsFromHandles(metafieldHandles);
      setFullCollections(res);
    }
  }, [collection, metafieldHandles]);

  useEffect(() => {
    if (enableMetafields) {
      fetchFullCmsCollections();
    }
  }, [collection]);

  const metaSubcollections = useMemo(() => {
    return fullCollections.map((el) => {
      return {
        image: {
          src:
            el.metafields?.find((el) => el.key === 'subcollection_image')
              ?.value || el.image?.src,
        },
        label:
          el.metafields?.find((el) => el.key === 'subcollection_label')
            ?.value || el.title,
        link: {
          url: `/collections/${el.handle}`,
        },
      };
    });
  }, [fullCollections]);

  const selectedSubCollections = useMemo(() => {
    return (
      (enableMetafields && metaSubcollections?.length
        ? metaSubcollections
        : subCollection) || []
    );
  }, [subCollection, metaSubcollections]);

  const hasSubcollections = selectedSubCollections?.length > 0;

  return (
    hasSubcollections && (
      <div className="px-4 max-md:py-4 md:px-10 md:pt-12">
        <div className={`mx-auto ${section?.maxWidth} relative`}>
          {selectedSubCollections && selectedSubCollections.length && (
            <Swiper
              className={''}
              grabCursor
              loop={false}
              modules={[Navigation]}
              onSwiper={setSwiper}
              slidesOffsetAfter={0}
              slidesOffsetBefore={0}
              slidesPerView={2}
              spaceBetween={16}
              breakpoints={{
                768: {
                  centeredSlides: false,
                  loop: false,
                  slidesPerView: 2,
                  spaceBetween: 20,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                },
                1024: {
                  centeredSlides: false,
                  loop: false,
                  slidesPerView: 5,
                  spaceBetween: 20,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                },
              }}
            >
              {swiper &&
                selectedSubCollections.map((sub, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link aria-label={sub?.label} href={sub?.link?.url}>
                        <div className="relative aspect-square">
                          {sub?.image?.src && (
                            <Image
                              alt={sub?.label}
                              className={``}
                              fill
                              priority
                              sizes="25vw"
                              src={sub?.image?.src}
                            />
                          )}
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}

              <div className="z-1 absolute left-0 right-0 top-[calc(50%)] md:hidden md:px-8 xl:px-14">
                <div
                  className={`swiper-button-prev left-0 left-0 top-[calc(50%)] !h-[2.5rem] !w-[2.5rem] rounded-full after:hidden lg:!flex`}
                  onClick={() => swiper.slidePrev()}
                >
                  <Svg
                    className="w-[1.25rem] text-black"
                    src="/svgs/chevron-left.svg#chevron-left"
                    title="Arrow Left"
                    viewBox="0 0 24 24"
                  />
                </div>

                <div
                  className={`swiper-button-next right-0 right-0 top-[calc(50%)] !h-[2.5rem] !w-[2.5rem] rounded-full after:hidden lg:!flex`}
                  onClick={() => swiper.slideNext()}
                >
                  <Svg
                    className="w-[1.25rem] text-black"
                    src="/svgs/chevron-right.svg#chevron-right"
                    title="Arrow Right"
                    viewBox="0 0 24 24"
                  />
                </div>
              </div>
            </Swiper>
          )}
        </div>
      </div>
    )
  );
}

SubCollectionSlider.displayName = 'SubCollection Slider';
SubCollectionSlider.Schema = Schema;
