import { Image, Link } from '../../snippets';
import { Schema } from './LeftPaneNavigation.schema';

export function LeftPaneNavigation({ cms }) {
  const { links, heading, section } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div
      className="px-contained py-contained"
      style={{ color: section?.textColor }}
    >
      <div
        className={`mx-auto flex flex-col items-center gap-4 md:gap-6 ${maxWidthClass} text-center`}
      >
        {heading &&
          (section?.aboveTheFold ? (
            <h1 className="h2 mx-auto max-w-[46rem]">{heading}</h1>
          ) : (
            <h2 className="mx-auto max-w-[46rem]">{heading}</h2>
          ))}

        <div className="flex flex-col gap-y-5 text-left font-black uppercase">
          {links &&
            links.map(({ link, logo, alt }) => {
              return (
                <div
                  className="flex flex-row gap-x-3 align-middle"
                  key={logo?.src}
                >
                  {logo && (
                    <Image
                      alt={alt}
                      className="relative h-[20px] w-auto"
                      height={20}
                      width={20}
                      src={logo?.src}
                    />
                  )}
                  <Link
                    aria-label={link?.text}
                    href={link?.url}
                    newTab={link?.newTab}
                    type={link?.type}
                  >
                    {link?.text}
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

LeftPaneNavigation.displayName = 'LeftPaneNavigation';
LeftPaneNavigation.Schema = Schema;
