import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { ColorOptionButton } from './ColorOptionButton';

export function ProductColorOptionValue({
  inventory,
  isLoading,
  isSelected,
  name,
  product,
  selectedOptions,
  setSelectedOptions,
  value,
  isCustom,
  customIndex,
  isSizeSelected = true,
  isGroup,
  fallbackChildProduct = null,
  groupedVariants= []
}) {
  const settings = useSettings();
  const swatches = settings?.product?.colors?.swatches;
  const isFromGrouping = product.grouping?.isTransformed;

  const newSelectedOptions = useMemo(() => {
    return selectedOptions
      ? {
          ...selectedOptions,
          [name]: value,
        }
      : null;
  }, [name, selectedOptions, value]);

  const swatch = useMemo(() => {
    if (!swatches) return null;
    return (
      swatches.find(
        ({ name: swatchName }) =>
          swatchName?.trim().toLowerCase() === value.toLowerCase()
      ) || null
    );
  }, [swatches, value]);

  return (
    <ColorOptionButton
      inventory={inventory}
      isLoading={isLoading}
      isSelected={isSelected}
      newSelectedOptions={newSelectedOptions}
      product={product}
      setSelectedOptions={setSelectedOptions}
      swatch={swatch}
      value={value}
      isCustom={isCustom}
      customIndex={customIndex}
      isSizeSelected={isSizeSelected}
      isGroup={isGroup}
      fallbackChildProduct={fallbackChildProduct}
      groupedVariants={groupedVariants}
    />
  );
}

ProductColorOptionValue.displayName = 'ProductColorOptionValue';
