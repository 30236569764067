import { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Schema } from './ProductsSlider.schema';
import { Link, ProductItem, Spinner, Svg } from '../../snippets';
import { useColorSwatches, useProductMetafields } from '../../hooks';
import { fetchProductsFromHandles, formatProductForNosto } from '../../utilities';
import { sendGraphlEvent, getCookie } from '../../snippets/NostoLayer/hooks/utils';

export function ProductsSlider({ cms, product }) {
  const {
    button,
    heading,
    subHeading,
    headingSize,
    productItem,
    products,
    section,
    slider,
    textColor,
    isNostoPowered,
    nostoPlacement,
    nostoPlacementId,
    metafieldKey,
  } = cms;

  // if (heading?.startsWith('Styles')) return null;

  const { isPreview } = useRouter();
  const { swatchesMap } = useColorSwatches();

  const metafields = useProductMetafields({ product });

  const [swiper, setSwiper] = useState(null);

  const { sliderStyle } = { ...slider };
  const slidesPerViewDesktop = slider?.slidesPerViewDesktop || 4;
  const slidesPerViewTablet = slider?.slidesPerViewTablet || 3.4;
  const slidesPerViewMobile = slider?.slidesPerViewMobile || 1.4;
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-none'
      : 'max-w-[var(--content-max-width)]';
  const containedClass = `${section?.verticalPaddingLevel} ${
    section?.verticalPaddingLevelMobile
  } ${!isFullBleedAndCentered && !section?.fullWidth ? 'px-contained' : ''}`;

  const swiperPadding = section?.fullWidth ? 'px-contained' : '';

  const [fullProducts, setFullProducts] = useState([]);
  const [metaProducts, setMetaProducts] = useState([]);
  const [nostoRefSrc, setNostoRefSrc] = useState([]);

  const nostoReady =
    process.env.NODE_ENV !== 'development' &&
    !!isNostoPowered &&
    !!nostoPlacementId;

  const filterProductsBasedOnIsPreview = useCallback(
    (_products) => {
      return isPreview
        ? _products.filter(Boolean)
        : _products.filter((resProduct) => resProduct?.status === 'ACTIVE');
    },
    [isPreview]
  );

  const fetchFullCmsProducts = useCallback(async () => {
    if (products && products.length) {
      const resProducts = await fetchProductsFromHandles(
        products.map((cmsProduct) => cmsProduct.product?.handle).filter(Boolean)
      );
      setFullProducts(filterProductsBasedOnIsPreview(resProducts));
    }
  }, [products]);

  const fetchChildProduct = useCallback(
    async (data) => {
      if (nostoReady) {
        // Nosto Product Fetch
        const handles = data.map((el) => {
          return el.url.split('/products/')[1];
        });

        if (Array.isArray(handles) && handles.length) {
          const resProducts = await fetchProductsFromHandles(handles);
          const filteredProducts = filterProductsBasedOnIsPreview(resProducts);
          if (filteredProducts.length) {
            setMetaProducts(filteredProducts);
          } else {
            fetchFullCmsProducts();
          }
        }
      }

      if (metafieldKey && product) {
        // Metafields Product Fetch
        if (Array.isArray(data) && data.length) {
          const resProducts = await fetchProductsFromHandles(data);
          const filteredProducts = filterProductsBasedOnIsPreview(resProducts);
          if (filteredProducts.length) {
            setMetaProducts(filteredProducts);
          } else {
            fetchFullCmsProducts();
          }
        }
      }
    },
    [isPreview, metafieldKey, nostoReady, product]
  );

  useEffect(() => {
    if (!metafieldKey) return;
    const metafieldsProducts = metafields?.bylt
      ? metafields?.bylt[metafieldKey]
      : null;
    if (!metafieldsProducts) {
      fetchFullCmsProducts();
      return;
    }
    const formatedProducts = JSON.parse(metafieldsProducts);
    fetchChildProduct(formatedProducts);
  }, [metafieldKey]);

  useEffect(() => {
    if (!nostoReady) return;
    const productId = product?.id?.split('gid://shopify/Product/')[1];
    nostojs((api) => {
      api
        .defaultSession()
        .setResponseMode('JSON_ORIGINAL')
        .viewProduct(productId)
        .setPlacements(api.placements.getPlacements())
        .load()
        .then((data) => {
          if (
            data &&
            data?.recommendations &&
            data?.recommendations[nostoPlacementId] &&
            data?.recommendations[nostoPlacementId]?.products
          ) {
            const nostoData =
              data?.recommendations[nostoPlacementId]?.products || [];
            fetchChildProduct(nostoData).catch(console.error);

            const nostoRef =
              data.recommendations[nostoPlacementId]?.params?.refSrc;
            setNostoRefSrc(nostoRef);
          }
        });
    });


  }, [nostoPlacementId, nostoReady, product]);

  useEffect(() => {
    if (!products?.length || metafieldKey || nostoReady) return;
    fetchFullCmsProducts();
  }, [products]);

  const triggerNostoClick = (passedProduct) => {
    if (isNostoPowered) {
      const nostoProduct = formatProductForNosto(passedProduct);
      const productId = nostoProduct?.id?.split('gid://shopify/Product/')[1];
      const nostoSectionId = '';

      nostojs((api) => {
        api
          .defaultSession()
          .setResponseMode('JSON_ORIGINAL')
          .viewProduct(productId) // id of product currently being viewed
          .setRef(productId, nostoPlacementId) // id of product and slot that resulted in the product view
          .setPlacements([nostoSectionId]) // placements to request content for on the product page
          .update()
          .then((data) => {});
      });

      nostojs((api) => {
        api
          .defaultSession()
          .recordAttribution('vp', productId, nostoRefSrc)
          .done();
      });

      const nostoCookie = getCookie('nosto-session');

      const query = `mutation {
        updateSession(by: BY_CID, id: "${nostoCookie}",
          params: {
            event: {
              type: VIEWED_PRODUCT
              target: "${productId}"
              ref: "${nostoPlacementId}"
            }
          }
        ) {
          id
        }
      }`;

      sendGraphlEvent(query);
    }
  };

  const sliderProducts = metaProducts?.length > 0 ? metaProducts : fullProducts;

  const isVisible = metafieldKey
    ? !!(metafieldKey && metafields?.bylt?.[metafieldKey])
    : true;

  const isNostoProducts = nostoReady && metaProducts?.length > 0;

  return (
    isVisible && (
      <div
        className={containedClass}
        {...(isNostoProducts ? { 'data-nosto-products': true } : null)}
      >
        <div className="relative m-auto flex flex-col items-center">
          <div className="flex flex-col">
            {heading && (
              <h2
                className={`${headingSize} px-4 text-center ${
                  subHeading ? 'mb-0' : 'mb-[15px] lg:mb-[30px]'
                }`}
                style={{ color: textColor }}
              >
                {heading}
              </h2>
            )}

            {subHeading && (
              <p className="mb-[15px] text-center text-sm lg:mb-[30px]">
                {subHeading}
              </p>
            )}
          </div>

          {sliderProducts?.length > 0 && (
            <>
              <Swiper
                centeredSlides={
                  isFullBleedAndCentered &&
                  sliderProducts.length >= slidesPerViewMobile * 2
                }
                className={`relative mt-0 w-full ${swiperPadding} ${maxWidthClass} ${
                  sliderStyle === 'fullBleedWithGradient'
                    ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
                    : ''
                }`}
                grabCursor
                loop={true}
                modules={[Navigation]}
                onSwiper={setSwiper}
                slidesOffsetAfter={0}
                slidesOffsetBefore={0}
                slidesPerView={slidesPerViewMobile}
                spaceBetween={16}
                breakpoints={{
                  768: {
                    centeredSlides:
                      isFullBleedAndCentered &&
                      sliderProducts.length >= slidesPerViewTablet * 2,
                    slidesPerView: slidesPerViewTablet,
                    spaceBetween: 20,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                  },
                  1024: {
                    centeredSlides:
                      isFullBleedAndCentered &&
                      sliderProducts.length >= slidesPerViewDesktop * 2,
                    slidesPerView: slidesPerViewDesktop,
                    spaceBetween: 20,
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 0,
                  },
                }}
              >
                {swiper &&
                  sliderProducts.map((sliderProduct, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div onClick={() => triggerNostoClick(sliderProduct)}>
                          <ProductItem
                            enabledColorNameOnHover={
                              productItem?.enabledColorNameOnHover
                            }
                            enabledColorSelector={
                              productItem?.enabledColorSelector
                            }
                            enabledQuickShop={productItem?.enabledQuickShop}
                            enabledStarRating={productItem?.enabledStarRating}
                            enabledVariantPrice={
                              productItem?.enabledVariantPrice
                            }
                            enabledVariantTitle={
                              productItem?.enabledVariantTitle
                            }
                            fullProduct={sliderProduct}
                            handle={sliderProduct?.handle}
                            index={index}
                            swatchesMap={swatchesMap}
                            imageLoadingMode={productItem?.imageLoadingMode}
                            fetchMode
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>

              <div
                className={`z-1 absolute left-0 right-0 ${
                  heading ? 'top-[45%] md:top-[48%]' : 'top-[37%] md:top-[40%]'
                } ${section?.fullWidth ? 'px-2' : 'px-4'} md:px-2 ${
                  sliderProducts?.length > slidesPerViewMobile
                    ? 'block'
                    : 'hidden'
                }  ${
                  sliderProducts?.length > slidesPerViewTablet
                    ? 'md:block'
                    : 'md:hidden'
                } ${
                  sliderProducts?.length > slidesPerViewDesktop
                    ? 'lg:block'
                    : 'lg:hidden'
                }`}
              >
                <div
                  className={`relative mx-auto ${maxWidthClass} ${
                    isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                  }`}
                >
                  <button
                    aria-label="See previous products"
                    className={`swiper-button-prev left-0 !h-[46px] !w-[46px] bg-white drop-shadow-[0_7px_29px_rgba(100,100,111,.2)] after:hidden ${
                      !isFullBleedAndCentered
                        ? section.fullWidth
                          ? 'left-0'
                          : '!left-[-23px]'
                        : ''
                    }`}
                    onClick={() => swiper.slidePrev()}
                    type="button"
                  >
                    <Svg
                      className="w-[1rem] text-black"
                      src="/svgs/chevron-left.svg#chevron-left"
                      title="Arrow Left"
                      viewBox="0 0 24 24"
                    />
                  </button>

                  <button
                    aria-label="See next products"
                    className={`swiper-button-next right-0 !h-[46px] !w-[46px] bg-white drop-shadow-[0_7px_29px_rgba(100,100,111,.2)] after:hidden ${
                      !isFullBleedAndCentered
                        ? section.fullWidth
                          ? 'right-0'
                          : '!right-[-23px]'
                        : ''
                    }`}
                    onClick={() => swiper.slideNext()}
                    type="button"
                  >
                    <Svg
                      className="w-[1rem] text-black"
                      src="/svgs/chevron-right.svg#chevron-right"
                      title="Arrow Right"
                      viewBox="0 0 24 24"
                    />
                  </button>
                </div>
              </div>
            </>
          )}

          {!swiper && (
            <div className="relative flex min-h-[20rem] items-center justify-center">
              <Spinner
                width="32"
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </div>
          )}

          {button?.text && (
            <div className="mt-10">
              <Link
                aria-label={button.text}
                className={`${section?.buttonStyle || 'btn-primary'}`}
                href={button.url}
                newTab={button.newTab}
                type={button.type}
              >
                {button.text}
              </Link>
            </div>
          )}

          {nostoPlacement && (
            <div
              className="hidden"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: nostoPlacement,
              }}
            />
          )}
        </div>
      </div>
    )
  );
}

ProductsSlider.displayName = 'ProductsSlider';
ProductsSlider.Schema = Schema;
