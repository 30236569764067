import { useCallback, useEffect, useState, useMemo } from 'react';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import {
  fetchProductsFromHandles,
  fetchVariantMetafields,
} from '../../../utilities';
import { ProductColorOptionValue } from './ProductColorOptionValue';
import { ProductOptionValue } from './ProductOptionValue';
import { useGlobalContext } from '../../../contexts';
import { useSwatchesDesing } from '../../../hooks';
import { SizeGuideModal, WairPlacement } from '../../../snippets';

export function GroupedProductOptions({
  product,
  selectedOptions,
  selectedVariant,
  setSelectedOptions,
  metafields,
  isSizeSelected,
  setIsSizeSelected,
  setIsThirdSelected,
  selectedProduct,
  setSelectedProduct,
}) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });

  //// NEW STUFF /////
  const [childProducts, setChildProducts] = useState([]);
  const [groupedVariants,  setGroupedVariants] = useState([])

  const { inventory: selectedInventory, success: selectedInventoryFetched } =
    useProductInventoryByHandle({
      handle: selectedProduct?.handle || product?.handle,
      withInventory: false,
    });

  const handleChildSwap = (e) => {
    const found = childProducts.find((el) => el.optionsMap.Size[0] === e.Size);

    if (found) {
      setSelectedProduct(found);
    }
  };
  ////

  const [variantMetafields, setVariantMetafields] = useState(null);

  const [groupingProductsMapByColor, setGroupingProductsMapByColor] =
    useState(null);

  const [parentProduct, setParentProduct] = useState(null);

  const handleSizeGuideClick = useCallback(() => {
    // example modal
    openModal(<SizeGuideModal product={product} />);
  }, []);

  useEffect(() => {
    if (!product?.grouping?.isTransformed) return;

    const fetchProductsForMap = async () => {
      const isVariant99 = product?.metafields?.find(
        (el) => el.key === 'custom_additional_variants'
      );

      const childHandles = [...product.grouping.products];

      const products = await fetchProductsFromHandles(childHandles);

      if (products[0].handle !== product.handle) {
        setParentProduct(products[0]);
      }

      products.shift();

      const preGroupVariants = []
      
      products.forEach(el => {
        preGroupVariants = preGroupVariants.concat(el.variants)
      })

      setChildProducts(products);
      setSelectedProduct(products[0]);
      setGroupedVariants(preGroupVariants)

      setGroupingProductsMapByColor(
        products?.reduce((acc, groupProduct) => {
          const size = groupProduct.optionsMap?.Size?.[0];
          if (!size) return acc;
          return {
            ...acc,
            [size]: groupProduct,
          };
        }, {})
      );
    };
    fetchProductsForMap();
  }, [product?.id]);

  const metafieldsInUse = parentProduct
    ? parentProduct?.metafields
    : product?.metafields;

  const variantGroups = metafieldsInUse
    // grab only the relevant metafields
    .filter(
      (meta) =>
        meta?.namespace === 'custom_fields' &&
        (meta?.key.includes('color_headline') || meta?.key.includes('colors'))
    )
    // sort on key to have headline above color array
    .sort((prev, curr) => prev.key - curr.key)
    // reduce the array of objects to one object and turn string of colors to an array
    .reduce(
      (obj, { key, value }) =>
        Object.assign(obj, {
          [key]: value.includes(',') ? value.split(',') : [value],
        }),
      {}
    );

  useEffect(() => {
    if (!selectedVariant) return;

    const fetchMetafieldsData = async () => {
      const metafields = await fetchVariantMetafields(selectedVariant?.id);

      setVariantMetafields(metafields);
    };
    fetchMetafieldsData();
  }, [selectedVariant]);

  const modelInfo =
    variantMetafields?.custom_fields?.model_details ||
    metafields?.custom_fields?.model_details ||
    '';

  //Swatches size classes
  const sizeSwatchDesign = useSwatchesDesing({ product });

  return (
    <div className="flex flex-col gap-2.5 md:gap-5">
      {product.options?.map(({ id, name, values }, optionIndex) => {
        const isSize = name === 'Size';
        const productOrGroupingValues = product.grouping?.isTransformed
          ? product.grouping.optionsMap[name]
          : values;

        return (
          <div key={id} className="border-border p-4 max-md:px-0 md:border">
            {name !== 'Color' ? (
              <>
                <div className="mb-2 flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <h3 className="pdp-option-label-b">{name}:</h3>

                    {isSizeSelected && (
                      <p className="pdp-option-label-g">
                        {selectedOptions?.[name]}
                      </p>
                    )}
                  </div>
                </div>

                <ul
                  className={`mb-3.5 flex flex-wrap gap-2 ${sizeSwatchDesign}`}
                  data-wair-option-name={name}
                >
                  {productOrGroupingValues?.map((value) => {
                    const isSelected = selectedOptions?.[name] === value;

                    return (
                      <li key={value}>
                        <ProductOptionValue
                          groupingProductsMapBySize={groupingProductsMapByColor}
                          inventory={selectedInventory}
                          isLoading={!inventoryFetched}
                          isSelected={isSelected}
                          name={name}
                          product={selectedProduct}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          value={value}
                          isFromGrouping={isSize}
                          setIsSizeSelected={(e) => optionIndex > 1 ? setIsThirdSelected(e) : setIsSizeSelected(e)}
                          setChild={handleChildSwap}
                          childVariants={groupedVariants}
                        />
                      </li>
                    );
                  })}
                </ul>

                {isSize && (
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm leading-none">{modelInfo}</p>
                    </div>

                    <div className="flex gap-2">
                      <button
                        className="flex flex-row gap-2.5 text-sm leading-none underline"
                        onClick={handleSizeGuideClick}
                        type="button"
                      >
                        Size Guide
                      </button>

                      <p className="leading-none">|</p>

                      <WairPlacement product={product}/>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="mb-2 flex items-center justify-between gap-2">
                  <div className="flex items-center gap-2">
                    <h3 className="pdp-option-label-b">
                      {variantGroups.color_headline ?? name}:
                    </h3>
                    <p className="pdp-option-label-g">
                      {!variantGroups.alternate_colors?.find(
                        (color) =>
                          color === selectedOptions?.[name].toLowerCase()
                      ) &&
                      !variantGroups.alternate_colors_2?.find(
                        (color) =>
                          color === selectedOptions?.[name].toLowerCase()
                      )
                        ? selectedOptions?.[name]
                        : ''}
                    </p>
                  </div>
                </div>

                <ul className="mb-2 flex flex-wrap gap-4">
                  {productOrGroupingValues
                    ?.filter(
                      (value) =>
                        !variantGroups.alternate_colors?.find(
                          (v) => v === value.toLowerCase()
                        ) &&
                        !variantGroups.alternate_colors_2?.find(
                          (v) => v === value.toLowerCase()
                        )
                    )
                    .map((value) => {
                      const isSelected = selectedOptions?.[name] === value;

                      return (
                        <li key={value}>
                          <ProductColorOptionValue
                            inventory={selectedInventory}
                            isLoading={!inventoryFetched}
                            isSelected={isSelected}
                            name={name}
                            product={selectedProduct}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            value={value}
                            isGroup={true}
                            fallbackChildProduct={childProducts[0]}
                            groupedVariants={groupedVariants}
                            isSizeSelected={isSizeSelected}
                          />
                        </li>
                      );
                    })}
                </ul>

                {variantGroups.alternate_color_headline && (
                  <>
                    <div className="mb-2 flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <h3 className="pdp-option-label-b">
                          {variantGroups.alternate_color_headline}:
                        </h3>
                        <p className="pdp-option-label-g">
                          {variantGroups.alternate_colors?.find(
                            (color) =>
                              color === selectedOptions?.[name].toLowerCase()
                          ) && selectedOptions?.[name]}
                        </p>
                      </div>
                    </div>

                    <ul className="mb-2 flex flex-wrap gap-4">
                      {productOrGroupingValues
                        ?.filter((value) => {
                          return variantGroups.alternate_colors?.find(
                            (v) => v === value.toLowerCase()
                          );
                        })
                        .map((value) => {
                          const isSelected = selectedOptions?.[name] === value;

                          return (
                            <li key={value}>
                              <ProductColorOptionValue
                                groupingProductsMapByColor={
                                  groupingProductsMapByColor
                                }
                                inventory={selectedInventory}
                                isLoading={!inventoryFetched}
                                isSelected={isSelected}
                                name={name}
                                product={selectedProduct}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                value={value}
                                isGroup={true}
                                fallbackChildProduct={childProducts[0]}
                                groupedVariants={groupedVariants}
                                isSizeSelected={isSizeSelected}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  </>
                )}

                {variantGroups.alternate_color_headline_2 && (
                  <>
                    <div className="mb-2 flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <h3 className="pdp-option-label-b">
                          {variantGroups.alternate_color_headline_2}:
                        </h3>
                        <p className="pdp-option-label-g">
                          {variantGroups.alternate_colors_2?.find(
                            (color) =>
                              color === selectedOptions?.[name].toLowerCase()
                          ) && selectedOptions?.[name]}
                        </p>
                      </div>
                    </div>

                    <ul className="mb-2 flex flex-wrap gap-4">
                      {productOrGroupingValues
                        ?.filter((value) =>
                          variantGroups.alternate_colors_2?.find(
                            (v) => v === value.toLowerCase()
                          )
                        )
                        .map((value) => {
                          const isSelected = selectedOptions?.[name] === value;

                          return (
                            <li key={value}>
                              <ProductColorOptionValue
                                groupingProductsMapByColor={
                                  groupingProductsMapByColor
                                }
                                inventory={selectedInventory}
                                isLoading={!inventoryFetched}
                                isSelected={isSelected}
                                name={name}
                                product={selectedProduct}
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                value={value}
                                isGroup={true}
                                fallbackChildProduct={childProducts[0]}
                                groupedVariants={groupedVariants}
                                isSizeSelected={isSizeSelected}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

GroupedProductOptions.displayName = 'GroupedProductOptions';
