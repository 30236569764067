import { COLORS, OBJECT_POSITIONS, FONT_OPTIONS } from '../../settings/common';

export function Schema() {
  return {
    category: 'Media',
    label: 'Tracking Order',
    key: 'tracking-order',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/three-tiles-preview.jpg?v=1675730352',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Track My Order',
      },
      {
        label: 'Tracking Button Text',
        name: 'trackingBtnText',
        component: 'text',
        defaultValue: 'FIND ORDER',
      },
      {
        label: 'Loading Text',
        name: 'loadingText',
        component: 'text',
        defaultValue: 'Please wait...',
      },
      {
        label: 'Back Button Text',
        name: 'backButtonText',
        component: 'text',
        defaultValue: '< Back to tracking',
      },
      {
        label: 'Order Not Found Text',
        name: 'notFoundText',
        component: 'text',
        defaultValue: `Sorry, we couldn’t find that order number. Please double-check
        your order confirmation email to ensure that the number you
        entered is correct.`,
      }
    ],
  };
}
