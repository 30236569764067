import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { store, useCustomer, useCartItems } from '@backpackjs/storefront';

import {
  fetchCustomerOrders,
  sendGraphlEvent,
  setCookie,
  getCookie,
  nostoMapLineItems,
  getIdFromRawShopifyId,
} from './utils';

export function useDataLayerInit({ DEBUG, pageTitle, template }) {
  const asPathRef = useRef(null);
  const customer = useCustomer();
  const { asPath } = useRouter();
  const cartItems = useCartItems();

  const accessToken = store.recoil.useRecoilValue(store.state.accessToken);
  const [userProperties, setUserProperties] = useState(null);

  const customerPending = typeof customer === 'undefined';
  const pathname = asPath?.split('?')[0];

  const generateUserProperties = useCallback(
    async ({ customer: _customer, accessToken: _accessToken }) => {
      let _userProperties = {};
      if (_customer) {
        const orders = await fetchCustomerOrders({
          accessToken: _accessToken,
        });
        _userProperties = {
          visitor_type: 'logged_in',
          user_consent: '',
          customer_address_1: _customer.defaultAddress?.address1 || '',
          customer_address_2: _customer.defaultAddress?.address2 || '',
          customer_city: _customer.defaultAddress?.city || '',
          customer_country: _customer.defaultAddress?.country || '',
          customer_country_code: _customer.defaultAddress?.countryCode || '',
          customer_email: _customer.email,
          customer_first_name: _customer.firstName,
          customer_id: _customer.id?.split('/').pop() || '',
          customer_last_name: _customer.lastName,
          customer_order_count: `${orders?.length || 0}`,
          customer_phone: _customer.defaultAddress?.phone || '',
          customer_province_code: _customer.defaultAddress?.provinceCode || '',
          customer_province: _customer.defaultAddress?.province || '',
          customer_tags: _customer.tags?.join(', ') || '',
          customer_total_spent: orders
            ?.reduce((acc, order) => {
              return acc + parseFloat(order.totalPriceV2?.amount || 0);
            }, 0)
            .toFixed(2),
          customer_zip: _customer.defaultAddress?.zip || '',
        };
      } else {
        _userProperties = {
          visitor_type: 'guest',
          user_consent: '',
        };
      }
      setUserProperties(_userProperties);
      return _userProperties;
    },
    []
  );

  const nostoGraphPageView = async () => {
    const nostoCookie = getCookie('nosto-session');
    const cartFragment = nostoMapLineItems(cartItems);
    const customerId = getIdFromRawShopifyId(customer?.id, 'Customer');

    if (nostoCookie) {
      const query = `mutation {
        updateSession(by: BY_CID, id: "${nostoCookie}",
          params: {
            ${customer ? `customer: {
              firstName: "${customer?.firstName}"
              lastName: "${customer?.lastName}"
              marketingPermission: ${customer?.acceptsMarketing}
              customerReference: "${customerId}"
            }` : '' }
            event: {
              type: VIEWED_PAGE
              target: "${window.location.href}"
            }
            ${cartFragment}
          }
        ) {
          id
        }
      }`;

      await sendGraphlEvent(query);
    } else {
      const query = `
      mutation {
        newSession(referer: "${window.location.href}")
      }`;

      const res = await sendGraphlEvent(query);

      if (res) {
        setCookie('nosto-session', res?.data?.newSession, 30);
      }
    }
  };

  // Set user id on customer ready
  useEffect(() => {
    if (!customer) return;
  }, [customer?.id]);

  // Set page view event on path change
  useEffect(() => {
    if (pathname === '/') {
      nostojs((api) => {
        api
          .defaultSession()
          .setResponseMode('HTML')
          .viewFrontPage()
          .setPlacements(api.placements.getPlacements())
          .load()
          .then((response) => {
            response.recommendations &&
              api.placements.injectCampaigns(response.recommendations);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    } else if (
      !pathname.includes('collections') ||
      !pathname.includes('products')
    ) {
      nostojs((api) => {
        api
          .defaultSession()
          .setResponseMode('HTML')
          .viewOther()
          .setPlacements(api.placements.getPlacements())
          .load()
          .then((response) => {
            response.recommendations &&
              api.placements.injectCampaigns(response.recommendations);
          })
          .catch((e) => {});
      });
    }

    if (typeof window !== 'undefined') {
      nostoGraphPageView();
    }

    if (DEBUG) console.log(`NOSTO page view`);
  }, [pathname]);

  // Generate user properties on customer ready and path change
  useEffect(() => {
    if (
      customerPending ||
      (customer && !accessToken?.token) ||
      asPath === asPathRef.current
    )
      return undefined;
    generateUserProperties({ customer, accessToken: accessToken?.token });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [accessToken?.token, asPath, customerPending]);

  return {
    generateUserProperties,
    userProperties,
  };
}
