import { useCallback, useState, useMemo, useEffect } from 'react';
import {
  useLocalizedVariant,
  useProductByHandle,
  useSettings,
} from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { ColorVariantSelector } from './ColorVariantSelector';
import { Link } from '../Link';
import { Svg } from '../Svg';
import { ProductItemMedia } from './ProductItemMedia/ProductItemMedia';
import { ProductItemPrice } from './ProductItemPrice';
import { ProductStars } from '../ProductStars';
import { QuickShop } from './QuickShop';
import { useDataLayerActions, useProductMetafields } from '../../hooks';
import { fetchProductFromHandle } from '../../utilities';

export function ProductItem({
  enabledColorNameOnHover,
  enabledColorSelector,
  enabledStarRating,
  enabledVariantPrice = true,
  enabledVariantTitle = true,
  fullProduct,
  handle,
  index,
  isSearchResults,
  onClick,
  product: passedProduct,
  swatchesMap,
  enableLazyLoading = true,
  imageLoadingMode = 'lazy',
}) {
  const { ref, inView } = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });

  const [childProduct, setChildProduct] = useState(null);

  const { product: fetchedFullProduct } = useProductByHandle({
    handle: fullProduct ? null : handle || passedProduct?.handle,
    fetchOnMount: inView,
  });
  const { sendClickProductItemEvent } = useDataLayerActions();

  const initialProduct = useMemo(() => {
    if (childProduct) return childProduct;
    if (fullProduct) return fullProduct;
    if (!passedProduct?.loaded) return fetchedFullProduct;
    return {
      ...passedProduct,
      ...fetchedFullProduct,
    };
  }, [fetchedFullProduct, fullProduct, passedProduct, childProduct]);

  const selectedProduct = useMemo(() => {
    return initialProduct;
  }, [initialProduct]);

  const [variantFromColorSelector, setVariantFromColorSelector] =
    useState(null);

  const selectedVariant = variantFromColorSelector
    ? variantFromColorSelector
    : selectedProduct?.variants?.[0];

  const metafields = useProductMetafields({ product: fetchedFullProduct });

  const variantUrl = selectedVariant
    ? `?variant=${
        selectedVariant?.id?.split('gid://shopify/ProductVariant/')[1]
      }`
    : '';

  const productUrl = selectedProduct
    ? selectedProduct?.grouping?.isTransformed
      ? `/products/${handle}`
      : `/products/${selectedProduct.handle}${variantUrl}`
    : '';

  const color =
    selectedVariant?.selectedOptionsMap?.Color ||
    selectedVariant?.selectedOptions[0]?.value;

  const isVariantAvailable = useMemo(() => {
    if (!selectedProduct) {
      return true;
    }

    if (selectedProduct?.grouping?.isTransformed) {
      return true;
    }

    const isAvailable = selectedProduct?.variants?.some(
      (el) =>
        (el.selectedOptionsMap.Color === color ||
          el?.selectedOptions[0]?.value === color) &&
        el.availableForSale === true
    );

    return isAvailable;
  }, [selectedProduct, selectedVariant]);

  const colorLabel = isVariantAvailable ? color : 'Sold Out';

  const title =
    selectedProduct?.title?.split('<!--')[0] || selectedProduct?.title;

  const { localized } = useLocalizedVariant({ variant: selectedVariant });

  const handleClick = useCallback(() => {
    sendClickProductItemEvent({
      isSearchResult: isSearchResults,
      listIndex: index,
      localized,
      product: selectedProduct,
      selectedVariant,
    });

    if (typeof onClick === 'function') onClick();
  }, [index, localized, selectedProduct, selectedVariant?.id]);

  const compareAtPrice = selectedVariant?.compareAtPrice
    ? +selectedVariant.compareAtPrice
    : null;

  const hasDiscount = !!(
    compareAtPrice && selectedVariant?.compareAtPrice !== selectedVariant?.price
  );

  const customPrice = useMemo(() => {
    if (selectedProduct) {
      const priceTag = selectedProduct.tags.find((el) =>
        el.includes('settings_customB-price_')
      );

      return priceTag ? priceTag.split('settings_customB-price_')[1] : null;
    }

    return null;
  }, [selectedProduct]);

  const customComparedAtPrice = useMemo(() => {
    if (selectedProduct) {
      const priceTag = selectedProduct.tags.find((el) =>
        el.includes('settings_customB-compareAtPrice_')
      );

      return priceTag
        ? priceTag.split('settings_customB-compareAtPrice_')[1]
        : null;
    }

    return null;
  }, [selectedProduct]);

  const customDiscount = useMemo(() => {
    if (selectedProduct) {
      const tag = selectedProduct.tags.find((el) =>
        el.includes('settings_customB-percentage_')
      );

      return tag ? tag.split('settings_customB-percentage_')[1] : null;
    }

    return null;
  }, [selectedProduct]);

  const price = selectedVariant?.price ? +selectedVariant.price : 0;

  const badge = useMemo(() => {
    if (fullProduct && fullProduct?.metafields) {
      return fullProduct?.metafields?.find(
        (meta) => meta.key === 'p_card_fabric_iconography'
      );
    }

    return fetchedFullProduct?.metafields?.find(
      (meta) => meta.key === 'p_card_fabric_iconography'
    );
  }, [fetchedFullProduct]);

  const settings = useSettings();

  const { quickShop } = { ...settings?.product };
  const { productItem } = { ...settings?.collection };
  const isSaleBadgeEnabled = !!productItem?.isSaleBadgeEnable;
  const isTypeBadgeEnabled = !!productItem?.isTypeBadgeEnabled;
  const typeBadgeBgColor = productItem?.typeBadgeBgColor || 'var(--primary)';
  const typeBadgeTextColor = productItem?.typeBadgeTextColor || 'white'
  const isFabricBadgeEnabled = productItem?.isFabricBadgeEnabled

  const isCustomProduct = selectedProduct?.handle?.includes('-custom-pack');

  const isQuickShopEligible = useMemo(() => {
    return (
      !fetchedFullProduct?.metafields?.find((m) =>
        m.key.includes('custom_additional_variants')
      ) &&
      quickShop?.enabledQuickShop &&
      !isCustomProduct &&
      !initialProduct?.grouping?.isTransformed
    );
  }, [fetchedFullProduct, isCustomProduct, quickShop, initialProduct]);

  const isVariantSelectorEligible = enabledColorSelector && !isCustomProduct;

  const isVariantTitleEligible = enabledVariantTitle && !isCustomProduct;

  useEffect(() => {
    if (!selectedProduct) return;

    const colorAlt = selectedProduct?.featuredImage.altText
      ?.trim()
      .toLowerCase();
    const variant = selectedProduct?.variants?.find(
      (el) =>
        el.selectedOptions[0]?.value?.toLowerCase() === colorAlt ||
        colorAlt?.includes(el.selectedOptions[0]?.value?.toLowerCase())
    );

    if (variant) {
      setVariantFromColorSelector(variant);
    }
  }, [selectedProduct]);

  const displayClass = passedProduct?.title?.includes('<!-') ? 'hidden' : '';

  const typeBadge = useMemo(() => {
    if (!metafields) {
      return null;
    }

    return metafields?.bylt?.type_badge || null;
  }, [fullProduct, metafields]);

  const hasTypeBadge = !!typeBadge;

  const isFullyLoaded = useMemo(() => {
    if (initialProduct?.isGrouped) {
      return childProduct ? true : false;
    }

    if (!fetchedFullProduct && fullProduct) return true;

    return fetchedFullProduct ? true : false;
  }, [fetchedFullProduct, childProduct]);

  useEffect(() => {
    if (!selectedProduct?.grouping?.isTransformed || childProduct) return;

    const fetchChildProduct = async () => {
      const childHandles = [...selectedProduct.grouping.products];
      const handle = childHandles[1];

      const childResponse = await fetchProductFromHandle(handle);

      setChildProduct(childResponse);
    };

    fetchChildProduct();
  }, [selectedProduct]);

  const hasSaleLabel = customDiscount
    ? true
    : compareAtPrice && price && hasDiscount
    ? true
    : false;
  return (
    <div className={`flex h-full flex-col ${displayClass}`} ref={ref}>
      <div className="relative flex w-full flex-col">
        <div className="group relative mb-[13px] w-full">
          <Link
            aria-label={title}
            className="mb-3 w-full"
            href={productUrl}
            onClick={handleClick}
            tabIndex="-1"
          >
            <ProductItemMedia
              selectedProduct={selectedProduct}
              selectedVariant={selectedVariant}
              hasPriority={!enableLazyLoading}
              loadingMode={imageLoadingMode}
              isLoaded={isFullyLoaded}
            />
          </Link>
          <div className="absolute bottom-[30px] z-[9] flex w-full justify-center text-center max-lg:hidden">
            {isQuickShopEligible && (
              <QuickShop
                enabledColorSelector={enabledColorSelector}
                selectedProduct={selectedProduct}
                selectedVariant={selectedVariant}
              />
            )}
          </div>

          {hasSaleLabel && isSaleBadgeEnabled && (
            <div
              className={`absolute left-[0.625rem] z-[1] rounded-[2.5px] bg-sale ${
                (hasTypeBadge && isTypeBadgeEnabled) ? 'bottom-[0.625rem]' : 'top-[0.625rem]'
              }`}
            >
              <p className="px-[8px] py-[5px] text-xs font-bold uppercase text-white">
                {customDiscount ||
                  Math.round(100 - (price / compareAtPrice) * 100)}
                % Off
              </p>
            </div>
          )}

          {hasTypeBadge && isTypeBadgeEnabled && (
            <div
              className="absolute left-[0.625rem] top-[0.625rem] z-[1] rounded-[2.5px]"
              style={{ backgroundColor: typeBadgeBgColor, color: typeBadgeTextColor }}
            >
              <p className="px-[8px] py-[5px] text-xs font-bold uppercase">
                {typeBadge}
              </p>
            </div>
          )}
        </div>

        <div className="flex flex-1 flex-col">
          {enabledStarRating &&
            initialProduct?.legacyResourceId &&
            metafields && (
              <div className="mb-1.5">
                <Link
                  aria-label={`Reviews for ${title}`}
                  href={productUrl}
                  onClick={handleClick}
                  tabIndex="-1"
                >
                  <ProductStars
                    legacyResourceId={initialProduct.legacyResourceId}
                    stampedData={metafields?.stamped}
                  />
                </Link>
              </div>
            )}

          {hasSaleLabel && !isSaleBadgeEnabled && (
            <div
              className={`mb-1 flex flex-row md:mb-2 ${
                compareAtPrice ? 'justify-between' : 'justify-end'
              } align-middle`}
            >
              {hasSaleLabel && (
                <p className="min-h-[1.25rem] text-sm font-bold uppercase text-sale">
                  Sale{' '}
                  {customDiscount ||
                    Math.round(100 - (price / compareAtPrice) * 100)}
                  % Off
                </p>
              )}
            </div>
          )}

          <div className="flex justify-between">
            <Link aria-label={title} href={productUrl} onClick={handleClick}>
              <h3 className="card-title text-bold uppercase">{title}</h3>
            </Link>

            {badge && !badge?.value?.includes('None') && isFabricBadgeEnabled && (
              <small
                className="text-nowrap flex h-[20px] items-center whitespace-nowrap rounded-full border-[1px] border-mediumGray px-2 py-[1px] text-[7px] font-bold uppercase text-mediumGray max-md:mr-0 max-md:text-[8px] md:py-1"
                style={{ textWrap: 'nowrap' }}
              >
                {badge.value}
              </small>
            )}
          </div>

          {enabledVariantPrice && (
            <ProductItemPrice
              selectedVariant={selectedVariant}
              customPrice={customPrice}
              customComparePrice={customComparedAtPrice}
              localized={localized}
            />
          )}

          {color && isVariantTitleEligible && enabledVariantTitle && (
            <p className="mt-1 font-sans text-[12px] font-bold italic !leading-none leading-[14px] text-mediumGray">
              {colorLabel}
            </p>
          )}
        </div>
      </div>

      {isVariantSelectorEligible && (
        <ColorVariantSelector
          enabledColorNameOnHover={enabledColorNameOnHover}
          initialProduct={initialProduct}
          selectedVariant={selectedVariant}
          setVariantFromColorSelector={setVariantFromColorSelector}
          swatchesMap={swatchesMap}
          productUrl={productUrl}
        />
      )}
    </div>
  );
}

ProductItem.displayName = 'ProductItem';
ProductItem.propTypes = {
  enabledColorNameOnHover: PropTypes.bool,
  enabledColorSelector: PropTypes.bool,
  enabledStarRating: PropTypes.bool,
  handle: PropTypes.string,
  index: PropTypes.number,
  isSearchResults: PropTypes.bool,
  onClick: PropTypes.func,
  swatchesMap: PropTypes.object,
};
