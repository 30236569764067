import { useMemo } from 'react';

import { Link, Svg } from '../../snippets';

export function ArticleHeroContent({
  aboveTheFold,
  isActiveSlide,
  isFirstSlide,
  slide,
}) {
  const { button, content, link, text } = slide;

  const {
    bgColor,
    colorDesktop,
    colorMobile,
    description,
    heading,
    hideHeadingDesktop,
    hideHeadingMobile,
    padding,
    subheading,
    headingFontSize,
    subHeadingFontSize,
    descriptionFontSize,
  } = text;

  const {
    alignmentMobile,
    alignmentDesktop,
    darkOverlay,
    maxWidthMobile,
    maxWidthDesktop,
    positionMobile,
    positionDesktop,
  } = content;

  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;
  const positionClasses = `${positionMobile} ${positionDesktop}`;
  const maxWidthContentClasses = `${maxWidthMobile} ${maxWidthDesktop}`;
  const darkOverlayClass = darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : '';
  const hiddenHeadingMobileClass =
    'max-md:absolute max-md:z-[-1] max-md:opacity-0 max-md:pointer-events-none';
  const hiddenHeadingDesktopClass =
    'md:absolute md:z-[-1] md:opacity-0 md:pointer-events-none';
  const hiddenHeadingClasses = `${
    hideHeadingMobile ? hiddenHeadingMobileClass : ''
  } ${hideHeadingDesktop ? hiddenHeadingDesktopClass : ''}`;
  const hiddenButtonClasses = `${
    button?.hideButtonsMobile ? 'max-md:hidden' : ''
  } ${button?.hideButtonsDesktop ? 'md:hidden' : ''}`;
  const textColorClasses = `${colorMobile} ${colorDesktop}`;

  const headingWithBreaks = useMemo(() => {
    const splitHeading = heading?.split('\n');
    if (splitHeading?.length === 1) return heading;
    return splitHeading?.reduce((acc, line, index, arr) => {
      acc.push(<span key={index}>{line}</span>);
      if (index < arr.length - 1) acc.push(<br key={`br${index}`} />);
      return acc;
    }, []);
  }, [heading]);

  return (
    <div
      className={`pointer-events-none absolute inset-0 flex h-full w-full p-4 md:p-12 lg:px-20 ${positionClasses} ${darkOverlayClass}`}
    >
      <div
        className={`pointer-events-auto relative flex flex-col gap-6 ${alignmentClasses} ${maxWidthContentClasses} ${textColorClasses}`}
      >
        <div
          style={{ backgroundColor: bgColor }}
          className={`${hiddenHeadingClasses} ${padding}`}
        >
          {aboveTheFold && isFirstSlide ? (
            <h1
              className={`${
                headingFontSize ? headingFontSize : 'text-title-h2'
              } normal-case ${headingWithBreaks ? 'mb-5' : 'mb-0'}`}
            >
              {headingWithBreaks}
            </h1>
          ) : (
            <h2
              className={`${
                headingFontSize ? headingFontSize : 'text-title-h2'
              } normal-case ${headingWithBreaks ? 'mb-5' : 'mb-0'}`}
            >
              {headingWithBreaks}
            </h2>
          )}

          {subheading && (
            <p
              className={`${
                subHeadingFontSize
                  ? subHeadingFontSize
                  : headingWithBreaks
                  ? 'text-title-h5'
                  : 'text-title-h4 md:text-title-h3'
              } mb-5 normal-case`}
            >
              {subheading}
            </p>
          )}

          {description && (
            <p
              className={`${
                descriptionFontSize ? descriptionFontSize : 'text-sm'
              } max-lg:mb-1 normal-case`}
            >
              {description}
            </p>
          )}

          {link && (
            <div className={`mt-4 flex flex-col ${alignmentClasses}`}>
              <div className="flex flex-wrap gap-4">
                {link.links?.map((item) => (
                  <Link
                    key={`${item?.platform} ${item.link.url}`}
                    aria-label={item?.link?.text}
                    href={item?.link?.url}
                    newTab={item?.link?.newTab}
                    type={item?.link?.type}
                    className="flex flex-row gap-2 underline"
                  >
                    <Svg
                      className="w-4 text-white"
                      src={`/svgs/noprecache/social/${item.platform}.svg#${item.platform}`}
                      title={item.platform}
                      viewBox="0 0 24 24"
                    />
                    {item?.link?.text}
                  </Link>
                ))}
              </div>
            </div>
          )}

          {button?.buttons?.length > 0 && (
            <ul
              className={`mt-5 flex flex-col gap-4 ${hiddenButtonClasses} ${alignmentClasses}`}
            >
              {button?.buttons?.slice(0, 2).map((buttonLink, index) => (
                <li key={index}>
                  {button.clickableSlide ? (
                    <button
                      type="button"
                      aria-label={buttonLink?.link?.text}
                      className={buttonLink?.style}
                    >
                      {buttonLink?.link.text}
                    </button>
                  ) : (
                    <Link
                      aria-label={buttonLink?.link?.text}
                      className={buttonLink?.style}
                      href={buttonLink?.link?.url}
                      newTab={buttonLink?.link?.newTab}
                      type={buttonLink?.link?.type}
                    >
                      {buttonLink?.link?.text}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {isActiveSlide && button?.clickableSlide && (
        <Link
          aria-label={button.buttons?.[0]?.link?.text}
          className="pointer-events-auto absolute inset-0 z-[1] h-full w-full"
          href={button.buttons?.[0]?.link?.url}
          type={button.buttons?.[0]?.link?.type}
        />
      )}
    </div>
  );
}

ArticleHeroContent.displayName = 'ArticleHeroContent';
