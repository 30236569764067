import {
  COLORS,
  OBJECT_POSITIONS,
  VERTICAL_PADDING,
  FONT_OPTIONS,
  FLEX_POSITIONS,
  TEXT_ALIGN,
  TEXT_COLORS,
} from '../../settings/common';

export function Schema() {
  return {
    category: 'Collection',
    label: 'Collection Tiles',
    key: 'collection-tiles',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/Screenshot_2023-10-31_at_2.30.46_PM.png?v=1698780701',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Collection Tiles Heading',
      },
      {
        label: 'Heading Font Size',
        name: 'headingFontSize',
        component: 'select',
        options: FONT_OPTIONS,
        default: '',
      },
      {
        label: 'Tiles',
        name: 'tiles',
        description: 'Max of 3 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 3,
        },
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Image Position',
            name: 'position',
            component: 'select',
            options: OBJECT_POSITIONS.mobile,
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Heading Font Size',
            name: 'headingFontSize',
            component: 'select',
            options: FONT_OPTIONS,
            default: '',
          },
          {
            label: '(Desktop) Text Color ',
            name: 'textColor',
            component: 'select',
            options: TEXT_COLORS.desktop,
          },
          {
            label: '(Mobile) Text Color',
            name: 'textColorMob',
            component: 'select',
            options: TEXT_COLORS.mobile,
          },
          {
            label: 'Text Alignment',
            name: 'textAlign',
            component: 'select',
            options: TEXT_ALIGN.mobile,
          },
          {
            label: 'Superheading',
            name: 'superheading',
            component: 'text',
          },
          {
            label: 'Super Heading Font Size',
            name: 'superHeadingFontSize',
            component: 'select',
            options: FONT_OPTIONS,
            default: '',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: '(Desktop) Button Style ',
            name: 'style',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
          {
            label: '(Mobile) Button Style',
            name: 'styleMob',
            component: 'select',
            options: [
              { label: 'Primary', value: 'max-md:btn-primary' },
              { label: 'Secondary', value: 'max-md:btn-secondary' },
              { label: 'Inverse Light', value: 'max-md:btn-inverse-light' },
              { label: 'Inverse Dark', value: 'max-md:btn-inverse-dark' },
            ],
          },
          {
            label: 'Hide Buttons',
            name: 'hideButtons',
            description: `Hide buttons and makes entire image clickable using primary button's link; hides any secondary button`,
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Content Position',
            name: 'contentPosition',
            component: 'select',
            options: [
              {
                value: 'justify-start items-start',
                label: 'Top Left',
              },
              {
                value: 'justify-start items-center',
                label: 'Top Center',
              },
              {
                value: 'justify-start items-end',
                label: 'Top Right',
              },
              {
                value: 'justify-center items-start',
                label: 'Center Left',
              },
              {
                value: 'justify-center items-center',
                label: 'Center Center',
              },
              {
                value: 'justify-center items-end',
                label: 'Center Right',
              },
              {
                value: 'justify-end items-start',
                label: 'Bottom Left',
              },
              {
                value: 'justify-end items-center',
                label: 'Bottom Center',
              },
              {
                value: 'justify-end items-end',
                label: 'Bottom Right',
              },
            ],
          },
          {
            label: '(Desktop) Titles Outside',
            description: 'Enables a mode to use the titles outside the image.',
            name: 'titlesOutside',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: '(Mobile) Titles Outside',
            description: 'Enables an expanded white space at the bottom used only in mobile.',
            name: 'isExpandedMobile',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          }
        ],
        defaultItem: {
          alt: 'Man in white and light tan outfit',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
          },
          position: 'object-center',
          heading: 'All new products\nthis season',
          superheading: '',
          link: {
            text: '',
            url: '',
            style: 'btn-primary',
          },
        },
        defaultValue: [
          {
            alt: 'Man in white and light tan outfit',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
            },
            position: 'object-center',
            heading: 'All new products\nthis season',
            superheading: '',
          },
          {
            alt: 'Man in brown coat sitting down',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/austin-wade-d2s8NQ6WD24-unsplash.jpg?v=1672348122',
            },
            position: 'object-center',
            heading: 'All new products\nthis season',
            superheading: '',
          },
          {
            alt: 'Man in gray sweater and tan coat',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-poses-in-light-colored-overcoat.jpg?v=1672348143',
            },
            position: 'object-center',
            subheading: 'New styles and new fits',
            heading: 'All new products\nthis season',
            superheading: '',
          },
        ],
      },
      {
        label: 'Footer Button',
        name: 'button',
        component: 'link',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Image aspect ratio, background color, text color, full width',
        fields: [
          {
            label: 'Height (desktop)',
            name: 'desktop',
            component: 'group',
            description: 'Static height, aspect ratio, min and max height',
            fields: [
              {
                label: 'Height Type',
                name: 'heightType',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                options: [
                  { label: 'Static', value: 'static' },
                  { label: 'Aspect Ratio', value: 'aspect-ratio' },
                ],
              },
              {
                label: 'Static Height',
                name: 'staticHeight',
                component: 'select',
                description: 'If enabled, hero set to static height',
                options: [
                  { label: '200px', value: 'lg:h-[12.5rem]' },
                  { label: '300px', value: 'lg:h-[18.75rem]' },
                  { label: '400px', value: 'lg:h-[25rem]' },
                  { label: '500px', value: 'lg:h-[31.25rem]' },
                  { label: '600px', value: 'lg:h-[37.5rem]' },
                  { label: '700px', value: 'lg:h-[43.75rem]' },
                  { label: '800px', value: 'lg:h-[50rem]' },
                ],
              },
              {
                label: 'Aspect Ratio',
                name: 'aspectRatio',
                component: 'select',
                description: 'If enabled, hero set to width:height ratio',
                options: [
                  { label: '3:1', value: 'lg:aspect-[3/1]' },
                  { label: '5:2', value: 'lg:aspect-[5/2]' },
                  { label: '2:1', value: 'lg:aspect-[2/1]' },
                  { label: '16:9', value: 'lg:aspect-[16/9]' },
                  { label: '3:2', value: 'lg:aspect-[3/2]' },
                  { label: '4:3', value: 'lg:aspect-[4/3]' },
                  { label: '5:4', value: 'lg:aspect-[5/4]' },
                  { label: '1:1', value: 'lg:aspect-[1/1]' },
                  { label: '4:5', value: 'lg:aspect-[4/5]' },
                  { label: '3:4', value: 'lg:aspect-[3/4]' },
                  { label: '2:3', value: 'lg:aspect-[2/3]' },
                  { label: '9:16', value: 'lg:aspect-[9/16]' },
                ],
              },
              {
                label: 'Aspect Ratio Min Height',
                name: 'minHeight',
                component: 'select',
                description: 'Min height on desktop regardless of aspect ratio',
                options: [
                  { label: 'None', value: '' },
                  { label: '200px', value: 'lg:min-h-[12.5rem]' },
                  { label: '300px', value: 'lg:min-h-[18.75rem]' },
                  { label: '400px', value: 'lg:min-h-[25rem]' },
                  { label: '500px', value: 'lg:min-h-[31.25rem]' },
                  { label: '600px', value: 'lg:min-h-[37.5rem]' },
                  { label: '700px', value: 'lg:min-h-[43.75rem]' },
                  { label: '800px', value: 'lg:min-h-[50rem]' },
                ],
              },
              {
                label: 'Aspect Ratio Max Height',
                name: 'maxHeight',
                component: 'select',
                description: 'Max height aspect ratio can grow to on desktop',
                options: [
                  { label: 'None', value: '' },
                  { label: '200px', value: 'lg:max-h-[12.5rem]' },
                  { label: '300px', value: 'lg:max-h-[18.75rem]' },
                  { label: '400px', value: 'lg:max-h-[25rem]' },
                  { label: '500px', value: 'lg:max-h-[31.25rem]' },
                  { label: '600px', value: 'lg:max-h-[37.5rem]' },
                  { label: '700px', value: 'lg:max-h-[43.75rem]' },
                  { label: '800px', value: 'lg:max-h-[50rem]' },
                ],
              },
            ],
            defaultValue: {
              heightType: 'static',
              staticHeight: 'lg:h-[43.75rem]',
              aspectRatio: 'lg:aspect-[2/1]',
              minHeight: '',
              maxHeight: '',
            },
          },
          {
            label: 'Height (tablet)',
            name: 'tablet',
            component: 'group',
            description: 'Static height, aspect ratio, min and max height',
            fields: [
              {
                label: 'Height Type',
                name: 'heightType',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                options: [
                  { label: 'Static', value: 'static' },
                  { label: 'Aspect Ratio', value: 'aspect-ratio' },
                ],
              },
              {
                label: 'Static Height',
                name: 'staticHeight',
                component: 'select',
                description: 'If enabled, hero set to static height',
                options: [
                  { label: '200px', value: 'md:max-lg:h-[12.5rem]' },
                  { label: '300px', value: 'md:max-lg:h-[18.75rem]' },
                  { label: '400px', value: 'md:max-lg:h-[25rem]' },
                  { label: '500px', value: 'md:max-lg:h-[31.25rem]' },
                  { label: '600px', value: 'md:max-lg:h-[37.5rem]' },
                  { label: '700px', value: 'md:max-lg:h-[43.75rem]' },
                  { label: '800px', value: 'md:max-lg:h-[50rem]' },
                ],
              },
              {
                label: 'Aspect Ratio',
                name: 'aspectRatio',
                component: 'select',
                description: 'If enabled, hero set to width:height ratio',
                options: [
                  { label: '3:1', value: 'md:max-lg:aspect-[3/1]' },
                  { label: '5:2', value: 'md:max-lg:aspect-[5/2]' },
                  { label: '2:1', value: 'md:max-lg:aspect-[2/1]' },
                  { label: '16:9', value: 'md:max-lg:aspect-[16/9]' },
                  { label: '3:2', value: 'md:max-lg:aspect-[3/2]' },
                  { label: '4:3', value: 'md:max-lg:aspect-[4/3]' },
                  { label: '5:4', value: 'md:max-lg:aspect-[5/4]' },
                  { label: '1:1', value: 'md:max-lg:aspect-[1/1]' },
                  { label: '4:5', value: 'md:max-lg:aspect-[4/5]' },
                  { label: '3:4', value: 'md:max-lg:aspect-[3/4]' },
                  { label: '2:3', value: 'md:max-lg:aspect-[2/3]' },
                  { label: '9:16', value: 'md:max-lg:aspect-[9/16]' },
                ],
              },
              {
                label: 'Aspect Ratio Min Height',
                name: 'minHeight',
                component: 'select',
                description: 'Min height on tablet regardless of aspect ratio',
                options: [
                  { label: 'None', value: '' },
                  { label: '200px', value: 'md:max-lg:min-h-[12.5rem]' },
                  { label: '300px', value: 'md:max-lg:min-h-[18.75rem]' },
                  { label: '400px', value: 'md:max-lg:min-h-[25rem]' },
                  { label: '500px', value: 'md:max-lg:min-h-[31.25rem]' },
                  { label: '600px', value: 'md:max-lg:min-h-[37.5rem]' },
                  { label: '700px', value: 'md:max-lg:min-h-[43.75rem]' },
                  { label: '800px', value: 'md:max-lg:min-h-[50rem]' },
                ],
              },
              {
                label: 'Aspect Ratio Max Height',
                name: 'maxHeight',
                component: 'select',
                description: 'Max height aspect ratio can grow to on tablet',
                options: [
                  { label: 'None', value: '' },
                  { label: '200px', value: 'md:max-lg:max-h-[12.5rem]' },
                  { label: '300px', value: 'md:max-lg:max-h-[18.75rem]' },
                  { label: '400px', value: 'md:max-lg:max-h-[25rem]' },
                  { label: '500px', value: 'md:max-lg:max-h-[31.25rem]' },
                  { label: '600px', value: 'md:max-lg:max-h-[37.5rem]' },
                  { label: '700px', value: 'md:max-lg:max-h-[43.75rem]' },
                  { label: '800px', value: 'md:max-lg:max-h-[50rem]' },
                ],
              },
            ],
            defaultValue: {
              heightType: 'static',
              staticHeight: 'md:max-lg:h-[37.5rem]',
              aspectRatio: 'md:max-lg:aspect-[16/9]',
              minHeight: '',
              maxHeight: '',
            },
          },
          {
            label: 'Height (mobile)',
            name: 'mobile',
            component: 'group',
            description: 'Static height, aspect ratio, min and max height',
            fields: [
              {
                label: 'Height Type',
                name: 'heightType',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                options: [
                  { label: 'Static', value: 'static' },
                  { label: 'Aspect Ratio', value: 'aspect-ratio' },
                ],
              },
              {
                label: 'Static Height',
                name: 'staticHeight',
                component: 'select',
                description: 'If enabled, hero set to static height',
                options: [
                  { label: '200px', value: 'max-md:h-[12.5rem]' },
                  { label: '300px', value: 'max-md:h-[18.75rem]' },
                  { label: '400px', value: 'max-md:h-[25rem]' },
                  { label: '500px', value: 'max-md:h-[31.25rem]' },
                  { label: '600px', value: 'max-md:h-[37.5rem]' },
                  { label: '700px', value: 'max-md:h-[43.75rem]' },
                  { label: '800px', value: 'max-md:h-[50rem]' },
                ],
              },
              {
                label: 'Aspect Ratio',
                name: 'aspectRatio',
                component: 'select',
                description: 'If enabled, hero set to width:height ratio',
                options: [
                  { label: '3:1', value: 'max-md:aspect-[3/1]' },
                  { label: '5:2', value: 'max-md:aspect-[5/2]' },
                  { label: '2:1', value: 'max-md:aspect-[2/1]' },
                  { label: '16:9', value: 'max-md:aspect-[16/9]' },
                  { label: '3:2', value: 'max-md:aspect-[3/2]' },
                  { label: '4:3', value: 'max-md:aspect-[4/3]' },
                  { label: '5:4', value: 'max-md:aspect-[5/4]' },
                  { label: '1:1', value: 'max-md:aspect-[1/1]' },
                  { label: '4:5', value: 'max-md:aspect-[4/5]' },
                  { label: '3:4', value: 'max-md:aspect-[3/4]' },
                  { label: '2:3', value: 'max-md:aspect-[2/3]' },
                  { label: '9:16', value: 'max-md:aspect-[9/16]' },
                ],
              },
              {
                label: 'Aspect Ratio Min Height',
                name: 'minHeight',
                component: 'select',
                description: 'Min height on mobile regardless of aspect ratio',
                options: [
                  { label: 'None', value: '' },
                  { label: '200px', value: 'max-md:min-h-[12.5rem]' },
                  { label: '300px', value: 'max-md:min-h-[18.75rem]' },
                  { label: '400px', value: 'max-md:min-h-[25rem]' },
                  { label: '500px', value: 'max-md:min-h-[31.25rem]' },
                  { label: '600px', value: 'max-md:min-h-[37.5rem]' },
                  { label: '700px', value: 'max-md:min-h-[43.75rem]' },
                  { label: '800px', value: 'max-md:min-h-[50rem]' },
                ],
              },
              {
                label: 'Aspect Ratio Max Height',
                name: 'maxHeight',
                component: 'select',
                description: 'Max height aspect ratio can grow to on mobile',
                options: [
                  { label: 'None', value: '' },
                  { label: '200px', value: 'max-md:max-h-[12.5rem]' },
                  { label: '300px', value: 'max-md:max-h-[18.75rem]' },
                  { label: '400px', value: 'max-md:max-h-[25rem]' },
                  { label: '500px', value: 'max-md:max-h-[31.25rem]' },
                  { label: '600px', value: 'max-md:max-h-[37.5rem]' },
                  { label: '700px', value: 'max-md:max-h-[43.75rem]' },
                  { label: '800px', value: 'max-md:max-h-[50rem]' },
                ],
              },
            ],
            defaultValue: {
              heightType: 'static',
              staticHeight: 'max-md:h-[31.25rem]',
              aspectRatio: 'max-md:aspect-[3/4]',
              minHeight: '',
              maxHeight: '',
            },
          },
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Text Color',
            name: 'textColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Button Style',
            name: 'buttonStyle',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Secondary', value: 'btn-secondary' },
              { label: 'Inverse Light', value: 'btn-inverse-light' },
              { label: 'Inverse Dark', value: 'btn-inverse-dark' },
            ],
          },
          {
            label: 'Vertical Padding Desktop',
            name: 'verticalPaddingLevel',
            component: 'select',
            options: VERTICAL_PADDING.desktop,
            defaultValue: 'md:py-contained',
          },
          {
            label: 'Vertical Padding Mobile',
            name: 'verticalPaddingLevelMobile',
            component: 'select',
            options: VERTICAL_PADDING.mobile,
            defaultValue: 'max-md:py-contained',
          },
        ],
        defaultValue: {
          aspectRatio: 'aspect-[3/4]',
          bgColor: 'var(--background)',
          textColor: 'var(--text)',
          buttonStyle: 'btn-primary',
          fullWidth: false,
        },
      },
    ],
  };
}
