import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { sendGraphlEvent, getCookie, nostoMapLineItems, getIdFromRawShopifyId } from './utils';
import { useCustomer, useCartItems } from '@backpackjs/storefront';

export function useDataLayerSearch({
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const asPathRef = useRef(null);
  const { asPath, query } = useRouter();
  const customer = useCustomer();
  const cartItems = useCartItems();

  const [searchPageResults, setSearchPageResults] = useState(null);
  const [searchResults, setSearchResults] = useState(null);

  const viewSearchGraph = async () => {
    const nostoCookie = getCookie('nosto-session');
    const cartFragment = nostoMapLineItems(cartItems);
    const customerId = getIdFromRawShopifyId(customer?.id, 'Customer');

    const nostoQuery = `mutation {
        updateSession(by: BY_CID, id: "${nostoCookie}",
          params: {
            ${customer ? `customer: {
              firstName: "${customer?.firstName}"
              lastName: "${customer?.lastName}"
              marketingPermission: ${customer?.acceptsMarketing}
              customerReference: "${customerId}"
            }` : ''}
            event: {
              type: SEARCHED_FOR
              target: "${query?.modalQuery}"
            }
            ${cartFragment}
          }
        ) {
          id
        }
      }`;

      await sendGraphlEvent(nostoQuery);
  }

  const viewSearchResultsEvent = useCallback(
    ({ results, userProperties: _userProperties }) => {
      if (!results?.length) return;

      nostojs((api) => {
        api
          .defaultSession()
          .setResponseMode('JSON_ORIGINAL')
          .setResponseMode('HTML')
          .viewSearch('')
          .setPlacements(api.placements.getPlacements())
          .load()
          .then((data) => {
          })
      })

      if (typeof window !== 'undefined') {
        viewSearchGraph()
      }

      if (DEBUG) console.log(`Nosto: Search`);
    },
    []
  );

  // Subscribe to PubSub topics for 'view_search_results' and 'select_item' events
  useEffect(() => {
    const viewSearchPageResults = PubSub.subscribe(
      'VIEW_SEARCH_PAGE_RESULTS',
      async (event, results) => {
        setSearchPageResults(results);
      }
    );
    const viewSearchResults = PubSub.subscribe(
      'VIEW_SEARCH_RESULTS',
      async (event, results) => {
        setSearchResults(results);
      }
    );

    return () => {
      if (viewSearchPageResults) {
        PubSub.unsubscribe(viewSearchPageResults);
      }
      if (viewSearchResults) {
        PubSub.unsubscribe(viewSearchResults);
      }
    };
  }, []);

  // Trigger 'user_data' and 'view_search_results' events after
  // new sidebar search results and base data is ready
  useEffect(() => {
    if (
      !asPath.startsWith('/pages/search') ||
      !searchPageResults?.length ||
      !userProperties ||
      asPath === asPathRef.current
    )
      return undefined;
    userDataEvent({ userProperties });
    viewSearchResultsEvent({ results: searchPageResults, userProperties });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [
    asPath,
    searchPageResults?.map((p) => p?.handle).join(''),
    !!userProperties,
  ]);

  // Trigger 'user_data' and 'view_search_results' events after
  // new search page results and base data is ready
  useEffect(() => {
    if (!searchResults || !userDataEventTriggered) return;
    viewSearchResultsEvent({ results: searchResults, userProperties });
  }, [searchResults, userDataEventTriggered]);

}
