import { Link, Image, Markdown as MarkdownSnippet } from '../../snippets';
import { fetchProductFromCollectionHandle } from '../../utilities';
import { useState, useEffect } from 'react';

import { OutfitCustomizerItem } from './OutfitCustomizerItem';

export function OutfitCustomizerOption({ group, setSelectedVariantOption }) {
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [loadedProducts, setLoadedProducts] = useState([]);
  const [selectedGroupItem, setSelectedGroupItem] = useState(null);

  const selectedGroupItemClasses = selectedGroupItem ? 'bg-white' : '';

  const handleSelectCollection = (data) => {
    if (selectedCollection) {
      setSelectedCollection(null);
      setSelectedGroupItem(null);
    } else {
      setSelectedCollection(data);
    }
  };

  useEffect(() => {
    setLoadedProducts([]);
    if (!selectedCollection) return;

    const fetchProductsForMap = async () => {
      const collection = await fetchProductFromCollectionHandle(
        selectedCollection.collectionHandle
      );

      if (collection) setLoadedProducts(collection);
    };
    fetchProductsForMap();
  }, [selectedCollection]);

  return (
    <div className="bg-[#f2f2f2]">
      <div
        className={`cursor-pointer p-5 text-base font-bold ${selectedGroupItemClasses} border-b border-b-[#e5e5e5] last:border-b-0 hover:border-b-[#202844] hover:bg-[#202844] hover:text-white`}
        onClick={() => handleSelectCollection(group)}
      >
        {selectedGroupItem ? `Type: ${selectedGroupItem}` : group.styleName}
      </div>

      {group.collectionHandle === selectedCollection?.collectionHandle && (
        <div className="max-h-[35vh] overflow-y-auto">
          {loadedProducts?.map((product) => {
            return (
              <div
                className={`border-b border-border p-5 ${
                  selectedGroupItem ? '' : 'hover:bg-[#202844] hover:text-white'
                } ${
                  !selectedGroupItem
                    ? ''
                    : selectedGroupItem && selectedGroupItem !== product.title
                    ? 'hidden hover:bg-[#202844] hover:text-white'
                    : 'bg-white hover:bg-white'
                }`}
              >
                <OutfitCustomizerItem
                  product={product}
                  selectGroupItem={setSelectedGroupItem}
                  setSelectedVariantOption={setSelectedVariantOption}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

OutfitCustomizerOption.displayName = 'OutfitCustomizerOption';
