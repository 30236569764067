import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';
import algoliasearch from 'algoliasearch/lite';
import { fetchProductsFromHandles } from '../../utilities';
import aa from 'search-insights';
import { useCustomer } from '@backpackjs/storefront';
import { object } from 'prop-types';

export function useAlgoliaResults({ term, mounted = true }) {
  const settings = useSettings();

  const [dataResults, setDataResults] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [algoliaIndex, setAlgoliaIndex] = useState('byltsearch_products');
  const [indexQuantity, setIndexQuantity] = useState(16);
  const { productsEnabled } = { ...settings?.search?.results };
  const customer = useCustomer();

  const cliendId = process.env.NEXT_PUBLIC_ALGOLIA_CLIENT_ID
  const algoliaApiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY

  const searchClient = algoliasearch(
    cliendId,
    algoliaApiKey
  );

  const pageIndex = settings?.search?.indexes?.pageIndex || 'byltsearch_pages'

  useEffect(() => {
    if (customer === undefined) return;
    let algoliaParams = {
      appId: cliendId,
      apiKey: algoliaApiKey,
    }

    if (customer?.id) {
      const userToken = customer?.id?.split('gid://shopify/Customer/')?.[1];
      algoliaParams.userToken = userToken;
    } else {
      algoliaParams.useCookie = true;
    }

    aa('init', algoliaParams);
  }, [customer]);

  const searchProductsOnTermChange = useCallback(
    (isPage2) => {
      if (!term && !mounted) {
        setDataResults({});
        return;
      }

      const value = term;

      let queries = [
        {
          indexName: 'bylt_products_query_suggestions',
          query: value,
          params: {
            facets: ['*'],
            hitsPerPage: 5,
          },
          clickAnalytics: true,
        },
        {
          indexName: 'byltsearch_products',
          query: value,
          params: {
            facets: ['options.color', 'options.size'],
            hitsPerPage: 1,
            page: 1,
          },
          clickAnalytics: true,
        },
        {
          indexName: algoliaIndex,
          query: value,
          params: {
            hitsPerPage: isPage2 ? 84 : indexQuantity, //Max 1000
            page: 0,
            facetFilters: activeFilters,
          },
          clickAnalytics: true,
        },
        {
          indexName: 'byltsearch_collections',
          query: value,
          params: {
            hitsPerPage: 24,
            page: 0,
            facetFilters: [],
          },
          clickAnalytics: true,
        },
        {
          indexName: pageIndex,
          query: value,
          params: {
            hitsPerPage: 24,
            page: 0,
            facetFilters: [],
          },
          clickAnalytics: true,
        },
      ];

      try {
        searchClient.multipleQueries(queries).then(async (data) => {
          let finalResults = {
            products: null,
            collections: {//Index 3 belongs to collections, them are linked to queries object params orders
              page: data.results[3]?.page,
              totalPages: data.results[3]?.nbPages,
              results: data.results[3]?.hits,
              queryID: data.results[3]?.queryID,
              index: data.results[3]?.index,
            },
            pages: {//Index 4 belongs to pages, them are linked to queries object params orders
              page: data.results[4]?.page,
              totalPages: data.results[4]?.nbPages,
              results: data.results[4]?.hits?.filter(
                (el) =>
                  !el?.handle?.includes('size') &&
                  !el?.title?.includes('Size Guide')
              ),
              queryID: data.results[4]?.queryID,
              index: data.results[4]?.index,
            },
            suggestions: {//Index 0 belongs to suggestions, them are linked to queries object params orders
              page: data.results[0]?.page,
              totalPages: data.results[0]?.nbPages,
              results: data.results[0]?.hits,
              queryID: data.results[0]?.queryID,
              index: data.results[0]?.index,
            },
            filters: {//Index 1 belongs to filters, them are linked to queries object params orders
              page: data.results[1]?.page,
              totalPages: data.results[1]?.nbPages,
              results: data.results[1]?.facets,
              queryID: data.results[1]?.queryID,
              index: data.results[1]?.index,
            },
          };

          if (
            data.results.length > 1 &&
            data.results[2] &&
            data.results[2]?.index === algoliaIndex
          ) {
            const handles = data.results[2]?.hits?.map((el) => {
              return el.handle;
            });

            const productResults = await fetchProductsFromHandles(handles);
            const formatedResults = productResults.map((el) => {
              const rawObject = data.results[2]?.hits?.find(
                (raw) => raw.handle === el.handle
              );

              return { ...el, objectID: rawObject?.objectID };
            });

            finalResults.products = {//Index 2 belongs to products, them are linked to queries object params orders
              page: data.results[2]?.page,
              totalPages: data.results[2]?.nbPages,
              results: formatedResults || [],
              queryID: data.results[2]?.queryID,
              index: data.results[2]?.index,
            };
          }

          setDataResults(finalResults);
        });
      } catch (error) {
        setDataResults([]);
      }
    },
    [term, activeFilters, algoliaIndex]
  );

  useEffect(() => {
    setIndexQuantity(16);
  }, [term]);

  useEffect(() => {
    searchProductsOnTermChange();
  }, [term, activeFilters, algoliaIndex]);

  const filtersResults = useMemo(() => {
    const rawFilters = dataResults?.filters?.results || {};
    let newFacets = [];

    if (!rawFilters && !mounted) return [];

    for (const [key, value] of Object.entries(rawFilters)) {
      const newName = key
        .split('.')
        [key.split('.').length - 1].replace('_', ' ');
      let valuesOption = [];
      for (const [keyV, valueV] of Object.entries(value)) {
        valuesOption.push({
          name: keyV,
          count: valueV,
        });
      }
      newFacets.push({
        key: key,
        name: newName.charAt(0).toUpperCase() + newName.slice(1),
        values: valuesOption,
      });
    }

    newFacets.forEach((facet) => {
      if (facet.key === 'price_range') {
        const sizeSort = [
          '0:10',
          '10:25',
          '25:50',
          '50:75',
          '75:100',
          '100:150',
          '150:200',
          '200:250',
          '250:300',
          '300:400',
          '400:500',
        ];
        // const values = facet.values
        facet.values.sort((a, b) => {
          let sortB = sizeSort.indexOf(b.name);
          let sortA = sizeSort.indexOf(a.name);
          if (sortA === -1) {
            sortA = parseFloat(a.name);
          }
          if (sortB === -1) {
            sortB = parseFloat(b.name);
          }

          return sortA - sortB;
        });
      }
    });

    let tags = newFacets.find((el) => el.key === 'tags');

    if (tags) {
      const facets = [];

      const rootFacets = tags.values.filter((tag) =>
        tag.name.includes('filter')
      );

      rootFacets.forEach((facet) => {
        const facetFragments = facet.name.split('_');
        const facetName = facet.name;
        const facetGroup = facetFragments[1];
        const facetLabel = facetFragments[2] || true;
        const facetCount = facet.count;

        facets.push({
          name: facetName,
          group: facetGroup,
          label: facetLabel,
          count: facetCount,
        });
      });

      let output = [];
      facets
        .filter((facet) => facet.name.toLowerCase() !== 'title')
        .forEach((facet) => {
          const index = output.findIndex((arrayItem) => {
            return facet.group === arrayItem.name;
          });

          if (index === -1) {
            output.push({
              key: 'tags',
              name: facet.group,
              values: [
                { name: facet.name, label: facet.label, count: facet.count },
              ],
            });
          } else {
            output[index].values.push({
              name: facet.name,
              label: facet.label,
              count: facet.count,
            });
          }
        });

      let finalFacets = newFacets.filter((el) => el.key !== 'tags');

      output.forEach((facet) => {
        if (facet.name === 'size') {
          const sizeSort = ['XS', 's', 'm', 'l', 'XL', '2XL', '3XL'];
          // const values = facet.values
          facet.values.sort((a, b) => {
            let sortB = sizeSort.indexOf(b.label);
            let sortA = sizeSort.indexOf(a.label);
            if (sortA === -1) {
              sortA = parseFloat(a.label);
            }
            if (sortB === -1) {
              sortB = parseFloat(b.label);
            }

            return sortA - sortB;
          });
        }
      });

      return output.concat(finalFacets);
    }

    return newFacets;
  }, [dataResults]);

  const addFilter = (filter) => {
    setActiveFilters((prevArray) => [...prevArray, filter]);
  };

  const removeFilter = (filter) => {
    setActiveFilters(activeFilters.filter((el) => el !== filter));
  };

  const changeSearchIndex = (index) => {
    switch (index) {
      case 'relevance':
        setAlgoliaIndex('byltsearch_products');
        break;
      case 'low-high':
        setAlgoliaIndex('byltsearch_products_price_low_desc');
        break;
      case 'high-low':
        setAlgoliaIndex('byltsearch_products_price_high_desc');
        break;
    }
  };

  const searchProductsNext = () => {
    searchProductsOnTermChange(true);
  };

  const trackOnClick = (object, index, type, eventName) => {
    const objectID = object?.objectID || '';
    const position = index + 1;

    const eventObject = {
      userToken: '',
      index: dataResults?.[type].index,
      eventName: eventName,
      queryID: dataResults?.[type].queryID,
      objectIDs: [objectID],
      positions: [position],
    };

    triggerTrackingEvent('clickedObjectIDsAfterSearch', eventObject);
  };

  const trackOnClickFilter = (filter) => {
    const eventObject = {
      userToken: '',
      index: dataResults?.filters.index,
      eventName: 'Brand Clicked',
      filters: [filter],
    };

    triggerTrackingEvent('clickedFilters', eventObject);
  };

  const triggerTrackingEvent = (eventType, eventData) => {
    let eventObject = { ...eventData };

    if (customer?.id) {
      const userToken = customer?.id?.split('gid://shopify/Customer/')?.[1];
      eventObject.userToken = userToken;

      aa(eventType, eventObject);
    } else {
      aa('getUserToken', {}, (err, userToken) => {
        if (err) return;

        eventObject.userToken = userToken;
        aa(eventType, eventObject);
      });
    }
  };

  return {
    dataResults,
    filtersResults,
    activeFilters,
    addFilter,
    removeFilter,
    changeSearchIndex,
    searchProductsNext,
    trackOnClick,
    trackOnClickFilter,
  };
}
