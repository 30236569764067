import { useMemo } from 'react';

export function useProductMetafields({ product }) {
  return useMemo(() => {
    if (!product) {
      return null;
    }

    return product?.metafields?.reduce((obj, metafield) => {
      const { namespace, key, value } = metafield;

      if (obj[namespace]) {
        obj[namespace][key] = value;
      } else {
        obj[namespace] = {};
        obj[namespace][key] = value;
      }

      return obj;
    }, {});
  }, [product]);
}
