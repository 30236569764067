import { useMemo } from 'react';
import { Image } from '../../../../snippets';
import { isLightHexColor } from '../../../../utilities';

export function ColorOptionIcon({
  disabled,
  isUnavailable,
  isSelected,
  swatch,
  value,
}) {
  const isLightColor = useMemo(() => {
    return isLightHexColor(swatch?.color);
  }, [swatch?.color]);

  const validClass = !disabled
    ? 'md:group-hover/color:border-text'
    : 'cursor-not-allowed';

  const selectedClass = isSelected ? 'border-text' : '';

  const beforeClasses =
    'before:h-px before:w-[150%] before:rotate-[-135deg] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:z-[1]';
  const afterClasses =
    'after:h-px after:w-[150%] after:rotate-[135deg] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2';

  const unavailableClass = isUnavailable
    ? `${beforeClasses} ${afterClasses} overflow-hidden ${
        isLightColor
          ? 'after:bg-black before:bg-black'
          : 'after:bg-[#999999] before:bg-[#999999]'
      }`
    : '';

  return (
    <div
      className={`relative flex h-5 w-5 items-center justify-center overflow-hidden rounded-[50%] border border-border transition ${validClass} ${unavailableClass} ${selectedClass}`}
      style={{
        backgroundColor:
          isSelected && swatch?.color === '#FFFFFF'
            ? 'var(--off-white)'
            : swatch?.color,
      }}
    >
      {swatch?.image?.src && (
        <Image
          alt={value}
          className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
          height="32"
          src={swatch.image.src}
          width="32"
        />
      )}

      <div
        className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-[50%] border-white transition-[border-width] duration-100 ${
          isSelected ? 'border-[2px]' : 'border-[0px]'
        }`}
      />
    </div>
  );
}

ColorOptionIcon.displayName = 'ColorOptionIcon';
