import { Image, Link } from '../../snippets';

export function BlogGridItem({ article }) {
  const url = article?.handle ? `/articles/${article?.handle}` : article?.link?.url;
  const urlText =  article?.link?.text || 'Learn More'
  const articleImage = article?.seo?.image || article?.image?.src || null;

  return (
    <div>
      <Link aria-label={article.title} href={url} tabIndex="-1">
        <div className="relative mb-4 aspect-square bg-offWhite">
          {articleImage && (
            <Image
              alt={article.title}
              fill
              sizes="(min-width: 1440px) 1200px, (min-width: 768px) 50vw, 100vw"
              src={articleImage}
            />
          )}
        </div>
      </Link>

      <div className="flex flex-col items-start gap-2">
        <p className="text-sm text-mediumGray">
          {article?.author ? `${article.author} | ` : ''}
        </p>

        <Link aria-label={article?.title} href={url} tabIndex="-1">
          <h3 className="h4 uppercase">{article?.title}</h3>
        </Link>

        {article?.excerpt && (
          <p
            className="overflow-hidden text-sm text-[#444447]"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {article?.excerpt}
          </p>
        )}

        <Link
          aria-label={`Read article ${article?.title}`}
          className="text-label mt-5 flex flex items-center gap-3 text-[15px] font-bold leading-[17px]"
          href={url}
        >
          {urlText}
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="flex translate-y-[-1px] items-center"
          >
            <path
              d="M1.5 12.3745L7 6.87451L1.5 1.37451"
              stroke="#202844"
              strokeWidth="2"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}

BlogGridItem.displayName = 'BlogGridItem';
