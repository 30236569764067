import { useState } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Schema } from './PressSlider.schema';
import { Image, Svg } from '../../snippets';

export function PressSlider({ cms }) {
  const { section, slides } = cms;
  const { bgColor, fullWidth, textColor } = { ...section };

  const [swiper, setSwiper] = useState(null);

  const maxWidthContainerClass = fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div
      className={`md:px-contained ${section?.verticalPaddingLevel}`}
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      {slides?.length > 0 && (
        <div className={`mx-auto pt-4 ${maxWidthContainerClass}`}>
          <Swiper
            className="pressSlider relative"
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
            centeredSlides
            loop
            pagination={{
              clickable: true,
            }}
            grabCursor
            modules={[Autoplay, Navigation, Pagination]}
            onSwiper={setSwiper}
            slidesPerView={1}
          >
            {slides.map(({ quote, alt, image }, index) => {
              return (
                <SwiperSlide className="w-full py-2" key={index}>
                  <div className="text-center">
                    <p className="mb-[27px] text-[55px] text-primary">“</p>
                    <h2 className="h3 mx-auto max-w-[50rem] px-4 text-center font-bold text-primary">
                      “{quote}”
                    </h2>
                    <div className="mx-auto flex aspect-[16/9] w-[216px]">
                      {image?.src && (
                        <Image
                          alt={alt}
                          width="216"
                          height="60"
                          src={image.src}
                        />
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}

            {slides.length && (
              <div className="z-1 absolute left-0 right-0 top-[calc(60%)] md:px-14">
                <div className="relative mx-auto min-[90rem]:max-w-full">
                  <button
                    aria-label="See previous slide"
                    type="button"
                    className="swiper-button-prev left-[0.5rem] top-[calc(50%-1.6875rem)] !flex !h-[24px] !w-[24px] rounded-full bg-gray after:hidden md:-left-[3.25rem]"
                    onClick={() => swiper.slidePrev()}
                  >
                    <Svg
                      className="w-[12px] text-white"
                      src="/svgs/chevron-left.svg#chevron-left"
                      title="Arrow Left"
                      viewBox="0 0 24 24"
                    />
                  </button>

                  <button
                    aria-label="See next slide"
                    type="button"
                    className="swiper-button-next right-[0.5rem] top-[calc(50%-1.6875rem)] !flex !h-[24px] !w-[24px] rounded-full bg-gray after:hidden md:-right-[3.25rem]"
                    onClick={() => swiper.slideNext()}
                  >
                    <Svg
                      className="w-[12px] text-white"
                      src="/svgs/chevron-right.svg#chevron-right"
                      title="Arrow Right"
                      viewBox="0 0 24 24"
                    />
                  </button>
                </div>
              </div>
            )}
          </Swiper>
        </div>
      )}
    </div>
  );
}

PressSlider.displayName = 'PressSlider';
PressSlider.Schema = Schema;
