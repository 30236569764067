import { useState } from 'react';
import axios from 'axios';
import { Image } from '../Image';
import { Markdown } from '../Markdown';
import { Link } from '../Link';

export function ContactUsForm({
  title,
  aboveTheFold,
  content,
  isTextCenter,
  buttons,
  successMessage,
  sideImage,
}) {
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault;

    const data = new FormData(e.target);

    const bodyToSend = {
      customer: {
        email: data.get('email'),
      },
      status: 'open',
      spam: false,
      messages: [
        {
          channel: 'contact_form',
          from_agent: true,
          source: {
            from: {
              name: data.get('name'),
              address: data.get('email'),
            },
            to: [
              {
                name: 'CX Team',
                address: 'support@byltbasics.com',
              },
            ],
            type: 'chat-contact-form',
          },
          via: 'contact_form',
          created_datetime: new Date(),
          body_html: data.get('message'),
          body_text: data.get('message'),
          sent_datetime: new Date(),
          subject: data.get('select'),
        },
      ],
    };

    const response = await axios.post(
      'https://relay-gorgias-request.ryan-groh.workers.dev/',
      bodyToSend
    );

    if (response.data) {
      setIsSent(true);
    }
  };

  return (
    <div className="flex flex-col gap-[60px] md:flex-row lg:gap-[79px]">
      {sideImage && (
        <div className="w-full md:w-[50%] max-lg:content-center">
          <div className="group/media relative p-[120%_0_0]">
            <Image
              alt={title}
              priority={aboveTheFold}
              fill
              src={sideImage.src}
            />
          </div>
        </div>
      )}

      <div
        className={`"w-full md:w-[50%] ${isTextCenter ? 'text-center' : ''}`}
      >
        {aboveTheFold ? (
          <h1 className="h3 mb-8">{title}</h1>
        ) : (
          <h2 className="h3 mb-8">{title}</h2>
        )}

        <div className="mb-[32px] lg:mb-[65px] [&>:last-child]:mt-4 [&_p]:mb-1 [&_p]:!max-w-[100%] [&_p]:text-sm">
          <Markdown>{content}</Markdown>
        </div>

        <div className="mb-[30px] flex flex-col lg:flex-row gap-[8px]">
          {buttons?.map(({ link, style }, index) => {
            return (
              link && (
                <Link
                  className={`${style} h-auto w-auto px-[12px] py-[8px] text-sm font-medium leading-none`}
                  aria-label={link.text}
                  href={link.url}
                  type={link.type}
                  key={index}
                >
                  <p>{link.text}</p>
                </Link>
              )
            );
          })}
        </div>

        <div>
          {isSent ? (
            <div className="rounded border border-border bg-[#ecffd8] p-[10px]">
              <p className="text-green-600">{successMessage}</p>
            </div>
          ) : (
            <form
              className="mt-5 flex flex-wrap justify-between"
              id="contact-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <input
                className="w-[48%] rounded bg-[#e5e5e5] px-[20px] py-[8px] placeholder:text-primary"
                name="name"
                placeholder="*Name"
                type="text"
              />
              <input
                className="w-[48%] rounded bg-[#e5e5e5] px-[20px] py-[8px] placeholder:text-primary"
                name="email"
                placeholder="*Email"
                type="text"
              />

              <select
                className="my-[23px] w-[100%] rounded bg-[#e5e5e5] px-[20px] py-[8px] placeholder:text-primary"
                name="select"
                defaultValue="DEFAULT"
              >
                <option disabled value="DEFAULT">
                  Select an option
                </option>
                <option value="Cancel my order">Cancel my order</option>
                <option value="How do I exchange/Return">
                  How do I exchange/Return
                </option>
                <option value="My refund is past the 30 days">
                  My refund is past the 30 days
                </option>
                <option value="My reward points aren’t working">
                  My reward points aren’t working
                </option>
                <option value="Product/fit">Product/fit</option>
                <option value="Ambassador program">Ambassador program</option>
                <option value="Wholesale">Wholesale</option>
                <option value="General">General</option>
              </select>

              <textarea
                className="mb-[23px] w-[100%] rounded bg-[#e5e5e5] px-[20px] py-[8px] placeholder:text-primary"
                placeholder="*Message"
                name="message"
                cols="30"
                rows="10"
              />

              <button type="submit" className="btn-secondary w-full">
                SEND
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

ContactUsForm.displayName = 'ContactUsForm';
