import { useEffect, useCallback, useRef, useState } from 'react';
import {
  useCart,
  useCartCount,
  useCartItems,
  useCurrency,
} from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import equal from 'fast-deep-equal';

import { mapLineItem } from './utils';
import { useGlobalContext } from '../../../contexts';

export function useDataLayerCart({
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const asPathRef = useRef(null);
  const { asPath } = useRouter();
  const cart = useCart();
  const cartCount = useCartCount();
  const cartItems = useCartItems();
  const currencyCode = useCurrency();
  const {
    state: { cartOpen },
  } = useGlobalContext();

  const [previousCartCount, setPreviousCartCount] = useState(null);
  const [previousCartItems, setPreviousCartItems] = useState(null);
  const [previousCartItemsMap, setPreviousCartItemsMap] = useState(null);

  const addToCartEvent = useCallback(
    ({ lineItems, userProperties: _userProperties }) => {
      if (!lineItems?.length) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        (window.location.pathname.startsWith('/collections') &&
          window.location.pathname) ||
        (previousPath?.startsWith('/collections') && previousPath) ||
        '';
      const event = {
        event: 'dl_add_to_cart',
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: lineItems[0].estimatedCost?.totalAmount?.currencyCode,
          add: {
            actionField: { list },
            products: lineItems.map(mapLineItem(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  const addToCartEventPostscript = useCallback(
    ({ lineItems, userProperties: _userProperties }) => {
      if (!lineItems?.length || !window.postscript) return;
      const productId =
        lineItems[0]?.variant?.product.id?.split('gid://shopify/Product/')[1] ||
        '';
      const variantId =
        lineItems[0]?.variant?.handle?.split(
          'gid://shopify/ProductVariant/'
        )[1] || '';

      try {
        if (!window?.postscript) return;

        const event = {
          shop_id: '304875',
          url: `https://byltbasics.com/products/${lineItems[0]?.variant?.product?.handle}`,
          search_params: {
            variant: variantId,
          },
          page_type: 'product',
          referrer: `https://byltbasics.com${window.location.pathname}`,
          resource: {
            category: lineItems[0]?.variant?.product?.productType,
            name: lineItems[0]?.variant?.product?.title,
            price_in_cents:
              parseFloat(lineItems[0]?.variant?.priveV2?.amount) * 100,
            resource_id: productId,
            resource_type: 'product',
            sku: lineItems[0]?.variant?.sku,
            variant_id: variantId,
            vendor: 'BYLT Basics',
          },
        };

        window?.postscript?.event(event);
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const removeFromCartEvent = useCallback(
    ({ lineItems, userProperties: _userProperties }) => {
      if (!lineItems?.length) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        (window.location.pathname.startsWith('/collections') &&
          window.location.pathname) ||
        (previousPath?.startsWith('/collections') && previousPath) ||
        '';
      const event = {
        event: 'dl_remove_from_cart',
        user_properties: _userProperties,
        ecommerce: {
          currencyCode: lineItems[0].estimatedCost?.totalAmount?.currencyCode,
          remove: {
            actionField: { list },
            products: lineItems.map(mapLineItem(list)),
          },
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  const viewCartEvent = useCallback(
    ({
      cart: _cart,
      currencyCode: _currencyCode,
      userProperties: _userProperties,
    }) => {
      if (!_cart) return;
      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list =
        (window.location.pathname.startsWith('/collections') &&
          window.location.pathname) ||
        (previousPath?.startsWith('/collections') && previousPath) ||
        '';
      const event = {
        event: 'dl_view_cart',
        user_properties: _userProperties,
        cart_total: _cart.estimatedCost?.totalAmount?.amount,
        ecommerce: {
          currencyCode: _currencyCode,
          actionField: { list: 'Shopping Cart' },
          impressions: _cart.lines?.slice(0, 7).map(mapLineItem(list)) || [],
        },
      };

      window.ElevarDataLayer = window.ElevarDataLayer ?? [];
      window.ElevarDataLayer.push(event);
      if (DEBUG) console.log(`DataLayer:elevar:${event.event}`, event);
    },
    []
  );

  // Trigger 'dl_user_data' and 'dl_view_cart' events on cart page
  useEffect(() => {
    if (
      !asPath.startsWith('/cart') ||
      !cart ||
      !currencyCode ||
      !userProperties ||
      asPath === asPathRef.current
    )
      return undefined;
    userDataEvent({ userProperties });
    viewCartEvent({ cart, currencyCode, userProperties });
    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, !!cart, !!currencyCode, !!userProperties]);

  // Trigger 'dl_view_cart' event when cart is opened
  useEffect(() => {
    if (!cartOpen || !currencyCode || !userDataEventTriggered) return;
    viewCartEvent({ cart, currencyCode, userProperties });
  }, [cartOpen, !!currencyCode, userDataEventTriggered]);

  // Determine if a cart item was added, removed, or updated for events
  useEffect(() => {
    if (!cart || !userDataEventTriggered) return;

    const cartItemsMap = cartItems.reduce((acc, line) => {
      if (!line.variant) return acc;
      const variantId = line.variant.id;
      if (!acc[variantId]) {
        return { ...acc, [variantId]: [line] };
      }
      return { ...acc, [variantId]: [...acc[variantId], line] };
    }, {});

    if (!previousCartItems || previousCartCount === cartCount) {
      setPreviousCartItems(cartItems);
      setPreviousCartCount(cartCount);
      setPreviousCartItemsMap(cartItemsMap);
      return;
    }

    const isAddedItems = [];
    const isIncreasedItems = [];
    const isRemovedItems = [];
    const isDecreasedItems = [];

    if (cartCount > previousCartCount) {
      cartItems.forEach((line, index) => {
        const variantId = line.variant?.id;
        if (!variantId) return;

        const previousLine = previousCartItemsMap[variantId]?.find(
          (prevLine) => {
            const hasSameSellingPlanSelection =
              (!prevLine.sellingPlanAllocation &&
                !line.sellingPlanAllocation) ||
              prevLine.sellingPlanAllocation?.sellingPlan?.id ===
                line.sellingPlanAllocation?.sellingPlan?.id;
            return (
              hasSameSellingPlanSelection &&
              equal(
                prevLine.attributes.filter((attr) => attr.key !== '_addedAt'),
                line.attributes.filter((attr) => attr.key !== '_addedAt')
              )
            );
          }
        );
        if (!previousLine) {
          isAddedItems.push({ ...line, index });
          return;
        }
        if (line.quantity > previousLine.quantity) {
          isIncreasedItems.push({
            ...line,
            quantity: line.quantity - previousLine.quantity,
            index,
          });
        }
      });
    } else if (cartCount < previousCartCount) {
      previousCartItems.forEach((prevLine, index) => {
        const variantId = prevLine.variant?.id;
        if (!variantId) return;

        const currentLine = cartItemsMap[variantId]?.find((line) => {
          const hasSameSellingPlanSelection =
            (!prevLine.sellingPlanAllocation && !line.sellingPlanAllocation) ||
            prevLine.sellingPlanAllocation?.sellingPlan?.id ===
              line.sellingPlanAllocation?.sellingPlan?.id;
          return (
            hasSameSellingPlanSelection &&
            equal(
              prevLine.attributes.filter((attr) => attr.key !== '_addedAt'),
              line.attributes.filter((attr) => attr.key !== '_addedAt')
            )
          );
        });
        if (!currentLine) {
          isRemovedItems.push({ ...prevLine, index });
          return;
        }
        if (currentLine.quantity < prevLine.quantity) {
          isDecreasedItems.push({
            ...prevLine,
            quantity: prevLine.quantity - currentLine.quantity,
            index,
          });
        }
      });
    }

    if (isAddedItems.length || isIncreasedItems.length) {
      addToCartEvent({
        lineItems: [...isAddedItems, ...isIncreasedItems],
        userProperties,
      });
      addToCartEventPostscript({
        lineItems: [...isAddedItems, ...isIncreasedItems],
        userProperties,
      });
    }
    if (isRemovedItems.length || isDecreasedItems.length) {
      removeFromCartEvent({
        lineItems: [...isRemovedItems, ...isDecreasedItems],
        userProperties,
      });
    }

    setPreviousCartItems(cartItems);
    setPreviousCartCount(cartCount);
    setPreviousCartItemsMap(cartItemsMap);
  }, [cart?.updatedAt, userDataEventTriggered]);
}
