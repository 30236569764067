import { useInView } from 'react-intersection-observer';

import { ArticleHeroContent } from './ArticleHeroContent';
import { ArticleHeroVideo } from './ArticleHeroVideo';
import { Image, Link, Svg } from '../../snippets';

export function ArticleHeroSlide({
  aboveTheFold,
  isActiveSlide,
  isFirstSlide,
  slide,
}) {
  const { image, video, showBreadCrumb, backButton } = slide;
  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  const isVisible =
    (aboveTheFold && isActiveSlide) ||
    (!aboveTheFold && isActiveSlide && inView);

  return (
    <div className="relative h-full w-full" ref={ref}>
      {showBreadCrumb && (
        <Link
          aria-label="Back To Ambassadors"
          href={backButton?.link?.url}
          className="absolute left-3 top-6 z-10 flex flex-row gap-1 text-white"
        >
          <Svg
            className="w-3 text-white"
            src="/svgs/chevron-left.svg#chevron-left"
            viewBox="0 0 24 24"
          />
          {backButton?.link?.text}
        </Link>
      )}

      <div className="relative h-full w-full overflow-hidden md:hidden">
        {video?.srcMobile && (
          <ArticleHeroVideo
            isVisible={isVisible}
            posterSrc={video.posterMobile?.src}
            videoSrc={video.srcMobile}
          />
        )}

        {image?.imageMobile?.src && !video?.srcMobile && (
          <Image
            alt={image.alt}
            className={`${image.positionMobile}`}
            fill
            priority={aboveTheFold && isFirstSlide}
            sizes="100vw"
            src={image.imageMobile.src}
          />
        )}
      </div>

      <div className="relative hidden h-full w-full overflow-hidden md:block">
        {video?.srcDesktop && (
          <ArticleHeroVideo
            isVisible={isVisible}
            posterSrc={video.posterDesktop?.src}
            videoSrc={video.srcDesktop}
          />
        )}

        {image?.imageDesktop?.src && !video?.srcDesktop && (
          <Image
            alt={image.alt}
            className={`${image.positionDesktop}`}
            fill
            priority={aboveTheFold && isFirstSlide}
            sizes="100vw"
            src={image.imageDesktop.src}
          />
        )}
      </div>

      <ArticleHeroContent
        aboveTheFold={aboveTheFold}
        isActiveSlide={isActiveSlide}
        isFirstSlide={isFirstSlide}
        slide={slide}
      />
    </div>
  );
}

ArticleHeroSlide.displayName = 'HeroSlide';
