import { useMemo } from 'react';

export function useVariantPrices({ variant, tags = [] }) {
  const { id, priceV2, compareAtPriceV2 } = { ...variant };

  const localeSymbol = variant?.locale
    ? variant.locale.currencySymbol + ''
    : '$';

  const isUS = variant?.locale?.alpha2 === 'US';

  const customPrice = isUS
    ? tags
        ?.find((el) => el.includes('settings_customB-price_'))
        ?.split('settings_customB-price_')[1]
    : null || null;

  const customComparedAtPrice = isUS
    ? tags
        ?.find((el) => el.includes('settings_customB-compareAtPrice_'))
        ?.split('settings_customB-compareAtPrice_')[1]
    : null || null;

  const customDiscount = isUS
    ? tags
        ?.find((el) => el.includes('settings_customB-percentage_'))
        ?.split('settings_customB-percentage_')[1]
    : null || null;

  return useMemo(() => {
    if (!priceV2?.amount) {
      return { price: null, compareAtPrice: null };
    }

    const amount = !customPrice
      ? parseFloat(priceV2.amount)
      : parseFloat(customPrice);
    const compareAtAmount = !customComparedAtPrice
      ? parseFloat(compareAtPriceV2?.amount || '0')
      : parseFloat(customComparedAtPrice);
    const discountAmount = !customDiscount
      ? Math.round(
          100 - (parseFloat(amount) * 100) / parseFloat(compareAtAmount)
        )
      : customDiscount;

    return {
      price: `${localeSymbol}${amount.toFixed(2).replace('.00', '')}`,
      compareAtPrice:
        compareAtAmount > amount
          ? `${localeSymbol}${compareAtAmount.toFixed(2).replace('.00', '')}`
          : null,
      discount: `${discountAmount}% OFF`,
    };
  }, [id, priceV2, tags]);
}
