import { useCallback, useState, useMemo } from 'react';
import { useCartItems } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import axios from 'axios';

export function useLoopCheckout({ product = null }) {
  const cartItems = useCartItems();
  const [loopCheckoutText, setLoopCheckoutText] = useState('Checkout With Loop')
  const router = useRouter();
  const isClient = typeof window !== 'undefined';

  const initLoopCheckout = useCallback(async (loopObject) => {
    try {
      setLoopCheckoutText('Loading Loop Portal...')
      const endpoint = 'https://api.loopreturns.com/api/v1/cart/'

      const loopCartItems = [...cartItems]?.filter(el => el.variant.product.handle !== "bylt-gift-card")?.map(el => {
        return el.variant.id.split('gid://shopify/ProductVariant/')[1]
      })

      const bodyToSend = {
        cart: loopCartItems
      }
  
      const response = await axios({
        url: endpoint,
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Authorization': process.env.NEXT_PUBLIC_LOOP_API_KEY,
        },
        data: bodyToSend
      });
  
      const loopToken = response?.data?.token
  
      if(loopToken){
        const loopDomain = 'byltbasics';
        const loopUrl = `https://${loopDomain}.loopreturns.com/#/cart/v2/${loopToken}`;
        
        localStorage.removeItem("loopOnstoreParams");

        window.location.replace(loopUrl);
      }
    } catch (error) {
      setLoopCheckoutText('Checkout With Loop')
    }
  }, [cartItems]);

  const hasLoopExperience = useMemo(() => {
    if (!isClient) return false

    const savedParam = localStorage.getItem('loopOnstoreParams');

    return !!(savedParam)
  }, [router, isClient]);

  const isBlockedByLoop = useMemo(() => {
    if (!product) return false

    return (product?.handle === "bylt-gift-card")
  }, [product])

  return { initLoopCheckout, loopCheckoutText, hasLoopExperience, isBlockedByLoop };
}
