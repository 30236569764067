export function Schema() {
  return {
    category: 'Text',
    label: 'Collection Toggle Buttons',
    key: 'collectionToggleButtons',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1464/5034/files/PLP-Toggle-Btns.png?v=1720717373',
    fields: [
      {
        component: 'list',
        name: 'buttons',
        label: 'Toggle Buttons',
        description: '',
        field: {
          component: 'link',
        },
        validate: {
          maxItems: 4,
          required: false,
        },
      },
    ],
  };
}
