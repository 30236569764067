import { useCallback, useEffect, useState, useMemo } from 'react';
import { ProductOptions } from './ProductOptions';

export function ProductKitOptions({product, childVariants, innerSetterFunction, productIndex, validateSelection}) {
  const [isSizeSelected, setIsSizeSelected] = useState(false);
  const [isThirdSelected, setIsThirdSelected] = useState(product?.options?.length > 2 ? false : true);

  useEffect(() => {
    if(isSizeSelected){
      validateSelection(productIndex)
    }
  }, [isSizeSelected])

  return (
    <div className="w-full">
      <ProductOptions
        product={product}
        selectedOptions={childVariants?.[product?.handle]?.selectedOptionsMap}
        selectedVariant={childVariants?.[product?.handle]}
        setSelectedOptions={innerSetterFunction}
        setIsSizeSelected={setIsSizeSelected}
        setIsThirdSelected={setIsThirdSelected}
        isSizeSelected={isSizeSelected}
        hasSizeValidation
        externalClasses={'!border-none !p-0'}
      />
    </div>
  );
}

ProductKitOptions.displayName = 'ProductKitOptions';
