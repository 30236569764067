import { Image } from '../Image';
import { Link } from '../Link';
import { Svg } from '../Svg';
import { SubmenuGroup } from './SubmenuGroup';

export function MenuNestedSidebar({
  handleCloseSidebar,
  nestedSidebarContent,
  handleNestedSidebar,
}) {
  const { links, menuItem, centerlinks, mobileHeadColor } = {
    ...nestedSidebarContent,
  };

  return (
    <div
      className={`scrollbar-hide absolute left-0 top-0 z-30 h-full w-full bg-background ${
        nestedSidebarContent ? 'visible' : 'invisible'
      }`}
    >
      <div className="scrollbar-hide h-full w-full overflow-y-auto">
        <div className="relative w-full border-b border-b-border">
          {nestedSidebarContent?.image?.src && (
            <Image
              alt={nestedSidebarContent?.alt}
              className="h-[95px] w-full object-cover"
              crop="center"
              height={Math.floor(400 / (16 / 9))}
              src={nestedSidebarContent?.image?.src}
              width="400"
            />
          )}

          <button
            aria-label="Go back to main menu"
            className="absolute top-0 z-[1] flex h-full w-full items-center justify-between gap-4 py-[31px] pl-[24.5px] pr-[18px] text-white"
            onClick={() => handleNestedSidebar(null)}
            type="button"
          >
            <div className="flex gap-2">
              <Svg
                className="w-5"
                src="/svgs/chevron-left.svg#chevron-left"
                title="Arrow Left"
                viewBox="0 0 24 24"
                style={{ color: mobileHeadColor }}
              />

              <span
                className="text-nav text-base"
                style={{ color: mobileHeadColor }}
              >
                Back
              </span>
            </div>

            <h3
              className="text-title-h3 text-left uppercase"
              style={{ color: mobileHeadColor }}
            >
              {menuItem?.text}
            </h3>
          </button>
        </div>

        <div className="pt-5">
          <ul>
            {centerlinks?.map((group, gIndex) => {
              return (
                <div key={gIndex}>
                  {group.subGroups.map((subGroup, sgIndex) => {
                    return (
                      <SubmenuGroup
                        subGroup={subGroup}
                        sgIndex={sgIndex}
                        handleCloseSidebar={handleCloseSidebar}
                      />
                    );
                  })}
                </div>
              );
            })}
          </ul>

          <ul className="mb-8 flex flex-col">
            {links?.map(({ link }, index) => {
              return (
                <li
                  className="text-nav flex items-center border-b border-lightGray px-[22px] py-4 text-base"
                  key={index}
                >
                  <Link
                    aria-label={link?.text}
                    className="hover-text-underline"
                    href={link?.url}
                    newTab={link?.newTab}
                    onClick={handleCloseSidebar}
                    type={link?.type}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

MenuNestedSidebar.displayName = 'MenuNestedSidebar';
