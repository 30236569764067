export const fetchProductFromHandle = async (handle) => {
  try {
    if (!handle) return null;
    const endpoint = `/json/products/${handle}.json`;
    const response = await fetch(endpoint);
    return await response.json();
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const fetchProductsFromHandles = async (handles) => {
  try {
    if (!Array.isArray(handles) || !handles?.length) return [];
    const productPromises = handles.map(fetchProductFromHandle);
    return Promise.all(productPromises);
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

export const fetchProductFromCollectionHandle = async (handle) => {
  try {
    if (!handle) return null;
    const endpoint = `/json/collections/${handle}.json`;
    const response = await fetch(endpoint);
    const collection = await response.json();
    const handles = collection?.products?.map((el) => {
      return el.handle;
    });
    const products = await fetchProductsFromHandles(handles);
    return products;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const formatImageItemAsMediaItem = ({ image, product }) => {
  if (!image || !product) return null;
  return {
    alt: image.altText,
    id: image.id,
    image,
    mediaContentType: 'IMAGE',
    preview: { image: { ...image, previewSrc: image.src } },
    product: { handle: product.handle },
  };
};

export const formatProductForNosto = (product) => {
  if (!product) return null;
  return {
    data: {
      handle: product.handle,
      images: product.images,
      productType: product.productType,
      title: product.title,
      __typename: 'ProductData',
    },
    handle: product.handle,
    id: product.id,
  };
};

export const formatProductsIntoItem = (products) => {
  const formated = products?.map((el) => formatProductForNosto(el));
  return formated;
};

export const fetchProductItemsFromHandles = async (handles) => {
  try {
    if (!Array.isArray(handles) || !handles?.length) return [];
    const productPromises = handles.map(fetchProductFromHandle);
    const products = await Promise.all(productPromises);

    return formatProductsIntoItem(products);
  } catch (error) {
    console.log(error.message);
    return [];
  }
};
