import { useSettings } from '@backpackjs/storefront';

import { EmailSignup } from './EmailSignup';
import { FooterLegal } from './FooterLegal';
import { FooterMenu } from './FooterMenu';
import { FooterSocial } from './FooterSocial';
import { FooterApps } from './FooterApps';
import { FooterCountrySelector } from './FooterCountrySelector';
import { Link } from '../Link';
import { Svg } from '../Svg';

export function Footer() {
  const settings = useSettings();
  const { bgColor, textColor } = { ...settings?.footer };

  return (
    <footer
      className="p-footer-mob lg:p-footer-desk"
      style={{ backgroundColor: bgColor, color: textColor }}
      id="site-footer"
    >
      <div className="mx-auto grid max-w-[1800px] grid-cols-1 lg:grid-cols-[1fr_auto] lg:gap-x-5">
        <div className="order-1 col-span-1 w-full lg:order-2">
          <FooterMenu />
        </div>

        <div className="order-2 w-full lg:order-3">
          <EmailSignup />
        </div>

        <div className="order-4 col-span-1 w-full lg:col-span-2 lg:pt-[132px] xl:col-span-3">
          <div className="flex flex-col-reverse items-center gap-6 px-4 pb-8 lg:flex-row lg:p-0">
            <FooterApps />
            <FooterSocial />
            <FooterCountrySelector
              placeholder="Select your country"
              name="footer country selector"
            />

            <Svg
              className="h-10 w-[110px] text-white lg:hidden"
              src="/svgs/bylt_logo_white_new.svg#bylt_logo_white"
              title="BYLT Basics logo"
              viewBox="0 0 72.333 22"
            />
          </div>
        </div>

        <div className="order-5 col-span-1 w-full lg:col-span-2 lg:pt-12 xl:col-span-3">
          <FooterLegal />
        </div>
      </div>
    </footer>
  );
}

Footer.displayName = 'Footer';
