import { Fragment } from 'react';
import { Image, Link } from '../../snippets';

export function ImageTile({ aspectRatio, content, item, mobileCols }) {
  const firstLink = item.buttons?.[0]?.link;
  const secondLink = item.buttons?.[1]?.link;
  const primaryButtonStyle = `${content?.primaryButtonStyle} max-xl:min-w-full max-xl:w-full`;
  const secondaryButtonStyle = `${content?.secondaryButtonStyle} w-full`;
  const headingStyles = item?.headingFontSize ? item?.headingFontSize : 'text-title-h3'

  return (
    <Link
      aria-label={firstLink?.text}
      className="w-full"
      href={content?.clickableImage ? firstLink?.url : ''}
      newTab={firstLink?.newTab}
      type={firstLink?.type}
    >
      {!content?.buttonsInside && content.outsidePosition === 'top' && (
        <div
          className={`flex ${content?.contentPosition} ${
            mobileCols === 'max-md:!grid-cols-2'
              ? 'mb-[14px] md:mb-[20px]'
              : 'mb-5'
          }`}
        >
          {item.heading && (
            <h3 className="text-body h5 text-left font-bold text-primary">
              {item.heading}
            </h3>
          )}
        </div>
      )}

      <div className={`relative bg-offWhite ${aspectRatio}`}>
        {item.image?.src && (
          <Image
            alt={item.alt}
            className={`${item.position}`}
            fill
            sizes="(min-width: 1024px) 30vw, (min-width: 768px) 50vw, 100vw"
            src={item.image.src}
          />
        )}
      </div>

      <div
        className={`pointer-events-none absolute inset-0 flex h-full w-full p-3 text-center lg:p-6 lg:px-8 lg:py-6 lg:pb-5 ${
          content?.darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : ''
        } ${content?.contentPosition}`}
      >
        {/* Desktop Layout */}
        <div className="pointer-events-auto flex flex-col gap-3 lg:gap-5">
          {item.heading && content?.buttonsInside && (
            <h3 className={`${headingStyles} normal-case max-md:h5 text-left text-white`}>
              {item.heading}
            </h3>
          )}

          {!content?.hideButtons && (firstLink?.text || secondLink?.text) && (
            <div className="flex flex-wrap justify-start gap-3">
              {item.buttons.slice(0, 2).map(({ link }, index) => {
                // hide second button if clickable image is enabled
                if (content?.clickableImage && index > 0) return null;

                return link?.text ? (
                  <Fragment key={index}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      aria-label={link.text}
                      className={`${
                        index === 1 ? primaryButtonStyle : secondaryButtonStyle
                      }  md:min-w-min md:max-w-[13rem] lg:min-w-[12rem] xl:min-w-[256px] h-14`}
                      href={!content?.clickableImage ? link.url : ''}
                      newTab={link.newTab}
                      type={link.type}
                    >
                      {link.text}
                    </Link>
                  </Fragment>
                ) : null;
              })}
            </div>
          )}
        </div>
      </div>

      {!content?.buttonsInside && content.outsidePosition === 'bottom' && (
        <div
          className={`flex ${content?.contentPosition} ${
            mobileCols === 'max-md:!grid-cols-2'
              ? 'mt-[14px] md:mt-[20px]'
              : 'mt-5'
          }`}
        >
          {item.heading && (
            <h3 className="text-body h5 text-left font-bold text-primary">
              {item.heading}
            </h3>
          )}
        </div>
      )}
    </Link>
  );
}

ImageTile.displayName = 'ImageTile';
