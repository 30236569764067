import { Image } from '../Image';
import { Link } from '../Link';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';

export function MenuDrawer({
  handleMenuDrawerClose,
  handleMenuDrawerStayOpen,
  handleMenuHoverOut,
  menuDrawerContent,
}) {
  const { imageLinks, links, mainLink, centerlinks, deskBottomLinks } = {
    ...menuDrawerContent,
  };
  const hasContent = imageLinks?.length > 0 || links?.length > 0;

  const selectedBgColor = deskBottomLinks?.isCustomColor
    ? deskBottomLinks?.customColor
    : deskBottomLinks?.bgColor;

  const hasBottomRightLink  = !!(deskBottomLinks?.rightLink && deskBottomLinks?.rightLink?.text)

  const gapClasses = !!(deskBottomLinks?.links && deskBottomLinks?.links?.length > 4) ? 'gap-[10px] xl:gap-[70px]' : 'gap-[70px]'

  const {
    state: { loopSelected }
  } = useGlobalContext();
  
  return (
    <div
      className={`absolute left-0 top-[100%] hidden w-full origin-top border-border bg-background transition duration-200 lg:block ${
        hasContent ? 'scale-y-100 border-b' : 'scale-y-0'
      }`}
      onMouseEnter={handleMenuDrawerStayOpen}
      onMouseLeave={handleMenuHoverOut}
    >
      {hasContent && (
        <>
          <div className="md:p-menu-drawer mx-[auto] grid max-w-full grid-cols-[12rem_1fr_1fr] gap-[55px] px-5 py-[30px]">
            <div>
              <ul className="flex flex-col gap-3.5 border-r-[1px] border-solid border-gray pr-[55px]">
                {links?.map(({ link }, index) => {
                  return (
                    <li key={index}>
                      <Link
                        aria-hidden={!hasContent}
                        aria-label={link?.text}
                        className={`color-primary hover-text-underline text-sm font-bold uppercase ${
                          index != 0 ? 'mt-3.5' : ''
                        }`}
                        href={link?.url}
                        newTab={link?.newTab}
                        onClick={handleMenuDrawerClose}
                        tabIndex={hasContent ? '0' : '-1'}
                        type={link?.type}
                      >
                        {link?.text}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="flex flex-row">
              {centerlinks?.map((group, gIndex) => {
                return (
                  <div
                    key={gIndex}
                    className={`${gIndex != 0 ? 'pl-[55px]' : ''}`}
                  >
                    {group.subGroups.map((subGroup, sgIndex) => {
                      return (
                        <div key={sgIndex}>
                          <div>
                            <Link
                              aria-label={subGroup?.mainLink?.text}
                              className="color-primary hover-text-underline text-sm"
                              href={subGroup?.mainLink?.url}
                              newTab={subGroup?.mainLink?.newTab}
                              onClick={handleMenuDrawerClose}
                              type={subGroup?.mainLink?.type}
                            >
                              <span className="color-primary block text-sm font-bold uppercase">
                                {subGroup?.mainLink?.text}
                              </span>
                            </Link>
                          </div>

                          <ul>
                            {subGroup?.secondaryLinks?.map(
                              (sgLink, sglIndex) => {
                                //LOOP HERE
                                const isHidden = !!(sgLink?.url?.includes('bylt-gift-card') && loopSelected)
                                return !isHidden && (
                                  <li
                                    className="mt-3.5 leading-none first:mt-[22px]"
                                    key={sglIndex}
                                  >
                                    <Link
                                      aria-label={sgLink?.text}
                                      className="hover-text-underline whitespace-nowrap text-sm text-[#444447]"
                                      href={sgLink?.url}
                                      newTab={sgLink?.newTab}
                                      onClick={handleMenuDrawerClose}
                                      type={sgLink?.type}
                                    >
                                      {sgLink?.text}
                                    </Link>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>

            <ul className="grid gap-5">
              {imageLinks?.map(
                ({ alt, caption, subCaption, image, link }, index) => {
                  return (
                    <li key={index}>
                      <div className="flex-end relative flex justify-end">
                        <div className="relative aspect-[4/3] h-full max-h-[270px] w-full max-w-[430px] overflow-hidden bg-offWhite">
                          <Link
                            aria-hidden={!hasContent}
                            aria-label={caption}
                            href={link?.url}
                            newTab={link?.newTab}
                            onClick={handleMenuDrawerClose}
                            tabIndex={hasContent ? '0' : '-1'}
                            type={link?.type}
                            className="block"
                          >
                            {image?.src && (
                              <Image
                                alt={alt}
                                className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                                crop="center"
                                height={Math.floor(400 / (16 / 9))}
                                src={image.src}
                                width="400"
                              />
                            )}

                            <p className="absolute bottom-[58px] flex w-full max-w-[430px] pl-[21px] text-sm font-medium text-white">
                              {subCaption}
                            </p>
                            <p className="... absolute bottom-[17px] flex w-full max-w-[430px] overflow-hidden text-ellipsis whitespace-nowrap pl-[21px] text-3xl font-bold text-white">
                              {caption}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </div>

          {deskBottomLinks && deskBottomLinks?.heading && (
            <div
              className="md:p-menu-drawer mb-8 grid grid-cols-[12rem_1fr] px-5 md:!py-4"
              style={{ backgroundColor: selectedBgColor }}
            >
              <div className="flex border-r border-gray">
                <p
                  className="text-sm font-bold uppercase"
                  style={{ color: deskBottomLinks?.color }}
                >
                  {deskBottomLinks?.heading}
                </p>
              </div>

              <div className="grid grid-cols-[1fr_1fr] pl-[55px]">
                <div className={`flex ${gapClasses} [&>*:nth-child(1)]:pl-0`}>
                  {deskBottomLinks?.links?.map((link) => {
                    return (
                      <Link
                        aria-label={link?.text}
                        className="px-4"
                        href={link?.url}
                        newTab={link?.newTab}
                        onClick={handleMenuDrawerClose}
                        type={link?.type}
                      >
                        <span
                          className="hover-text-underline text-sm cursor-pointer"
                          style={{ color: deskBottomLinks?.color }}
                        >
                          {link?.text}
                        </span>
                      </Link>
                    );
                  })}
                </div>

                {hasBottomRightLink && (
                  <div className="text-right">
                    <Link
                      aria-label={deskBottomLinks?.rightLink?.text}
                      href={deskBottomLinks?.rightLink?.url}
                      newTab={deskBottomLinks?.rightLink?.newTab}
                      onClick={handleMenuDrawerClose}
                      type={deskBottomLinks?.rightLink?.type}
                    >
                      <div className="hover-text-underline inline-flex gap-2 cursor-pointer">
                        <span
                          className="text-sm font-bold"
                          style={{ color: deskBottomLinks?.color }}
                        >
                          {deskBottomLinks?.rightLink?.text}
                        </span>

                        <Svg
                          className="w-4"
                          style={{ color: deskBottomLinks?.color }}
                          src="/svgs/arrow-right.svg#arrow-right"
                          title="right"
                          viewBox="0 0 24 24"
                        />
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

MenuDrawer.displayName = 'MenuDrawer';
